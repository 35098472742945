import React from 'react';
import CheckBox from '../../../../ui/CheckBox';
import TaskCompleteIndicator from '../TaskCompleteIndicator';
import { IconButton, Menu, MenuItem } from '@mui/material';
import RoundButton from '../../../../ui/RoundButton';
import { ReactComponent as DeleteIcon } from '../../../../assets/vector/delete.svg';
import { ReactComponent as NoteIcon } from '../../../../assets/vector/note.svg';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import SubinfoDoctorSpan from '../../../../ui/SubinfoDoctorSpan';

interface ITaskDetailItemProps {
  id: number;
  firstName: string;
  lastName: string;
  index: number;
  middleName: string;
  note: string | null;
  specialty: string;
  onDeleteFromTask?: (id: number, index: number) => void;
  facility?: string;
  meetupDone: boolean;
  taskType?: TaskVariantTypes;
}

const TaskDetailItem = (props: ITaskDetailItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement | HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteFromTask = () => {
    props.onDeleteFromTask && props.onDeleteFromTask(props.id, props.index);
  };
  return (
    <div className="task-detail-item">
      <TaskCompleteIndicator
        classNames={['task-detail-item__checkbox']}
        completed={props.meetupDone}
        type={props.taskType}
      />
      <div className="task-detail-item__info">
        <p className="task-detail-item__fullname">
          {props.lastName} {props.firstName} {props.middleName}
        </p>
        <div className="task-detail-item__subinfo">
          <SubinfoDoctorSpan variant="lpu" value={props.facility} />
          <SubinfoDoctorSpan variant="specialty" value={props.specialty} />
          {/* <p className="task-detail-item__user-info">
            {props.specialty}
            {props.specialty && props.facility && ', '}
            {props.facility && props.facility}
          </p> */}
          {/* <SubinfoDoctorSpan variant="specailty" value={props.specialty} /> */}

          {props.note && (
            <div className="task-detail-item__note-wrapper">
              <NoteIcon className="task-detail-item__note-icon" />
              <p className="task-detail-item__note">{props.note}</p>
            </div>
          )}
        </div>
        <RoundButton
          onClick={handleOpenMenu}
          content={<DeleteIcon />}
          classNames={['task-detail-item__delete-btn']}
        />
      </div>
      <Menu open={isMenuOpened} onClose={handleCloseMenu} anchorEl={anchorEl}>
        <p className="task-detail-item__delete-title">Удалить из задания?</p>
        <MenuItem
          className="task-detail-item__delete-menu-btn"
          onClick={handleDeleteFromTask}
        >
          Удалить
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TaskDetailItem;

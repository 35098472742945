import React from 'react';
// import './styles.scss';
import { ReactComponent as SignInLogo } from '../../../../assets/vector/sign-in-logo.svg';
import Input from '../../../../ui/Input';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { SignInController } from '../../sign-in.controller';
import { UserController } from '../../../../controllers/user.controller';

export const SignInForm = () => {
  const dispatch = useAppDispatch();
  const signIn = useAppSelector((state) => state.signIn);
  const controller = new SignInController(dispatch);
  const userController = new UserController(dispatch);

  const handleSignIn = () => {
    userController.login(signIn.email, signIn.password);
  };
  return (
    <>
      <div className="sign-in-form">
        <SignInLogo className="sign-in-form__logo" />
        <h2 className="sign-in-form__title">Вход</h2>
        <Input
          onInput={controller.onEmailChange}
          value={signIn.email}
          classNames={['sign-in-form__login-input']}
          placeholder="Логин"
        />
        <Input
          onInput={controller.onPasswordChange}
          value={signIn.password}
          classNames={['sign-in-form__password-input']}
          placeholder="Пароль"
          type="password"
        />
        <Button className="sign-in-form__sign-in" onClick={handleSignIn}>
          Войти
        </Button>
      </div>

      <p className="sign-in-form__error">{signIn.error}</p>
    </>
  );
};

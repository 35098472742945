import { AppDispatch, RootState, store } from '../../app.store';
import { TaskService } from '../../services/tasks.service';
import { TaskStatusTypes } from '../../types/tasks.types';
import {
  setLimit,
  setLoading,
  setOffset,
  setPage,
  setTabValue,
  setTasks,
  setTotal,
} from './store/tasks-list.slice';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';

export class TaskListController {
  dispatch: AppDispatch;
  getState: () => RootState;
  taskService: TaskService = new TaskService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  getTasks = async () => {
    this.dispatch(setLoading(true));
    const state = this.getState().tasksList;
    const data = await this.taskService.getTaskList(
      state.limit,
      state.offset,
      state.tabValue
    );
    if (data) {
      this.dispatch(setTasks(data.data));
      this.dispatch(setTotal(data.total));
    }
    this.dispatch(setLoading(false));
  };

  onPageChange = async (_: any, page: number) => {
    const state = this.getState().tasksList;
    this.dispatch(setPage(page));
    this.dispatch(setOffset((page - 1) * state.limit));
    this.getTasks();
  };

  onTabChange = (_: any, value: TaskStatusTypes) => {
    this.dispatch(setTabValue(value));
    this.dispatch(setPage(1));
    this.dispatch(setOffset(0));
    this.getTasks();
  };
  setLimit = (limit: number) => {
    this.dispatch(setLimit(limit));
  };

  clearList = async () => {};

  onMenuItemClick = async (status: TaskStatusTypes, id: number) => {
    if (!id) return;
    await this.taskService.updateTask(id, { status });
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_TASKS);
  };

  onDeleteTask = async (id: number) => {
    await this.taskService.deleteTask(id);
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_TASKS);
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAddLpuFormState {
  isLoading: boolean;
  name: string;
  locality: string;
  localityId: number;
  foundLocalities: any[];
  foundDistricts: any[];
  street: string;
  district: string;
  errors: {
    location: string;
  };
}

const initialState: IAddLpuFormState = {
  isLoading: false,
  name: '',
  locality: '',
  district: '',
  street: '',
  localityId: 0,
  foundLocalities: [],
  foundDistricts: [],
  errors: {
    location: '',
  },
};

export const addLpuFormSlice = createSlice({
  name: 'add-lpu-form',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setLocality: (state, action: PayloadAction<string>) => {
      state.locality = action.payload;
    },
    setLocalityId: (state, action: PayloadAction<number>) => {
      state.localityId = action.payload;
    },
    setFoundLocalities: (state, action: PayloadAction<any[]>) => {
      state.foundLocalities = action.payload;
    },
    setFoundDistricts: (state, action: PayloadAction<any[]>) => {
      state.foundDistricts = action.payload;
    },
    setDistrict: (state, action: PayloadAction<string>) => {
      state.district = action.payload;
    },
    setStreet: (state, action: PayloadAction<string>) => {
      state.street = action.payload;
    },
    setLocationError: (state, action: PayloadAction<string>) => {
      state.errors.location = action.payload;
    },
    clearStore: (state) => {
      for (const [key] of Object.entries(initialState)) {
        state[key as keyof object] = initialState[key as keyof object];
      }
    },
  },
});

export const {
  setLoading,
  setName,
  setLocality,
  setFoundLocalities,
  setLocationError,
  setLocalityId,
  clearStore,
  setDistrict,
  setFoundDistricts,
  setStreet,
} = addLpuFormSlice.actions;

export const addLpuFormReducer = addLpuFormSlice.reducer;

import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import RoundedLabel from '../../ui/RoundedLabel';
import ArrowIcon from '@mui/icons-material/ArrowDropDown';
import { ITaskExecutor } from '../../interfaces/tasks.interfaces';
import { classNamesParser } from '../../helpers/classNamesParser';
import { UserRolesTypes } from '../../types/users.types';

interface IExecutorSelectProps {
  executors?: ITaskExecutor[];
  value?: string[];
  onSelect?: (event: SelectChangeEvent<string[]>) => void;
  classNames?: string[];
  showRoles?: UserRolesTypes[];
}

const ExecutorsSelect = (props: IExecutorSelectProps) => {
  //const value = props?.value?.map((executor) => String(executor.id));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const defaultRoles: UserRolesTypes[] = props.showRoles?.length
    ? props.showRoles
    : ['medical_agent'];

  const value: string[] | undefined | '' = props.value?.map((item) =>
    String(item)
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classNamesParser('executors-select', props.classNames)}>
      <Select
        className="executors-select__select"
        open={open}
        multiple
        onClose={handleClose}
        MenuProps={{
          anchorEl: anchorEl,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        }}
        value={value}
        label="Age"
        style={{ display: 'none' }}
        onChange={props.onSelect}
      >
        {!!props.executors?.length &&
          props.executors.map((executor) => {
            const isShow = defaultRoles.includes(executor.role);

            return isShow ? (
              <MenuItem value={executor.id} key={executor.id}>
                <RoundedLabel fullname={executor.fullName || ''} />{' '}
                {executor.fullName}
              </MenuItem>
            ) : null;
          })}
      </Select>
      <Button className="executors-select__btn" onClick={handleOpen}>
        <div className="executors-select__executors-wrapper">
          {value?.length === 0 ? (
            <p className="executors-select__placeholder">Исполнители</p>
          ) : (
            !!props.value?.length &&
            props.value.map((executorId: string) => {
              const foundExecutor = props?.executors?.find(
                (executor) => String(executor.id) === executorId
              );

              if (foundExecutor) {
                return (
                  <RoundedLabel
                    key={foundExecutor.id}
                    fullname={foundExecutor.fullName || ''}
                    classNames={['executors-select__executor']}
                  />
                );
              }
            })
          )}
        </div>

        <div className="executors-select__choose-label">
          Выбрать
          <ArrowIcon />
        </div>
      </Button>
    </div>
  );
};

export default ExecutorsSelect;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USERS_LIST_LIMIT } from '../../../constants/app.constants';
import { IUserItem } from '../index.interfaces';
import { UserActiveTypes } from '../../../types/users.types';

interface IUsersListState {
  isLoading: boolean;
  tabValue: UserActiveTypes;
  isAddModalOpen: boolean;
  isEditModalOpen: boolean;
  users: IUserItem[];
  currentUser?: IUserItem;
  isUserLoading: boolean;

  limit: number;
  offset: number;
  haveMore: boolean;
}

const initialState: IUsersListState = {
  isLoading: false,
  tabValue: 'active',
  isAddModalOpen: false,
  isEditModalOpen: false,
  limit: USERS_LIST_LIMIT,
  offset: 0,
  isUserLoading: false,
  users: [],
  haveMore: true,
};

export const usersListSlice = createSlice({
  name: 'users-list',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTabValue: (state, action: PayloadAction<UserActiveTypes>) => {
      state.tabValue = action.payload;
    },

    setUsers: (state, action: PayloadAction<IUserItem[]>) => {
      state.users = action.payload;
    },
    pushUsers: (state, action: PayloadAction<IUserItem[]>) => {
      state.users.push(...action.payload);
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },

    setCurrentUser: (state, action: PayloadAction<IUserItem>) => {
      state.currentUser = action.payload;
    },
    setCurrentUserLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserLoading = action.payload;
    },

    setIsEditModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditModalOpen = action.payload;
    },
    setIsAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddModalOpen = action.payload;
    },
    deleteUserById: (state, action: PayloadAction<number>) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    updateUser: (state, action: PayloadAction<IUserItem>) => {
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
  },
});

export const {
  setLoading,
  setTabValue,
  setUsers,
  setIsAddModalOpen,
  setIsEditModalOpen,
  setHaveMore,
  setOffset,
  deleteUserById,
  pushUsers,
  setCurrentUser,
  updateUser,
  setCurrentUserLoading,
} = usersListSlice.actions;

export const usersListReducer = usersListSlice.reducer;

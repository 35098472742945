import { CircularProgress } from '@mui/material';
import React from 'react';

const FullscreenSpinner = () => {
  return (
    <div className="fullscreen-spinner">
      <CircularProgress
        className="fullscreen-spinner__spinner"
        disableShrink
        size={32}
        thickness={4}
      />
    </div>
  );
};

export default FullscreenSpinner;

import React, { useEffect } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, SelectChangeEvent } from '@mui/material';
import AppSelect from '../../../../ui/AppSelect';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import ExecutorsSelect from '../../../../components/ExecutorsSelect';
import { ITaskExecutor } from '../../../../interfaces/tasks.interfaces';
import { ReferenceTableController } from '../../reference-table.controller';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  MEETUP_TYPE_VALUES,
  SHOW_CITIES_TASK_COUNT,
  SHOW_LPU_TASK_COUNT,
  SHOW_SPECIALITIES_TASK_COUNT,
} from '../../constants';
import EditableNote from '../../../../components/EditableNote';
import { setTaskType } from '../../store/reference-table.slice';
import moment from 'moment';
import AddDoctorsTasksSkeleton from '../AddDoctorsTasksSkeleton';

interface IAddDoctorsTasksModalProps {
  classNames?: string[];
  onCloseModal?: () => void;
  handleCreateClick?: () => void;
  handleMeetupChange?: (event: SelectChangeEvent) => void;
  taskTypeValue?: string;
  executors?: ITaskExecutor[];
  selectedExecutors?: ITaskExecutor[];
  handleExecutorChange?: (event: SelectChangeEvent<string[]>) => void;
}

const meetupsOptions: IAppSelectOption[] = [
  { id: 1, value: MEETUP_TYPE_VALUES.MEETUP, text: MEETUP_TYPE_VALUES.MEETUP },
  {
    id: 2,
    value: MEETUP_TYPE_VALUES.ENVELOPE,
    text: MEETUP_TYPE_VALUES.ENVELOPE,
  },
];

const AddDoctorsTasksModal = (props: IAddDoctorsTasksModalProps) => {
  const dispatch = useAppDispatch();
  const controller = new ReferenceTableController(dispatch);
  const referenceTable = useAppSelector((state) => state.referenceTable);

  const fetchData = async () => {
    controller.fetchExecutors();
  };

  useEffect(() => {
    dispatch(setTaskType(MEETUP_TYPE_VALUES.MEETUP));
    fetchData();
  }, []);

  return (
    <div
      className={classNamesParser('add-doctors-tasks-modal', props.classNames)}
    >
      <div className="add-doctors-tasks-modal__heading">
        <h3 className="add-doctors-tasks-modal__title">Новое задание</h3>
        <RoundButton
          classNames={['add-doctors-tasks-modal__close-modal']}
          onClick={props.onCloseModal}
          content={<CloseIcon />}
        />
      </div>
      {referenceTable.isTasksCreating ? (
        <AddDoctorsTasksSkeleton />
      ) : (
        <div className="add-doctors-tasks-modal__form">
          <AppSelect
            classNames={['add-doctors-tasks-modal__form-input']}
            options={meetupsOptions}
            handleChange={props.handleMeetupChange}
            value={props.taskTypeValue}
          />
          <ExecutorsSelect
            classNames={['add-doctors-tasks-modal__form-input']}
            executors={props.executors}
            //@ts-ignore
            value={props.selectedExecutors as string[]}
            onSelect={props.handleExecutorChange}
          />
          <EditableNote
            isEditing={referenceTable.isNoteEdit}
            classNames={['add-doctors-tasks-modal__form-input']}
            onNoteClick={controller.handleNoteClick}
            onConfirm={controller.handleAcceptNoteClick}
            onCancelEdit={controller.handleCancelNoteClick}
            noteInput={referenceTable.taskProps.remark}
            onNoteInputChange={controller.handleNoteChange}
            note={referenceTable.taskProps.remark}
            placeholder="Добавьте описание"
          />
          <div className="add-doctors-tasks-modal__summary">
            <div className="add-doctors-tasks-modal__doctors-count-container">
              <span className="add-doctors-tasks-modal__summary-text">
                Врачей в задании:&nbsp;
              </span>
              <span className="add-doctors-tasks-modal__summary-value">
                {referenceTable.total}
              </span>
            </div>
            <h4 className="add-doctors-tasks-modal__summary-filter-title">
              Критерии выборки
            </h4>
            {!!referenceTable.selectedSpecialities.length && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  Специальность:&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  {!!referenceTable.selectedSpecialities.length &&
                    referenceTable.selectedSpecialities.map(
                      (speciality, i, arr) => {
                        const isLast =
                          arr.length > SHOW_SPECIALITIES_TASK_COUNT
                            ? i === SHOW_SPECIALITIES_TASK_COUNT - 1
                            : i === arr.length - 1;
                        const showMessage = i >= SHOW_SPECIALITIES_TASK_COUNT;
                        const message = ` ,+ еще ${
                          arr.length - SHOW_SPECIALITIES_TASK_COUNT
                        }`;

                        if (i > SHOW_SPECIALITIES_TASK_COUNT) return;

                        return showMessage
                          ? message
                          : `${speciality.title}${isLast ? '' : ', '}`;
                      }
                    )}
                </span>
              </div>
            )}
            {!!referenceTable.selectedLpu.length && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  ЛПУ:&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  {!!referenceTable.selectedLpu.length &&
                    referenceTable.selectedLpu.map((lpu, i, arr) => {
                      const isLast =
                        arr.length > SHOW_LPU_TASK_COUNT
                          ? i === SHOW_LPU_TASK_COUNT - 1
                          : i === arr.length - 1;
                      const showMessage = i >= SHOW_LPU_TASK_COUNT;
                      const message = ` ,+ еще ${
                        arr.length - SHOW_LPU_TASK_COUNT
                      }`;

                      if (i > SHOW_LPU_TASK_COUNT) return;

                      return showMessage
                        ? message
                        : `${lpu.title}${isLast ? '' : ', '}`;
                    })}
                </span>
              </div>
            )}
            {!!referenceTable.filters.lastMeetupDate && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  Дата последней встречи (ранее чем):&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  {moment(referenceTable.filters.lastMeetupDate).format(
                    'DD MMMM YYYY'
                  )}
                </span>
              </div>
            )}

            {!!referenceTable.searchedValue && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  Поиск:&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  {referenceTable.searchedValue}
                </span>
              </div>
            )}

            {referenceTable.filters.appAvailability !== null && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  Наличие мобильного приложения:&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  {referenceTable.filters.appAvailability ? 'Да' : 'Нет'}
                </span>
              </div>
            )}

            {!!referenceTable.filters.address?.length && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  Города:&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  {referenceTable.filters.address.map((address, i, arr) => {
                    const isLast =
                      arr.length > SHOW_CITIES_TASK_COUNT
                        ? i === SHOW_CITIES_TASK_COUNT - 1
                        : i === arr.length - 1;
                    const showMessage = i >= SHOW_CITIES_TASK_COUNT;
                    const message = ` ,+ еще ${
                      arr.length - SHOW_CITIES_TASK_COUNT
                    }`;

                    if (i > SHOW_CITIES_TASK_COUNT) return;

                    return showMessage
                      ? message
                      : `${address.placeName}${isLast ? '' : ', '}`;
                  })}
                </span>
              </div>
            )}
            {referenceTable.filters.isArchived && (
              <div className="add-doctors-tasks-modal__summary-filter">
                <span className="add-doctors-tasks-modal__summary-text">
                  Активность пользователей:&nbsp;
                </span>
                <span className="add-doctors-tasks-modal__summary-value">
                  Не активные
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="add-doctors-tasks-modal__buttons">
        <Button
          className="add-doctors-tasks-modal__cancel"
          onClick={props.onCloseModal}
        >
          Отмена
        </Button>
        <Button
          className="add-doctors-tasks-modal__create"
          onClick={props.handleCreateClick}
          disabled={referenceTable.isTasksCreating}
        >
          Создать
        </Button>
      </div>
    </div>
  );
};

export default AddDoctorsTasksModal;

import React from 'react';
import { getInitialsFromFullName } from '../../helpers/getInitialsFromFullName';
import { classNamesParser } from '../../helpers/classNamesParser';
import { Tooltip } from '@mui/material';

interface IRoundedLabelProps {
  fullname: string;
  classNames?: string[];
}
const RoundedLabel = (props: IRoundedLabelProps) => {
  const inititals = getInitialsFromFullName(props.fullname);
  return (
    <Tooltip title={props.fullname}>
      <div className={classNamesParser('rounded-label', props.classNames)}>
        {inititals}
      </div>
    </Tooltip>
  );
};

export default RoundedLabel;

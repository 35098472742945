import { REPORTS } from '../api/api';
import axios from '../axios/axios';
import { MeetupStatusTypes } from '../types/tasks.types';

export class ReportsService {
  getMeetupsReport = async (
    meetupStatus: MeetupStatusTypes,
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number
  ) => {
    let result = null;
    try {
      const response = await axios.get(`${REPORTS.GET_MEETUP_REPORT}`, {
        params: {
          meetupStatus,
          monthEnd,
          monthStart,
          yearEnd,
          yearStart,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getDoctorDynamicReport = async (
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number,
    limit?: number | null,
    offset?: number | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${REPORTS.GET_DOCTORS_DYNAMIC_REPORT}`,
        {
          params: {
            monthEnd,
            monthStart,
            yearEnd,
            yearStart,
            limit,
            offset,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getLpuDynamicReport = async (
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number,
    limit?: number | null,
    offset?: number | null
  ) => {
    let result = null;

    try {
      const response = await axios.get(`${REPORTS.GET_LPU_DYNAMIC_REPORT}`, {
        params: {
          monthEnd,
          monthStart,
          yearEnd,
          yearStart,
          limit,
          offset,
        },
      });

      if (response.status === 200) {
        result = response.data;
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  };

  getCityDynamicReport = async (
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number,
    limit?: number | null,
    offset?: number | null
  ) => {
    let result = null;

    try {
      const response = await axios.get(`${REPORTS.GET_CITY_DYNAMIC_REPORT}`, {
        params: {
          monthEnd,
          monthStart,
          yearEnd,
          yearStart,
          limit,
          offset,
        },
      });

      if (response.status === 200) {
        result = response.data;
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  };

  getHistoryReport = async () => {};
}

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import {
  addFiles,
  removeFile,
  removeImage,
  setComment,
  setCommentInput,
  setDoctorID,
  setDoctorInfo,
  setError,
  setFailComment,
  setFeedbackType,
  setImages,
  setIsCommentEditModalVisible,
  setIsFeedbackChecked,
  setIsSuccessMeetup,
  setLoading,
  setMeetupID,
  setRemovedImages,
  setScore,
  setTaskID,
} from './store/meetup-result.store';
import {
  MeetupFeedbackTypes,
  MeetupResultMarkTypes,
  MeetupStatusTypes,
} from '../../types/tasks.types';
import { MeetupsService } from '../../services/meetups.service';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { CatalogsService } from '../../services/catalogs.service';
import { SelectChangeEvent } from '@mui/material';
import {
  ENVELOPE_VALUE_NAMES,
  ERROR_CREATE_MEETUP,
  ERROR_UPDATE_MEETUP,
  FEEDBACK_ERROR_TEXT,
  MEETUP_VALUE_NAMES,
} from './constants';
import { showAppSnackBar } from '../../store/app.slice';
import { ResponseImage } from '../../types/images.types';
import { getPhotoFullURL } from '../../helpers/getPhotoFullURL';
import { ICompleteMeetupBody } from '../../interfaces/api.tasks.interfaces';
import { imageService } from '../../services/image.service';

export class MeetupResultController {
  dispatch: AppDispatch;
  getState: () => RootState;

  meetupService: MeetupsService = new MeetupsService();
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  setTaskId = (id: number) => {
    this.dispatch(setTaskID(id));
  };
  setDoctorId = (id: number) => {
    this.dispatch(setDoctorID(id));
  };
  onScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setScore(e.target.value as MeetupResultMarkTypes));
  };

  onFeedbackCheck = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setIsFeedbackChecked(e.target.checked));
  };

  onSuccessMeetupChange = (e: SelectChangeEvent) => {
    this.dispatch(setIsSuccessMeetup(e.target.value));
  };

  openCommentEditModal = () => {
    this.dispatch(setIsCommentEditModalVisible(true));
  };

  closeCommentEditModal = () => {
    this.dispatch(setIsCommentEditModalVisible(false));
  };

  onCommentSave = () => {
    const state = this.getState().meetupResult;
    this.dispatch(setComment(state.commentInput));
    this.closeCommentEditModal();
  };

  onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setCommentInput(e.target.value));
  };

  onFeedbackTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setFeedbackType(e.target.value as MeetupFeedbackTypes));
  };

  onFailureCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setFailComment(e.target.value));
  };

  onConfirmMeetup = async (
    files: File[],
    callback?: () => void,
    isUnscheduled?: boolean
  ) => {
    const state = this.getState().meetupResult;
    if (this.haveErrors()) return;
    this.dispatch(setLoading(true));

    if (state.meetupID !== null) {
      await this.onUpdateMeetup(callback, files);
      this.dispatch(setLoading(false));
      return;
    }
    if (isUnscheduled) {
      await this.createUnscheduledMeetup(files, callback);
      this.dispatch(setLoading(false));
      return;
    }
    await this.onCreateResultMeetup(files, callback);
    this.dispatch(setLoading(false));
  };

  haveErrors = () => {
    const state = this.getState().meetupResult;
    if (!!state.feedbackType && state.isFeedbackChecked && !state.comment) {
      this.dispatch(setError(FEEDBACK_ERROR_TEXT));
      return true;
    }
    return false;
  };

  createUnscheduledMeetup = async (files?: File[], callback?: () => void) => {
    const state = this.getState().meetupResult;

    if (!state.doctorID) return;

    const isSuccess =
      state.isSuccessMeetup === MEETUP_VALUE_NAMES.MEETUP_DONE ||
      state.isSuccessMeetup === ENVELOPE_VALUE_NAMES.ENVELOPE_DONE;

    const body = {
      doctorID: state.doctorID,
      status: (isSuccess ? 'done' : 'failed') as MeetupStatusTypes,
      resultMark: isSuccess && state.type === 'meetup' ? state.score : null,
      resultComment: isSuccess ? state.comment : state.failComment,
      feedbackType:
        isSuccess && state.isFeedbackChecked ? state.feedbackType : null,
      photos: isSuccess && state.isFeedbackChecked ? files : [],
    };

    const formData = this.getFormDataMeetupBody(body);

    const data = await this.meetupService.createUnscheduledMeetup(formData);

    if (data !== null && callback) {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'success',
          text: 'Встреча зафиксирована',
        })
      );
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_DOCTOR_MEETUPS_DONE);
      callback();
    } else {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось зафиксировать встречу',
        })
      );
    }
  };

  onCreateResultMeetup = async (files: File[], callback?: () => void) => {
    const state = this.getState().meetupResult;

    if (!state.taskID || !state.doctorID) return;

    const isSuccess =
      state.isSuccessMeetup === MEETUP_VALUE_NAMES.MEETUP_DONE ||
      state.isSuccessMeetup === ENVELOPE_VALUE_NAMES.ENVELOPE_DONE;

    const body = {
      doctorID: state.doctorID,
      status: (isSuccess ? 'done' : 'failed') as MeetupStatusTypes,
      resultMark: isSuccess && state.type === 'meetup' ? state.score : null,
      resultComment: isSuccess ? state.comment : state.failComment,
      feedbackType:
        isSuccess && state.isFeedbackChecked ? state.feedbackType : null,
      photos: isSuccess && state.isFeedbackChecked ? files : [],
    };

    const formData = this.getFormDataMeetupBody(body);

    const data = await this.meetupService.completeMeetup(
      state.taskID,
      formData
    );

    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_DETAILS);
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_LIST);
      callback && callback();
    } else {
      this.dispatch(setError(ERROR_CREATE_MEETUP));
      return;
    }
  };

  onUpdateMeetup = async (callback?: () => void, files?: File[]) => {
    const state = this.getState().meetupResult;
    if (!state.meetupID || !state.doctorID) return;

    const isSuccess =
      state.isSuccessMeetup === MEETUP_VALUE_NAMES.MEETUP_DONE ||
      state.isSuccessMeetup === ENVELOPE_VALUE_NAMES.ENVELOPE_DONE;

    const comment = state.comment ? state.comment : null;
    const failComment = state.failComment ? state.failComment : null;
    const resultComment = isSuccess ? comment : failComment;

    const body = {
      doctorID: state.doctorID,
      status: (isSuccess ? 'done' : 'failed') as MeetupStatusTypes,
      resultMark: isSuccess ? state.score : null,
      resultComment,
      feedbackType:
        isSuccess && state.isFeedbackChecked ? state.feedbackType : null,
      photos: isSuccess && state.isFeedbackChecked ? files : [],
    };
    const formData = this.getFormDataMeetupBody(body);

    const data = await this.meetupService.updateMeetupResult(
      state.meetupID,
      formData
    );

    if (data === null) {
      this.dispatch(setError(ERROR_UPDATE_MEETUP));
      return;
    }

    if (state.removedImages.length > 0) {
      await this.meetupService.deleteMeetupImage(
        state.meetupID,
        state.removedImages
      );
      const imagesToRevalidate = state.images.filter((image) =>
        state.removedImages.find((id) => image.id === id)
      );
      imagesToRevalidate.forEach((image) => {
        imageService.revalidateImage(image.filename);
      });
    }

    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_DETAILS);
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_LIST);
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_DOCTOR_MEETUPS_DONE);
      callback && callback();
    }
  };

  clearState = () => {
    this.dispatch(setFeedbackType('common'));
    this.dispatch(setScore('good'));
    this.dispatch(setIsSuccessMeetup(''));
    this.dispatch(setIsFeedbackChecked(false));
    this.dispatch(setCommentInput(''));
    this.dispatch(setIsCommentEditModalVisible(false));
    this.dispatch(setFailComment(''));
    this.dispatch(setComment(''));
    this.dispatch(setMeetupID(null));
    this.dispatch(setError(''));
    this.dispatch(setLoading(false));
    this.dispatch(setImages([]));
    this.dispatch(setRemovedImages([]));
  };

  getMeetupResult = async () => {
    const state = this.getState().meetupResult;
    if (!state.doctorID || !state.taskID) return;
    const data = await this.meetupService.getMeetupResult(
      state.taskID,
      state.doctorID
    );
    if (data) {
      const statusValues =
        state.type === 'meetup'
          ? {
              true: MEETUP_VALUE_NAMES.MEETUP_DONE,
              false: MEETUP_VALUE_NAMES.MEETUP_FAIL,
            }
          : {
              true: ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
              false: ENVELOPE_VALUE_NAMES.ENVELOPE_FAIL,
            };

      this.dispatch(setMeetupID(data.id));
      this.dispatch(setComment(data.resultComment));
      this.dispatch(setCommentInput(data.resultComment));
      this.dispatch(
        setIsSuccessMeetup(
          data.status === 'done' ? statusValues.true : statusValues.false
        )
      );
      if (!(data.status === 'done'))
        this.dispatch(setFailComment(data.resultComment));
      this.dispatch(setScore(data.resultMark));
      this.dispatch(setIsFeedbackChecked(data.feedbackType !== null));
      if (data.feedbackType !== null)
        this.dispatch(setFeedbackType(data.feedbackType));
      this.dispatch(
        setImages(
          data.photos.map((photo: ResponseImage) => ({
            id: photo.id,
            filename: getPhotoFullURL(photo.filename),
          }))
        )
      );
    }
  };

  getDoctorInfo = async () => {
    const doctorId = this.getState().meetupResult.doctorID || 0;

    try {
      const result = await this.catalogsService.getDoctorById(doctorId);

      if (result) {
        this.dispatch(setDoctorInfo(result));
      }
    } catch (e) {
      console.error(e);
    }
  };

  deleteMeetupResult = async (callback?: () => void) => {
    this.dispatch(setLoading(true));
    const state = this.getState().meetupResult;
    if (!state.meetupID) return;
    const data = await this.meetupService.deleteMeetupResult(state.meetupID);
    this.dispatch(setLoading(false));
    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_DETAILS);
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_LIST);
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_DOCTOR_MEETUPS_DONE);
      callback && callback();
    }
  };

  getFormDataMeetupBody = (body: ICompleteMeetupBody) => {
    const formData = new FormData();
    for (const [k, v] of Object.entries(body)) {
      if (v === null || undefined) continue;

      if (Array.isArray(v)) {
        for (const item of v) {
          formData.append(`${k}`, item);
        }
      } else {
        formData.append(k, v);
      }
    }

    return formData;
  };

  onRemoveImage = (id: string) => {
    this.dispatch(removeImage(id));
  };
}

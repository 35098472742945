import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { ReactComponent as PhoneIcon } from '../../../../assets/vector/phone.svg';
import RoundButton from '../../../../ui/RoundButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { makeCallPhoneNumber } from '../../../../helpers/makeCallPhoneNumber';

interface IPhoneButtonMobileProps {
  phones?: string[];
  onOpenEditForm?: () => void;
  onAddPhone?: () => void;
}

const PhoneButtonMobile = (props: IPhoneButtonMobileProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const phonesCount = (props.phones && props.phones.length) || 0;
  const phonesContent =
    phonesCount > 0 ? props.phones?.join(', ') : 'Телефон не указан';

  const handlePhoneClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (phonesCount > 1) {
      handleClick(e);
      return;
    }
    if (phonesCount === 0) {
      props.onOpenEditForm && props.onOpenEditForm();
      props.onAddPhone && props.onAddPhone();
      return;
    }

    if (phonesCount === 1) {
      makeCallPhoneNumber(props.phones?.[0] || '');
    }
  };

  const onSelectPhoneNumber = (phone: string) => {
    makeCallPhoneNumber(phone);
    handleClose();
  };
  return (
    <div className="phone-button-mobile">
      <Button
        className="phone-button-mobile__phone-btn"
        onClick={handlePhoneClick}
      >
        <PhoneIcon
          className={`phone-button-mobile__phone-icon ${
            !phonesCount ? 'phone-button-mobile__phone-icon_no-phones' : ''
          }`}
        />
        <span
          className={`phone-button-mobile__phones ${
            !phonesCount ? 'phone-button-mobile__phones_no-phones' : ''
          }`}
        >
          {phonesContent}
        </span>
      </Button>
      <RoundButton
        onClick={props.onOpenEditForm}
        classNames={['phone-button-mobile__edit-phone-btn']}
        content={<EditNoteIcon />}
      />

      <Menu open={open} anchorEl={anchorEl} onClick={handleClose}>
        {props.phones?.map((phone, i) => (
          <MenuItem key={i} onClick={() => onSelectPhoneNumber(phone)}>
            {phone}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PhoneButtonMobile;

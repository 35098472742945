import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITaskExecutor } from '../../../interfaces/tasks.interfaces';
import { TaskVariantTypes } from '../../../types/tasks.types';
interface ICreateTaskFormState {
  type: TaskVariantTypes;
  executors: ITaskExecutor[];
  selectedExecutors: string[];
  doctorsId: number[];
  isEditingNote: boolean;
  note: string;
  noteInput: string;
}

const initialState: ICreateTaskFormState = {
  executors: [],
  type: 'meetup',
  selectedExecutors: [],
  isEditingNote: false,
  noteInput: '',
  note: '',
  doctorsId: [],
};

export const createTaskFormSlice = createSlice({
  name: 'create-task-form',
  initialState,
  reducers: {
    setDoctorsId: (state, action: PayloadAction<number[]>) => {
      state.doctorsId = action.payload;
    },
    setSelectedExecutors: (state, action: PayloadAction<string[]>) => {
      state.selectedExecutors = action.payload;
    },
    setExecutors: (state, action: PayloadAction<ITaskExecutor[]>) => {
      state.executors = action.payload;
    },
    setTaskType: (state, action: PayloadAction<TaskVariantTypes>) => {
      state.type = action.payload;
    },
    setNoteInput: (state, action: PayloadAction<string>) => {
      state.noteInput = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    setIsEditingNote: (state, action: PayloadAction<boolean>) => {
      state.isEditingNote = action.payload;
    },
  },
});

export const {
  setDoctorsId,
  setExecutors,
  setSelectedExecutors,
  setTaskType,
  setIsEditingNote,
  setNote,
  setNoteInput,
} = createTaskFormSlice.actions;
export const createTaskFormReducer = createTaskFormSlice.reducer;

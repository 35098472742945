import { AppDispatch, RootState, store } from '../../app.store';
import {
  setDistrict,
  setIsEdit,
  setName,
  setPlace,
  setStreet,
  setCityId,
  setCityError,
  setFoundCities,
  clearAddress,
  setFoundDistricts,
  setData,
  clearErrors,
} from './store/edit-lpu-form.slice';
import { ChangeEvent } from 'react';
import { CatalogsService } from '../../services/catalogs.service';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { IApiKladrCity } from '../../interfaces/api.catalogs.interfaces';
import { showAppSnackBar } from '../../store/app.slice';

export class EditLpuFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  onEditClick = () => {
    const store = this.getState().editLpuForm;
    this.dispatch(setIsEdit(!store.isEdit));
  };

  handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setName(event.target.value));
  };
  handlePlaceChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPlace(event.target.value));
  };
  handleDistrictChange = (event: ChangeEvent<HTMLInputElement>, data: any) => {
    this.dispatch(setDistrict(data?.title));
  };
  handleStreetChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setStreet(event.target.value));
  };

  clearValues = () => {
    this.dispatch(setName(''));
    this.dispatch(setPlace(''));
    this.dispatch(setDistrict(''));
    this.dispatch(setStreet(''));
  };

  onSaveClick = async () => {
    try {
      const store = this.getState().editLpuForm;
      await this.catalogsService.updateLpuById(store.id, {
        name: store.name,
        address: {
          placeName: store.address.placeName,
          district: store.address.district ? store.address.district : null,
          street: store.address.street ? store.address.street : null,
        },
      });
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      this.dispatch(setIsEdit(false));
    } catch (e) {
      console.error(e);
    }
  };

  findSameLocations = (value: string) => {
    const locations = this.getState().editLpuForm.foundCities;
    const foundLocation = locations.find((item) => item.title === value);

    if (foundLocation) {
      this.dispatch(setCityError(''));
      this.dispatch(setCityId(foundLocation.id));
    } else {
      this.dispatch(setCityError('Выберите из списка'));
    }
  };

  onCityChange = async (_: any, value: any) => {
    this.findSameLocations(value?.title);
    if (value?.title) {
      this.dispatch(clearAddress());
      this.dispatch(setPlace(value.title));
      this.dispatch(setCityId(value.id));
      await this.fetchDistricts();
    } else {
      this.dispatch(setPlace(''));
      this.dispatch(setCityId(0));
    }
  };

  fetchLPUData = async (id?: number) => {
    if (!id) return;
    try {
      const response = await this.catalogsService.getLpuById(id);
      if (response) {
        this.dispatch(setData(response));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCities = async (isFirstLoad?: boolean) => {
    const store = this.getState().editLpuForm;

    try {
      const query = store.address.placeName || '';
      const response = await this.catalogsService.getKladrCities(
        isFirstLoad ? '' : query
      );

      if (response) {
        const formattedResult = response?.result?.map(
          (item: IApiKladrCity) => ({
            id: item.cityID,
            title: item.name,
          })
        );

        this.dispatch(setFoundCities(formattedResult));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchDistricts = async () => {
    const state = this.getState().editLpuForm;
    const cityId = this.getState().editLpuForm.cityId;
    try {
      const response = await this.catalogsService.getKladrDistricts(cityId);
      if (response) {
        const formattedDistricts = response?.data?.map((item: any) => {
          return { title: item, id: Math.random() * 10 };
        });
        this.dispatch(setFoundDistricts(formattedDistricts));
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleCityChange = (event: any) => {
    const value = event.target.value;
    this.findSameLocations(value);
    this.dispatch(setPlace(value));
    this.fetchDistricts();
  };

  fetchMedInstitutionById = async (id: number) => {
    const response = await this.catalogsService.getLpuById(id);
    if (response) {
      this.dispatch(setData(response));
    }
  };

  handleCancelEditClick = async () => {
    const state = this.getState().editLpuForm;
    await this.fetchMedInstitutionById(state.id);
    this.dispatch(setIsEdit(false));
    this.dispatch(clearErrors());
  };

  handleDeactivateLPU = async () => {
    const state = this.getState().editLpuForm;
    const data = await this.catalogsService.updateLpuById(state.id, {
      archived: true,
    });

    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      this.fetchLPUData(state.id);
    } else
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось деактивировать ЛПУ',
        })
      );
  };

  handleActivateLPU = async () => {
    const state = this.getState().editLpuForm;
    const data = await this.catalogsService.updateLpuById(state.id, {
      archived: false,
    });

    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      this.fetchLPUData(state.id);
    } else
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось активировать ЛПУ',
        })
      );
  };

  handleDeleteLPU = async (callback?: () => void) => {
    const state = this.getState().editLpuForm;
    const data = await this.catalogsService.deleteLpuById(state.id);
    if (data) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      callback && callback();
    } else
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось удалить ЛПУ',
        })
      );
  };
}

import React from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import { idText } from 'typescript';
import { getRoleByKey } from '../../../../helpers/getRoleByKey';
import { UserRolesTypes } from '../../../../types/users.types';

interface IUserItemProps {
  id: number;
  classNames?: string[];
  userName: string;
  role: UserRolesTypes;
  onClick?: (id: number) => void;
}

export const UserItem = (props: IUserItemProps) => {
  const handleCick = () => {
    props.onClick && props.onClick(props.id);
  };
  return (
    <div className={classNamesParser('user-item', props.classNames)}>
      <div className="user-item__wrapper" onClick={handleCick}>
        <h3 className="user-item__name">
          {props.userName || 'ФИО не указано'}
        </h3>
        <p className="user-item__role">{getRoleByKey(props.role)}</p>
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ITasksPageState {
  isLoading: boolean;
  detailTaskModal: boolean;
  currentTaskId: number | null;
}

const initialState: ITasksPageState = {
  isLoading: false,
  detailTaskModal: false,
  currentTaskId: null,
};

export const tasksPageSlice = createSlice({
  name: 'tasks-page',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVisibleDetailTaskModal: (state, action: PayloadAction<boolean>) => {
      state.detailTaskModal = action.payload;
    },
    setCurrentTaskId: (state, action: PayloadAction<number | null>) => {
      state.currentTaskId = action.payload;
    },
  },
});

export const { setLoading, setVisibleDetailTaskModal, setCurrentTaskId } =
  tasksPageSlice.actions;
export const tasksPageReducer = tasksPageSlice.reducer;

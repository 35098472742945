import React, { ChangeEvent } from 'react';
import { ReactComponent as PhoneIcon } from '../../../../assets/vector/phone.svg';
import { Button } from '@mui/material';
import RoundButton from '../../../../ui/RoundButton';
import ConfirmIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import Input from '../../../../ui/Input';

interface IEditablePhonesListProps {
  classNames?: string[];
  isEditing?: boolean;
  phones?: string[];
  onPhoneNumberChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPhoneNumberClick: (id: number | null) => void;
  onCancel?: () => void;
  onSave?: () => void;
  editInput: string;
  error: string;
}

const EditablePhonesList = (props: IEditablePhonesListProps) => {
  const phonesCount = (props.phones && props.phones.length) || 0;

  return (
    <div className="editable-phones-list">
      <div className="editable-phones-list__list">
        {phonesCount === 0 && !props.isEditing && (
          <Button
            className="editable-phones-list__btn editable-phones-list__btn_no-phone"
            onClick={() => props.onPhoneNumberClick(null)}
          >
            <PhoneIcon className="editable-phones-list__icon editable-phones-list__icon_no-phone" />{' '}
            Телефон не указан
          </Button>
        )}
        {props.isEditing && (
          <div className="editable-phones-list__input-wrapper">
            <Input
              fullWidth
              type="tel"
              autoFocus
              value={props.editInput}
              error={props.error}
              onInput={props.onPhoneNumberChange}
              placeholder="Введите номер телефона"
              classNames={['editable-phones-list__input']}
            />
            <div className="editable-phones-list__edit-buttons">
              <RoundButton
                content={<ConfirmIcon />}
                onClick={props.onSave}
                classNames={['editable-phones-list__edit-btn']}
              />
              <RoundButton
                content={<CancelIcon />}
                onClick={props.onCancel}
                classNames={['editable-phones-list__edit-btn']}
              />
            </div>
          </div>
        )}
        {!props.isEditing &&
          props.phones?.map((phone, i) => (
            <Button
              key={i}
              className="editable-phones-list__btn"
              onClick={() => props.onPhoneNumberClick(i)}
            >
              <PhoneIcon className="editable-phones-list__icon" /> {phone}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default EditablePhonesList;

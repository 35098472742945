import { Skeleton } from '@mui/material';
import React from 'react';

const DoctorSkeletonLoader = () => {
  return (
    <div className="doctor-skeleton-loader">
      <Skeleton
        className="doctor-skeleton-loader__checkbox"
        width={18}
        height={18}
      />
      <div className="doctor-skeleton-loader__right-part">
        <Skeleton height={16} className="doctor-skeleton-loader__name" />
        <Skeleton height={10} className="doctor-skeleton-loader__info" />
      </div>
    </div>
  );
};

export default DoctorSkeletonLoader;

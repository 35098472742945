import React from 'react';
import { DoctorChangeActionEnum, DoctorChangedFieldEnum } from '../../enums';
import {
  IApiDoctor,
  IApiLpuItem,
  IApiSpecialityItem,
  ICreatedBy,
} from '../../../../interfaces/api.catalogs.interfaces';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import SubinfoDoctorSpan from '../../../../ui/SubinfoDoctorSpan';
import { DOCTOR_FIELDS_CHANGE_MAP } from '../../constants';
import moment from 'moment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { parseValue } from '../../helpers';
import { IDoctorChange } from '../../interfaces';

type ValueType =
  | string
  | string[]
  | IApiSpecialityItem
  | IApiLpuItem[]
  | boolean
  | null;

interface IChangelogListItemProps {
  doctor: IApiDoctor;
  updatedAt: string;
  updatedBy: ICreatedBy;
  classNames?: string[];
  changes: IDoctorChange[];
  action: DoctorChangeActionEnum;
}

const ChangelogListItem = (props: IChangelogListItemProps) => {
  const doctorFullname = `${props.doctor?.lastName || ''} ${
    props.doctor?.firstName || ''
  } ${props.doctor?.middleName || ''}`;
  const timezoneAbsHoursOffset = Math.abs(moment().zone() / 60);

  const sameYearDate = moment(props.updatedAt);

  return (
    <div className={classNamesParser('changelog-list-item', props.classNames)}>
      <div className="changelog-list-item__header">
        <span className="changelog-list-item__header-title">
          {doctorFullname}
        </span>
        <span className="changelog-list-item__header-subtitle">
          {props.doctor?.medInstitutions?.map((lpu) => (
            <SubinfoDoctorSpan variant="lpu" value={lpu.name} />
          ))}

          <SubinfoDoctorSpan
            variant="specialty"
            value={props.doctor?.specialty?.name}
          />
        </span>
      </div>

      <div className="changelog-list-item__card">
        <div className="changelog-list-item__info">
          <span className="changelog-list-item__changes-title">
            {props.action === DoctorChangeActionEnum.UPDATE
              ? 'Список изменений:'
              : 'Новый врач:'}
          </span>
          <div>
            <span className="changelog-list-item__date">
              {moment(props.updatedAt)
                .add(timezoneAbsHoursOffset, 'hour')
                .format(`DD MMMM${sameYearDate ? '' : ' YYYY'} HH:mm`)}
            </span>
            <span className="changelog-list-item__create-by">
              {props.updatedBy.fullName}
            </span>
          </div>
        </div>
        <div className="changelog-list-item__list">
          {props.changes.map((change) => (
            <>
              <p className="changelog-list-item__field-name">
                {DOCTOR_FIELDS_CHANGE_MAP[change.field]}
              </p>
              <div className="changelog-list-item__changes">
                {props.action === DoctorChangeActionEnum.UPDATE && (
                  <>
                    <div className="changelog-list-item__value changelog-list-item__value_old">
                      <p>{parseValue(change.oldValue) || 'не указано'}</p>
                    </div>
                    <ArrowForwardIcon className=" changelog-list-item__arrow" />
                  </>
                )}
                <div
                  className={`changelog-list-item__value  ${
                    props.action === DoctorChangeActionEnum.CREATE
                      ? 'changelog-list-item__value_new'
                      : ''
                  }`}
                >
                  {/* <p>{newValue || 'не указано'}</p> */}
                  {parseValue(change.newValue) || 'не указано'}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      {/* {props.meetups?.length
        ? props.meetups.map((item) => {
            return (
              <div
                className="changelog-list-item__meetup-card"
                key={Math.random()}
              >
                <span className="changelog-list-item__meetup-card-title">
                  {MEETUP_WORD_MAP[item.type]}
                </span>
                <div className="changelog-list-item__meetup-info">
                  
                  {item.feedback?.type && (
                    <span className="changelog-list-item__meetup-theme">
                      {getLocaleFeedbackType(item.feedback?.type)}
                    </span>
                  )}

                  {item.resultMark && (
                    <span className="changelog-list-item__meetup-mark">{`Оценка: ${getResultMark(
                      item.resultMark
                    )}`}</span>
                  )}
                </div>
                {item.resultComment && (
                  <div className="changelog-list-item__meetup-result-comment">
                    {item.resultComment}
                  </div>
                )}
              </div>
            );
          })
        : null} */}
    </div>
  );
};

export default ChangelogListItem;

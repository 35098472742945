import { IMPORT_MIS } from '../api/api';
import axios from '../axios/axios';

export class ImportDataService {
  getUploadedHistory = async (offset: number, limit: number) => {
    let result = null;
    try {
      const response = await axios.get(IMPORT_MIS.GET_UPLOADED_FILES, {
        params: {
          limit,
          offset,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  uploadFile = async (body: FormData) => {
    let result = null;
    try {
      const response = await axios.post(IMPORT_MIS.UPLOAD_MIS_FILE, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getUploadedFile = async (
    fileID: number,
    offset: number,
    limit: number,
    matched: boolean,
    searchByDoctor?: string | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${IMPORT_MIS.UPLOAD_MIS_FILE}/${fileID}/report`,
        { params: { offset, limit, matched, searchByDoctor } }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  deleteUpload = async (fileID: number) => {
    let result = null;
    try {
      const response = await axios.delete(
        `${IMPORT_MIS.UPLOAD_MIS_FILE}/${fileID}`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  setDoctorToReport = async (
    doctorID: number,
    doctorRecordID: number,
    fileID: number
  ) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${IMPORT_MIS.UPLOAD_MIS_FILE}/${fileID}/report`,
        { doctorID, doctorRecordID }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  clearDoctorReport = async (doctorRecordID: number, fileID: number) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${IMPORT_MIS.UPLOAD_MIS_FILE}/${fileID}/report`,
        { doctorID: null, doctorRecordID }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  createTask = async (fileID: number) => {
    let result = null;
    try {
      const response = await axios.get(
        `${IMPORT_MIS.CREATE_TASK}/${fileID}/create-task`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  updateName = async (fileID: number, name: string) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${IMPORT_MIS.UPDATE_NAME}/${fileID}/name`,
        { name },
        { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  createTasksByAllFiles = async () => {
    let result = null;
    try {
      const response = await axios.get(IMPORT_MIS.CREATE_TASKS_BY_ALL_FILES);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  uploadToStats = async (fileID: number) => {
    let result = null;

    try {
      const response = await axios.patch(
        `${IMPORT_MIS.LOAD_TO_STATS}/${fileID}/load-to-stats`
      );

      if (response.status === 200) {
        result = response.data;
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  };
}

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { CSSProperties } from 'react';
import { Drawer as MUIDrawer } from '@mui/material';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IDrawerProps {
  open?: boolean;
  anchor?: 'left' | 'right';
  children: ReactJSXElement;
  classNames?: string[];
  onClose?: () => void;
  styles?: CSSProperties;
}

const Drawer = (props: IDrawerProps) => {
  return (
    <MUIDrawer
      classes={{ paper: classNamesParser('drawer', props.classNames) }}
      anchor={props.anchor}
      open={props.open}
      className="drawer"
      onClose={props.onClose}
      style={props.styles}
    >
      {props.children}
    </MUIDrawer>
  );
};

export default Drawer;

import axios from 'axios';
import { apiBaseUrl } from '../configs/config';
import { AUTH } from '../api/api';
import EventEmitter from '../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../helpers/EventEmitter/events.constants';

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: false,
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.accessToken;
  if ('Authorization' in config.headers || !accessToken) return config;
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // if (
    //   (error?.code === 'ERR_NETWORK' || error.response?.status === 502) &&
    //   error.response?.status !== 413
    // ) {
    //   EventEmitter.emit(EMITTER_EVENTS.SET_SERVER_ERROR);
    // }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const response = await axiosInstance.post(AUTH.REFRESH_TOKEN, {
        refreshToken: localStorage.refreshToken,
      });
      localStorage.accessToken = response.data?.access_token;
      localStorage.refreshToken = response.data?.refresh_token;
      originalRequest.headers.Authorization =
        'Bearer ' + response.data?.access_token;
      return axiosInstance(originalRequest);
    }

    if (
      error.response?.data === 'auth.refresh_token_not_found' &&
      error.response?.status === 400
    ) {
      EventEmitter.emit(EMITTER_EVENTS.SET_AUTH_ERROR);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

import { IAppSelectOption } from '../../ui/AppSelect/app-select.interface';

export const MAX_DOCTORS_SHOW = 3;

export const ENVELOPE_VALUE_NAMES = {
  ENVELOPE_DONE: 'Конверт отдан',
  ENVELOPE_FAIL: 'Встреча не состоялась',
};

export const envelopeOptions: IAppSelectOption[] = [
  {
    id: 1,
    text: ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
    value: ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
  },
  {
    id: 2,
    text: ENVELOPE_VALUE_NAMES.ENVELOPE_FAIL,
    value: ENVELOPE_VALUE_NAMES.ENVELOPE_FAIL,
  },
];

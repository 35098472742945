import { AppDispatch, RootState, store } from '../../app.store';
import { saveBase64AsFile } from '../../helpers/saveFile';
import { ExportService } from '../../services/export.service';
import { ImportDataService } from '../../services/import-data.service';
import { showAppSnackBar } from '../../store/app.slice';
import { DownloadReportsVariants } from './enums';
import {
  setDownloadingID,
  setIsLoading,
  setLimit,
} from './store/upload-history.slice';
import {
  setHistory,
  setOffset,
  setPage,
  setTotal,
} from './store/upload-history.slice';

export class UploadHistoryController {
  dispatch: AppDispatch;
  getState: () => RootState;
  importDataService = new ImportDataService();
  exportService: ExportService = new ExportService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  getUploadHistory = async () => {
    this.dispatch(setIsLoading(true));
    const state = this.getState().uploadHistory;
    const data = await this.importDataService.getUploadedHistory(
      state.offset,
      state.limit
    );
    if (data) {
      this.dispatch(setHistory(data.data));
      this.dispatch(setTotal(data.total));
    }
    this.dispatch(setIsLoading(false));
  };

  onPageChange = (_: any, page: number) => {
    const state = this.getState().uploadHistory;
    this.dispatch(setPage(page));
    this.dispatch(setOffset((page - 1) * state.limit));
    this.getUploadHistory();
  };

  onCreateTask = async (fileId: number) => {
    this.dispatch(setDownloadingID(fileId));

    const data = await this.importDataService.createTask(fileId);
    if (data !== null) {
      this.dispatch(
        showAppSnackBar({
          text: 'Задание успешно создано',
          snackBarSeverity: 'success',
        })
      );
    } else {
      this.dispatch(
        showAppSnackBar({
          text: 'Не удалось создать задание',
          snackBarSeverity: 'error',
        })
      );
    }
    this.dispatch(setDownloadingID(null));
  };

  downloadPDF = async (id: number, filename: string) => {};

  downloadExcel = async (id: number, fileName: string) => {
    this.dispatch(setDownloadingID(id));
    const data = await this.exportService.downloadReport(
      id,
      DownloadReportsVariants.EXCEL
    );
    const mediaType =
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

    if (data) {
      saveBase64AsFile(`${fileName}.xls`, mediaType + data);
    }
    this.dispatch(setDownloadingID(null));
  };

  setLimit = async (limit: number) => {
    this.dispatch(setLimit(limit));
  };
}

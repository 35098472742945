import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { imageService } from '../../services/image.service';

interface ServerImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  width: number;
  height: number;
}

export const ServerImage = (props: ServerImageProps) => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    setSrc(null);
    imageService.getImage(props.src).then((image) => setSrc(image));
  }, [props.src]);

  return src ? (
    <img {...props} src={src} width={undefined} height={undefined} />
  ) : (
    <Skeleton width={props.width} height={props.height} />
  );
};

import { IAppSelectOption } from '../../../ui/AppSelect/app-select.interface';
export const HISTORY_REPORT_LIMIT = 20;

//Фильтры типа встречи
export const MEETUP_TYPE_NAMES = {
  ALL: 'Все',
  ENVELOPE: 'Конверт',
  MEETUP: 'Встреча',
};

export const MEETUP_TYPE_VALUES = {
  ALL: '',
  ENVELOPE: 'envelope',
  MEETUP: 'meetup',
};

export const meetupTypeValues: IAppSelectOption[] = [
  {
    id: 1,
    text: MEETUP_TYPE_NAMES.ALL,
    value: MEETUP_TYPE_VALUES.ALL,
  },
  {
    id: 2,
    text: MEETUP_TYPE_NAMES.ENVELOPE,
    value: MEETUP_TYPE_VALUES.ENVELOPE,
  },
  {
    id: 3,
    text: MEETUP_TYPE_NAMES.MEETUP,
    value: MEETUP_TYPE_VALUES.MEETUP,
  },
];

//Фильтры комментария

export const COMMENT_NAMES = {
  ALL: 'Все',
  COMMENTS: 'Только с комментарием',
};

export const COMMENT_VALUES = {
  ALL: '',
  COMMENTS: 'true',
};

export const commentValues: IAppSelectOption[] = [
  {
    id: 1,
    text: COMMENT_NAMES.ALL,
    value: COMMENT_VALUES.ALL,
  },
  {
    id: 2,
    text: COMMENT_NAMES.COMMENTS,
    value: COMMENT_VALUES.COMMENTS,
  },
];

//Фильтры обратной связи

export const FEEDBACK_NAMES = {
  ALL: 'Все',
  COMMENTS: 'Только с ответом',
};

export const FEEDBACK_VALUES = {
  ALL: '',
  COMMENTS: 'true',
};

export const feedbackValues: IAppSelectOption[] = [
  {
    id: 1,
    text: FEEDBACK_NAMES.ALL,
    value: FEEDBACK_VALUES.ALL,
  },
  {
    id: 2,
    text: FEEDBACK_NAMES.COMMENTS,
    value: FEEDBACK_VALUES.COMMENTS,
  },
];

//Фильтры даты

export const DATE_NAMES = {
  ALL: 'Все',
  MONTH: 'Предыдущие 30 дней',
  HALF_YEAR: 'Предыдущие 6 месяцев',
  YEAR: 'Предыдущие 12 месяцев',
};

export const DATE_VALUES = {
  ALL: '',
  MONTH: '30',
  HALF_YEAR: '180',
  YEAR: '360',
};

export const dateValues: IAppSelectOption[] = [
  {
    id: 1,
    text: DATE_NAMES.ALL,
    value: DATE_VALUES.ALL,
  },
  {
    id: 2,
    text: DATE_NAMES.MONTH,
    value: DATE_VALUES.MONTH,
  },
  {
    id: 3,
    text: DATE_NAMES.HALF_YEAR,
    value: DATE_VALUES.HALF_YEAR,
  },
  {
    id: 4,
    text: DATE_NAMES.YEAR,
    value: DATE_VALUES.YEAR,
  },
];

export const EXECUTOR_NAMES = {
  ALL: 'Все',
};

export const EXECUTOR_VALUES = {
  ALL: '',
};

export const agentValues: IAppSelectOption[] = [
  {
    id: 1,
    text: DATE_NAMES.ALL,
    value: DATE_VALUES.ALL,
  },
];

import { Button, Menu, capitalize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { DateCalendar } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IMonthYearPickerProps {
  placeholder?: string;
  date?: string;
  onChange: (date: string) => void;
  disabled?: boolean;
  error?: boolean;
  classNames?: string[];
}

const MonthYearPicker = (props: IMonthYearPickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMonthChange = (date: Moment) => {
    date && props.onChange(date.format('YYYY-MM-DD'));
    handleClose();
  };
  const handleYearChange = (date: Moment) => {
    date && props.onChange(date.format('YYYY-MM-DD'));
  };
  return (
    <div className={classNamesParser('month-year-picker', props.classNames)}>
      <Button
        className={`month-year-picker__btn ${props.error ? '--error' : ''}`}
        onClick={handleClick}
        disabled={props.disabled}
      >
        {props.date
          ? capitalize(moment(props.date).format('MMMM YYYY'))
          : props.placeholder}
        <ArrowDropDown />
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
      >
        <DateCalendar
          value={props.date ? moment(props.date) : null}
          onMonthChange={handleMonthChange}
          onYearChange={handleYearChange}
          views={['year', 'month']}
        />
      </Menu>
    </div>
  );
};

export default MonthYearPicker;

import React, {
  ChangeEvent,
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import RoundButton from '../RoundButton';
import { classNamesParser } from '../../helpers/classNamesParser';
import { ReactComponent as SearchIcon } from '../../assets/vector/search.svg';

interface ISearchButtonProps {
  onInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onSearch?: () => Promise<void> | void;
  onClear?: () => void;
  placeholder?: string;
  styles?: CSSProperties;
  classNames?: string[];
}

const SearchBar = (props: ISearchButtonProps) => {
  const inputRef = useRef<any>(null);

  const onEnterPress = useMemo(
    () => (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        props.onSearch && props.onSearch();
      }
    },
    []
  );

  useEffect(() => {
    document.addEventListener('keypress', onEnterPress);
    return () => {
      document.removeEventListener('keypress', onEnterPress);
    };
  }, []);

  const handleInputClick = () => {
    inputRef.current.focus();
  };

  return (
    <div
      className={classNamesParser('search-bar', props.classNames)}
      style={props.styles}
    >
      <div className="search-bar__search-icon-wrapper">
        <SearchIcon className="search-bar__search-icon" />
      </div>
      <input
        onClick={handleInputClick}
        ref={inputRef}
        className="search-bar__input"
        onInput={props.onInput}
        value={props.value}
        placeholder={props.placeholder}
        autoFocus
      />
      <RoundButton
        classNames={['search-bar__round-button']}
        onClick={props.onClear}
        content={<CloseIcon className={'search-bar__round-button-icon'} />}
      />
    </div>
  );
};

export default SearchBar;

export enum TaskFiltersEnum {
  DOCTOR_FULLNAME = 'doctor_fullname',
  LPU_NAME = 'lpu_name',
  SPECIALTY_NAME = 'specialty_name',
}

export enum TaskVariantFiltersEnum {
  ALL = 'all',
  MEETUP = 'meetup',
  ENVELOPE = 'envelope',
}

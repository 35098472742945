import React, { ChangeEvent, useEffect } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { AddUserFormController } from '../../controllers/add-user-form.controller';
import Input from '../../ui/Input';
import CheckBox from '../../ui/CheckBox';
import RoundButton from '../../ui/RoundButton';
import { Close } from '@mui/icons-material';
import { UserRolesTypes } from '../../types/users.types';
import ErrorAlert from '../../ui/ErrorAlert';

interface IAddUserFormProps {
  onClose: () => void;
  onConfirm?: (
    fullName: string,
    email: string,
    login: string,
    password: string,
    contactPhone: string,
    role: UserRolesTypes
  ) => Promise<number | null>;
}

const AddUserForm = (props: IAddUserFormProps) => {
  const addUserFormState = useAppSelector((state) => state.addUserForm);
  const dispatch = useAppDispatch();
  const controller = new AddUserFormController(dispatch);

  const isAdminChecked = addUserFormState.role === 'admin';
  const isManagerChecked = addUserFormState.role === 'manager';
  const isRepresentativeChecked = addUserFormState.role === 'medical_agent';

  const handleConfirm = async () => {
    if (!controller.hasEmptyFields() && props.onConfirm)
      controller.onCreateUser(
        await props.onConfirm(
          addUserFormState.fullName,
          addUserFormState.email,
          addUserFormState.login,
          addUserFormState.password,
          addUserFormState.phoneNumber,
          addUserFormState.role
        )
      );
  };

  useEffect(() => {
    return () => {
      controller.clearState();
    };
  }, []);

  return (
    <div className="add-user-form">
      <div className="add-user-form__header">
        <h3 className="add-user-form__title">Новый пользователь</h3>
        <RoundButton
          content={<Close />}
          onClick={props.onClose}
          classNames={['add-user-form__close-btn']}
        />
      </div>
      <div className="add-user-form__fields">
        <Input
          classNames={['add-user-form__input']}
          onInput={controller.handleFullNameChange}
          value={addUserFormState.fullName}
          placeholder="ФИО пользователя"
          error={addUserFormState.errors.fullName}
          fullWidth
        />
        <Input
          value={addUserFormState.phoneNumber}
          onInput={controller.handlePhoneNumberChange}
          classNames={['add-user-form__input']}
          placeholder="Контактный телефон"
          fullWidth
        />
        <Input
          value={addUserFormState.email}
          classNames={['add-user-form__input']}
          onInput={controller.handleEmailChange}
          placeholder="Электронная почта"
          fullWidth
        />
        <Input
          value={addUserFormState.login}
          classNames={['add-user-form__input']}
          onInput={controller.handleLoginChange}
          placeholder="Логин"
          error={addUserFormState.errors.login}
          fullWidth
        />
        <Input
          value={addUserFormState.password}
          classNames={['add-user-form__input']}
          onInput={controller.handlePasswordChange}
          placeholder="Пароль"
          error={addUserFormState.errors.password}
          fullWidth
          type="password"
        />
        <div className="add-user-form__checkbox-container">
          <CheckBox
            classNames={['add-user-form__check-box']}
            label="Администратор"
            onChange={controller.handleIsAdminChange}
            checked={isAdminChecked}
          />
          <CheckBox
            classNames={['add-user-form__check-box']}
            onChange={controller.handleIsManagerChange}
            label="Менеджер"
            checked={isManagerChecked}
          />
          <CheckBox
            classNames={['add-user-form__check-box']}
            label="Представитель"
            onChange={controller.handleIsMedicalAgentChange}
            checked={isRepresentativeChecked}
          />
        </div>
      </div>

      <ErrorAlert
        classNames={['add-user-form__error-alert']}
        error={addUserFormState.errors.addUser}
      />
      <div className="add-user-form__controls">
        <Button className="add-user-form__cancel" onClick={props.onClose}>
          Отмена
        </Button>
        <Button className="add-user-form__save" onClick={handleConfirm}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default AddUserForm;

import { AppDispatch, RootState, store } from '../../app.store';
import { CatalogsService } from '../../services/catalogs.service';
import {
  setDistrict,
  setFoundDistricts,
  setFoundLocalities,
  setLoading,
  setLocality,
  setLocalityId,
  setLocationError,
  setName,
  setStreet,
} from './store/add-lpu-form.slice';
import { ChangeEvent } from 'react';
import { IApiKladrCity } from '../../interfaces/api.catalogs.interfaces';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';

export class AddLpuFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.dispatch(setName(value));
  };

  handleLocalityChange = (event: any) => {
    const value = event.target.value;
    this.findSameLocations(value);
    this.dispatch(setLocality(value));
  };

  findSameLocations = (value: string) => {
    const locations = this.getState().addLpuForm.foundLocalities;
    const foundLocation = locations.find((item) => item.title === value);

    if (foundLocation) {
      this.dispatch(setLocationError(''));
      this.dispatch(setLocalityId(foundLocation.id));
    } else {
      this.dispatch(setLocationError('Выберите из списка'));
    }
  };

  fetchCities = async () => {
    const store = this.getState().addLpuForm;

    try {
      const response = await this.catalogsService.getKladrCities(
        store.locality
      );

      if (response) {
        const formattedResult = response?.result?.map(
          (item: IApiKladrCity) => ({
            id: item.cityID,
            title: item.name,
          })
        );

        this.dispatch(setFoundLocalities(formattedResult));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchDistricts = async () => {
    const store = this.getState().addLpuForm;

    try {
      const response = await this.catalogsService.getKladrDistricts(
        store.localityId
      );

      if (response) {
        const formattedResult = response?.data?.map(
          (item: string, i: number) => ({
            id: i,
            title: item,
          })
        );

        this.dispatch(setFoundDistricts(formattedResult));
      }
    } catch (e) {
      console.error(e);
    }
  };

  onCityChange = (_: any, value: any) => {
    this.findSameLocations(value?.title);
    if (value?.title) {
      this.dispatch(setLocality(value.title));
      this.fetchDistricts();
    } else {
      this.dispatch(setLocality(''));
      this.dispatch(setLocalityId(0));
      this.dispatch(setStreet(''));
      this.fetchCities();
    }
  };

  onDistrictChange = (e: any, value: any) => {
    this.dispatch(setDistrict(value ? value.title : ''));
  };

  onDistrictInput = (e: any) => {
    this.fetchDistricts();
    this.dispatch(setDistrict(e.target.value));
  };

  onStreetChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setStreet(e.target.value));
  };

  onSaveClick = async () => {
    this.dispatch(setLoading(true));

    const store = this.getState().addLpuForm;

    try {
      const response = await this.catalogsService.createLpu(store.name, {
        placeName: store.locality,
        district: store.district,
        street: store.street,
      });

      if (typeof response === 'number') {
        EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
        EventEmitter.emit(EMITTER_EVENTS.CREATE_LPU_SUCCESS, response);
        EventEmitter.emit(EMITTER_EVENTS.SET_SELECTED_CARD, response);
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setLoading(false));
  };
}

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { TableComponents } from 'react-virtuoso';
import { IHistoryReportItem } from '../../interfaces';
const HistoryReportTable: TableComponents<IHistoryReportItem> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} className="history-report__table" />,
  TableHead,
  TableRow: ({ item: _item, ...props }) => {
    return <TableRow {...props} />;
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default HistoryReportTable;

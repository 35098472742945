import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ChangelogListController } from '../../changelog-list.controller';
import { Pagination } from '@mui/material';
import ChangelogLoader from '../ChangelogLoader';
import ChangelogListItem from '../ChangelogListItem';
import { IDoctorChangesItem } from '../../interfaces';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';

export const ChangelogList = () => {
  const changelogList = useAppSelector((state) => state.changelogList);
  const dispatch = useAppDispatch();
  const controller = new ChangelogListController(dispatch);

  useEffect(() => {
    controller.getDoctorsChanges();

    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_CHANGELOG_WITH_SEARCH,
      controller.onSearchByFullname
    );
    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_CHANGELOG_WITH_SEARCH);
    };
  }, []);

  const pageCount = Math.ceil(changelogList.total / changelogList.limit);

  return (
    <div className="changelog-list">
      <div className="changelog-list__list">
        {changelogList.isLoading ? (
          <ChangelogLoader />
        ) : (
          changelogList.items.map((item: IDoctorChangesItem) => (
            <ChangelogListItem
              doctor={item.doctor}
              updatedAt={item.updatedAt}
              changes={item.changes}
              updatedBy={item.updatedBy}
              action={item.action}
            />
          ))
        )}
      </div>

      {pageCount !== 1 && (
        <Pagination
          count={pageCount}
          page={changelogList.page}
          onChange={controller.onPageChange}
          className="changelog-list__pagination"
        />
      )}
    </div>
  );
};

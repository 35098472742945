import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import React, { useRef, useState, MouseEvent } from 'react';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';

interface ISelectFilterProps {
  options: IAppSelectOption[];
  value?: IAppSelectOption;
  onChange?: (item: IAppSelectOption) => void;
  onClear?: () => void;
}

const SelectFilter = (props: ISelectFilterProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };
  const onChange = (item: IAppSelectOption) => {
    item && props.onChange && props.onChange(item);
    onMenuClose();
  };

  const onDeleteBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onClear && props.onClear();
  };

  return (
    <>
      <Button
        onClick={onMenuOpen}
        className={`select-filter ${
          !!props.value?.value ? 'select-filter_selected' : ''
        }`}
      >
        <p className="select-filter__value">{props.value?.text}</p>

        {!props.value?.value ? (
          <KeyboardArrowDownIcon className="select-filter__arrow" />
        ) : (
          <IconButton
            className="select-filter__delete"
            onClick={onDeleteBtnClick}
          >
            <Close className="select-filter__delete-icon" />
          </IconButton>
        )}
      </Button>

      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onMenuClose}
        classes={{
          root: 'select-filter__menu',
          list: 'select-filter__menu',
          paper: 'select-filter__menu-paper',
        }}
      >
        {props.options.map((item) => {
          const isSelected = props.value?.id === item.id;
          return (
            <MenuItem
              key={item.id}
              value={item.value}
              className={`select-filter__menu-item ${
                isSelected ? 'select-filter__menu-item_selected' : ''
              }`}
              onClick={() => onChange(item)}
            >
              <div className="select-filter__menu-item-container">
                <CheckIcon
                  fontSize="small"
                  className={`select-filter__menu-item-check-icon ${
                    isSelected
                      ? 'select-filter__menu-item-check-icon_selected'
                      : ''
                  }`}
                />
                <span className="select-filter__text">{item.text}</span>
                <span className="select-filter__help-text">
                  {item.helpText}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default SelectFilter;

import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IRoundButtonProps {
  classNames?: string[];
  content?: any;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
  dataAttrs?: any;
  disabled?: boolean;
}

const RoundButton = (props: IRoundButtonProps) => {
  return (
    <button
      disabled={props.disabled}
      style={{
        backgroundColor: props.backgroundColor,
      }}
      onClick={props.onClick}
      className={classNamesParser('round-button', props.classNames)}
      {...props.dataAttrs}
    >
      {props.content}
    </button>
  );
};

export default RoundButton;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IFeedback } from '../interfaces';
import { FEEDBACK_LIST_LIMIT } from '../constants';
import { FeedbackListTabsTypes } from '../types';
import { FeedbackStatusTypes } from '../../../types/feedbacks.types';

interface IFeedbackListState {
  isLoading: boolean;
  feedbacks: IFeedback[];
  limit: number;
  offset: number;
  haveMore: boolean;
  tabValue: FeedbackListTabsTypes;
}

const initialState: IFeedbackListState = {
  isLoading: false,
  feedbacks: [],
  offset: 0,
  limit: FEEDBACK_LIST_LIMIT,
  haveMore: true,
  tabValue: 'in_progress',
};

export const feedbackListSlice = createSlice({
  name: 'feedback-list',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },
    setTabValue: (state, action: PayloadAction<FeedbackListTabsTypes>) => {
      state.tabValue = action.payload;
    },
    pushFeedbacks: (state, action: PayloadAction<IFeedback[]>) => {
      state.feedbacks = [...state.feedbacks, ...action.payload];
    },
    clearFeedbacks: (state) => {
      state.feedbacks = [];
    },
    setFeedbackStatus: (
      state,
      action: PayloadAction<{ id: number; status: FeedbackStatusTypes }>
    ) => {
      state.feedbacks = state.feedbacks.map((feedback) => {
        if (feedback.id === action.payload.id) {
          const updatedFeedback = feedback;
          updatedFeedback.status = action.payload.status;
          return updatedFeedback;
        }
        return feedback;
      });
    },
  },
});

export const {
  setOffset,
  setHaveMore,
  setLoading,
  setTabValue,
  pushFeedbacks,
  clearFeedbacks,
  setFeedbackStatus,
} = feedbackListSlice.actions;
export const feedbackListReducer = feedbackListSlice.reducer;

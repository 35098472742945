import { AppDispatch, RootState, store } from '../../app.store';
import { ViewController } from '../../interfaces/general.interfaces';
import { MeetupsService } from '../../services/meetups.service';
import { MeetupFeedbackTypes } from '../../types/tasks.types';
import { IHistoryFilters } from './interfaces';
import {
  setList,
  setOffset,
  setLoading,
  setFilters,
  setHaveMore,
  pushToList,
} from './store/completed-meetups.slice';

export class CompletedMeetupsController extends ViewController {
  meetupsService: MeetupsService = new MeetupsService();

  getCompletedMeetups = async () => {
    const store = this.getState().completedMeetups;
    if (store.isLoading || !store.haveMore) return;
    this.dispatch(setLoading(true));
    try {
      const result = await this.meetupsService.getCompletedMeetupsOld(
        store.limit,
        store.offset,
        store.filters.searchName,
        store.filters.comment,
        store.filters.feedbackType
      );

      if (result) {
        this.dispatch(pushToList(result.data));
        this.dispatch(setOffset(store.offset + store.limit));
        const haveMore = result.total > store.list.length + result.data.length;
        this.dispatch(setHaveMore(haveMore));
      }
    } catch (e) {
      this.dispatch(setHaveMore(false));
      console.error(e);
    }
    this.dispatch(setLoading(false));
  };

  onFiltersChange = (filters: IHistoryFilters) => {
    this.dispatch(setFilters(filters));
    this.dispatch(setHaveMore(true));
    this.dispatch(setLoading(false));
    this.dispatch(setList([]));
    this.dispatch(setOffset(0));
    this.getCompletedMeetups();
  };
}

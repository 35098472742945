import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IAppSelectOption } from './app-select.interface';

interface IAppSelectProps {
  classNames?: string[];
  value?: string;
  handleChange?: (event: SelectChangeEvent) => void;
  options?: IAppSelectOption[];
  placeholder?: string;
  label?: string;
  renderText?: boolean;
}

const showPlaceholder = (text?: string) => {
  return <span className="app-select__placeholder">{text}</span>;
};

const AppSelect = (props: IAppSelectProps) => {
  const getTextByValue = (value: string) =>
    props?.options?.find((item) => item.value === value)?.text;

  return (
    <div className={classNamesParser('app-select', props.classNames)}>
      {props.label && <span className="app-select__label">{props.label}</span>}
      <Select
        value={props.value}
        onChange={props.handleChange}
        displayEmpty
        renderValue={(value: string) => {
          if (props.placeholder?.length && !value) {
            return showPlaceholder(props.placeholder);
          }

          return (
            <span key={value}>
              {props.renderText ? getTextByValue(value) : value}
            </span>
          );
        }}
        MenuProps={{
          style: { zIndex: 1400 },
        }}
      >
        {!!props?.options?.length &&
          props.options.map((item: IAppSelectOption) => {
            return (
              <MenuItem
                key={item.id}
                value={item.value}
                className="app-select__menu-item"
              >
                <div className="app-select__menu-item-container">
                  <span className="app-select__text">{item.text}</span>
                  <span className="app-select__help-text">{item.helpText}</span>
                </div>
              </MenuItem>
            );
          })}
      </Select>
    </div>
  );
};

export default AppSelect;

import { USERS_MANAGE } from '../api/api';
import axios from '../axios/axios';
import { UserActiveTypes, UserRolesTypes } from '../types/users.types';
import {
  ICreateUserBody,
  IEditUserBody,
} from '../modules/UsersList/index.interfaces';
import qs from 'qs';

export class UsersService {
  getUsers = async (
    limit?: number | null,
    offset?: number | null,
    state?: UserActiveTypes | null,
    searchByDoctor?: string | null,
    roles?: UserRolesTypes[]
  ) => {
    let result = null;
    try {
      const response = await axios.get(USERS_MANAGE, {
        params: {
          limit,
          offset,
          state,
          searchByDoctor,
          roles,
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params, {
            arrayFormat: 'repeat',
            skipNulls: true,
          });
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  createUser = async (body: ICreateUserBody) => {
    let result = null;
    try {
      const response = await axios.post(USERS_MANAGE, body);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  getUserById = async (id: number) => {
    let result = null;
    try {
      const response = await axios.get(`${USERS_MANAGE}/${id}`);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
  deleteUser = async (id: number) => {
    let result = null;
    try {
      const response = await axios.delete(`${USERS_MANAGE}/${id}`);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
  editUser = async (id: number, body: IEditUserBody) => {
    let result = null;
    try {
      const response = await axios.patch(`${USERS_MANAGE}/${id}`, body);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  setUserState = async (id: number, state: UserActiveTypes) => {
    let result = null;
    try {
      const response = await axios.patch(`${USERS_MANAGE}/${id}`, { state });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  changePassword = async (id: number, password: string) => {
    let result = null;
    try {
      const response = await axios.patch(`${USERS_MANAGE}/${id}`, { password });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
}

import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import AppModal from '../../../../components/AppModal';
import { IMeetupFeedback } from '../../interfaces';
import { FeedbackMenu } from '../../../FeedbackMenu';
import RoundButton from '../../../../ui/RoundButton';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import { getLocaleFeedbackType } from '../../../FeedbackMenu/helpers/getLocaleFeedbackType';
import { ReactComponent as DangerIcon } from '../../../../assets/vector/danger-sign.svg';
import moment from 'moment';

interface IFeedbacksListProps {
  feedbacks: IMeetupFeedback[];
}

export const FeedbacksList = (props: IFeedbacksListProps) => {
  const [feedbackListOpened, setFeedbackListOpened] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState<number>();
  const feedbackOpened = Boolean(currentFeedback);

  useEffect(() => {
    if (!feedbackListOpened) return;
    if (feedbackListOpened && props.feedbacks.length === 1) {
      setCurrentFeedback(props.feedbacks[0].id);
    }
  }, [feedbackListOpened]);

  const onCloseFeedbacksList = () => {
    setFeedbackListOpened(false);
  };

  const onCloseFeedback = () => {
    if (props.feedbacks.length === 1) {
      setFeedbackListOpened(false);
    }
    setCurrentFeedback(undefined);
  };

  return (
    <>
      <Button
        className="feedbacks-list__button"
        onClick={() => setFeedbackListOpened(true)}
      >
        <DangerIcon className="feedbacks-list__danger-icon" />
        Обратная связь &#171;В работе&#187; - {props.feedbacks.length}
        <ArrowForwardIos className="feedbacks-list__feedback-icon feedbacks-list__feedback-icon--button" />
      </Button>

      <AppModal
        hideBackdrop
        classNames={['feedbacks-list__modal']}
        open={feedbackListOpened}
        onClose={onCloseFeedbacksList}
      >
        <>
          <h4 className="feedbacks-list__header">
            Активные обратные связи
            <RoundButton onClick={onCloseFeedbacksList} content={<Close />} />
          </h4>
          <ul className="feedbacks-list__list">
            {props.feedbacks.map((feedback) => (
              <li key={feedback.id}>
                <button
                  className="feedbacks-list__feedback"
                  onClick={() => setCurrentFeedback(feedback.id)}
                >
                  {getLocaleFeedbackType(feedback.type)}
                  <div className="feedbacks-list__feedback-info">
                    <span>
                      {moment(feedback.createdAt).format('DD MMMM YYYY')}
                    </span>
                    <ArrowForwardIos className="feedbacks-list__feedback-icon" />
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </>
      </AppModal>
      <AppModal
        classNames={['feedbacks-list__modal feedbacks-list__modal--feedback']}
        open={feedbackOpened}
        hideBackdrop
      >
        <FeedbackMenu feedbackId={currentFeedback} onClose={onCloseFeedback} />
      </AppModal>
    </>
  );
};

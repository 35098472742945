import moment from 'moment/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IReferenceDynamicState {
  isLoading: boolean;
  startDate: string;
  endDate: string;
  isShowDynamicValues: boolean;
  dynamicFrom: string;
  dynamicTo: string;
  errors: {
    lastMeetupDate: string;
  };
}

const initialState: IReferenceDynamicState = {
  isLoading: false,
  startDate: moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'),
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  isShowDynamicValues: false,
  dynamicFrom: '0',
  dynamicTo: '0',
  errors: {
    lastMeetupDate: '',
  },
};

export const referenceDynamicSlice = createSlice({
  name: 'reference-dynamic',
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    setIsShowDynamicValues: (state, action: PayloadAction<boolean>) => {
      state.isShowDynamicValues = action.payload;
    },
    setDynamicFrom: (state, action: PayloadAction<string>) => {
      state.dynamicFrom = action.payload;
    },
    setDynamicTo: (state, action: PayloadAction<string>) => {
      state.dynamicTo = action.payload;
    },
  },
});
export const {
  setStartDate,
  setEndDate,
  setIsShowDynamicValues,
  setDynamicFrom,
  setDynamicTo,
} = referenceDynamicSlice.actions;
export const referenceDynamicReducer = referenceDynamicSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IApiAddress,
  IApiLpuItem,
} from '../../../interfaces/api.catalogs.interfaces';

interface IEditLpuFormState {
  isLoading: boolean;
  isEdit: boolean;
  name: string;
  address: IApiAddress;
  deleted: boolean;
  id: number;
  city: string;
  cityId: number;
  foundCities: any[];
  foundDistricts: any[];
  errors: {
    city: string;
    district: string;
  };
}

const initialState: IEditLpuFormState = {
  isLoading: false,
  isEdit: false,
  name: '',
  address: {
    placeName: '',
    district: '',
    street: '',
  },
  deleted: false,
  id: 0,
  city: '',
  cityId: 0,
  foundCities: [],
  foundDistricts: [],
  errors: {
    city: '',
    district: '',
  },
};

export const editLpuFormSlice = createSlice({
  name: 'edit-lpu-form',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setData: (state, action: PayloadAction<IApiLpuItem>) => {
      state.name = action.payload.name;
      state.deleted = action.payload.deleted;
      state.id = action.payload.id;
      state.address = action.payload.address;
      state.cityId = action.payload?.address?.cityID || 0;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setPlace: (state, action: PayloadAction<string>) => {
      state.address.placeName = action.payload;
    },
    setDistrict: (state, action: PayloadAction<string>) => {
      state.address.district = action.payload;
    },
    setStreet: (state, action: PayloadAction<string>) => {
      state.address.street = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setCityId: (state, action: PayloadAction<number>) => {
      state.cityId = action.payload;
    },
    setFoundCities: (state, action: PayloadAction<any[]>) => {
      state.foundCities = action.payload;
    },
    setCityError: (state, action: PayloadAction<string>) => {
      state.errors.city = action.payload;
    },
    setDistrictError: (state, action: PayloadAction<string>) => {
      state.errors.district = action.payload;
    },
    setFoundDistricts: (state, action: PayloadAction<any[]>) => {
      state.foundDistricts = action.payload;
    },
    clearAddress: (state) => {
      state.address = {
        placeName: '',
        district: '',
        street: '',
        id: 0,
      };
    },
    clearErrors: (state) => {
      state.errors = {
        city: '',
        district: '',
      };
    },
    clearStore: (state) => {
      for (const [key] of Object.entries(initialState)) {
        state[key as keyof object] = initialState[key as keyof object];
      }
    },
  },
});

export const {
  setLoading,
  setIsEdit,
  setData,
  setName,
  setPlace,
  setDistrict,
  setStreet,
  setCity,
  setCityId,
  setFoundCities,
  setCityError,
  setDistrictError,
  setFoundDistricts,
  clearAddress,
  clearStore,
  clearErrors,
} = editLpuFormSlice.actions;

export const editLpuFormReducer = editLpuFormSlice.reducer;

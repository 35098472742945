import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { UserController } from './controllers/user.controller';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import EventEmitter from './helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from './helpers/EventEmitter/events.constants';
import { checkIsIOSAgent } from './helpers/checkIsIOSAgent';
import { addMaximumScaleIOS } from './helpers/addMaximumScaleIOS';
import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material';
import { closeSnackBarWithDefaults } from './store/app.slice';
import CloseIcon from '@mui/icons-material/Close';

function App() {
  const dispatch = useAppDispatch();
  const controller = new UserController(dispatch);
  const app = useAppSelector((state) => state.app);

  useEffect(() => {
    if (localStorage?.accessToken?.length > 0) {
      controller.getProfile();
    } else {
      controller.clearState();
    }
    EventEmitter.subscribe(
      EMITTER_EVENTS.SET_SERVER_ERROR,
      controller.setServerError
    );
    EventEmitter.subscribe(
      EMITTER_EVENTS.SET_TOKENS_ERROR,
      controller.setTokensInvalidError
    );
    EventEmitter.subscribe(
      EMITTER_EVENTS.SET_AUTH_ERROR,
      controller.setAuthenticateError
    );

    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.SET_SERVER_ERROR);
      EventEmitter.unsubscribe(EMITTER_EVENTS.SET_TOKENS_ERROR);
      EventEmitter.unsubscribe(EMITTER_EVENTS.SET_AUTH_ERROR);
    };
  }, []);

  useEffect(() => {
    checkIsIOSAgent() && addMaximumScaleIOS();
  }, []);

  const handleCloseSnackBar = () => {
    dispatch(closeSnackBarWithDefaults());
  };

  return (
    <div className="app">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={app.showAppSnackBar}
        autoHideDuration={app.snackBarTimeout}
        onClose={handleCloseSnackBar}
      >
        <Alert
          severity={app.snackBarSeverity as AlertColor}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackBar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          {app.snackBarText}
        </Alert>
      </Snackbar>
      <Outlet />
    </div>
  );
}

export default App;

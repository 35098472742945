import React, { useEffect } from 'react';
import { BurgerMenu } from '../../../../ui/BurgerMenu';
import {
  Button,
  Menu,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { MeetupsReportController } from '../../meetups-report.controller';
import moment from 'moment';
import { DateCalendar } from '@mui/x-date-pickers';
import MonthYearPicker from '../../../../ui/MonthYearPicker';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';

export const MeetupsReport = () => {
  const meetupsReport = useAppSelector((state) => state.meetupsReport);
  const dispatch = useAppDispatch();
  const controller = new MeetupsReportController(dispatch);
  useEffect(() => {
    controller.getStatistic();
  }, []);

  return (
    <div className="meetups-report">
      <section className="meetups-report__header-container">
        <h2 className="meetups-report__header">
          <BurgerMenu classNames={['meetups-report__burger-menu']} />
          Отчет по встречам
        </h2>
        <div className="meetups-report__filters">
          <div className="meetups-report__period-container">
            <span>Период</span>
            <MonthYearPicker
              placeholder="Начало"
              date={meetupsReport.startDate}
              onChange={controller.onStartDateChange}
            />
            <MonthYearPicker
              placeholder="Конец"
              date={meetupsReport.endDate}
              onChange={controller.onEndDateChange}
            />
          </div>
        </div>
      </section>
      {meetupsReport.data.length ? (
        <TableContainer className="meetups-report__table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="meetups-report__table-heading"
                  style={{ paddingLeft: 0 }}
                >
                  Сотрудник
                </TableCell>

                {meetupsReport.headingDates?.map((date) => (
                  <TableCell
                    className="meetups-report__table-heading"
                    align="right"
                    key={date}
                  >
                    {date}
                  </TableCell>
                ))}
                <TableCell
                  className="meetups-report__table-heading"
                  align="right"
                >
                  Динамика
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetupsReport.data.map((row) => (
                <TableRow
                  key={row.agent.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row" style={{ paddingLeft: 0 }}>
                    <span className="meetups-report__fullname">
                      {row.agent.fullName}
                    </span>
                  </TableCell>

                  {row.units.map((statistic) => (
                    <TableCell align="right" key={statistic.scale.month}>
                      <span className="meetups-report__summary">
                        {statistic.value}
                      </span>
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    <span
                      className={`meetups-report__dynamic ${
                        row.dynamic < 0 ? 'negative' : ''
                      }`}
                    >
                      {row.dynamic !== 0 && (
                        <ArrowDropDown
                          className={`meetups-report__dynamic-indicator ${
                            row.dynamic < 0 ? '' : 'positive'
                          }`}
                        />
                      )}
                      {Math.abs(row.dynamic)}%
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoDataPlaceholder
          classNames={['meetups-report__no-data']}
          isHaveTabOffset
          label="Нет данных"
        />
      )}
    </div>
  );
};

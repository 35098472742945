import React, { useEffect } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import AppSelect from '../../../../ui/AppSelect';
import ExecutorsSelect from '../../../../components/ExecutorsSelect';
import EditableNote from '../../../../components/EditableNote';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import { CreateFormController } from '../../create-task-form.controller';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

interface ICreateTaskFormProps {
  classNames?: string[];
  doctors?: number[];
  onClose?: () => void;
  onCreate?: () => void;
}

const taskOptions: IAppSelectOption[] = [
  { id: 1, value: 'meetup', text: 'Встреча' },
  { id: 2, value: 'envelope', text: 'Передать конверт' },
];

export const CreateTaskForm = (props: ICreateTaskFormProps) => {
  const dispatch = useAppDispatch();
  const controller = new CreateFormController(dispatch);
  const createTaskForm = useAppSelector((state) => state.createTaskForm);
  const onCreateTask = () => controller.onCreateTask(props.onCreate);

  useEffect(() => {
    props.doctors && controller.setDoctorsId(props.doctors);
  }, [props.doctors]);

  useEffect(() => {
    controller.getExecutors();

    return () => {
      controller.clearState();
    };
  }, []);

  return (
    <div className={classNamesParser('create-task-form', props.classNames)}>
      <div className="create-task-form__heading">
        <h3 className="create-task-form__title">Новое задание</h3>
        <RoundButton
          classNames={['create-task-form__close-modal']}
          onClick={props.onClose}
          content={<CloseIcon />}
        />
      </div>
      <div className="create-task-form__form">
        <AppSelect
          classNames={['create-task-form__form-input']}
          options={taskOptions}
          handleChange={controller.onTaskTypeChange}
          value={createTaskForm.type}
          renderText
        />
        <ExecutorsSelect
          classNames={['create-task-form__form-input']}
          executors={createTaskForm.executors}
          value={createTaskForm.selectedExecutors}
          onSelect={controller.onExecutorsChange}
        />
        <EditableNote
          isEditing={createTaskForm.isEditingNote}
          classNames={['create-task-form__form-input']}
          onNoteClick={controller.onNoteClick}
          onConfirm={controller.onAcceptNoteClick}
          onCancelEdit={controller.onCancelNoteClick}
          noteInput={createTaskForm.noteInput}
          onNoteInputChange={controller.onNoteInputChange}
          note={createTaskForm.note}
        />
        <div className="create-task-form__summary">
          <div className="create-task-form__doctors-count-container">
            <span className="create-task-form__summary-text">
              Врачей в задании:&nbsp;
            </span>
            <span className="create-task-form__summary-value">
              {createTaskForm.doctorsId.length}
            </span>
          </div>
        </div>
      </div>
      <div className="create-task-form__buttons">
        <Button className="create-task-form__cancel" onClick={props.onClose}>
          Отмена
        </Button>
        <Button className="create-task-form__create" onClick={onCreateTask}>
          Создать
        </Button>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import { Button, Pagination, Tab, Tabs } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { usersMock } from '../../mocks/users.mock';
import { UserItem } from '../..';
import { UsersListController } from '../../users-list.controller';
import AddIcon from '@mui/icons-material/Add';
import AppModal from '../../../../components/AppModal';
import AddUserForm from '../../../../components/AddUserForm';
import EditUserForm from '../../../../components/EditUserForm';
import { BurgerMenu } from '../../../../ui/BurgerMenu';
import { Virtuoso } from 'react-virtuoso';

interface IUsersListProps {
  classNames?: string[];
}

export const UsersList = (props: IUsersListProps) => {
  const dispatch = useAppDispatch();
  const usersList = useAppSelector((state) => state.usersList);
  const controller = new UsersListController(dispatch);

  const loadMore = useCallback(
    () => setTimeout(() => controller.getUsers(), 0),
    [controller.getUsers]
  );

  useEffect(() => {
    const timeout = loadMore();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={classNamesParser('users-list', props.classNames)}>
      <section className="users-list__title-container">
        <h2 className="users-list__title">
          <BurgerMenu classNames={['users-list__burger-menu']} />
          Пользователи
        </h2>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={controller.openAddModal}
        >
          Добавить
        </Button>
      </section>
      <Tabs
        value={usersList.tabValue}
        className="users-list__tabs"
        onChange={controller.handleTabChange}
      >
        <Tab value={'active'} label="Активные" />
        <Tab value={'not_active'} label="Неактивные" />
      </Tabs>

      <Virtuoso
        data={usersList.users}
        endReached={loadMore}
        defaultItemHeight={76.5}
        components={{
          Footer: () => <div style={{ marginTop: 32 }}>&nbsp;</div>,
        }}
        itemContent={(i, item) => (
          <UserItem
            id={item.id}
            onClick={controller.onUserClick}
            key={item.id}
            classNames={['users-list__item']}
            userName={item.fullName}
            role={item.role}
          />
        )}
      />

      <AppModal
        hideBackdrop
        classNames={['users-list__app-modal']}
        onClose={controller.closeAddModal}
        open={usersList.isAddModalOpen}
      >
        <AddUserForm
          onClose={controller.closeAddModal}
          onConfirm={controller.onCreateUser}
        />
      </AppModal>
      <AppModal
        hideBackdrop
        classNames={['users-list__app-modal']}
        onClose={controller.closeEditModal}
        open={usersList.isEditModalOpen}
      >
        <EditUserForm
          isLoading={usersList.isUserLoading}
          user={usersList.currentUser}
          onClose={controller.closeEditModal}
          onConfirm={controller.onEditUser}
          onChangeState={controller.onChangeUserState}
          onDelete={controller.onDeleteUser}
          onPasswordChange={controller.onPasswordChange}
        />
      </AppModal>
    </div>
  );
};

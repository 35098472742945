import React, { useState } from 'react';
import PercentageProgress from '../../../../ui/PercentageProgress';
import RoundedLabel from '../../../../ui/RoundedLabel';
import { ReactComponent as TaskIcon } from '../../../../assets/vector/note.svg';
import {
  TaskStatusTypes,
  TaskVariantTypes,
} from '../../../../types/tasks.types';
import moment from 'moment';
import { ITaskExecutor } from '../../interfaces/index.interfaces';
import { ReactComponent as TaskCompleteIcon } from '../../../../assets/vector/task-complete.svg';
import RoundButton from '../../../../ui/RoundButton';
import RoundMenuButton from '../../../../ui/RoundMenuButton';
import { IRoundMenuButtonItem } from '../../../../ui/RoundMenuButton/index.interfaces';
import { Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

interface ITaskItemProps {
  percentage: number;
  onClick?: (id: number) => void;
  type: TaskVariantTypes;
  status: TaskStatusTypes;
  createdAt: string;
  description: string | null;
  id: number;
  executors: ITaskExecutor[];
  countDoctors: number;
  finishedAt: string | null;
  finishedDoctors: number;
  onMenuItemClick?: (
    status: TaskStatusTypes,
    id: number
  ) => Promise<void> | void;
  onDeleteTask?: (id: number) => void;
}

const TaskItem = (props: ITaskItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTaskClick = () => {
    if (!open) {
      props.onClick && props.onClick(props.id);
    }
  };

  const handleChangeStatus = (status: TaskStatusTypes) => {
    props.onMenuItemClick && props.onMenuItemClick(status, props.id);
  };

  const handleDeleteTask = () => {
    props.onDeleteTask && props.onDeleteTask(props.id);
  };

  // const menuItems: IRoundMenuButtonItem[] = [
  //   {
  //     id: 1,
  //     text: props.status === 'draft' ? 'В работу' : 'Завершить',
  //     color: '#2574D0',
  //   },
  //   { id: 2, text: 'Удалить', color: '#FF4C06' },
  // ];

  // const handleMenuItemClick = (value: string) => {
  //   if (props.onMenuItemClick) {
  //     const status: TaskStatusTypes =
  //       value === 'В работу'
  //         ? 'in_progress'
  //         : value === 'Завершить'
  //         ? 'done'
  //         : 'draft';

  //     props.onMenuItemClick(status, props.id);
  //   }
  // };

  // const handleMenuStateChange = (value: boolean) => {
  //   setMenuOpen(value);
  // };

  return (
    <div className="task-item" onClick={handleTaskClick}>
      <div className="task-item__wrapper">
        {props.status !== 'done' &&
          (props.status === 'in_progress' ? (
            <PercentageProgress
              value={Math.floor(props.percentage)}
              styles={{
                width: 64,
                height: 64,
              }}
              thickness={2}
              classNames={['task-item__progress']}
            />
          ) : (
            <div className="task-item__new-label">черновик</div>
          ))}

        <div className="task-item__task-info">
          <h3 className="task-item__title">
            {props.status === 'done' && <TaskCompleteIcon />}
            {props.type === 'envelope' ? 'Передать конверт' : 'Встреча'}
          </h3>
          {props.description && (
            <div className="task-item__description-wrapper">
              <TaskIcon className="task-item__task-icon" />
              <p className="task-item__description">{props.description}</p>
            </div>
          )}
          <div className="task-item__sub-info">
            {moment(props.createdAt).format('DD MMMM YYYY')},{' '}
            {props.status === 'done'
              ? `Выполнено: ${props.finishedDoctors} / ${props.countDoctors}`
              : `Врачей: ${props.countDoctors}`}
          </div>
        </div>
      </div>

      <div className="task-item__task-labels">
        <RoundButton
          content={<MoreHoriz />}
          onClick={handleClick}
          classNames={['task-item__round-menu']}
        />
        {/* <RoundMenuButton
          onStateChange={handleMenuStateChange}
          menuItems={menuItems}
          onMenuClick={handleMenuItemClick}
          disableMenuIds={
            (props.executors?.length && props.status === 'done') ||
            props.status === 'in_progress'
              ? void 0
              : [1]
          }
        /> */}
        {props.executors.map(
          (executor) =>
            executor.fullName && (
              <RoundedLabel
                fullname={executor.fullName}
                key={executor.id}
                classNames={['task-item__rounded-label']}
              />
            )
        )}

        {/* {props.status === 'done' && (
          <div className="task-item__statistic">
            <p className="task-item__count">
              {props.finishedDoctors} / {props.countDoctors}
            </p>
            <div className="task-item__complete-date-wrapper">
              <TaskCompleteIcon />
              <span className="task-item__complete-date">
                {moment(props.finishedAt).format('DD MMMM YYYY')}
              </span>
            </div>
          </div>
        )} */}

        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          {(props.status === 'draft' || props.status === 'done') && (
            <MenuItem
              disabled={!props.executors.length}
              style={{ color: '#2574D0' }}
              onClick={() => handleChangeStatus('in_progress')}
            >
              Передать в работу
            </MenuItem>
          )}

          {props.status === 'in_progress' && (
            <MenuItem
              style={{ color: '#2574D0' }}
              onClick={() => handleChangeStatus('done')}
            >
              Завершить
            </MenuItem>
          )}
          {props.status === 'in_progress' && (
            <MenuItem
              style={{ color: '#FF4C06' }}
              onClick={() => handleChangeStatus('draft')}
            >
              Отменить
            </MenuItem>
          )}
          {props.status === 'draft' && (
            <MenuItem style={{ color: '#FF4C06' }} onClick={handleDeleteTask}>
              Удалить
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default TaskItem;

import { useMemo } from 'react';
import { UploadFile } from '../../../../ui/UploadFile';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { ReactComponent as CameraIcon } from '../../../../assets/vector/camera.svg';
import { ResponseImage } from '../../../../types/images.types';
import { ServerImage } from '../../../../components/ServerImage';

interface IAttachImageProps {
  files: File[];
  removedImages: string[];
  images: ResponseImage[];
  onAddFiles: (value: File[]) => void;
  onRemoveFile: (id: number) => void;
  onRemoveImage: (id: string) => void;
}

export const AttachImage = (props: IAttachImageProps) => {
  const images = useMemo(() => {
    return props.images.filter(
      (image) => !props.removedImages.includes(image.id)
    );
  }, [props.images, props.removedImages]);

  const files = useMemo(() => {
    return props.files.map((file) => URL.createObjectURL(file));
  }, [props.files]);

  return (
    <div className="attach-image">
      <UploadFile asChild onChange={props.onAddFiles} multiple>
        <button className="attach-image__upload-btn">
          <div className="attach-image__btn-icon-wrapper">
            <CameraIcon className="attach-image__btn-icon" />
          </div>
        </button>
      </UploadFile>
      {images.map((image) => (
        <div key={image.id} className="attach-image__image-wrapper">
          <ServerImage
            src={image.filename}
            className="attach-image__image"
            width={100}
            height={100}
          />
          <IconButton
            className="attach-image__remove-btn"
            onClick={() => props.onRemoveImage(image.id)}
          >
            <Close />
          </IconButton>
        </div>
      ))}
      {files.map((file, i) => (
        <div key={i} className="attach-image__image-wrapper">
          <img src={file} className="attach-image__image" />
          <IconButton
            className="attach-image__remove-btn"
            onClick={() => props.onRemoveFile(i)}
          >
            <Close />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

import { useCallback, useEffect } from 'react';
import { IDoctorDynamicData } from '../../interfaces';
import { TableCell, TableRow } from '@mui/material';
import DynamicTableRow from '../DynamicTableRow';
import { TableVirtuoso } from 'react-virtuoso';
import DynamicTableComponent from '../DynamicTableComponent';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import { DynamicFixedHeader } from '../DynamicFixedHeader';

interface IDoctorsTableProps {
  data: IDoctorDynamicData[];
  headingDates: string[];
  loadFunc: () => void;
  clearTable: () => void;
}

const DoctorsTable = (props: IDoctorsTableProps) => {
  const loadMore = useCallback(() => setTimeout(() => props.loadFunc(), 0), []);

  useEffect(() => {
    const timeout = loadMore();

    return () => {
      props.clearTable();
      clearTimeout(timeout);
    };
  }, []);

  return props.data.length > 0 ? (
    <TableVirtuoso
      components={DynamicTableComponent}
      fixedHeaderContent={() => (
        <DynamicFixedHeader title="ФИО" headingDates={props.headingDates} />
      )}
      data={props.data}
      endReached={props.loadFunc}
      itemContent={(i, item) => {
        const doctorFullname = `${item.doctor?.lastName || ''} ${
          item.doctor?.firstName || ''
        } ${item.doctor?.middleName || ''}`;
        return (
          <DynamicTableRow
            key={item.doctor.id}
            id={item.doctor.id}
            name={doctorFullname}
            units={item.units}
            dynamic={item.dynamic}
          />
        );
      }}
    />
  ) : (
    <NoDataPlaceholder styles={{ margin: '32px 0' }} />
  );
};

export default DoctorsTable;

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import { ImportDataService } from '../../services/import-data.service';
import {
  setCurrentFileId,
  setCurrentTotal,
  pushData,
  clearData,
  setIsHideMatched,
  setIsLoadCountVisible,
  setLoadedToStatistics,
  setMismatchedCount,
  setOffset,
  setHaveMore,
  setSearchInput,
  setSearchOpened,
  setSearchValue,
  setSearchedDoctors,
  setTotal,
  setUploadDate,
  setLoading,
  updateRowMatch,
  clearRowMatch,
} from './store/upload-table.slice';
import moment from 'moment';
import { CatalogsService } from '../../services/catalogs.service';
import { IDoctorCandidate } from './index.interfaces';
import { showAppSnackBar } from '../../store/app.slice';
import { ViewController } from '../../interfaces/general.interfaces';

export class UploadTableController extends ViewController {
  importDataService = new ImportDataService();
  catalogsService: CatalogsService = new CatalogsService();

  setCurrentFileId = async (id: number) => {
    this.dispatch(setCurrentFileId(id));
  };

  getFileData = async () => {
    const state = this.getState().uploadTable;
    console.log(!state.currentFileId || !state.haveMore || state.isLoading);

    if (!state.currentFileId || !state.haveMore || state.isLoading) return;
    this.dispatch(setLoading(true));
    const data = await this.importDataService.getUploadedFile(
      state.currentFileId,
      state.offset,
      state.limit,
      !state.isHideMatched,
      !!state.searchValue ? state.searchValue : null
    );
    if (data) {
      this.dispatch(pushData(data.data));
      this.dispatch(setCurrentTotal(data.total));
      this.dispatch(setMismatchedCount(data.countNotMatched));
      state.total === 0 && this.dispatch(setTotal(data.total));
      this.dispatch(setUploadDate(moment(data.date).format('D MMMM YYYY')));
      this.dispatch(setLoadedToStatistics(data.loadedToStatistics));
      this.dispatch(setOffset(state.offset + state.limit));
      const haveMore = data.total > state.data.length + data.data.length;
      this.dispatch(setHaveMore(haveMore));
    }
    this.dispatch(setLoading(false));
  };

  hideResultAlert = async () => {
    this.dispatch(setIsLoadCountVisible(false));
  };

  clearState = () => {
    this.dispatch(setHaveMore(true));
    this.dispatch(setLoading(false));
    this.dispatch(setOffset(0));
    this.dispatch(setIsHideMatched(false));
    this.dispatch(setTotal(0));
    this.dispatch(setIsLoadCountVisible(true));
    this.dispatch(setSearchOpened(false));
    this.dispatch(setSearchValue(''));
    this.dispatch(setSearchInput(''));
    this.dispatch(clearData());
  };

  onChangeHideMatched = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setIsHideMatched(e.target.checked));
    this.refreshList();
  };

  onSearchDoctors = async (fullName: string) => {
    const data = await this.catalogsService.getDoctorsList(
      null,
      null,
      null,
      null,
      null,
      null,
      fullName
    );
    if (data !== null) {
      this.dispatch(setSearchedDoctors(data.data as IDoctorCandidate[]));
    }
  };

  clearSearchedDoctors = async () => {
    this.dispatch(setSearchedDoctors([]));
  };

  onMatchDoctor = async (
    doctorID: number,
    rowID: number,
    doctorFullname: string,
    index: number
  ) => {
    const state = this.getState().uploadTable;
    if (!state.currentFileId) return;
    const data = await this.importDataService.setDoctorToReport(
      doctorID,
      rowID,
      state.currentFileId
    );
    if (data !== null) {
      this.dispatch(updateRowMatch({ i: index, doctorID, doctorFullname }));
    }
  };

  clearDoctorReport = async (rowID: number, index: number) => {
    const state = this.getState().uploadTable;
    if (!state.currentFileId) return;
    const data = await this.importDataService.clearDoctorReport(
      rowID,
      state.currentFileId
    );
    if (data !== null) {
      this.dispatch(clearRowMatch(index));
      this.dispatch(
        showAppSnackBar({ snackBarSeverity: 'success', text: 'Успешно!' })
      );
    } else {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Ошибка. Что-то пошло не так.',
        })
      );
    }
  };

  onOpenSearch = () => {
    this.dispatch(setSearchOpened(true));
  };

  onCloseSearch = () => {
    this.dispatch(setSearchOpened(false));
    this.dispatch(setSearchInput(''));
    this.dispatch(setSearchValue(''));
    this.onSearch();
  };

  onSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setSearchInput(e.target.value));
  };

  onSearch = () => {
    const state = this.getState().uploadTable;
    this.dispatch(setSearchValue(state.searchInput));
    this.refreshList();
  };

  refreshList = async () => {
    this.dispatch(setOffset(0));
    this.dispatch(setHaveMore(true));
    this.dispatch(setLoading(false));
    this.dispatch(clearData());
    await this.getFileData();
    await this.getFileData();
  };
}

import React from 'react';
import ArrowIcon from '@mui/icons-material/ArrowDropDown';
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import RoundedLabel from '../../../../ui/RoundedLabel';
import { IFeedbackUser } from '../../interfaces';

interface IExecutorSelectProps {
  value?: IFeedbackUser;
  liables?: IFeedbackUser[];
  onSelect: (event: SelectChangeEvent<string>) => void;
}

const ExecutorSelect = (props: IExecutorSelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const value = String(props.value?.id || '');

  return (
    <>
      <Select
        className="executor-select__select"
        open={open}
        onClose={handleClose}
        MenuProps={{
          anchorEl: anchorEl,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        }}
        value={value}
        label="Age"
        style={{ display: 'none' }}
        onChange={props.onSelect}
      >
        {props.liables?.map((liable) => (
          <MenuItem value={liable.id} key={liable.id}>
            <RoundedLabel fullname={liable.fullName || ''} /> {liable.fullName}
          </MenuItem>
        ))}
      </Select>
      <Button className="executor-select" onClick={handleOpen}>
        <div className="executor-select__executors">
          {!value ? (
            <p className="executor-select__placeholder">Ответственный</p>
          ) : (
            <RoundedLabel
              fullname={props.value?.fullName || ''}
              classNames={['executor-select__executor']}
            />
          )}
        </div>

        <div className="task-executors__choose">
          Выбрать
          <ArrowIcon />
        </div>
      </Button>
    </>
    // <div className="executor-select">
    //   <p className="executor-select__placeholder">Ответственный</p>
    //   <Button className="executor-select__choose">
    //     Выбрать
    //     <ArrowIcon className="executor-select__arrow" />
    //   </Button>
    // </div>
  );
};

export default ExecutorSelect;

import { Button } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import Input from '../../../../ui/Input';
import { ReactComponent as PhoneIcon } from '../../../../assets/vector/phone.svg';
import RoundButton from '../../../../ui/RoundButton';
import { Close } from '@mui/icons-material';
import DeleteConfirmMenu from '../../../../components/DeleteConfirmMenu';

interface IContactsMobileFormProps {
  classNames?: string;
  phoneNumbers?: string[];
  onAddNumber: () => void;
  onDeleteNumber: (id: number) => void;
  onClose?: () => void;
  onInputChage: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  onSave: () => void;
  phoneNumberErrors: string[];
}

const ContactsMobileForm = (props: IContactsMobileFormProps) => {
  const [phoneIdToDelete, setPhoneIdToDelete] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setPhoneIdToDelete(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteConfirm = () => {
    if (phoneIdToDelete !== null) props.onDeleteNumber(phoneIdToDelete);
    handleClose();
  };

  return (
    <div className="contacts-mobile-form">
      <div className="contacts-mobile-form__header">
        <Button className="contacts-mobile-form__close" onClick={props.onClose}>
          Отменить
        </Button>
        <h5 className="contacts-mobile-form__title">Контакты</h5>
        <Button className="contacts-mobile-form__close" onClick={props.onSave}>
          Готово
        </Button>
      </div>
      <div className="contacts-mobile-form__inputs">
        {props.phoneNumbers?.map((phone, id: number) => (
          <div className="contacts-mobile-form__input-wrapper">
            <Input
              fullWidth
              type="tel"
              value={phone}
              error={props.phoneNumberErrors[id]}
              key={id}
              autoFocus={id === (props.phoneNumbers?.length || 1) - 1}
              onInput={(e) => props.onInputChage(e, id)}
              classNames={['contacts-mobile-form__input']}
            />
            <RoundButton
              content={<Close />}
              onClick={(e) => handleClick(e, id)}
              classNames={['contacts-mobile-form__delete-btn']}
            />
          </div>
        ))}
      </div>
      <Button className="contacts-mobile-form__add" onClick={props.onAddNumber}>
        <PhoneIcon />
        Добавить
      </Button>

      <DeleteConfirmMenu
        onClose={handleClose}
        open={open}
        text="Вы действительно хотите удалить контакт?"
        deleteText="Удалить"
        anchorEl={anchorEl}
        onConfirm={onDeleteConfirm}
      />
    </div>
  );
};

export default ContactsMobileForm;

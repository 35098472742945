import React, { CSSProperties, useCallback, useEffect } from 'react';
import { BurgerMenu } from '../../../../ui/BurgerMenu';
import { FilterList, MoreHoriz } from '@mui/icons-material';
import {
  Menu,
  MenuItem,
  Pagination,
  Tab,
  TableContainer,
  Tabs,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { DynamicTableController } from '../../dynamic-table.controller';
import { IDynamicPeriod } from '../../interfaces';
import RoundButton from '../../../../ui/RoundButton';
import { DynamicTableTabs } from '../../enums';
import MonthYearPicker from '../../../../ui/MonthYearPicker';
import DoctorsTable from '../DoctorsTable';
import LpuDynamicTable from '../LpuDynamicTable';
import CityDynamicTable from '../CityDynamicTable';
import { setOffset } from '../../store/dynamic-table.slice';
import { ReactComponent as IconExcel } from '../../../../assets/vector/excel.svg';
import { store } from '../../../../app.store';

export const DynamicTable = () => {
  const dynamicTable = useAppSelector((state) => state.dynamicTable);
  const dispatch = useAppDispatch();
  const controller = new DynamicTableController(dispatch);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isHasDynamicValues = () => {
    const state = store.getState().dynamicTable;
    const hasValuesMap = {
      [DynamicTableTabs.DOCTORS]: !!state.doctorsDynamic.length,
      [DynamicTableTabs.CITY]: !!state.cityDynamic.length,
      [DynamicTableTabs.FACILITY]: !!state.lpuDynamic.length,
    };
    return hasValuesMap[state.tabValue];
  };

  const handleDownloadExcelReport = () => {
    const tabValue = store.getState().dynamicTable.tabValue;
    const downloadFunctionsMap = {
      [DynamicTableTabs.DOCTORS]: controller.handleDownloadDoctorDynamic,
      [DynamicTableTabs.CITY]: controller.handleDownloadCityDynamic,
      [DynamicTableTabs.FACILITY]: controller.handleDownloadLpuDynamic,
    };
    return downloadFunctionsMap[tabValue]();
  };

  return (
    <div className="dynamic-table">
      <section className="dynamic-table__header-container">
        <h2 className="dynamic-table__header">
          <BurgerMenu classNames={['dynamic-table__burger-menu']} />
          Динамика назначений
        </h2>
        <div className="dynamic-table__period-container">
          <span>Период</span>
          <MonthYearPicker
            placeholder="Начало"
            onChange={controller.onStartDateChange}
            date={dynamicTable.startDate}
          />
          <MonthYearPicker
            placeholder="Конец"
            onChange={controller.onEndDateChange}
            date={dynamicTable.endDate}
          />
        </div>
      </section>
      <div className="dynamic-table__content">
        <div className="dynamic-table__tabs-wrapper">
          <Tabs
            className="dynamic-table__tabs"
            value={dynamicTable.tabValue}
            onChange={controller.onTabValueChange}
          >
            <Tab label="ЛПУ" value={DynamicTableTabs.FACILITY} />
            <Tab label="Населенный пункт" value={DynamicTableTabs.CITY} />
            <Tab label="Врачи" value={DynamicTableTabs.DOCTORS} />
          </Tabs>
          <div className="dynamic-table__filters">
            <RoundButton content={<MoreHoriz />} onClick={handleClick} />
          </div>
        </div>
        {/* <TableContainer className="dynamic-table__table"> */}
        {dynamicTable.tabValue === DynamicTableTabs.DOCTORS && (
          <DoctorsTable
            loadFunc={controller.getDoctorsStatistic}
            data={dynamicTable.doctorsDynamic}
            headingDates={dynamicTable.headingDates}
            clearTable={controller.clearCurrentStats}
          />
        )}
        {dynamicTable.tabValue === DynamicTableTabs.FACILITY && (
          <LpuDynamicTable
            data={dynamicTable.lpuDynamic}
            loadFunc={controller.getLpuStatistics}
            headingDates={dynamicTable.headingDates}
            clearTable={controller.clearCurrentStats}
          />
        )}
        {dynamicTable.tabValue === DynamicTableTabs.CITY && (
          <CityDynamicTable
            data={dynamicTable.cityDynamic}
            loadFunc={controller.getCityStatistics}
            headingDates={dynamicTable.headingDates}
            clearTable={controller.clearCurrentStats}
          />
        )}
        {/* </TableContainer> */}
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          style={MenuItemStyles}
          onClick={handleDownloadExcelReport}
          disabled={!isHasDynamicValues()}
        >
          <IconExcel style={IconExcelStyles} />
          Скачать отчет
        </MenuItem>
      </Menu>
    </div>
  );
};

const IconExcelStyles: CSSProperties = {
  width: 22,
  height: 22,
  marginRight: 8,
};
const MenuItemStyles: CSSProperties = {
  paddingLeft: 12,
  display: 'flex',
  alignItems: 'center',
};

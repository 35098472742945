import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { ArrowDropDown } from '@mui/icons-material';
interface IDoctorsTableRowProps {
  id: number;
  name: string;
  units: any[];
  dynamic: number;
}

const DynamicTableRow = (props: IDoctorsTableRowProps) => {
  return (
    <>
      <TableCell scope="row" style={{ paddingLeft: 0 }}>
        <span className="dynamic-table-row__name">{props.name}</span>
      </TableCell>
      {props.units.map((unit, i) => (
        <TableCell align="right" key={i}>
          <span className="dynamic-table-row__summary">{unit.value} ₽</span>
        </TableCell>
      ))}
      <TableCell align="right">
        <span
          className={`dynamic-table-row__dynamic ${
            props.dynamic < 0 ? 'negative' : ''
          }`}
        >
          {props.dynamic !== 0 && (
            <ArrowDropDown
              className={`dynamic-table-row__dynamic-indicator ${
                props.dynamic < 0 ? '' : 'positive'
              }`}
            />
          )}
          {Math.abs(props.dynamic)}%
        </span>
      </TableCell>
    </>
  );
};

export default DynamicTableRow;

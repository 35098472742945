import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMeetupHistoryPageState {
  filtersOpened: boolean;
  filtersApplied: boolean;
}

const initialState: IMeetupHistoryPageState = {
  filtersOpened: false,
  filtersApplied: false,
};

export const meetupHistoryPageSlice = createSlice({
  name: 'meetup-history-page',
  initialState,
  reducers: {
    setFiltersOpened: (state, action: PayloadAction<boolean>) => {
      state.filtersOpened = action.payload;
    },
    setFiltersApplied: (state, action: PayloadAction<boolean>) => {
      state.filtersApplied = action.payload;
    },
  },
});

export const { setFiltersOpened, setFiltersApplied } =
  meetupHistoryPageSlice.actions;

export const meetupHistoryPageReducer = meetupHistoryPageSlice.reducer;

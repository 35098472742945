import { AppDispatch, RootState, store } from '../app.store';
import { objectKeysSnakeToCamel } from '../helpers/objectKeysSnakeToCamel';
import { setError } from '../modules/SignInForm/store/sign-in.slice';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import {
  clearState,
  setEmail,
  setFullName,
  setId,
  setIsAuthenticated,
  setLoading,
  setRole,
} from '../store/user.slice';

export class UserController {
  dispatch: AppDispatch;
  getState: () => RootState;
  authService: AuthService = new AuthService();
  userService: UserService = new UserService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  getProfile = async () => {
    const data = await this.userService.getProfile();
    if (data) {
      this.dispatch(setFullName(data.fullName));
      this.dispatch(setEmail(data.email));
      this.dispatch(setId(data.id));
      this.dispatch(setIsAuthenticated(true));
      this.dispatch(setLoading(false));
      this.dispatch(setRole(data.role));
    } else {
      this.clearState();
    }
  };

  login = async (login: string, password: string) => {
    this.dispatch(setLoading(true));
    const data = await this.authService.login(login, password);
    if (data) {
      const parsedData = objectKeysSnakeToCamel(data);
      localStorage.accessToken = parsedData.accessToken;
      localStorage.refreshToken = parsedData.refreshToken;
      await this.getProfile();
    } else {
      this.dispatch(
        setError(
          'Вы указали неверное сочетание логина или пароля. Обратитесь к Администатору.'
        )
      );
    }
    this.dispatch(setLoading(false));
  };

  logout = async () => {
    await this.authService.logout();
    this.dispatch(setIsAuthenticated(false));
    this.dispatch(setLoading(false));
    localStorage.accessToken = '';
    localStorage.refreshToken = '';
  };

  setServerError = async () => {
    this.dispatch(setIsAuthenticated(false));
    this.dispatch(setLoading(false));
    localStorage.accessToken = '';
    localStorage.refreshToken = '';
    this.dispatch(setError('Ошибка сервера. Обратитесь к Администатору.'));
  };

  setAuthenticateError = async () => {
    this.dispatch(setIsAuthenticated(false));
    this.dispatch(setLoading(false));
    localStorage.accessToken = '';
    localStorage.refreshToken = '';
    this.dispatch(setError('Под вашим аккаунтом уже кто-то находится в сети.'));
  };

  setTokensInvalidError = async () => {
    this.dispatch(setIsAuthenticated(false));
    this.dispatch(setLoading(false));
    localStorage.accessToken = '';
    localStorage.refreshToken = '';
    this.dispatch(setError('Ваша сессия истекла. Авторизуйтесь повторно'));
  };

  clearState = async () => {
    this.dispatch(clearState());
  };
}

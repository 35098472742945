export const APP_AVAILABILITY_NAMES = {
  ALL: 'Все',
  YES: 'Использует',
  NO: 'Не использует',
};

export const ACTIVE_TYPE_NAMES = {
  ACTIVE: 'Активные',
  INACTIVE: 'Не активные',
};

export const APP_AVAILABILITY_MAP = {
  [APP_AVAILABILITY_NAMES.ALL]: null,
  [APP_AVAILABILITY_NAMES.YES]: true,
  [APP_AVAILABILITY_NAMES.NO]: false,
};

export const ACTIVE_TYPES_MAP = {
  [ACTIVE_TYPE_NAMES.ACTIVE]: false,
  [ACTIVE_TYPE_NAMES.INACTIVE]: true,
};

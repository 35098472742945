import React, { ChangeEvent } from 'react';
import { ReactComponent as NoteIcon } from '../../assets/vector/note.svg';
import { Button } from '@mui/material';
import RoundButton from '../../ui/RoundButton';
import ConfirmIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import Input from '../../ui/Input';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IEditableNoteProps {
  classNames?: string[];
  isEditing?: boolean;
  onCancelEdit?: () => void;
  onConfirm?: () => void;
  note?: string | null;
  noteInput?: string;
  onNoteInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onNoteClick?: () => void;
  placeholder?: string;
}

const EditableNote = (props: IEditableNoteProps) => {
  const placeholder = props.placeholder
    ? props.placeholder
    : 'Добавьте заметку';
  return (
    <div className={classNamesParser('editable-note', props.classNames)}>
      {props.isEditing ? (
        <div className="editable-note__input-wrapper">
          <Input
            multiline
            maxRows={5}
            fullWidth
            autoFocus
            value={props.noteInput}
            onInput={props.onNoteInputChange}
            placeholder={placeholder}
            classNames={['editable-note__input']}
          />
          <div className="editable-note__edit-buttons">
            <RoundButton
              content={<ConfirmIcon />}
              onClick={props.onConfirm}
              classNames={['editable-note__edit-btn']}
            />
            <RoundButton
              content={<CancelIcon />}
              onClick={props.onCancelEdit}
              classNames={['editable-note__edit-btn']}
            />
          </div>
        </div>
      ) : (
        <Button className="editable-note__note" onClick={props.onNoteClick}>
          <NoteIcon className="editable-note__icon" />
          {props.note ? props.note : placeholder}
        </Button>
      )}
    </div>
  );
};

export default EditableNote;

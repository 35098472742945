import React from 'react';
import RoundedLabel from '../../../../ui/RoundedLabel';
import { Button, Tooltip } from '@mui/material';
import SubinfoDoctorSpan from '../../../../ui/SubinfoDoctorSpan';
import { ReactComponent as MeetupIcon } from '../../../../assets/vector/handshake.svg';
import { ReactComponent as EnvelopeIcon } from '../../../../assets/vector/envelope-success.svg';
import { MeetupStatusTypes } from '../../../../types/tasks.types';
interface IReportCellProps {
  value: string;
  className?: string;
  status?: MeetupStatusTypes;
}

const ExecutorCell = (props: IReportCellProps) => {
  return (
    <div className={props.className}>
      <RoundedLabel fullname={props.value} />
    </div>
  );
};

const FeedbackCell = (props: IReportCellProps) => {
  return (
    <Tooltip title={props.value}>
      <p className={props.className}>{props.value}</p>
    </Tooltip>
  );
};

const DefaultCell = (props: IReportCellProps) => {
  return <p className={props.className}>{props.value}</p>;
};

const LpuCell = (props: IReportCellProps) => {
  return (
    <Tooltip title={props.value}>
      <div>
        <SubinfoDoctorSpan
          classNames={[props.className || '']}
          variant="lpu"
          value={props.value}
        />
      </div>
    </Tooltip>
  );
};
const SpecialtyCell = (props: IReportCellProps) => {
  return (
    <Tooltip title={props.value}>
      <div>
        <SubinfoDoctorSpan
          classNames={[props.className || '']}
          variant="specialty"
          value={props.value}
        />
      </div>
    </Tooltip>
  );
};

const TypeCell = (props: IReportCellProps) => {
  const Icon = props.value === 'envelope' ? EnvelopeIcon : MeetupIcon;
  return (
    <Icon
      className={`${props.className} ${props.className}_${props.status} ${props.className}_${props.value}`}
    />
  );
};

export {
  ExecutorCell,
  FeedbackCell,
  DefaultCell,
  LpuCell,
  SpecialtyCell,
  TypeCell,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskDetailFiltersType } from '../index.types';
import { ITaskDoctor, ITaskExecutor } from '../interfaces';
import { TaskStatusTypes, TaskVariantTypes } from '../../../types/tasks.types';
import { TASK_DETAIL_DOCTOR_LIMIT } from '../constants';
import { TaskDetailFilters } from '../enums';
import { TaskFiltersEnum } from '../../../components/TaskFilters/task-filters.enum';
interface ITaskDetailState {
  isLoading: boolean;
  filter: TaskFiltersEnum;
  taskId?: number;
  doctors: ITaskDoctor[];
  status?: TaskStatusTypes;
  createdAt?: string;
  description: string | null;
  type?: TaskVariantTypes;
  header?: string;
  limit: number;
  offset: number;
  haveMore: boolean;
  executors: string[];
  availableExecutors: ITaskExecutor[];
  isEditingDescription: boolean;
  descriptionInput: string;
  searchInput: string;
  searchValue: string;
}

const initialState: ITaskDetailState = {
  isLoading: false,
  filter: TaskFiltersEnum.DOCTOR_FULLNAME,
  doctors: [],
  availableExecutors: [],
  description: null,
  executors: [],
  limit: TASK_DETAIL_DOCTOR_LIMIT,
  offset: 0,
  haveMore: true,
  isEditingDescription: false,
  descriptionInput: '',
  searchInput: '',
  searchValue: '',
};

export const taskDetailSlice = createSlice({
  name: 'task-detail',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFilter: (state, action: PayloadAction<TaskFiltersEnum>) => {
      state.filter = action.payload;
    },
    setTaskId: (state, action: PayloadAction<number>) => {
      state.taskId = action.payload;
    },
    setType: (state, action: PayloadAction<TaskVariantTypes>) => {
      state.type = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setCreatedAt: (state, action: PayloadAction<string>) => {
      state.createdAt = action.payload;
    },
    pushDoctors: (state, action: PayloadAction<ITaskDoctor[]>) => {
      state.doctors = [...state.doctors, ...action.payload];
    },
    clearDoctors: (state) => {
      state.doctors = [];
    },
    setStatus: (state, action: PayloadAction<TaskStatusTypes>) => {
      state.status = action.payload;
    },
    setHeader: (state, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },
    setExecutors: (state, action: PayloadAction<string[]>) => {
      state.executors = action.payload;
    },
    setAvailableExecutors: (state, action: PayloadAction<ITaskExecutor[]>) => {
      state.availableExecutors = action.payload;
    },
    setIsEditingDescription: (state, action: PayloadAction<boolean>) => {
      state.isEditingDescription = action.payload;
    },
    setDescriptionInput: (state, action: PayloadAction<string>) => {
      state.descriptionInput = action.payload;
    },
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    removeDoctorByIndex: (state, action: PayloadAction<number>) => {
      state.doctors = state.doctors.filter((_, i) => i !== action.payload);
    },
  },
});

export const {
  setLoading,
  setFilter,
  setTaskId,
  setCreatedAt,
  removeDoctorByIndex,
  setDescription,
  pushDoctors,
  clearDoctors,
  setStatus,
  setHeader,
  setType,
  setOffset,
  setAvailableExecutors,
  setExecutors,
  setDescriptionInput,
  setIsEditingDescription,
  setSearchInput,
  setHaveMore,
  setSearchValue,
} = taskDetailSlice.actions;
export const taskDetailReducer = taskDetailSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IApiDoctor,
  IApiDoctorMeetupsDone,
  IApiSpecialityItem,
} from '../../../interfaces/api.catalogs.interfaces';
import { MAX_MEETUPS_COUNT } from '../constants';

interface IEditDoctorFormState {
  isLoading: boolean;
  isEdit: boolean;
  info: IApiDoctor;
  editableFields: IApiDoctor;
  receivedDoctorId: number;
  specialities: any[];
  specialtySearchText: string;
  errors: {
    specialty: string;
    lpu: string[];
    birthDate: string;
    phoneErrors: string[];
  };
  lpuList: any[];
  lpuSearchText: string;
  isNoteEdit: boolean;
  noteInput: string;
  meetupsOpen: boolean;
  meetups: IApiDoctorMeetupsDone[];
  meetupsTotal: number;
  meetupsLimit: number;
  meetupsOffset: number;
  meetupsCurrentPage: number;
  meetupsPages: number;
}

const initialState: IEditDoctorFormState = {
  isLoading: false,
  isEdit: false,
  receivedDoctorId: 0,
  specialities: [],
  specialtySearchText: '',
  lpuList: [],
  lpuSearchText: '',
  info: {
    id: 0,
    archived: false,
    birthday: '',
    contactPhones: [],
    firstName: '',
    hasInstalledApp: false,
    lastName: '',
    medInstitutions: [],
    meetupDone: false,
    middleName: '',
    note: '',
    specialty: {
      name: '',
      id: 0,
      deleted: false,
    },
  },
  editableFields: {
    id: 0,
    archived: false,
    birthday: '',
    contactPhones: [],
    firstName: '',
    hasInstalledApp: false,
    lastName: '',
    medInstitutions: [],
    meetupDone: false,
    middleName: '',
    note: '',
    specialty: {
      name: '',
      id: 0,
      deleted: false,
    },
  },
  errors: {
    specialty: '',
    lpu: [],
    birthDate: '',
    phoneErrors: [],
  },
  isNoteEdit: false,
  noteInput: '',
  meetupsOpen: false,
  meetups: [],
  meetupsTotal: 0,
  meetupsLimit: MAX_MEETUPS_COUNT,
  meetupsOffset: 0,
  meetupsCurrentPage: 1,
  meetupsPages: 0,
};

export const editDoctorFormSlice = createSlice({
  name: 'edit-doctor-form',
  initialState,
  reducers: {
    setReceivedDoctorId: (state, action: PayloadAction<number>) => {
      state.receivedDoctorId = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setDoctorInfo: (state, action: PayloadAction<IApiDoctor>) => {
      state.info = action.payload;
      state.info.firstName = action.payload.firstName || '';
      state.info.lastName = action.payload.lastName || '';
      state.info.middleName = action.payload.middleName || '';
    },
    setEditableFields: (state, action: PayloadAction<IApiDoctor>) => {
      state.editableFields = action.payload;
    },
    setPhoneByIndex: (
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) => {
      if (state.editableFields.contactPhones) {
        state.editableFields.contactPhones[action.payload.index] =
          action.payload.value;
      }
    },
    pushToPhones: (state, action: PayloadAction<any>) => {
      if (state.editableFields.contactPhones) {
        state.editableFields.contactPhones.push(action.payload);
      }
    },
    popPhone: (state) => {
      if (state.editableFields.contactPhones) {
        state.editableFields.contactPhones.pop();
      }
    },
    slicePhone: (state, action: PayloadAction<number>) => {
      if (state.editableFields.contactPhones) {
        state.editableFields.contactPhones.splice(action.payload, 1);
      }
    },
    toggleHasInstalledApp: (state) => {
      state.editableFields.hasInstalledApp = !state.info.hasInstalledApp;
    },
    setHasInstalledApp: (state, action: PayloadAction<boolean>) => {
      state.editableFields.hasInstalledApp = action.payload;
    },
    setSpecialities: (state, action: PayloadAction<any[]>) => {
      state.specialities = action.payload;
    },
    setSpecialty: (state, action: PayloadAction<IApiSpecialityItem | null>) => {
      state.editableFields.specialty = action.payload;
    },
    setSpecialtySearchText: (state, action: PayloadAction<string>) => {
      state.specialtySearchText = action.payload;
    },
    setSpecialtyError: (state, action: PayloadAction<string>) => {
      state.errors.specialty = action.payload;
    },
    setLpuError: (
      state,
      action: PayloadAction<{ index: number; text: string }>
    ) => {
      state.errors.lpu[action.payload.index] = action.payload.text;
    },
    setPhoneErrors: (state, action: PayloadAction<string[]>) => {
      state.errors.phoneErrors = action.payload;
    },
    clearErrors: (state) => {
      state.errors.specialty = '';
      state.errors.lpu = [];
      state.errors.phoneErrors = [];
      state.errors.birthDate = '';
    },
    addLpuByIndex: (
      state,
      action: PayloadAction<{ value: any; index: number }>
    ) => {
      if (state.editableFields.medInstitutions) {
        state.editableFields.medInstitutions[action.payload.index] =
          action.payload.value;
      }
    },
    addLpuField: (state) => {
      const mockLpu = {
        id: 0,
        name: '',
        deleted: false,
        address: {
          id: 0,
          placeName: '',
          district: '',
          street: '',
        },
      };

      if (state.editableFields.medInstitutions) {
        state.editableFields.medInstitutions.push(mockLpu);
      }
    },
    removeLpu: (state, action: PayloadAction<number>) => {
      const index = action.payload;

      if (state.editableFields.medInstitutions) {
        state.editableFields.medInstitutions.splice(index, 1);
      }
    },
    removePhoneError: (state, action: PayloadAction<number>) => {
      state.errors.phoneErrors.splice(action.payload, 1);
    },
    setLpuSearchText: (state, action: PayloadAction<string>) => {
      state.lpuSearchText = action.payload;
    },
    setLpuList: (state, action: PayloadAction<any[]>) => {
      state.lpuList = action.payload;
    },
    removeLpuErrorByIndex: (state, action: PayloadAction<number>) => {
      state.errors.lpu.splice(action.payload, 1);
    },
    setBirthDay: (state, action: PayloadAction<string>) => {
      state.info.birthday = action.payload;
    },
    setEditableBirthday: (state, action: PayloadAction<string>) => {
      state.editableFields.birthday = action.payload;
    },
    setIsNoteEdit: (state, action: PayloadAction<boolean>) => {
      state.isNoteEdit = action.payload;
    },
    setNoteInputValue: (state, action: PayloadAction<string>) => {
      state.noteInput = action.payload;
    },
    setNoteValue: (state, action: PayloadAction<string>) => {
      state.info.note = action.payload;
    },
    setArchived: (state, action: PayloadAction<boolean>) => {
      if (state.info?.archived) {
        state.info.archived = action.payload;
      }
    },
    setMeetupsOpen: (state, action: PayloadAction<boolean>) => {
      state.meetupsOpen = action.payload;
    },
    setMeetups: (state, action: PayloadAction<IApiDoctorMeetupsDone[]>) => {
      state.meetups = action.payload;
    },
    setMeetupsTotal: (state, action: PayloadAction<number>) => {
      state.meetupsTotal = action.payload;
    },
    setMeetupsLimit: (state, action: PayloadAction<number>) => {
      state.meetupsLimit = action.payload;
    },
    setMeetupsOffset: (state, action: PayloadAction<number>) => {
      state.meetupsOffset = action.payload;
    },
    setMeetupsCurrentPage: (state, action: PayloadAction<number>) => {
      state.meetupsCurrentPage = action.payload;
    },
    setMeetupsPages: (state, action: PayloadAction<number>) => {
      state.meetupsPages = Math.ceil(action.payload / state.meetupsLimit);
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.editableFields.firstName = action.payload;
    },
    setMiddleName: (state, action: PayloadAction<string>) => {
      state.editableFields.middleName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.editableFields.lastName = action.payload;
    },
    setBirthDateError: (state, action: PayloadAction<string>) => {
      state.errors.birthDate = action.payload;
    },
  },
});

export const {
  setLoading,
  setIsEdit,
  setDoctorInfo,
  setReceivedDoctorId,
  setPhoneByIndex,
  pushToPhones,
  popPhone,
  toggleHasInstalledApp,
  setSpecialities,
  setSpecialty,
  setSpecialtySearchText,
  setSpecialtyError,
  clearErrors,
  slicePhone,
  addLpuField,
  removeLpu,
  setLpuSearchText,
  setLpuList,
  addLpuByIndex,
  setLpuError,
  removeLpuErrorByIndex,
  setBirthDay,
  setIsNoteEdit,
  setEditableBirthday,
  setNoteValue,
  setArchived,
  setMeetupsOpen,
  setMeetups,
  setMeetupsTotal,
  setMeetupsLimit,
  setMeetupsOffset,
  setMeetupsCurrentPage,
  setMeetupsPages,
  setFirstName,
  setHasInstalledApp,
  setMiddleName,
  setLastName,
  setNoteInputValue,
  setBirthDateError,
  setPhoneErrors,
  removePhoneError,
  setEditableFields,
} = editDoctorFormSlice.actions;

export const editDoctorFormReducer = editDoctorFormSlice.reducer;

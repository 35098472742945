export const MEETUPS_ADAPTIVE_PAGINATION = {
  itemLgHeight: 66,
  itemSmHeight: 110,
  lgOffset: 100,
  smOffset: 100,
};

export const TASKS_ADAPTIVE_PAGINATION = {
  itemLgHeight: 96,
  itemSmHeight: 96,
  lgOffset: 164,
  smOffset: 164,
};

export const UPLOAD_HISTORY_ADAPTIVE_PAGINATION = {
  itemLgHeight: 74,
  itemSmHeight: 74,
  lgOffset: 173,
  smOffset: 173,
};

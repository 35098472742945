import { Button, TextField, useMediaQuery } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

const FORM_HEIGHT_OFFSET = 130;

interface ICommentEditProps {
  onClose?: () => void;
  onSave?: () => void;
  comment: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CommentEdit = (props: ICommentEditProps) => {
  const [inputHeight, setInputHeight] = useState('100%');
  const matchSm = useMediaQuery('(max-width:768px)');
  const resizeForm = async () => {
    if (!matchSm) {
      setInputHeight('100%');
      return;
    }
    setInputHeight(
      `${(visualViewport?.height || 0) - FORM_HEIGHT_OFFSET}px` || '100%'
    );
  };

  useEffect(() => {
    visualViewport?.addEventListener('resize', resizeForm);

    return () => {
      visualViewport?.removeEventListener('resize', resizeForm);
    };
  }, []);
  return (
    <div className="comment-edit">
      <div className="comment-edit__header">
        <Button className="comment-edit__close" onClick={props.onClose}>
          Отменить
        </Button>
        <h3 className="comment-edit__title">Комментарий</h3>{' '}
        <Button className="comment-edit__save" onClick={props.onSave}>
          Сохранить
        </Button>
      </div>
      <div
        className="comment-edit__input-wrapper"
        style={{ height: inputHeight }}
      >
        <TextField
          onFocus={resizeForm}
          multiline
          autoFocus
          onChange={props.onChange}
          rows={1}
          placeholder="Добавьте комментарий"
          value={props.comment}
          className="comment-edit__input"
        />
      </div>
    </div>
  );
};

export default CommentEdit;

import { TaskVariantTypes } from '../../../types/tasks.types';

export const getLocaleTaskType = (key: TaskVariantTypes) => {
  const types = {
    ['envelope']: 'Передать конверт',
    ['meetup']: 'Встреча',
    ['unplanned']: 'Встреча',
  };
  return types[key];
};

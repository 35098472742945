import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IHistoryReportItem, ISearchFilterOption } from '../interfaces';
import {
  COMMENT_NAMES,
  COMMENT_VALUES,
  DATE_NAMES,
  DATE_VALUES,
  EXECUTOR_NAMES,
  EXECUTOR_VALUES,
  FEEDBACK_NAMES,
  FEEDBACK_VALUES,
  HISTORY_REPORT_LIMIT,
  MEETUP_TYPE_NAMES,
  MEETUP_TYPE_VALUES,
} from '../constants';
import {
  HistoryReportFilterDirectionEnum,
  HistoryReportSortParams,
  TableHeadEnum,
} from '../enums';
import { ICustomizedHookItem } from '../../../components/CustomizedHook/customized-hook.interfaces';
import { IAppSelectOption } from '../../../ui/AppSelect/app-select.interface';

export interface IHistoryReportState {
  //Таблица
  list: IHistoryReportItem[];
  isLoading: boolean;
  offset: number;
  limit: number;
  filterDirection: HistoryReportFilterDirectionEnum;
  filter: HistoryReportSortParams;
  haveMore: boolean;

  //Фильтры autocomplete
  lpuSearchText: string;
  specialtySearchText: string;
  selectedLpu: ICustomizedHookItem | null;
  selectedSpecialty: ICustomizedHookItem | null;
  selectedFeedbackValue: string | null;
  lpuList: any[];
  specialtyList: ICustomizedHookItem[];

  //Фильтры select
  meetupType: IAppSelectOption;
  dateFilter: IAppSelectOption;
  commentFilter: IAppSelectOption;
  feedbackFilter: IAppSelectOption;
  searchedAgents: IAppSelectOption[];
  agentFilter: IAppSelectOption;

  //Фильтры sarch
  searchedDoctors: ISearchFilterOption[];
  selectedDoctor: ISearchFilterOption | null;
  doctorSearchText: string;
}

const initialState: IHistoryReportState = {
  //Таблица
  list: [],
  isLoading: false,
  offset: 0,
  limit: HISTORY_REPORT_LIMIT,
  filterDirection: HistoryReportFilterDirectionEnum.DESC,
  filter: TableHeadEnum.DATE,
  haveMore: true,

  //Фильтры autocomplete
  lpuSearchText: '',
  specialtySearchText: '',
  lpuList: [],
  specialtyList: [],
  selectedLpu: null,
  selectedSpecialty: null,
  selectedFeedbackValue: '',

  //Фильтры select
  meetupType: {
    id: 1,
    text: MEETUP_TYPE_NAMES.ALL,
    value: MEETUP_TYPE_VALUES.ALL,
  },
  dateFilter: {
    id: 1,
    text: DATE_NAMES.ALL,
    value: DATE_VALUES.ALL,
  },
  commentFilter: {
    id: 1,
    text: COMMENT_NAMES.ALL,
    value: COMMENT_VALUES.ALL,
  },
  feedbackFilter: {
    id: 1,
    text: FEEDBACK_NAMES.ALL,
    value: FEEDBACK_VALUES.ALL,
  },
  agentFilter: {
    id: 1,
    text: EXECUTOR_NAMES.ALL,
    value: EXECUTOR_VALUES.ALL,
  },
  searchedAgents: [],

  //Фильтры search
  searchedDoctors: [],
  selectedDoctor: null,
  doctorSearchText: '',
};

const historyReportSlice = createSlice({
  initialState,
  name: 'history-report',
  reducers: {
    setList: (state, action: PayloadAction<IHistoryReportItem[]>) => {
      state.list = [...state.list, ...action.payload];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setFilter: (state, action: PayloadAction<HistoryReportSortParams>) => {
      state.filter = action.payload;
    },
    setFilterDirection: (
      state,
      action: PayloadAction<HistoryReportFilterDirectionEnum>
    ) => {
      state.filterDirection = action.payload;
    },
    clearList: (state) => {
      state.list = [];
      state.haveMore = true;
      state.offset = 0;
      state.isLoading = false;
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },

    setLpuSearchText: (state, action: PayloadAction<string>) => {
      state.lpuSearchText = action.payload;
    },
    setDoctorSearchText: (state, action: PayloadAction<string>) => {
      state.doctorSearchText = action.payload;
    },
    setSpecialtySearchText: (state, action: PayloadAction<string>) => {
      state.specialtySearchText = action.payload;
    },
    setLpuList: (state, action: PayloadAction<any[]>) => {
      state.lpuList = action.payload;
    },
    setSpecialtyList: (state, action: PayloadAction<ICustomizedHookItem[]>) => {
      state.specialtyList = action.payload;
    },
    setSelectedLpu: (state, action: PayloadAction<ICustomizedHookItem>) => {
      state.selectedLpu = action.payload;
    },

    setSelectedSpecialty: (
      state,
      action: PayloadAction<ICustomizedHookItem>
    ) => {
      state.selectedSpecialty = action.payload;
    },
    deleteLpu: (state) => {
      state.selectedLpu = null;
    },
    deleteSpecialty: (state) => {
      state.selectedSpecialty = null;
    },
    setSelectedFeedbackValue: (state, action: PayloadAction<string | null>) => {
      state.selectedFeedbackValue = action.payload;
    },
    clearSelectedLpu: (state) => {
      state.selectedLpu = null;
    },
    clearSelectedSpecialty: (state) => {
      state.selectedSpecialty = null;
    },

    setMeetupType: (state, action: PayloadAction<IAppSelectOption>) => {
      state.meetupType = action.payload;
    },
    setDateFilter: (state, action: PayloadAction<IAppSelectOption>) => {
      state.dateFilter = action.payload;
    },
    setCommentFilter: (state, action: PayloadAction<IAppSelectOption>) => {
      state.commentFilter = action.payload;
    },
    setFeedbackFilter: (state, action: PayloadAction<IAppSelectOption>) => {
      state.feedbackFilter = action.payload;
    },
    setSearchedDoctors: (
      state,
      action: PayloadAction<ISearchFilterOption[]>
    ) => {
      state.searchedDoctors = action.payload;
    },

    setSelectedDoctor: (
      state,
      action: PayloadAction<ISearchFilterOption | null>
    ) => {
      state.selectedDoctor = action.payload;
    },

    setSearchedAgents: (state, action: PayloadAction<IAppSelectOption[]>) => {
      state.searchedAgents = action.payload;
    },
    setAgentFilter: (state, action: PayloadAction<IAppSelectOption>) => {
      state.agentFilter = action.payload;
    },
    clearMeetupType: (state) => {
      state.meetupType = initialState.meetupType;
    },
    clearDateFilter: (state) => {
      state.dateFilter = initialState.dateFilter;
    },
    clearCommentFilter: (state) => {
      state.commentFilter = initialState.commentFilter;
    },
    clearFeedbackFilter: (state) => {
      state.feedbackFilter = initialState.feedbackFilter;
    },
    clearAgentFilter: (state) => {
      state.agentFilter = initialState.agentFilter;
    },
  },
});

export const {
  setList,
  setLoading,
  setOffset,
  setFilter,
  setHaveMore,
  setFilterDirection,
  setLpuList,
  setLpuSearchText,
  setSelectedFeedbackValue,
  setSelectedLpu,
  setSelectedSpecialty,
  setSpecialtyList,
  setSpecialtySearchText,
  deleteLpu,
  deleteSpecialty,
  clearSelectedLpu,
  clearSelectedSpecialty,
  clearList,
  clearAgentFilter,
  setMeetupType,
  setCommentFilter,
  setFeedbackFilter,
  setDateFilter,
  setSearchedDoctors,
  setSelectedDoctor,
  setDoctorSearchText,
  setSearchedAgents,
  setAgentFilter,
  clearCommentFilter,
  clearDateFilter,
  clearFeedbackFilter,
  clearMeetupType,
} = historyReportSlice.actions;
export const historyReportReducer = historyReportSlice.reducer;

import { DoctorChangedFieldEnum } from '../enums';

export const CHANGELOG_LIST_LIMIT = 6;

export const DOCTOR_FIELDS_CHANGE_MAP = {
  [DoctorChangedFieldEnum.LASTNAME]: 'Фамилия',
  [DoctorChangedFieldEnum.FIRSTNAME]: 'Имя',
  [DoctorChangedFieldEnum.MIDDLENAME]: 'Отчество',
  [DoctorChangedFieldEnum.BIRTHDAY]: 'Дата рождения',
  [DoctorChangedFieldEnum.HAS_INSTALLED_APP]: 'Наличие мобильного приложения',
  [DoctorChangedFieldEnum.NOTE]: 'Заметка',
  [DoctorChangedFieldEnum.SPECIALTY]: 'Специальность',
  [DoctorChangedFieldEnum.LPU]: 'ЛПУ',
  [DoctorChangedFieldEnum.CONTACT_PHONE]: 'Контакты',
  [DoctorChangedFieldEnum.ARCHIVED]: 'В архиве',
};

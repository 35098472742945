import { Button } from '@mui/material';
import React, { ReactNode } from 'react';
import SortIcon from '@mui/icons-material/Sort';
import { HistoryReportFilterDirectionEnum } from '../../enums';

interface IReportCellProps {
  value: string;
  className?: string;
  onClick?: () => void;
  isCurrentFilter?: boolean;
  filterDirection?: HistoryReportFilterDirectionEnum;
}
const HeaderFilterCell = (props: IReportCellProps) => {
  return (
    <Button
      className="fixed-header__filter-btn"
      onClick={props.onClick}
      disableRipple
      disableElevation
      disableFocusRipple
    >
      {props.value}

      <div
        className={`fixed-header__sort-wrapper  ${
          props.isCurrentFilter ? 'fixed-header__sort-wrapper_current' : ''
        }`}
      >
        <SortIcon
          className={`fixed-header__sort fixed-header__sort_${
            props.filterDirection
          } ${props.isCurrentFilter ? 'fixed-header__sort_current' : ''}`}
        />
      </div>
    </Button>
  );
};

const HeaderDefaultCell = (props: IReportCellProps) => {
  return <p className={props.className}>{props.value}</p>;
};

export { HeaderFilterCell, HeaderDefaultCell };

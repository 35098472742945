import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ICityDynamicData,
  IDoctorDynamicData,
  ILpuDynamicData,
} from '../interfaces';
import { DynamicTableTabs } from '../enums';
import moment from 'moment';
import { DYNAMIC_TABLE_LIMIT } from '../constants';

interface IDynamicTableState {
  isLoading: boolean;
  tabValue: DynamicTableTabs;
  doctorsDynamic: IDoctorDynamicData[];
  lpuDynamic: ILpuDynamicData[];
  haveMore: boolean;
  startDate: string;
  endDate: string;
  headingDates: string[];
  limit: number;
  offset: number;
  cityDynamic: ICityDynamicData[];
}

const initialState: IDynamicTableState = {
  isLoading: false,
  startDate: moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'),
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  headingDates: [],
  tabValue: DynamicTableTabs.DOCTORS,
  doctorsDynamic: [],
  limit: DYNAMIC_TABLE_LIMIT,
  offset: 0,
  lpuDynamic: [],
  cityDynamic: [],
  haveMore: true,
};

export const dynamicTableSlice = createSlice({
  name: 'dynamic-table',
  initialState,

  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTabValue: (state, action: PayloadAction<DynamicTableTabs>) => {
      state.tabValue = action.payload;
    },

    setHeadingDates: (state, action: PayloadAction<string[]>) => {
      state.headingDates = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },
    pushDoctorsDynamic: (
      state,
      action: PayloadAction<IDoctorDynamicData[]>
    ) => {
      state.doctorsDynamic = [...state.doctorsDynamic, ...action.payload];
    },
    pushLpuDynamic: (state, action: PayloadAction<ILpuDynamicData[]>) => {
      state.lpuDynamic = [...state.lpuDynamic, ...action.payload];
    },
    pushCityDynamic: (state, action: PayloadAction<ICityDynamicData[]>) => {
      state.cityDynamic = [...state.cityDynamic, ...action.payload];
    },
    clearDoctorsDynamic: (state) => {
      state.doctorsDynamic = [];
    },
    clearLpuDynamic: (state) => {
      state.lpuDynamic = [];
    },
    clearCityDynamic: (state) => {
      state.cityDynamic = [];
    },
  },
});

export const {
  setLoading,
  setTabValue,
  pushDoctorsDynamic,
  setHeadingDates,
  setEndDate,
  setStartDate,
  clearCityDynamic,
  clearDoctorsDynamic,
  clearLpuDynamic,
  setOffset,
  setHaveMore,
  pushLpuDynamic,
  pushCityDynamic,
} = dynamicTableSlice.actions;
export const dynamicTableReducer = dynamicTableSlice.reducer;

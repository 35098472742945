import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { classNamesParser } from '../../../../helpers/classNamesParser';

interface IResultAlertProps {
  count: number;
  classNames?: string[];
  onClose: () => void;
  text?: string;
}

const ResultAlert = (props: IResultAlertProps) => {
  return (
    <div className={classNamesParser('upload-result', props.classNames)}>
      <p className="upload-result__result">{props.text}</p>
      <IconButton className="upload-result__close-btn" onClick={props.onClose}>
        <CloseIcon className="upload-result__close-icon" />
      </IconButton>
    </div>
  );
};

export default ResultAlert;

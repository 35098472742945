import { MoreHoriz } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import ErrorAlert from '../../../../ui/ErrorAlert';
import { IDoctorCandidate } from '../../index.interfaces';
import SuggestionDoctor from '../SuggestionDoctor';

interface IUploadRowProps {
  id: number;
  index: number;
  fullName: string;
  localFullName?: string | null;
  amount: number;
  doctorCandidates: IDoctorCandidate[];
  onMatchDoctor?: (
    doctorId: number,
    rowId: number,
    doctorFullname: string,
    index: number
  ) => void;
  searchedDoctors: IDoctorCandidate[];
  clearSearchedDoctors: () => void;
  onClearReport?: (rowID: number, index: number) => void;
}

const UploadRow = (props: IUploadRowProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onDoctorChoose = (id: number, doctorFullname: string) => {
    props.onMatchDoctor &&
      props.onMatchDoctor(id, props.id, doctorFullname, props.index);
  };

  const onClearReport = () => {
    props.onClearReport && props.onClearReport(props.id, props.index);
    handleCloseMenu();
  };

  return (
    <div className="upload-row">
      <h4 className="upload-row__fullname">{props.fullName}</h4>
      <p className="upload-row__amount">{props.amount} ₽</p>
      <h4 className="upload-row__local-fullname">
        <SuggestionDoctor
          suggestions={props.doctorCandidates}
          fullName={props.localFullName}
          onChooseSuggestion={onDoctorChoose}
          findedDoctors={props.searchedDoctors}
          clearSearchedDoctors={props.clearSearchedDoctors}
        />
        <IconButton className="upload-row__more-btn" onClick={handleOpenMenu}>
          <MoreHoriz />
        </IconButton>
      </h4>

      <Menu anchorEl={anchorEl} open={isMenuOpened} onClose={handleCloseMenu}>
        {/*<MenuItem>Создать запись</MenuItem>*/}
        {/*<MenuItem>Выбрать вручную</MenuItem>*/}
        <MenuItem onClick={onClearReport}>Очистить соответствие</MenuItem>
      </Menu>
    </div>
  );
};

export default UploadRow;

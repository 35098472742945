import React from 'react';
import {
  DefaultCell,
  ExecutorCell,
  FeedbackCell,
  LpuCell,
  SpecialtyCell,
  TypeCell,
} from '../components/ReportCells';
import {
  HeaderDefaultCell,
  HeaderFilterCell,
} from '../components/ReportHeaderCells';
import { HistoryReportSortParams, TableHeadEnum } from '../enums';
import {
  HistoryAgentFilter,
  HistoryCommentFilter,
  HistoryDateFilter,
  HistoryDoctorFilter,
  HistoryFeedbackFilter,
  HistoryLpuFilter,
  HistorySpecialtyFilter,
  HistoryTypeFilter,
} from '../components/HistoryReportFilters';
import { ICustomizedHookItem } from '../../../components/CustomizedHook/customized-hook.interfaces';

export const getHeaderNameByKey = (key: TableHeadEnum) => {
  const MAP = {
    [TableHeadEnum.COMMENT]: 'Комментарий',
    [TableHeadEnum.DATE]: 'Дата',
    [TableHeadEnum.EXECUTOR]: 'Представитель',
    [TableHeadEnum.FEEDBACK]: 'Ответ',
    [TableHeadEnum.FULLNAME]: 'ФИО',
    [TableHeadEnum.LPU]: 'ЛПУ',
    [TableHeadEnum.SPECIALTY]: 'Специальность',
    [TableHeadEnum.TYPE]: 'Тип',
  };
  return MAP[key];
};

export const getTableCellByKey = (key: TableHeadEnum) => {
  const MAP = {
    [TableHeadEnum.COMMENT]: FeedbackCell,
    [TableHeadEnum.DATE]: DefaultCell,
    [TableHeadEnum.EXECUTOR]: ExecutorCell,
    [TableHeadEnum.FEEDBACK]: FeedbackCell,
    [TableHeadEnum.FULLNAME]: DefaultCell,
    [TableHeadEnum.LPU]: LpuCell,
    [TableHeadEnum.SPECIALTY]: SpecialtyCell,
    [TableHeadEnum.TYPE]: TypeCell,
  };
  return MAP[key];
};

export const getTableHeaderCellByKey = (key: TableHeadEnum) => {
  const MAP = {
    [TableHeadEnum.COMMENT]: HeaderDefaultCell,
    [TableHeadEnum.DATE]: HeaderFilterCell,
    [TableHeadEnum.EXECUTOR]: HeaderDefaultCell,
    [TableHeadEnum.FEEDBACK]: HeaderDefaultCell,
    [TableHeadEnum.FULLNAME]: HeaderFilterCell,
    [TableHeadEnum.LPU]: HeaderFilterCell,
    [TableHeadEnum.SPECIALTY]: HeaderFilterCell,
    [TableHeadEnum.TYPE]: HeaderDefaultCell,
  };
  return MAP[key];
};

export const getTableHeaderFilterByKey = (key: TableHeadEnum) => {
  const MAP = {
    [TableHeadEnum.COMMENT]: HistoryCommentFilter,
    [TableHeadEnum.DATE]: HistoryDateFilter,
    [TableHeadEnum.EXECUTOR]: HistoryAgentFilter,
    [TableHeadEnum.FEEDBACK]: HistoryFeedbackFilter,
    [TableHeadEnum.FULLNAME]: HistoryDoctorFilter,
    [TableHeadEnum.LPU]: HistoryLpuFilter,
    [TableHeadEnum.SPECIALTY]: HistorySpecialtyFilter,
    [TableHeadEnum.TYPE]: HistoryTypeFilter,
  };
  return MAP[key];
};

export const getTableCellWidthByKey = (key: TableHeadEnum) => {
  const MAP = {
    [TableHeadEnum.COMMENT]: '13%',
    [TableHeadEnum.DATE]: '10%',
    [TableHeadEnum.EXECUTOR]: '110px',
    [TableHeadEnum.FEEDBACK]: '15%',
    [TableHeadEnum.FULLNAME]: '20%',
    [TableHeadEnum.LPU]: '15%',
    [TableHeadEnum.SPECIALTY]: '15%',
    [TableHeadEnum.TYPE]: '82px',
  };

  return MAP[key];
};

export const hasDuplicate = (item: ICustomizedHookItem | any, array: any[]) => {
  return !!array.find((arrItem) => arrItem.id === item.id);
};

export const getSortParamName = (key: HistoryReportSortParams) => {
  const MAP = {
    [TableHeadEnum.DATE]: 'meetup_date',
    [TableHeadEnum.FULLNAME]: 'doctor_fullname',
    [TableHeadEnum.LPU]: 'lpu_name',
    [TableHeadEnum.SPECIALTY]: 'specialty_name',
  };

  return MAP[key];
};

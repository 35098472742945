import React from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Navigate } from 'react-router-dom';
import { UserRolesTypes } from '../../types/users.types';
import FullscreenSpinner from '../../ui/FullscreenSpinner';

interface IPrivateRouteProps {
  reverseAuth?: boolean;
  children: JSX.Element;
  roles?: UserRolesTypes[];
}

const PrivateRoute = (props: IPrivateRouteProps) => {
  const user = useAppSelector((state) => state.user);
  // return <FullscreenSpinner />;

  if (user.isLoading) {
    return <FullscreenSpinner />;
  }

  if (props.reverseAuth) {
    return !user.isAuthenticated ? props.children : <Navigate to={'/'} />;
  }

  return user.isAuthenticated ? (
    !props.roles?.includes(user.role as UserRolesTypes) ? (
      <Navigate to={'/meetups'} />
    ) : (
      props.children
    )
  ) : (
    <Navigate to={'/sign-in'} />
  );
};

export default PrivateRoute;

import React from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import SubinfoDoctorSpan from '../../../../ui/SubinfoDoctorSpan';

interface ITableCardProps {
  classNames?: string[];
  title?: string;
  subTitle?: string;
  isActive?: boolean;
  onClick?: () => void;
  variant?: string;
  medInstitutions?: string[];
  specialty?: string;
}

export const TableCard = (props: ITableCardProps) => {
  const classNames = props.classNames ? props.classNames : [];

  return (
    <div
      onClick={props.onClick}
      style={{
        height: !!props.subTitle ? '' : 64,
      }}
      className={classNamesParser('table-card', [
        ...classNames,
        props.isActive ? 'table-card_active' : '',
      ])}
    >
      <h4 className="table-card__title">{props.title}</h4>

      {props.variant === 'doctors' ? (
        <div className="table-card__doctor-subinfo">
          {props.medInstitutions?.map((lpu) => (
            <SubinfoDoctorSpan variant="lpu" value={lpu} />
          ))}
          <SubinfoDoctorSpan variant="specialty" value={props.specialty} />
        </div>
      ) : (
        <span className="table-card__sub-title">{props.subTitle}</span>
      )}
    </div>
  );
};

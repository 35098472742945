import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiSpecialityItem } from '../../../interfaces/api.catalogs.interfaces';

interface IEditSpecialityFormState {
  isLoading: boolean;
  isEdit: boolean;
  name: string;
  id: number | null;
  isDeleted: boolean;
}

const initialState: IEditSpecialityFormState = {
  isLoading: false,
  isEdit: false,
  name: '',
  id: null,
  isDeleted: false,
};

export const editSpecialityFormSlice = createSlice({
  name: 'edit-speciality-form',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setData: (state, action: PayloadAction<IApiSpecialityItem>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.isDeleted = action.payload.deleted;
    },
    clearData: (state) => {
      state.id = null;
      state.name = '';
      state.isDeleted = false;
      state.isEdit = false;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
  },
});

export const { setLoading, setIsEdit, setData, clearData, setName } =
  editSpecialityFormSlice.actions;

export const editSpecialityFormReducer = editSpecialityFormSlice.reducer;

import React, {
  ChangeEvent,
  useRef,
  useState,
  DragEvent,
  useEffect,
} from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { UploadFieldController } from '../../upload-file.controller';
import { ReactComponent as WaitIcon } from '../../../../assets/vector/wait-icon.svg';
import ErrorAlert from '../../../../ui/ErrorAlert';
import { setUploadDate } from '../../store/upload-field.slice';
import MonthYearPicker from '../../../../ui/MonthYearPicker';

interface IUploadFieldProps {
  onSuccessUpload?: (id: number, isNew: boolean) => void;
  uploadDate?: string;
}

export const UploadField = (props: IUploadFieldProps) => {
  const uploadField = useAppSelector((state) => state.uploadField);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const controller = new UploadFieldController(dispatch);

  useEffect(() => {
    // dispatch(setUploadDate(props.uploadDate || ''));
  }, [props.uploadDate]);

  const onLoadClick = () => {
    inputRef.current && inputRef.current.click();
  };

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!uploadField.uploadDate) return;
    setIsLoading(true);
    await controller.onFileLoad(e, props.onSuccessUpload);
    setIsLoading(false);
  };
  const handleDrag = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!uploadField.uploadDate) return;
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDragging(true);
    } else if (event.type === 'dragleave') {
      setIsDragging(false);
    }
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    if (!uploadField.uploadDate) return;
    setIsLoading(true);
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files[0])
      await controller.onFileDrop(
        event.dataTransfer.files[0],
        props.onSuccessUpload
      );
    setIsLoading(false);
  };

  return (
    <div
      className="upload-field"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      aria-disabled
    >
      {!isLoading && (
        <MonthYearPicker
          onChange={controller.onUploadDateChange}
          placeholder="Выберите период"
          date={uploadField.uploadDate}
          classNames={['upload-field__date-picker']}
          // error={!!uploadField.uploadDate || uploadField.dateError}
        />
      )}
      <div className="upload-field__text-wrapper">
        {isLoading ? (
          <WaitIcon className="upload-field__icon upload-field__watch" />
        ) : (
          <GridViewIcon className="upload-field__icon" />
        )}
        <p className="upload-field__instruction">
          {!isLoading && !uploadField.uploadDate
            ? 'Выберите период, затем выберите csv файл.'
            : isDragging
            ? 'Отпустите файл, чтобы начать загрузку'
            : isLoading
            ? 'Загрузка файла, подождите пожалуйста'
            : 'Поместите файл в область загрузки или нажмите'}
        </p>
        {!isDragging && !isLoading && (
          <>
            <Button
              className="upload-field__file-btn"
              onClick={onLoadClick}
              disabled={!uploadField.uploadDate}
            >
              Выбрать файл
            </Button>
          </>
        )}
      </div>

      <input
        ref={inputRef}
        onChange={onInputChange}
        type="file"
        style={{ display: 'none' }}
      />
      {!isDragging && !isLoading && (
        <ErrorAlert
          error={uploadField.error}
          classNames={['upload-field__error']}
        />
      )}
    </div>
  );
};

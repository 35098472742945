import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITask } from '../interfaces/index.interfaces';
import { TASKS_LIST_LIMIT } from '../constants';
import { TaskStatusTypes } from '../../../types/tasks.types';
interface ITasksListState {
  isLoading: boolean;
  tasks: ITask[];
  limit: number;
  offset: number;
  page: number;
  total: number;
  tabValue: TaskStatusTypes;
}

const initialState: ITasksListState = {
  isLoading: false,
  tasks: [],
  limit: TASKS_LIST_LIMIT,
  offset: 0,
  page: 1,
  total: 0,
  tabValue: 'in_progress',
};

export const tasksListSlice = createSlice({
  name: 'tasks-list',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTasks: (state, action: PayloadAction<ITask[]>) => {
      state.tasks = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setTabValue: (state, action: PayloadAction<TaskStatusTypes>) => {
      state.tabValue = action.payload;
    },
  },
});

export const {
  setLoading,
  setOffset,
  setPage,
  setTasks,
  setTotal,
  setTabValue,
  setLimit,
} = tasksListSlice.actions;
export const tasksListReducer = tasksListSlice.reducer;

export const isScrollToBottomClosure = () => {
  let _scrollTop = 0;

  return (scrollTop: number) => {
    const result = _scrollTop < scrollTop;
    _scrollTop = scrollTop;
    return result;
  };
};

// Пример применения функции.
// const isScrollToBottom = isScrollToBottomClosure();

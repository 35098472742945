import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import FeedbackItem from '../FeedbackItem';
import { Tab, Tabs } from '@mui/material';
import { FeedbackListController } from '../../feedback-list.controller';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import { Virtuoso } from 'react-virtuoso';

interface IFeedbackListProps {
  onFeedbackClick?: (id: number) => void;
  onSelectDoctor: (id: number) => void;
  isSelecting?: boolean;
  selectedDoctors?: number[];
  currentFeedback?: number;
}

export const FeedbackList = (props: IFeedbackListProps) => {
  const dispatch = useAppDispatch();
  const controller = new FeedbackListController(dispatch);
  const feedbackList = useAppSelector((state) => state.feedbackList);

  const fetchFeedbacks = useCallback(
    () => controller.getFeedbackList(),
    [controller.getFeedbackList]
  );

  useEffect(() => {
    fetchFeedbacks();

    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_FEEDBACK,
      controller.updateFeedbackStatus
    );

    return () => {
      controller.clearList();
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_FEEDBACK);
    };
  }, []);

  return (
    <div className="feedback-list">
      <Tabs
        className="feedback-list__tabs"
        value={feedbackList.tabValue}
        onChange={controller.onTabChange}
      >
        <Tab value={'in_progress'} label="В работе" />
        <Tab value={'done'} label="Архив" />
      </Tabs>

      {feedbackList.feedbacks.length ? (
        <Virtuoso
          data={feedbackList.feedbacks}
          endReached={fetchFeedbacks}
          itemContent={(_, feedback) => (
            <FeedbackItem
              key={feedback.id}
              isSelecting={props.isSelecting}
              isSelected={props.selectedDoctors?.includes(feedback.doctor?.id)}
              onSelect={props.onSelectDoctor}
              onClick={props.onFeedbackClick}
              type={feedback.type}
              status={feedback.status}
              id={feedback.id}
              author={feedback.createdBy}
              comment={feedback.comment || ''}
              createdAt={feedback.createdAt}
              doctor={feedback.doctor}
              liable={feedback.liable}
              isCurrentFeedback={props.currentFeedback === feedback.id}
            />
          )}
          components={{
            Footer: () => <div style={{ padding: '32px 0' }}></div>,
          }}
        />
      ) : (
        <NoDataPlaceholder isHaveTabOffset />
      )}
    </div>
  );
};

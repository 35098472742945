import { MEETUPS } from '../api/api';
import axios from '../axios/axios';
import {
  ICompleteMeetupBody,
  IHistoryMeetupItem,
} from '../interfaces/api.tasks.interfaces';
import {
  MeetupFeedbackTypes,
  MeetupStatusTypes,
  TaskStatusTypes,
  TaskVariantTypes,
} from '../types/tasks.types';
import { MeetupsListFilters } from '../pages/Meetups/enums';
import { IListResponse } from '../interfaces/general.interfaces';
import qs from 'qs';
import {
  HistoryReportFilterDirectionEnum,
  HistoryReportSortParams,
} from '../modules/HistoryReport/enums';

export class MeetupsService {
  getMeetupsList = async (
    limit: number,
    offset: number,
    tab: 'active' | 'completed',
    orderBy: 'lpu_name' | 'specialty_name' | 'doctor_fullname',
    searchName?: string,
    taskType?: TaskVariantTypes | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(MEETUPS.GET_DOCTORS_LIST, {
        params: {
          limit,
          offset,
          tab,
          orderBy,
          searchName,
          taskType,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getTasksByDoctor = async (
    doctorId: number,
    shortResponse?: boolean,
    type?: TaskVariantTypes
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${MEETUPS.GET_TASKS_BY_DOCTOR}/${doctorId}`,
        {
          params: {
            type,
            response: shortResponse ? 'button_list' : 'full',
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  completeMeetup = async (taskId: number, body: FormData) => {
    let result = null;
    try {
      const response = await axios.post(
        `${MEETUPS.CONFIRM_MEETUP}/${taskId}/meetups`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getMeetupResult = async (taskID: number, doctorID: number) => {
    let result = null;
    try {
      const response = await axios.get(
        `${MEETUPS.GET_MEETUP_RESULT}/${taskID}/meetup-by-doctor`,
        {
          params: {
            doctorID,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  updateMeetupResult = async (meetupID: number, body: FormData) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${MEETUPS.UPDATE_TASK_RESULT}/${meetupID}`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  deleteMeetupResult = async (meetupID: number) => {
    let result = null;
    try {
      const response = await axios.delete(
        `${MEETUPS.DELETE_MEETUP_RESULT}/${meetupID}`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  closeMassMeetups = async (
    type: TaskVariantTypes,
    doctors: number[],
    comment?: string,
    status?: MeetupStatusTypes
  ) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${MEETUPS.CLOSE_MASS_MEETUPS}/close-mass`,
        {
          type,
          doctors,
          comment,
          status,
        }
      );
      if (response.status === 200) {
        result = response.data || true;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getCompletedMeetups = async (
    limit?: number,
    offset?: number,
    searchName?: string | null,
    comment?: string | null,
    feedbackType?: MeetupFeedbackTypes | null,
    lpuIDs?: number[] | null,
    specialtyIDs?: number[] | null,
    taskType?: TaskVariantTypes | null,
    doctorName?: string | null,
    agentIDs?: number[] | null,
    dateFirst?: string | null,
    dateLast?: string | null,
    hasFeedbackComments?: true | null,
    hasMeetupComment?: boolean | null,
    orderBy?: string | null,
    orderDirection?: HistoryReportFilterDirectionEnum | null
  ) => {
    let result = null;
    try {
      const response = await axios.get<IListResponse<IHistoryMeetupItem>>(
        `${MEETUPS.GET_COMPLETED_MEETUPS}`,
        {
          params: {
            limit,
            offset,
            searchName,
            comment,
            feedbackType,
            lpuIDs,
            specialtyIDs,
            taskType,
            doctorName,
            agentIDs,
            dateFirst,
            dateLast,
            hasFeedbackComments,
            hasMeetupComment,
            orderBy,
            orderDirection,
          },
          paramsSerializer: (params: any) => {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
              skipNulls: true,
            });
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getCompletedMeetupsOld = async (
    limit?: number,
    offset?: number,
    searchName?: string | null,
    comment?: string | null,
    feedbackType?: MeetupFeedbackTypes | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(`${MEETUPS.GET_COMPLETED_MEETUPS_OLD}`, {
        params: {
          limit,
          offset,
          searchName,
          comment,
          feedbackType,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  createUnscheduledMeetup = async (body: FormData) => {
    let result = null;
    try {
      const response = await axios.post(
        MEETUPS.CREATE_UNSCHEDULED_MEETUP,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  deleteMeetupImage = async (meetupID: number, images: string[]) => {
    let result = null;
    try {
      const response = await axios.delete(
        `${MEETUPS.DELETE_MEETUP_IMAGE}/${meetupID}/photos`,
        {
          params: {
            id: images,
          },
          paramsSerializer: (params: any) => {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
              skipNulls: true,
            });
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };
}

export enum TableHeadEnum {
  TYPE = 'type',
  FULLNAME = 'fullname',
  LPU = 'lpu',
  SPECIALTY = 'specialty',
  DATE = 'date',
  EXECUTOR = 'executor',
  COMMENT = 'comment',
  FEEDBACK = 'feedback',
}

export type HistoryReportSortParams =
  | TableHeadEnum.FULLNAME
  | TableHeadEnum.SPECIALTY
  | TableHeadEnum.LPU
  | TableHeadEnum.DATE;

export enum HistoryReportFilterDirectionEnum {
  ASC = 'asc',
  DESC = 'desc',
}

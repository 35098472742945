import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MeetupFeedbackTypes } from '../../../types/tasks.types';
import { IHistoryFilters } from '../interfaces';

interface ICompletedMeetupsState {
  isLoading: boolean;
  limit: number;
  offset: number;
  haveMore: boolean;
  list: any[];
  filters: IHistoryFilters;
}

const initialState: ICompletedMeetupsState = {
  isLoading: false,
  limit: 10,
  haveMore: true,
  offset: 0,
  list: [],
  filters: {
    searchName: null,
    comment: null,
    feedbackType: null,
  },
};

export const completedMeetupsSlice = createSlice({
  name: 'completed-meetups',
  initialState,

  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    pushToList: (state, action: PayloadAction<any[]>) => {
      state.list.push(...action.payload);
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setFilters: (state, action: PayloadAction<IHistoryFilters>) => {
      state.filters = action.payload;
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },
  },
});

export const {
  setLoading,
  setOffset,
  setList,
  setLimit,
  setFilters,
  pushToList,
  setHaveMore,
} = completedMeetupsSlice.actions;
export const completedMeetupsReducer = completedMeetupsSlice.reducer;

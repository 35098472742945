export const addMaximumScaleIOS = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    const scaleAttr = /maximum\-scale=[0-9\.]+/g;

    if (content && scaleAttr.test(content)) {
      content = content.replace(scaleAttr, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

import React, { useCallback, useEffect } from 'react';
import RoundButton from '../../../../ui/RoundButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import Input from '../../../../ui/Input';
import TaskDetailItem from '../TaskDetailItem';
import { Button, Chip, Menu, MenuItem, Pagination } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { TaskDetailController } from '../../task-detail.controller';
import { TaskStatusTypes } from '../../../../types/tasks.types';
import { getLocaleStatusByKey } from '../../heplers/getLocaleStatusByKey';
import DoctorSkeletonLoader from '../DoctorSkeletonLoader';
import { Check as ConfirmIcon, Clear as CancelIcon } from '@mui/icons-material';
import ExecutorsSelect from '../../../../components/ExecutorsSelect';
import TaskFilters from '../../../../components/TaskFilters';
import SearchBar from '../../../../ui/SearchBar';
import { Virtuoso } from 'react-virtuoso';

interface ITaskDetailFormProps {
  onCloseForm?: () => void;
  taskId: number | null;
}
export const TaskDetailForm = (props: ITaskDetailFormProps) => {
  const dispatch = useAppDispatch();
  const controller = new TaskDetailController(dispatch);
  const taskDetail = useAppSelector((state) => state.taskDetail);

  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const isMoreMenuOpened = Boolean(moreAnchorEl);

  const loadDoctors = useCallback(
    () => setTimeout(() => controller.getDoctors(), 0),
    [controller.getDoctors]
  );

  useEffect(() => {
    controller.checkParamInLocalstorage();
    if (props.taskId) {
      controller.setTaskId(props.taskId);
      controller.getTask();
    }
    const timeout = loadDoctors();

    return () => {
      controller.clearState();
      clearTimeout(timeout);
    };
  }, [props.taskId]);

  const handleOpenMoreMenu = (
    event: React.MouseEvent<HTMLElement | HTMLButtonElement>
  ) => {
    setMoreAnchorEl(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setMoreAnchorEl(null);
  };

  const handleChangeStatus = (status: TaskStatusTypes) => {
    controller.onChangeStatus(status);
    handleCloseMoreMenu();
  };

  const handleDeleteTask = async () => {
    await controller.onDeleteTask();
    props.onCloseForm && props.onCloseForm();
  };

  const statusLabel = getLocaleStatusByKey(taskDetail.status || '');

  return (
    <div className="task-detail-form">
      <div className="task-detail-form__header">
        <h3 className="task-detail-form__title">
          {taskDetail.type === 'envelope' ? 'Передать конверт' : 'Встреча'}
          <Chip label={statusLabel} className="task-detail-form__status" />
        </h3>
        <div className="task-detail-form__header-controls">
          <TaskFilters
            filter={taskDetail.filter}
            onChangeFilter={controller.onFilterParamChange}
          />
          <RoundButton
            classNames={['task-detail-form__more-btn']}
            content={<MoreHorizIcon />}
            onClick={handleOpenMoreMenu}
          />

          <RoundButton
            classNames={['task-detail-form__close-btn']}
            content={<CloseIcon />}
            onClick={props.onCloseForm}
          />
        </div>
      </div>
      <Chip label={statusLabel} className="task-detail-form__status-mobile" />
      <ExecutorsSelect
        executors={taskDetail.availableExecutors}
        onSelect={controller.onExecutorsChange}
        value={taskDetail.executors}
      />
      {taskDetail.isEditingDescription ? (
        <div className="task-detail-form__input-wrapper">
          <Input
            classNames={['task-detail-form__note-input']}
            placeholder="Введите описание"
            fullWidth
            autoFocus
            maxRows={3}
            onInput={controller.onChangeDescriptionInput}
            value={taskDetail.descriptionInput}
            multiline
            label="Примечание"
          />
          <div className="task-detail-form__edit-controls">
            <RoundButton
              classNames={['task-detail-form__edit-btn']}
              onClick={controller.onEditDescription}
              content={<ConfirmIcon />}
            />
            <RoundButton
              classNames={['task-detail-form__edit-btn']}
              content={<CancelIcon />}
              onClick={controller.onCancelEditingDescription}
            />
          </div>
        </div>
      ) : (
        <Button
          className="task-detail-form__note-btn"
          onClick={controller.onStartEditingDescription}
        >
          {taskDetail.description || 'Добавьте примечание'}
          <span className="task-detail-form__note-label">Примечание</span>
        </Button>
      )}

      <SearchBar
        classNames={[
          'task-detail-form__search-bar',
          !!taskDetail.searchValue
            ? 'task-detail-form__search-bar_searching'
            : '',
        ]}
        placeholder="Поиск по ФИО"
        value={taskDetail.searchInput}
        onClear={controller.onSearchClear}
        onInput={controller.onSearchInput}
        onSearch={controller.onSearch}
      />

      <div className="task-detail-form__list">
        <Virtuoso
          data={taskDetail.doctors}
          endReached={loadDoctors}
          overscan={500}
          itemContent={(i, item) => (
            <TaskDetailItem
              key={item.id}
              id={item.id}
              index={i}
              firstName={item.firstName}
              lastName={item.lastName}
              middleName={item.middleName}
              note={item.note}
              specialty={item.specialty?.name}
              onDeleteFromTask={controller.onDeleteDoctor}
              facility={item.medInstitutions?.[0]?.name}
              meetupDone={item.meetupDone}
              taskType={taskDetail.type}
            />
          )}
          components={{
            Header: () => <div style={{ marginBottom: 16 }}></div>,
            Footer: () => {
              return (
                <>
                  {taskDetail.haveMore
                    ? new Array(Math.floor(taskDetail.limit / 2))
                        .fill(null)
                        .map((_, i) => <DoctorSkeletonLoader key={i} />)
                    : null}
                </>
              );
            },
          }}
        />
      </div>
      <Menu
        open={isMoreMenuOpened}
        anchorEl={moreAnchorEl}
        onClose={handleCloseMoreMenu}
      >
        {(taskDetail.status === 'draft' || taskDetail.status === 'done') && (
          <MenuItem
            disabled={!taskDetail.executors.length}
            style={{ color: '#2574D0' }}
            onClick={() => handleChangeStatus('in_progress')}
          >
            Передать в работу
          </MenuItem>
        )}

        {taskDetail.status === 'in_progress' && (
          <MenuItem
            style={{ color: '#2574D0' }}
            onClick={() => handleChangeStatus('done')}
          >
            Завершить
          </MenuItem>
        )}
        {taskDetail.status === 'in_progress' && (
          <MenuItem
            style={{ color: '#FF4C06' }}
            onClick={() => handleChangeStatus('draft')}
          >
            Отменить
          </MenuItem>
        )}
        {taskDetail.status === 'draft' && (
          <MenuItem style={{ color: '#FF4C06' }} onClick={handleDeleteTask}>
            Удалить
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

import { AppDispatch, RootState, store } from '../app.store';

export interface IErrorUI {
  message: string;
  type: string;
}

export interface IListResponse<T> {
  total: number;
  data: T[];
}

export class ViewController {
  dispatch: AppDispatch;
  getState: () => RootState;
  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }
}

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { TableComponents } from 'react-virtuoso';

const DynamicTableComponent: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer className="dynamic-table__table" {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      {...props}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => {
    return (
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        {...props}
      />
    );
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

export default DynamicTableComponent;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserActiveTypes, UserRolesTypes } from '../types/users.types';

interface IUserState {
  isLoading: boolean;
  isAuthenticated: boolean;
  role?: UserRolesTypes;
  fullName: string;
  email: string;
  id?: number;
  isActive: boolean;
}

const initialState: IUserState = {
  isLoading: true,
  isAuthenticated: false,
  email: '',
  fullName: '',
  isActive: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },

    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setRole: (state, action: PayloadAction<UserRolesTypes>) => {
      state.role = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },

    clearState: (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.email = '';
      state.fullName = '';
      state.id = undefined;
      state.isActive = true;
      state.role = undefined;
    },
  },
});

export const {
  setLoading,
  setEmail,
  setFullName,
  setIsActive,
  setRole,
  setIsAuthenticated,
  setId,
  clearState,
} = userSlice.actions;

export const userReducer = userSlice.reducer;

import React, { ReactNode, useEffect, useRef } from 'react';

interface IMenuWrapperProps {
  children: ReactNode;
  className: string;
}

const MenuWrapper = (props: IMenuWrapperProps) => {
  const menuWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    menuWrapperRef.current?.removeAttribute('tabindex');
  }, [menuWrapperRef.current]);

  return (
    <div ref={menuWrapperRef} className={props.className}>
      {props.children}
    </div>
  );
};

export default MenuWrapper;

import React from 'react';
import { IFeedbackUser } from '../../interfaces';
import moment from 'moment';
import { classNamesParser } from '../../../../helpers/classNamesParser';

interface IFeedbackCommentUser {
  id: number;
  comment: string;
  createdAt: string;
  createdBy: IFeedbackUser;
  classNames?: string[];
}

const FeedbackComment = (props: IFeedbackCommentUser) => {
  return (
    <div className={classNamesParser('feedback-comment', props.classNames)}>
      <p className="feedback-comment__comment">{props.comment}</p>
      <p className="feedback-comment__author">
        {moment(props.createdAt).format('D MMMM YYYY')},{' '}
        {props.createdBy?.fullName || ''}
      </p>
    </div>
  );
};

export default FeedbackComment;

import { Button } from '@mui/material';
import React, { ChangeEvent } from 'react';
import Input from '../../../../ui/Input';
import { useVisualViewportOffsetHeight } from '../../../../hooks/useVisualViewportOffset';

interface INoteMobileFormProps {
  onCancel: () => void;
  onSave: () => void;
  inputValue: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FORM_HEIGHT_OFFSET = 130;

const NoteMobileForm = (props: INoteMobileFormProps) => {
  const { inputHeight, resizeForm } =
    useVisualViewportOffsetHeight(FORM_HEIGHT_OFFSET);
  return (
    <div className="note-mobile-form">
      <div className="note-mobile-form__header">
        <Button className="note-mobile-form__cancel" onClick={props.onCancel}>
          Отменить
        </Button>
        <h4 className="note-mobile-form__title">Заметка</h4>

        <Button className="note-mobile-form__save" onClick={props.onSave}>
          Сохранить
        </Button>
      </div>
      <div
        className="note-mobile-form__input-wrapper"
        style={{ height: inputHeight }}
      >
        <Input
          classNames={['note-mobile-form__input']}
          onFocus={resizeForm}
          multiline
          autoFocus
          onInput={props.onInputChange}
          rows={1}
          placeholder="Добавьте комментарий"
          value={props.inputValue}
        />
      </div>
    </div>
  );
};

export default NoteMobileForm;

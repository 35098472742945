export const REFERENCE_TYPES = {
  DOCTORS: 'doctors',
  LPU: 'lpu',
  SPECIALITY: 'speciality',
};
export const TAB_REFERENCE_TYPES = {
  0: REFERENCE_TYPES.DOCTORS,
  1: REFERENCE_TYPES.LPU,
  2: REFERENCE_TYPES.SPECIALITY,
};

export const REFERENCE_TABLE_LIMIT = 21;

export const SHOW_SPECIALITIES_TASK_COUNT = 3;
export const SHOW_LPU_TASK_COUNT = 3;
export const SHOW_CITIES_TASK_COUNT = 3;

export const MEETUP_TYPE_VALUES = {
  MEETUP: 'Встреча',
  ENVELOPE: 'Передать конверт',
};

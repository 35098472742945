import { AppDispatch, RootState, store } from '../../app.store';
import { CatalogsService } from '../../services/catalogs.service';
import {
  setBirthDay,
  setContactPhones,
  setFirstName,
  setHasInstalledApp,
  setLastName,
  setLoading,
  setMiddleName,
  setNote,
  setSpecialities,
  setSpecialitySearchText,
  setSpecialtyId,
  setSpecialityError,
  splicePhoneByIndex,
  setPhoneByIndex,
  setLpuList,
  addLpuByIndex,
  setLpuError,
  setLpuSearchText,
  addLpuField,
  removeLpu,
  setBirthDayError,
  setPhoneErrors,
} from './store/add-doctor-form.slice';
import { ChangeEvent, MouseEvent } from 'react';
import {
  parseEditDoctorLpuIds,
  parseEditDoctorPhones,
} from '../EditDoctorForm';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import validator from 'validator';
import isNumeric = validator.isNumeric;
import { showAppSnackBar } from '../../store/app.slice';

export class AddDoctorFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  fetchLpuList = async () => {
    const searchText = this.getState().addDoctorForm.lpuSearchText;

    try {
      const response = await this.catalogsService.getLpuList(null, searchText);

      if (response) {
        const formattedList = response.data.map((item) => ({
          ...item,
          title: item.name,
        }));

        this.dispatch(setLpuList(formattedList));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchSpecialities = async () => {
    this.dispatch(setLoading(true));

    try {
      const searchText = this.getState().addDoctorForm.specialitySearchText;
      const response = await this.catalogsService.getSpecialities(
        null,
        searchText
      );

      if (response) {
        const formattedData = response.data.map((item) => ({
          ...item,
          title: item.name,
        }));
        this.dispatch(setSpecialities(formattedData));
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setLoading(false));
  };

  handleBirthDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regEx = new RegExp(
      '(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[0-2]).(19|20)\\d{2}'
    );
    const errorText = 'Формат даты должен быть таким 31.12.2022';

    if (!value.length) {
      return this.dispatch(setBirthDay(value));
    }

    if (isNumeric(value) || value.split('').includes('.') || !value.length) {
      const prevStoreValue = this.getState().addDoctorForm.birthDay || '';
      this.dispatch(setBirthDay(value));
      const currStoreValue = this.getState().addDoctorForm.birthDay;
      const formattedValue =
        (currStoreValue?.length === 2 || currStoreValue?.length === 5) &&
        prevStoreValue?.length < value.length
          ? `${value}.`
          : value;
      this.dispatch(setBirthDay(formattedValue));
    }

    if (!value.match(regEx) && value.length >= 10) {
      this.dispatch(setBirthDay(''));
      this.dispatch(setBirthDayError(errorText));
    } else if (value.length > 10) {
      this.dispatch(setBirthDay(''));
      this.dispatch(setBirthDayError(errorText));
    } else {
      this.dispatch(setBirthDayError(''));
    }
  };

  handleBirthDayClear = () => {
    this.dispatch(setBirthDay(''));
  };

  handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.dispatch(setFirstName(value));
  };

  handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.dispatch(setLastName(value));
  };

  handleMiddleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.dispatch(setMiddleName(value));
  };

  handleHasInstalledAppChange = () => {
    const hasInstalled = this.getState().addDoctorForm.hasInstalledApp;
    this.dispatch(setHasInstalledApp(!hasInstalled));
  };

  handleSpecialtyInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    this.dispatch(setSpecialitySearchText(value));
    await this.fetchSpecialities();
    this.validateSpeciality();
    this.updateSpecialityId();
  };

  handleSpecialtyChange = async (
    event: any,
    newValue: any,
    reason?: string
  ) => {
    if (reason === 'clear') {
      event?.stopPropagation();
    }

    this.dispatch(setSpecialitySearchText(newValue?.name ? newValue.name : ''));

    if (!newValue) {
      await this.fetchSpecialities();
    }

    this.validateSpeciality();
    this.updateSpecialityId();
  };

  updateSpecialityId = () => {
    const hasErrors = !!this.getState().addDoctorForm.errors.speciality;

    if (!hasErrors) {
      const specialtySearchText =
        this.getState().addDoctorForm.specialitySearchText;
      const specialities = this.getState().addDoctorForm.specialities;
      const foundSpeciality = specialities.find(
        (item) => item.name === specialtySearchText
      );
      this.dispatch(setSpecialtyId(foundSpeciality?.id || 0));
    } else {
      this.dispatch(setSpecialtyId(0));
    }
  };

  validateSpeciality = () => {
    const specialities = this.getState().addDoctorForm.specialities;
    const searchText = this.getState().addDoctorForm.specialitySearchText;
    const isExist = specialities.find((item) => item.name === searchText);

    if (searchText) {
      this.dispatch(
        setSpecialityError(isExist ? '' : 'Специальность не найдена')
      );
    } else {
      this.dispatch(setSpecialityError(''));
    }
  };

  handleAddPhoneClick = () => {
    const contactPhones = this.getState().addDoctorForm.contactPhones;
    this.dispatch(setContactPhones([...contactPhones, '']));
  };

  handleRemovePhoneClick = (index: number) => {
    const phones = this.getState().addDoctorForm.contactPhones;

    if (phones.length > 1) {
      this.dispatch(splicePhoneByIndex(index));
    } else {
      this.dispatch(setContactPhones(['']));
    }
  };

  handlePhoneChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    this.dispatch(
      setPhoneByIndex({
        index,
        value: event.target.value,
      })
    );
  };

  handleLpuInput = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const value: string = event.target.value;
    this.dispatch(setLpuSearchText(value));
    this.validateLpu(index);
    this.fetchLpuList();
  };

  handleLpuFocus = async (event: any, index: number) => {
    const value: string = event.target.value;
    this.dispatch(setLpuSearchText(value));
    await this.fetchLpuList();
    this.validateLpu(index);
    console.log(event.offsetX);
  };

  handleLpuChange = async (
    event: any,
    newValue: any,
    index: number,
    reason?: any
  ) => {
    if (reason === 'clear') {
      event?.stopPropagation();
    }

    const mockLpu = {
      id: 0,
      name: '',
      deleted: false,
      address: {
        id: 0,
        placeName: '',
        district: '',
        street: '',
      },
    };

    const lpuList = this.getState().addDoctorForm.medInstitutions;
    if (newValue) {
      this.dispatch(
        addLpuByIndex({
          value: newValue,
          index,
        })
      );
      this.dispatch(setLpuSearchText(newValue.name));
    } else if (lpuList.length > 1) {
      this.dispatch(removeLpu(index));
      this.dispatch(setLpuSearchText(''));
    } else {
      this.dispatch(
        addLpuByIndex({
          value: mockLpu,
          index,
        })
      );
      this.dispatch(setLpuSearchText(''));
      await this.fetchLpuList();
    }

    this.validateLpu(index);
  };

  validateLpu = (index: number) => {
    const lpuSearchText = this.getState().addDoctorForm.lpuSearchText;
    const isExist = this.getState().addDoctorForm.lpuList.find(
      (item) => item.name === lpuSearchText
    );

    if (isExist || !lpuSearchText) {
      this.dispatch(
        setLpuError({
          index,
          text: '',
        })
      );
    } else {
      this.dispatch(
        setLpuError({
          index,
          text: 'ЛПУ не найден',
        })
      );
    }
  };

  handleAddLpuClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    this.dispatch(addLpuField());
  };

  handleSaveClick = async () => {
    this.dispatch(setLoading(true));
    const store = this.getState().addDoctorForm;
    let lpuIds: number[] = [];
    let phones: string[] = [];
    const formattedBirthDay = store.birthDay.split('.').reverse().join('-');
    if (store.medInstitutions) {
      lpuIds = parseEditDoctorLpuIds(store.medInstitutions);
    }

    if (store.contactPhones) {
      phones = parseEditDoctorPhones(store.contactPhones);
    }

    const regEx = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

    const errors =
      phones.map((number) =>
        regEx.test(number) ? '' : 'Указан неверный формат номера телефона'
      ) || [];

    this.dispatch(setPhoneErrors(errors));

    try {
      const response = await this.catalogsService.createDoctor(
        store.note || null,
        store.specialityId || null,
        store.lastName || null,
        store.firstName || null,
        store.middleName || null,
        formattedBirthDay || null,
        store.hasInstalledApp,
        lpuIds,
        phones
      );

      if (typeof response === 'number') {
        EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
        EventEmitter.emit(EMITTER_EVENTS.CREATE_DOCTOR_SUCCESS, response);
        EventEmitter.emit(EMITTER_EVENTS.SET_SELECTED_CARD, response);
      } else {
        const error = response.split('_');
        const phoneError = error[error.length - 1];
        this.dispatch(
          setPhoneErrors(
            errors.map((err, i) => {
              if (phoneError === phones[i]) {
                return 'Указан неверный формат номера телефона';
              } else return err;
            })
          )
        );
        this.dispatch(
          showAppSnackBar({
            snackBarSeverity: 'error',
            text: 'Не удалось создать врача. Проверьте введенные данные',
          })
        );
        return;
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setLoading(false));
  };

  handleMobileContactsSave = (contacts: string[]) => {
    this.dispatch(setContactPhones(contacts));
  };
}

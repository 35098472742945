import React, { ChangeEvent } from 'react';
import CheckboxMUI from '@mui/material/Checkbox';
import { classNamesParser } from '../../helpers/classNamesParser';
import UncheckedIcon from '@mui/icons-material/SquareRounded';
import CheckedDisabledIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import DoneIcon from '@mui/icons-material/Done';

interface ICheckBoxProps {
  classNames?: string[];
  label?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const CheckBox = (props: ICheckBoxProps) => {
  return (
    <div className={classNamesParser('check-box', props.classNames)}>
      {props.label && <p className="check-box__label">{props.label}</p>}
      <CheckboxMUI
        checked={props.checked}
        defaultChecked={props.disabled}
        onChange={props.onChange}
        disableRipple={props.disabled}
        disabled={props.disabled}
        icon={<UncheckedIcon className="check-box__unchecked-icon" />}
        checkedIcon={
          props.disabled ? (
            <CheckedDisabledIcon className="check-box__checked-disabled-icon" />
          ) : (
            <DoneIcon className="check-box__checked-icon" />
          )
        }
        classes={{
          root: 'check-box__input',
          disabled: 'check-box__disabled',
        }}
      />
    </div>
  );
};

export default CheckBox;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReportInfo } from '../interfaces';
import moment from 'moment';

interface IMeetupReportState {
  isLoading: boolean;
  data: IReportInfo[];
  headingDates: string[];
  startDate: string;
  endDate: string;
}

const initialState: IMeetupReportState = {
  isLoading: false,
  data: [],
  headingDates: [],
  startDate: moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'),
  endDate: moment(new Date()).format('YYYY-MM-DD'),
};

export const meetupsReportSlice = createSlice({
  name: 'meetups-report',
  initialState,

  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setData: (state, action: PayloadAction<IReportInfo[]>) => {
      state.data = action.payload;
    },
    setHeadingDates: (state, action: PayloadAction<string[]>) => {
      state.headingDates = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
  },
});

export const {
  setLoading,
  setData,
  setHeadingDates,
  setEndDate,
  setStartDate,
} = meetupsReportSlice.actions;
export const meetupsReportReducer = meetupsReportSlice.reducer;

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../app.store';
import {
  setFullName,
  setPhoneNumber,
  setEmail,
  setLogin,
  setPassword,
  setRole,
  setError,
  clearState,
  setChangePasswordModalVisible,
} from '../store/edit-user-form.slice';
import { IUserItem } from '../modules/UsersList/index.interfaces';
import { setIsEditing } from '../store/edit-user-form.slice';

export class EditUserFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  //   addUserFormService: EditUserFormService = new EditUserFormService();
  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }
  handleFullNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setFullName(e.target.value));
  };
  handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPhoneNumber(e.target.value));
  };
  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setEmail(e.target.value));
  };
  handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setLogin(e.target.value));
  };
  handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPassword(e.target.value));
  };
  handleIsAdminChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setRole('admin'));
  };
  handleIsManagerChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setRole('manager'));
  };
  handleIsMedicalAgentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setRole('medical_agent'));
  };

  handleEdit = () => {
    this.dispatch(setIsEditing(true));
  };

  handleCancelEdit = () => {
    this.dispatch(setIsEditing(false));
    this.dispatch(setPassword(''));
    this.dispatch(
      setError({
        type: 'fullName',
        message: '',
      })
    );
    this.dispatch(
      setError({
        type: 'login',
        message: '',
      })
    );
    this.dispatch(
      setError({
        type: 'password',
        message: '',
      })
    );
    this.dispatch(
      setError({
        type: 'editUser',
        message: '',
      })
    );
  };

  setUser = (user: IUserItem) => {
    this.dispatch(setFullName(user.fullName || ''));
    this.dispatch(setPhoneNumber(user.contactPhone || ''));
    this.dispatch(setEmail(user.email || ''));
    this.dispatch(setRole(user.role));
    this.dispatch(setLogin((user.login as string) || ''));
  };

  hasEmptyFields = () => {
    const editUserForm = this.getState().editUserForm;
    const fields = {
      fullName: editUserForm.fullName,
      login: editUserForm.login,
      password: editUserForm.password,
    };
    let fieldKeys: string[] = [];

    for (const [key, value] of Object.entries(fields)) {
      if (!value && key !== 'password') {
        fieldKeys.push(key);
        this.dispatch(
          setError({
            type: key,
            message: 'Обязательное поле',
          })
        );
      } else {
        this.dispatch(
          setError({
            type: key,
            message: '',
          })
        );
      }
    }

    return !!fieldKeys.length;
  };

  validatePassword = () => {
    const editUserForm = this.getState().editUserForm;
    const isPasswordValid = !!editUserForm.password;
    if (!isPasswordValid) {
      this.dispatch(
        setError({
          type: 'password',
          message: 'Обязательное поле',
        })
      );
    } else {
      this.dispatch(
        setError({
          type: 'password',
          message: '',
        })
      );
    }
    return isPasswordValid;
  };

  hasErrors = () => {
    const errors = this.getState().editUserForm.errors;
    let result = false;

    for (const [key, value] of Object.entries(errors)) {
      if (key === 'editUser' || key === 'changePassword') continue;
      if (value) {
        result = true;
      }
    }

    return result;
  };

  clearState = () => {
    this.dispatch(clearState());
  };

  onEditUser = (result: any) => {
    this.dispatch(
      setError({
        type: 'editUser',
        message: result ? '' : 'Не удалось изменить пользователя',
      })
    );
  };

  openChangePasswordModal = () => {
    this.dispatch(setChangePasswordModalVisible(true));
  };

  closeChangePasswordModal = () => {
    this.dispatch(setChangePasswordModalVisible(false));
  };

  onPasswordChange = (result: any) => {
    this.dispatch(
      setError({
        type: 'changePassword',
        message: result ? '' : 'Не удалось изменить пароль',
      })
    );
    if (result) {
      this.closeChangePasswordModal();
    }
  };
}

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import { ImportDataService } from '../../services/import-data.service';
import moment from 'moment';
import { setError, setUploadDate } from './store/upload-field.slice';
export class UploadFieldController {
  dispatch: AppDispatch;
  getState: () => RootState;
  importDataService = new ImportDataService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  uploadFile = async (file: File, filename: string) => {
    const formData = new FormData();
    const uploadDate = this.getState().uploadField.uploadDate;
    const date = moment().format('YYYY-MM-DD');
    formData.append('file', file);
    formData.append('date', uploadDate ? uploadDate : date);
    formData.append('filename', filename);
    const data = await this.importDataService.uploadFile(formData);
    if (data) this.dispatch(setError(''));
    else this.dispatch(setError('Не удалось загрузить файл'));
    return data;
  };

  onFileLoad = async (
    e: ChangeEvent<HTMLInputElement>,
    callback?: (id: number, isNew: boolean) => void
  ) => {
    if (!e.target?.files?.[0]) return;
    const utf8File = await this.convertFileToUTF8(e.target?.files?.[0]);
    if (utf8File === null) {
      this.dispatch(setError('Не удалось загрузить файл'));
      return;
    }
    const fileId = await this.uploadFile(utf8File, e.target.files[0].name);
    callback && fileId && callback(fileId, true);
  };

  convertFileToUTF8 = async (file: File) => {
    return new Promise<File | null>((resolve) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        try {
          const encoding = 'windows-1251';
          if (!event.target?.result) return;
          const rawData = event.target?.result as string;
          const decoder = new TextDecoder(encoding);
          const utf8Data = decoder.decode(
            //@ts-ignore
            new Uint8Array([...rawData].map((char) => char.charCodeAt(0)))
          );
          const blob = new Blob([utf8Data], {
            type: 'text/csv;charset=utf-8;',
          });
          const utf8File = new File([blob], file.name);
          resolve(utf8File);
        } catch (err) {
          resolve(null);
        }
      };
      reader.readAsBinaryString(file);
    });
  };

  onFileDrop = async (
    file: File,
    callback?: (id: number, isNew: boolean) => void
  ) => {
    const utf8File = await this.convertFileToUTF8(file);
    if (utf8File === null) {
      this.dispatch(setError('Не удалось загрузить файл'));
      return;
    }
    const fileId = await this.uploadFile(utf8File, file.name);
    callback && fileId && callback(fileId, true);
  };

  onUploadDateChange = async (date: string) => {
    console.log(date);
    this.dispatch(setUploadDate(date));
  };
}

import { AppDispatch, RootState, store } from '../../app.store';
import { SelectChangeEvent } from '@mui/material';
import {
  deleteSpeciality,
  setSelectedLpu,
  setSelectedSpeciality,
  setSpecialities,
  setSpecialitySearchText,
  setLpuList,
  setLpuSearchText,
  deleteLpu,
  setCityList,
  clearSelectedLpu,
  clearSelectedSpecialities,
  setSelectedApp,
  setLastMeetupDate,
  setActiveType,
  setCitySearchText,
  setSelectedCity,
  setDistricts,
  deleteCity,
  setSelectedDistrict,
  setSelectedCities,
  setLastMeetupDateError,
} from './store/reference-filters.slice';
import { ICustomizedHookItem } from '../../components/CustomizedHook/customized-hook.interfaces';
import { CatalogsService } from '../../services/catalogs.service';
import { hasDuplicate } from './helpers';
import { IApiKladrCity } from '../../interfaces/api.catalogs.interfaces';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { parseEditDoctorLpuIds } from '../EditDoctorForm';
import moment, { Moment } from 'moment';
import {
  ACTIVE_TYPE_NAMES,
  ACTIVE_TYPES_MAP,
  APP_AVAILABILITY_MAP,
  APP_AVAILABILITY_NAMES,
} from './constants';
import { ChangeEvent } from 'react';
import validator from 'validator';
import isNumeric = validator.isNumeric;

export class ReferenceFiltersController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  fetchSpecialities = async () => {
    const store = this.getState().referenceFilters;
    try {
      const response = await this.catalogsService.getSpecialities(
        false,
        store.specialitySearchText,
        null,
        null,
        true
      );
      const formattedData = response?.data?.map<ICustomizedHookItem>(
        (item) => ({
          id: item.id,
          title: String(item?.name),
        })
      );
      this.dispatch(setSpecialities(formattedData as ICustomizedHookItem[]));
    } catch (e) {
      console.error(e);
    }
  };

  fetchLpuList = async () => {
    const searchText = this.getState().referenceFilters.lpuSearchText;

    try {
      const response = await this.catalogsService.getLpuList(
        null,
        searchText,
        null,
        null,
        null,
        true
      );

      if (response) {
        const formattedList = response.data.map((item) => ({
          ...item,
          title: item.name,
        }));

        this.dispatch(setLpuList(formattedList));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchCities = async () => {
    const store = this.getState().referenceFilters;

    try {
      const response = await this.catalogsService.getKladrCities(
        !!store.citySearchText ? store.citySearchText : null
      );

      if (response) {
        const formattedResult = response?.result?.map(
          (item: IApiKladrCity) => ({
            id: item.cityID,
            title: item.name,
          })
        );

        this.dispatch(setCityList(formattedResult));
      }
    } catch (e) {
      console.error(e);
    }
  };

  onSpecialityChange = (item: ICustomizedHookItem) => {
    const selectedSpecialities =
      this.getState().referenceFilters.selectedSpecialities;

    if (!hasDuplicate(item, selectedSpecialities)) {
      this.dispatch(setSelectedSpeciality({ id: item.id, title: item.title }));
      this.dispatch(setSpecialitySearchText(''));
      this.fetchSpecialities();
    }
  };

  onSpecialityInput = (event: any) => {
    this.dispatch(setSpecialitySearchText(event.target.value));
  };

  onSpecialityDelete = (item: ICustomizedHookItem) => {
    this.dispatch(deleteSpeciality({ id: item.id, title: item.title }));
  };

  handleLpuInput = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    this.dispatch(setLpuSearchText(value));
  };

  handleCityInput = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    this.dispatch(setCitySearchText(value));
  };

  onLpuChange = (item: ICustomizedHookItem) => {
    const selectedLpu = this.getState().referenceFilters.selectedLpu;

    if (!hasDuplicate(item, selectedLpu)) {
      this.dispatch(setSelectedLpu({ id: item.id, title: item.title }));
      this.dispatch(setLpuSearchText(''));
      this.fetchLpuList();
    }
  };

  onCityChange = (item: ICustomizedHookItem | any) => {
    const selectedCities = this.getState().referenceFilters.selectedCities;

    if (!hasDuplicate(item, selectedCities)) {
      this.dispatch(
        setSelectedCity({ id: item.id, title: item.title, subOptions: [] })
      );
      this.dispatch(setCitySearchText(''));
      this.fetchCities();
    }
  };

  onCityDelete = (item: ICustomizedHookItem) => {
    this.dispatch(deleteCity({ id: item.id, title: item.title }));
  };

  handleDistrictClick = (cityId?: any, item?: string) => {
    if (item) {
      const index = this.getState().referenceFilters.selectedCities.findIndex(
        (item, i) => item.id === cityId
      );
      this.dispatch(
        setSelectedDistrict({
          cityIndex: index,
          district: item,
        })
      );
    }
  };

  handleCityTagClick = async (e: any, id: any) => {
    try {
      const response = await this.catalogsService.getKladrDistricts(id);

      this.dispatch(setDistricts(response.data));
    } catch (e) {
      console.error(e);
    }
  };

  onLpuDelete = (item: ICustomizedHookItem) => {
    this.dispatch(deleteLpu({ id: item.id, title: item.title }));
  };

  // getDynamicFilterValues = () => {
  //   const store = this.getState().referenceFilters;

  //   const monthStart = moment(store.startDate)
  //     .locale('en')
  //     .format('MMMM')
  //     .toLowerCase();
  //   const monthEnd = moment(store.endDate)
  //     .locale('en')
  //     .format('MMMM')
  //     .toLowerCase();
  //   const yearStart = Number(moment(store.startDate).format('YYYY'));
  //   const yearEnd = Number(moment(store.endDate).format('YYYY'));
  //   const dynamicFrom = Number(store.dynamicFrom);
  //   const dynamicTo = Number(store.dynamicTo);

  //   return {
  //     monthStart: store.isShowDynamicValues ? monthStart : null,
  //     monthEnd: store.isShowDynamicValues ? monthEnd : null,
  //     yearStart: store.isShowDynamicValues ? yearStart : null,
  //     yearEnd: store.isShowDynamicValues ? yearEnd : null,
  //     dynamicFrom: store.isShowDynamicValues ? dynamicFrom : null,
  //     dynamicTo: store.isShowDynamicValues ? dynamicTo : null,
  //   };
  // };

  handleApplyClick = (callback?: () => void) => {
    const store = this.getState().referenceFilters;
    const selectedLpu = store.selectedLpu;
    const selectedSpecialities = store.selectedSpecialities;
    const date = store.lastMeetupDate
      ? store.lastMeetupDate.split('.').reverse().join('-')
      : null;
    const appAvailability = store.selectedAppValue;
    const activeType = store.activeType;

    const lpuIds = parseEditDoctorLpuIds(selectedLpu);
    const specialityIds = selectedSpecialities
      .filter((item) => !!item.id)
      .map((item) => item.id);

    const isAnySelectFilter =
      selectedLpu.length ||
      selectedSpecialities.length ||
      !!date ||
      appAvailability?.length ||
      activeType?.length;

    const formattedAddresses = store.selectedCities.map((address) => ({
      placeName: address.title,
      districts: address?.subOptions?.length ? address.subOptions : null,
    }));

    // const dynamicValues = this.getDynamicFilterValues();

    EventEmitter.emit(
      EMITTER_EVENTS.UPDATE_DOCTORS_WITH_FILTERS,
      isAnySelectFilter
        ? {
            lpuIds,
            specialityIds,
            selectedLpu,
            selectedSpecialities,
            date,
            appAvailability:
              APP_AVAILABILITY_MAP[appAvailability as keyof object],
            activeType: ACTIVE_TYPES_MAP[activeType as keyof object],
            formattedAddresses: formattedAddresses.length
              ? formattedAddresses
              : null,
            // monthStart: dynamicValues.monthStart,
            // monthEnd: dynamicValues.monthEnd,
            // yearStart: dynamicValues.yearStart,
            // yearEnd: dynamicValues.yearEnd,
            // dynamicFrom: dynamicValues.dynamicFrom,
            // dynamicTo: dynamicValues.dynamicTo,
          }
        : null
    );

    callback && callback();
  };

  handleClearFilters = () => {
    this.dispatch(clearSelectedSpecialities());
    this.dispatch(clearSelectedLpu());
    this.dispatch(setLastMeetupDate(null));
    this.dispatch(setSelectedApp(APP_AVAILABILITY_NAMES.ALL));
    this.dispatch(setActiveType(ACTIVE_TYPE_NAMES.ACTIVE));
    this.dispatch(setSelectedCities([]));

    // this.dispatch(
    //   setStartDate(moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'))
    // );
    // this.dispatch(setEndDate(moment(new Date()).format('YYYY-MM-DD')));
    // this.dispatch(setIsShowDynamicValues(false));
    // this.dispatch(setDynamicFrom('0'));
    // this.dispatch(setDynamicTo('0'));

    EventEmitter.emit(EMITTER_EVENTS.CLEAR_DOCTORS_FILTERS);
  };

  setDefaultsFilters = () => {
    const store = this.getState().referenceFilters;
    const selectedAppValue = store.selectedAppValue;
    const activeType = store.activeType;

    this.dispatch(
      setSelectedApp(
        selectedAppValue?.length ? selectedAppValue : APP_AVAILABILITY_NAMES.ALL
      )
    );
    this.dispatch(
      setActiveType(activeType?.length ? activeType : ACTIVE_TYPE_NAMES.ACTIVE)
    );
  };

  handleSelectedAppChange = (event: SelectChangeEvent<string>) => {
    this.dispatch(setSelectedApp(event.target.value));
  };

  handleActiveTypeChange = (event: SelectChangeEvent<string>) => {
    this.dispatch(setActiveType(event.target.value));
  };

  handleLastMeetupDate = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regEx = new RegExp(
      '(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[1,2]).(19|20)\\d{2}'
    );
    const errorText = 'Формат даты должен быть таким 31.12.2022';

    if (value.length > 10) return;

    if (!value.length) {
      return this.dispatch(setLastMeetupDate(value));
    }

    if (isNumeric(value) || value.split('').includes('.') || !value.length) {
      const prevStoreValue =
        this.getState().referenceFilters.lastMeetupDate || '';
      this.dispatch(setLastMeetupDate(value));
      const currStoreValue = this.getState().referenceFilters.lastMeetupDate;
      const formattedValue =
        (currStoreValue?.length === 2 || currStoreValue?.length === 5) &&
        prevStoreValue?.length < value.length
          ? `${value}.`
          : value;
      this.dispatch(setLastMeetupDate(formattedValue));
    }

    if (!value.match(regEx) && value.length >= 10) {
      this.dispatch(setLastMeetupDateError(errorText));
    } else if (value.length > 10) {
      this.dispatch(setLastMeetupDateError(errorText));
    } else {
      this.dispatch(setLastMeetupDateError(''));
    }
  };

  handleClearMeetupDate = () => {
    this.dispatch(setLastMeetupDate(null));
  };

  // handleStartDateChange = (date: string) => {
  //   const state = this.getState().referenceFilters;
  //   if (moment(date).isAfter(moment(state.endDate))) {
  //     this.dispatch(setEndDate(date));
  //   }
  //   this.dispatch(setStartDate(date));
  // };

  // handleEndDateChange = (date: string) => {
  //   const state = this.getState().referenceFilters;
  //   if (moment(state.startDate).isAfter(moment(date))) {
  //     this.dispatch(setStartDate(date));
  //   }
  //   this.dispatch(setEndDate(date));
  // };

  // handleDynamicSwitchClick = () => {
  //   const isShowDynamicValues =
  //     this.getState().referenceFilters.isShowDynamicValues;
  //   this.dispatch(setIsShowDynamicValues(!isShowDynamicValues));
  // };

  // handleDynamicFromChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   const regEx = new RegExp('^0$|^-?[1-9]\\d*(\\.\\d+)?$');

  //   if (value.match(regEx) || !value || value === '-') {
  //     this.dispatch(setDynamicFrom(value));
  //   }
  // };

  // handleDynamicToChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   const regEx = new RegExp('^0$|^-?[1-9]\\d*(\\.\\d+)?$');

  //   if (value.match(regEx) || !value || value === '-') {
  //     this.dispatch(setDynamicTo(value));
  //   }
  // };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAddSpecialityFormState {
  isLoading: boolean;
  name: string;
}

const initialState: IAddSpecialityFormState = {
  isLoading: false,
  name: '',
};

export const addSpecialityFormSlice = createSlice({
  name: 'add-speciality-form',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
  },
});

export const { setLoading, setName } = addSpecialityFormSlice.actions;

export const addSpecialityFormReducer = addSpecialityFormSlice.reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MeetupFeedbackTypes } from '../../../types/tasks.types';

interface IHistoryFiltersState {
  searchedName: string;
  searchedComment: string;
  feedbackType: MeetupFeedbackTypes | '';
}

const initialState: IHistoryFiltersState = {
  searchedName: '',
  searchedComment: '',
  feedbackType: '',
};

const historyFiltersSlice = createSlice({
  name: 'history-filters',
  initialState,
  reducers: {
    setFeedbackType: (
      state,
      action: PayloadAction<MeetupFeedbackTypes | ''>
    ) => {
      state.feedbackType = action.payload;
    },
    setSearchedName: (state, action: PayloadAction<string>) => {
      state.searchedName = action.payload;
    },
    setSearchedComment: (state, action: PayloadAction<string>) => {
      state.searchedComment = action.payload;
    },
    clearState: (state: any) => {
      for (const [key] of Object.entries(initialState)) {
        state[key] = initialState[key as keyof object];
      }
    },
  },
});

export const {
  setFeedbackType,
  setSearchedComment,
  setSearchedName,
  clearState,
} = historyFiltersSlice.actions;
export const historyFiltersSliceReducer = historyFiltersSlice.reducer;

import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import React, { useMemo, useState } from 'react';
import { Button, debounce } from '@mui/material';
import { ICustomizedHookItem } from '../CustomizedHook/customized-hook.interfaces';
import CheckIcon from '@mui/icons-material/Check';
import SearchBar from '../../ui/SearchBar';

const Listbox = styled('ul')(
  ({ theme }) => `
    width: 100%;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    z-index: 1;
  
    & li {
      padding: 5px 12px;
      display: flex;
      align-items: baseline;
      cursor: pointer;
      color: rgb(85, 92, 96);
  
      & span {
        flex-grow: 1;
      }
  
      & svg {
        color: transparent;
        width: 12px;
        margin-right: 4px;
        height: 12px;
      }
    }
  
    & li[aria-selected='true'] {

      font-weight: 600;
  
      & svg {
        color: rgb(85, 92, 96);
      }
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: ${
        theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'
      };
      cursor: pointer;
  
      & svg {
        color: currentColor;
      }
    }
  `
);

interface IAutoCompleteSearchList {
  options?: ICustomizedHookItem[] | any[];
  selectedOptions?: ICustomizedHookItem[] | any;
  onListAcceptButtonClick?: () => void;
  onChange?: (item: ICustomizedHookItem | any) => void;
  onDelete?: (item: ICustomizedHookItem | any) => void;
  onInput?: (event: any) => void;
  placeholder?: string;
  inputValue?: string;
  className?: string;
  showListAcceptButton?: boolean;
}

const AutoCompleteSearchList = React.forwardRef<
  HTMLUListElement,
  IAutoCompleteSearchList
>((props, ref) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isScrolling, setScrolling] = useState(false);

  const updateScrolling = () => setScrolling(false);

  const handleUpdateScrollingState = useMemo(
    () => debounce(updateScrolling, 200),
    []
  );
  const handleListScroll = (e: React.UIEvent<HTMLElement>) => {
    setScrolling(true);
    setScrollTop(e.currentTarget.scrollTop);
    handleUpdateScrollingState();
  };
  return (
    <Listbox
      ref={ref}
      onScroll={(e: any) => handleListScroll(e)}
      className={props.className}
      style={{
        padding: '8px',

        zIndex: 10,
      }}
    >
      <SearchBar
        placeholder={props.placeholder}
        onInput={props.onInput}
        value={props.inputValue}
        onClear={() =>
          props.onInput && props.onInput({ target: { value: '' } })
        }
        styles={{ marginBottom: !!props.options?.length ? 16 : 0 }}
      />

      {props.options &&
        props.options.map((option: ICustomizedHookItem, index: number) => (
          <li
            onClick={() => props.onChange && props.onChange(option)}
            className="auto-complete-search-list__item"
            aria-selected={
              !!props.selectedOptions?.find(
                (item: ICustomizedHookItem) => item?.id === option?.id
              )
            }
          >
            <CheckIcon fontSize="small" />
            <span>{option.title}</span>
          </li>
        ))}
      {isScrolling ||
        (props.showListAcceptButton ? (
          <Button
            style={{
              transition: 'none',
              position: 'absolute',
              bottom: 20 - scrollTop,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '95%',
              backgroundColor: '#263238',
            }}
            onClick={props.onListAcceptButtonClick}
          >
            {`Выбрать`}
          </Button>
        ) : null)}
    </Listbox>
  );
});

export default AutoCompleteSearchList;

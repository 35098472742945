import { AppDispatch, RootState, store } from '../../app.store';
import { CatalogsService } from '../../services/catalogs.service';
import { setLoading, setName } from './store/add-speciality-form.slice';
import { ChangeEvent } from 'react';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';

export class AddSpecialityFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setName(event.target.value));
  };

  onCancelClick = (callback?: () => void) => {
    this.dispatch(setName(''));
    callback && callback();
  };

  onSaveClick = async (callback?: () => void) => {
    this.dispatch(setLoading(true));
    const name = this.getState().addSpecialityForm.name.trim();

    try {
      const response = await this.catalogsService.createSpeciality(name);

      if (typeof response === 'number') {
        this.dispatch(setName(''));
        callback && callback();
        EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
        EventEmitter.emit(EMITTER_EVENTS.CREATE_SPECIALTY_SUCCESS, response);
        EventEmitter.emit(EMITTER_EVENTS.SET_SELECTED_CARD, response);
      }

      if (response) {
        this.dispatch(setName(''));
        callback && callback();
        EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      }
    } catch (e: any) {
      console.error(e.message);
    }

    this.dispatch(setLoading(false));
  };
}

export const AUTH = {
  REFRESH_TOKEN: '/auth/access_token',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
};

export const PROFILE = {
  GET_PROFILE: '/users/profile/private',
};

export const USERS_MANAGE = '/users/manage';

export const CATALOGS = {
  GET_SPECIALITIES: '/catalogs/med-specialties',
  CREATE_SPECIALITY: '/catalogs/med-specialties',
  GET_SPECIALITY: '/catalogs/med-specialties',
  DELETE_SPECIALITY: '/catalogs/med-specialties',
  UPDATE_SPECIALITY: '/catalogs/med-specialties',

  GET_LPU_LIST: '/catalogs/lpu',
  CREATE_LPU: '/catalogs/lpu',
  GET_LPU_BY_ID: '/catalogs/lpu',
  DELETE_LPU_BY_ID: '/catalogs/lpu',
  UPDATE_LPU_BY_ID: '/catalogs/lpu',

  GET_KLADR_CITIES: '/catalogs/address/cities',
  GET_KLADR_DISTRICTS: '/catalogs/address/cities',

  GET_DOCTORS: '/doctors/search-doctors',
  GET_DOCTOR_BY_ID: '/doctors',
  UPDATE_DOCTOR_BY_ID: '/doctors',
  GET_DOCTOR_AGENT_BY_ID: '/doctors',
  CREATE_DOCTOR: '/doctors',
  CREATE_DOCTORS_TASKS: '/doctors/create-task',
  DOCTOR_MEETUPS_DONE: '/doctors',
  DELETE_DOCTOR_BY_ID: '/doctors',

  GET_CITY_FROM_LPU: '/catalogs/lpu/address',
};

export const IMPORT_MIS = {
  GET_UPLOADED_FILES: '/import/mis',
  UPLOAD_MIS_FILE: '/import/mis',
  DELETE_MIS_FILE: '/import/mis',
  GET_UPLOADED_REPORT: '/import/mis',
  UPDATE_UPLOADED_REPORT: '/import/mis',
  CREATE_TASK: '/import/mis',
  UPDATE_NAME: '/import/mis',
  CREATE_TASKS_BY_ALL_FILES: '/import/mis/create-task',
  LOAD_TO_STATS: '/import/mis',
};

export const EXPORT_REPORTS = {
  GET_MIS_REPORT: '/export/mis/report',

  DOWNLOAD_DOCTOR_DYNAMIC: '/reports/mis/doctor-dynamics/by-doctors/excel',
  DOWNLOAD_LPU_DYNAMIC: '/reports/mis/doctor-dynamics/by-lpu/excel',
  DOWNLOAD_CITY_DYNAMIC: '/reports/mis/doctor-dynamics/by-city/excel',
};

export const REPORTS = {
  GET_MEETUP_REPORT: '/reports/medical-agent-meetup-stats',
  GET_DOCTORS_DYNAMIC_REPORT: '/reports/mis/doctor-dynamics/by-doctors',
  GET_LPU_DYNAMIC_REPORT: '/reports/mis/doctor-dynamics/by-lpu',
  GET_CITY_DYNAMIC_REPORT: '/reports/mis/doctor-dynamics/by-city',
};

export const TASKS = {
  GET_TASKS_LIST: '/tasks/manager',
  GET_TASK: '/tasks',
  GET_DOCTORS: '/tasks',
  DELETE_TASK: '/tasks',
  UPDATE_TASK: '/tasks',
  ADD_DOCTOR_TASK: '/tasks',
  REMOVE_DOCTOR_TASK: '/tasks',
  REMOVE_EXECUTOR: '/tasks',
  ADD_EXECUTOR: '/tasks',
  CREATE_TASK: '/tasks',
};

export const MEETUPS = {
  GET_DOCTORS_LIST: '/tasks/doctors',
  GET_TASKS_BY_DOCTOR: '/tasks/doctors',
  GET_MEETUP_RESULT: '/tasks',
  UPDATE_TASK_RESULT: '/tasks/meetups',
  CONFIRM_MEETUP: '/tasks',
  DELETE_MEETUP_RESULT: '/tasks/meetups',
  CLOSE_MASS_MEETUPS: '/tasks/meetups',
  GET_COMPLETED_MEETUPS: '/tasks/meetups/v2',
  GET_COMPLETED_MEETUPS_OLD: '/tasks/meetups/v1',
  CREATE_UNSCHEDULED_MEETUP: '/tasks/meetups',
  DELETE_MEETUP_IMAGE: '/tasks/meetups',
};

export const FEEDBACK = {
  GET_FEEDBACK_LIST: '/tasks/meetups/feedbacks',
  GET_FEEDBACK_BY_ID: '/tasks/meetups/feedbacks',
  GET_COMMENTS_BY_FEEDBACK: '/tasks/meetups/feedbacks',
  SET_FEEDBACK_LIABLE: '/tasks/meetups/feedbacks',
  UPDATE_FEEDBACK_STATUS: '/tasks/meetups/feedbacks',
  ADD_COMMENT: '/tasks/meetups/feedbacks',
};

export const CHANGELOG = {
  GET_DOCTORS_CHANGELOG: '/admin/changelog/doctors',
};

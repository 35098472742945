import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import TaskItem from '../TaskItem';
import { TaskListController } from '../../task-list.controller';
import { Pagination, Tab, Tabs } from '@mui/material';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import TaskSkeletonLoader from '../TaskSkeletonLoader';
import useAdaptivePaginationLimit from '../../../../hooks/useAdaptivePaginationLimit';
import { TASKS_ADAPTIVE_PAGINATION } from '../../../../constants/adaptive-pagintation.constants';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';

interface ITaskListProps {
  onTaskClick?: (id: number) => void;
}

export const TasksList = (props: ITaskListProps) => {
  const tasksList = useAppSelector((state) => state.tasksList);
  const dispatch = useAppDispatch();
  const controller = new TaskListController(dispatch);
  const adaptiveLimit = useAdaptivePaginationLimit(TASKS_ADAPTIVE_PAGINATION);

  useEffect(() => {
    controller.setLimit(adaptiveLimit);
  }, [adaptiveLimit]);

  useEffect(() => {
    controller.getTasks();
    EventEmitter.subscribe(EMITTER_EVENTS.UPDATE_TASKS, controller.getTasks);

    return () => {
      controller.clearList();
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_TASKS);
    };
  }, []);

  return (
    <div className="tasks-list">
      <div className="tasks-list__list">
        <Tabs
          className="tasks-list__tabs"
          value={tasksList.tabValue}
          onChange={controller.onTabChange}
        >
          <Tab label="Активные" value={'in_progress'} />
          <Tab label="Завершенные" value={'done'} />
        </Tabs>
        {tasksList.isLoading ? (
          new Array(tasksList.limit)
            .fill(null)
            .map((_, i) => <TaskSkeletonLoader key={i} />)
        ) : tasksList.tasks.length ? (
          tasksList.tasks.map((task) => (
            <TaskItem
              percentage={task.percentComplete}
              onClick={props.onTaskClick}
              id={task.id}
              status={task.taskStatus}
              key={task.id}
              description={task.description}
              createdAt={task.createdAt}
              type={task.type}
              countDoctors={task.countDoctors}
              finishedDoctors={task.countDoctorsFinishedMeetups}
              executors={task.executors}
              finishedAt={task.finishedAt}
              onMenuItemClick={controller.onMenuItemClick}
              onDeleteTask={controller.onDeleteTask}
            />
          ))
        ) : (
          <NoDataPlaceholder isHaveTabOffset />
        )}
      </div>
      {Math.ceil(tasksList.total / tasksList.limit) !== 1 && (
        <Pagination
          count={Math.ceil(tasksList.total / tasksList.limit)}
          page={tasksList.page}
          onChange={controller.onPageChange}
          className="tasks-list__pagination"
        />
      )}
    </div>
  );
};

import { AppDispatch, RootState, store } from '../../app.store';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import {
  clearTable,
  setCurrentPage,
  setExecutors,
  setIsNoteEdit,
  setSearchedValue,
  setIsTasksCreating,
  setList,
  setLoading,
  setOffset,
  setOpenSearch,
  setPages,
  setSearchText,
  setSelectedCardId,
  setSelectedExecutors,
  setShowTasksModal,
  setTaskRemark,
  setTaskType,
  setTotal,
  setShowUnactive,
  setTabValue,
} from './store/reference-table.slice';
import { CatalogsService } from '../../services/catalogs.service';
import { MEETUP_TYPE_VALUES, REFERENCE_TYPES } from './constants';
import { SelectChangeEvent } from '@mui/material';
import { TaskVariantTypes } from '../../types/tasks.types';
import { UsersService } from '../../services/users.service';
import { showAppSnackBar } from '../../store/app.slice';
import { ReferenceTypes } from './types';

export class ReferenceTableController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();
  usersService: UsersService = new UsersService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  handleTabChange = (newValue: ReferenceTypes) => {
    this.dispatch(clearTable());
    this.dispatch(setSearchText(''));
    this.dispatch(setOpenSearch(false));
    this.dispatch(setTabValue(newValue));
  };

  handleCardClick = (cardId: number) => {
    this.dispatch(setSelectedCardId(cardId));
  };

  fetchSpecialities = async () => {
    this.dispatch(setLoading(true));

    const store = this.getState().referenceTable;

    try {
      const result = await this.catalogsService.getSpecialities(
        store.isShowUnactive ? store.isShowUnactive : null,
        store.searchText,
        store.limit,
        store.offset
      );

      if (result) {
        this.dispatch(setList(result.data));
        this.dispatch(setTotal(result.total));
        this.dispatch(setPages(result.total));
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setLoading(false));
  };

  fetchLpuList = async () => {
    this.dispatch(setLoading(true));

    const store = this.getState().referenceTable;

    try {
      const result = await this.catalogsService.getLpuList(
        store.isShowUnactive ? store.isShowUnactive : null,
        store.searchText,
        null,
        store.limit,
        store.offset
      );

      if (result) {
        this.dispatch(setList(result.data));
        this.dispatch(setTotal(result.total));
        this.dispatch(setPages(result.total));
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setLoading(false));
  };

  fetchDoctors = async () => {
    this.dispatch(setLoading(true));

    const store = this.getState().referenceTable;

    try {
      const result = await this.catalogsService.getDoctorsList(
        store.filters?.appAvailability,
        null,
        null,
        store.filters?.specialtyIDs,
        store.filters?.lpuIDs,
        store.filters?.isArchived,
        store.searchText ? store.searchText : null,
        store.limit,
        store.offset,
        store.filters?.lastMeetupDate,
        store.filters.address,
        store.filters.monthStart,
        store.filters.monthEnd,
        store.filters.yearStart,
        store.filters.yearEnd,
        store.filters.dynamicFrom,
        store.filters.dynamicTo
      );

      if (result) {
        const formattedData = result?.data?.map((doctor: any) => {
          const medInstitutions = doctor.medInstitutions.map(
            (medInstitution: any) => medInstitution.name
          );

          const subData = `${medInstitutions}${
            !!medInstitutions.length && doctor.specialty?.name ? '/' : ''
          }${doctor.specialty?.name || ''}`;

          return {
            ...doctor,
            name: `${doctor.lastName || ''} ${doctor.firstName || ''} ${
              doctor.middleName || ''
            }`,
            medInstitutions,
            specialty: doctor?.specialty?.name,
          };
        });

        this.dispatch(setList(formattedData));
        this.dispatch(setTotal(result.total));
        this.dispatch(setPages(result.total));
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setLoading(false));
  };

  fetchDataByCategory = async () => {
    const currentTabValueName = this.getState().referenceTable.currentTab;

    if (currentTabValueName === REFERENCE_TYPES.SPECIALITY) {
      this.fetchSpecialities();
    }

    if (currentTabValueName === REFERENCE_TYPES.LPU) {
      this.fetchLpuList();
    }

    if (currentTabValueName === REFERENCE_TYPES.DOCTORS) {
      this.fetchDoctors();
    }
  };

  fetchExecutors = async () => {
    try {
      const response = await this.usersService.getUsers();

      if (response) {
        this.dispatch(setExecutors(response.data));
      }
    } catch (e) {
      console.error(e);
    }
  };

  onPageClick = (event: React.ChangeEvent<unknown>, value: number) => {
    const limit = this.getState().referenceTable.limit;
    this.dispatch(setOffset((value - 1) * limit));
    this.dispatch(setCurrentPage(value));
    this.fetchDataByCategory();
  };

  onEnterSearch = () => {
    const state = this.getState().referenceTable;
    this.dispatch(clearTable());
    this.fetchDataByCategory();
    if (state.currentTab === REFERENCE_TYPES.DOCTORS)
      this.dispatch(setSearchedValue(state.searchText));
  };

  onSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.dispatch(setSearchText(value));
  };

  onCloseSearch = () => {
    this.dispatch(clearTable());
    this.dispatch(setOpenSearch(false));
    this.dispatch(setSearchText(''));
    this.dispatch(setSearchedValue(''));
    this.fetchDataByCategory();
  };

  onOpenSearch = () => {
    this.dispatch(setSearchText(''));
    this.dispatch(setOpenSearch(true));
    this.dispatch(setSearchedValue(''));
  };

  handleTasksModalClose = () => {
    this.dispatch(setShowTasksModal(false));
  };

  handleTasksModalShow = () => {
    this.dispatch(setShowTasksModal(true));
  };

  handleMenuClick = (value: string) => {
    const store = this.getState().referenceTable;

    if (
      (store.isApplyFilters || store.isApplyDynamic || !!store.searchedValue) &&
      value.toLowerCase() === `добавить все (${store.total})`
    ) {
      this.handleTasksModalShow();
    }
  };

  handleMeetupChange = (event: SelectChangeEvent) => {
    this.dispatch(setTaskType(event.target.value as string));
  };

  handleExecutorChange = (event: SelectChangeEvent<string[]>) => {
    const values: string[] = event?.target?.value as string[];
    let stringValues: string[] = values.map((item) => String(item));
    const selectedId = stringValues.pop();

    if (!stringValues.find((id) => id === selectedId)) {
      stringValues.push(String(selectedId));
    } else {
      stringValues = stringValues.filter((id) => id !== selectedId);
    }

    this.dispatch(setSelectedExecutors(stringValues));
  };

  handleNoteClick = () => {
    this.dispatch(setIsNoteEdit(true));
  };

  handleAcceptNoteClick = () => {
    this.dispatch(setIsNoteEdit(false));
  };
  handleCancelNoteClick = () => {
    this.dispatch(setTaskRemark(''));
    this.dispatch(setIsNoteEdit(false));
  };

  handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setTaskRemark(event.target.value));
  };

  handleCreateDoctorsTasks = async () => {
    const store = this.getState().referenceTable;
    this.dispatch(setIsTasksCreating(true));
    try {
      const response = await this.catalogsService.createDoctorsTasks(
        store.filters.appAvailability,
        null,
        null,
        store.filters.specialtyIDs,
        store.filters.lpuIDs,
        store.filters.isArchived,
        !!store.searchedValue && store.openSearch ? store.searchedValue : null,
        null,
        null,
        store.filters.lastMeetupDate,
        store.taskProps.selectedExecutors,
        store.taskProps.taskType === MEETUP_TYPE_VALUES.MEETUP
          ? 'meetup'
          : 'envelope',
        store.taskProps.remark,
        store.filters.address
      );

      if (response) {
        this.dispatch(
          showAppSnackBar({
            text: 'Задание создано',
            snackBarSeverity: 'success',
          })
        );
        this.dispatch(setSelectedExecutors([]));
        this.dispatch(setTaskRemark(''));
        this.dispatch(setTaskType('meetup'));
        this.handleTasksModalClose();
      } else {
        this.dispatch(
          showAppSnackBar({
            text: 'Ошибка. Что-то пошло не так',
            snackBarSeverity: 'error',
          })
        );
      }
    } catch (e) {
      console.error(e);
    }

    this.dispatch(setIsTasksCreating(false));
  };

  handleShowUnactiveCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const store = this.getState().referenceTable;
    this.dispatch(setShowUnactive(e.target.checked));
    this.dispatch(setOffset(0));
    this.dispatch(setCurrentPage(1));
    if (store.currentTab === REFERENCE_TYPES.LPU) this.fetchLpuList();
    else this.fetchSpecialities();
  };
}

import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef } from 'react';
import RoundButton from '../../../../ui/RoundButton';
import { ReactComponent as NoteIcon } from '../../../../assets/vector/note.svg';
import ExecutorSelect from '../ExecutorSelect';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { FeedbackMenuController } from '../../feedback-menu.controller';
import { getLocaleFeedbackType } from '../../helpers/getLocaleFeedbackType';
import moment from 'moment';
import FeedbackComment from '../FeedbackComment';
import ConfirmIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import Input from '../../../../ui/Input';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import AppSelect from '../../../../ui/AppSelect';
import RequiredRole from '../../../../components/RequiredRole';
import {
  IImageSliderHandler,
  ImageSlider,
} from '../../../../components/ImageSlider';
import { ServerImage } from '../../../../components/ServerImage';

interface IFeedbackMenuProps {
  onClose?: () => void;
  feedbackId?: number;
}

export const FeedbackMenu = (props: IFeedbackMenuProps) => {
  const dispatch = useAppDispatch();
  const feedbackMenu = useAppSelector((state) => state.feedbackMenu);
  const sliderRef = useRef<IImageSliderHandler | null>(null);
  const { role } = useAppSelector((state) => state.user);
  const controller = new FeedbackMenuController(dispatch);
  useEffect(() => {
    if (props.feedbackId) {
      controller.setFeedbackId(props.feedbackId);
      (async () => {
        await controller.getFeedbackDetails();
        await controller.getLiables();
        await controller.getComments();
      })();
    }

    return () => {
      controller.clearState();
    };
  }, [props.feedbackId]);

  const type = feedbackMenu.type
    ? getLocaleFeedbackType(feedbackMenu.type)
    : '';

  const pageCount = Math.ceil(feedbackMenu.total / feedbackMenu.limit);

  const statusOptions: IAppSelectOption[] = [
    { id: 1, value: 'in_progress', text: 'В работе' },
    { id: 2, value: 'done', text: 'Завершена' },
  ];

  return (
    <div className="feedback-menu">
      <div className="feedback-menu__header">
        <h4 className="feedback-menu__header-title">
          Работа с обратной связью
        </h4>
        <RoundButton
          classNames={['task-detail-form__close-btn']}
          content={<CloseIcon />}
          onClick={props.onClose}
        />
      </div>
      <div className="feedback-menu__info">
        <div className="feedback-menu__content">
          <span className="feedback-menu__type">{type}</span>

          <p className="feedback-menu__feedback">
            {feedbackMenu.comment || 'Комментарий не указан'}
          </p>
        </div>
        <p
          className={`feedback-menu__feedback-info${
            role === 'medical_agent'
              ? ' feedback-menu__feedback-info--short'
              : ''
          }`}
        >
          {feedbackMenu.createdBy?.fullName},{' '}
          {moment(feedbackMenu.createdAt).format('D MMMM YYYY')}
        </p>
        <ul className={'feedback-menu__images-list'}>
          {feedbackMenu.photos.map((image) => (
            <li key={image.id}>
              <button
                className="feedback-menu__image-wrapper"
                onClick={() => sliderRef.current?.showSlide(image.id)}
              >
                <ServerImage
                  src={image.filename}
                  className="feedback-menu__image"
                  width={100}
                  height={100}
                />
              </button>
            </li>
          ))}
        </ul>

        <div className="feedback-menu__selectors">
          <RequiredRole roles={['admin', 'manager']}>
            <ExecutorSelect
              value={feedbackMenu.liable}
              liables={feedbackMenu.liables}
              onSelect={controller.onLiableChange}
            />
          </RequiredRole>

          <RequiredRole roles={['admin', 'manager']}>
            <AppSelect
              classNames={['feedback-menu__status-select']}
              value={feedbackMenu.status}
              options={statusOptions}
              renderText
              handleChange={controller.onFeedbackStatusChange}
            />
          </RequiredRole>
        </div>

        <RequiredRole roles={['admin', 'manager']}>
          {feedbackMenu.isAddingComment ? (
            <div className="feedback-menu__input-wrapper">
              <Input
                multiline
                maxRows={5}
                fullWidth
                autoFocus
                value={feedbackMenu.commentInput}
                onInput={controller.onCommentInputChange}
                placeholder="Добавьте комментарий"
                classNames={['feedback-menu__comment-input']}
              />
              <div className="feedback-menu__edit-buttons">
                <RoundButton
                  disabled={feedbackMenu.isCommentLoading}
                  content={<ConfirmIcon />}
                  onClick={controller.onSaveComment}
                  classNames={['feedback-menu__edit-btn']}
                />
                <RoundButton
                  content={<CancelIcon />}
                  disabled={feedbackMenu.isCommentLoading}
                  onClick={controller.onCancelAddComment}
                  classNames={['feedback-menu__edit-btn']}
                />
              </div>
            </div>
          ) : (
            <Button
              className="feedback-menu__add-comment"
              onClick={controller.onStartAddComment}
            >
              <NoteIcon className="feedback_menu__note-icon" />
              Добавить комментарий
            </Button>
          )}
        </RequiredRole>

        <div className="feedback-menu__comment-list">
          {feedbackMenu.comments.map((comment) => (
            <FeedbackComment
              key={comment.id}
              classNames={['feedback-menu__feedback-comment']}
              id={comment.id}
              comment={comment.comment}
              createdAt={comment.createdAt}
              createdBy={comment.createdBy}
            />
          ))}
        </div>
      </div>

      {/* <Pagination
        className="feedback-menu__pagination"
        onChange={controller.onPageChange}
        count={pageCount}
        page={feedbackMenu.page}
      /> */}

      {feedbackMenu.photos[0] && (
        <ImageSlider ref={sliderRef} images={feedbackMenu.photos} />
      )}
    </div>
  );
};

import React from 'react';
import { CHANGELOG_LIST_LIMIT } from '../../constants';
import { Skeleton } from '@mui/material';

interface IChangelogLoaderProps {}

const ChangelogLoader = (props: IChangelogLoaderProps) => {
  return (
    <>
      {new Array(CHANGELOG_LIST_LIMIT).fill(null).map((_, i) => (
        <div key={i} className="changelog-loader">
          <Skeleton
            classes={{
              root: 'changelog-loader__title',
            }}
          />
          <Skeleton
            classes={{
              root: 'changelog-loader__subtitle',
            }}
          />

          <div className="changelog-loader__info">
            <Skeleton
              classes={{
                root: 'changelog-loader__type',
              }}
            />
            <Skeleton
              classes={{
                root: 'changelog-loader__meetup-info',
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ChangelogLoader;

import { UserRolesTypes } from '../types/users.types';

export const getRoleByKey = (key: UserRolesTypes) => {
  const roles = {
    ['admin']: 'Администратор',
    ['manager']: 'Менеджер',
    ['medical_agent']: 'Представитель',
  };

  return roles[key];
};

import React, { FC } from 'react';
import { TableRow, TableCell, Button, useMediaQuery } from '@mui/material';
import { HistoryReportSortParams, TableHeadEnum } from '../../enums';
import {
  getHeaderNameByKey,
  getTableCellWidthByKey,
  getTableHeaderCellByKey,
  getTableHeaderFilterByKey,
} from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { HistoryReportController } from '../../history-report.controller';

const FixedHeader = () => {
  const historyReport = useAppSelector((state) => state.historyReport);
  const dispatch = useAppDispatch();
  const controller = new HistoryReportController(dispatch);

  return (
    <TableRow className="fixed-header">
      {Object.keys(TableHeadEnum).map((key) => {
        const keyValue = key.toLowerCase() as TableHeadEnum;
        const isCurrentFilter = keyValue === historyReport.filter;
        const onFilterClick = () =>
          controller.handleFilterClick(keyValue as HistoryReportSortParams);
        const CellComponent = getTableHeaderCellByKey(keyValue);
        const FilterComponent = getTableHeaderFilterByKey(keyValue);

        return (
          <TableCell
            key={key}
            className={`fixed-header__cell fixed-header__cell_${keyValue}`}
            width={getTableCellWidthByKey(keyValue)}
          >
            <CellComponent
              value={getHeaderNameByKey(keyValue)}
              filterDirection={historyReport.filterDirection}
              isCurrentFilter={isCurrentFilter}
              onClick={onFilterClick}
              className="fixed-header__default-cell"
            />
            <FilterComponent state={historyReport} controller={controller} />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default FixedHeader;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface IFeedbackPageState {
  isLoading: boolean;
  feedbackDetailVisible: boolean;
  currentFeedbackId?: number;
  isSelecting: boolean;
  selectedDoctors: number[];
  createTaskVisible: boolean;
}

const initialState: IFeedbackPageState = {
  isLoading: false,
  feedbackDetailVisible: false,
  createTaskVisible: false,
  isSelecting: false,
  selectedDoctors: [],
};

export const feedbackPageSlice = createSlice({
  name: 'feedback-page',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFeedbackDetailVisible: (state, action: PayloadAction<boolean>) => {
      state.feedbackDetailVisible = action.payload;
    },
    setCurrentFeedbackId: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.currentFeedbackId = action.payload;
    },
    setSelectedDoctors: (state, action: PayloadAction<number[]>) => {
      state.selectedDoctors = action.payload;
    },
    setIsSelecting: (state, action: PayloadAction<boolean>) => {
      state.isSelecting = action.payload;
    },
    setCreateTaskVisible: (state, action: PayloadAction<boolean>) => {
      state.createTaskVisible = action.payload;
    },
  },
});

export const {
  setLoading,
  setCurrentFeedbackId,
  setFeedbackDetailVisible,
  setSelectedDoctors,
  setCreateTaskVisible,
  setIsSelecting,
} = feedbackPageSlice.actions;
export const feedbackPageReducer = feedbackPageSlice.reducer;

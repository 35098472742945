import React, { MouseEvent } from 'react';
import { RoundedIconTypes } from '../../types';
import { getIconByKey } from '../../helpers/getIconByKey';
import { RoundedIconVariantsEnum } from '../../enums';
import RoundButton from '../../../../ui/RoundButton';
import { IShortTask } from '../../interfaces';
import { Menu, MenuItem } from '@mui/material';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import { getLocaleTaskType } from '../../helpers/getLocaleTaskType';
import { sleep } from '../../../../helpers/sleep';
import { store } from '../../../../app.store';
import moment from 'moment';

interface ITaskButtonProps {
  doctorID: number;
  doctorFullname: string;
  variant: RoundedIconVariantsEnum;
  count?: number;
  onClick?: (type: TaskVariantTypes) => Promise<void>;
  tasks: IShortTask[];
  onTaskClick?: (params: {
    type: TaskVariantTypes;
    id: number;
    name: string;
    doctorID?: number;
  }) => void;
}

const TaskButton = (props: ITaskButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleButtonClick = async (
    e: MouseEvent<HTMLElement | HTMLButtonElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (
      props.variant === RoundedIconVariantsEnum.ENVELOPE_DONE ||
      props.variant === RoundedIconVariantsEnum.MEETUP_DONE
    )
      return;
    const target = e.currentTarget;
    props.variant === RoundedIconVariantsEnum.ENVELOPE_PROGRESS &&
      props.onClick &&
      (await props.onClick('envelope'));
    props.variant === RoundedIconVariantsEnum.MEETUP_PROGRESS &&
      props.onClick &&
      (await props.onClick('meetup'));
    if (store.getState().meetupsList.currentTasks.length) setAnchorEl(target);
  };

  const handleTaskClick = (
    e: MouseEvent<HTMLLIElement>,
    id: number,
    type: TaskVariantTypes
  ) => {
    e.preventDefault();
    e.stopPropagation();
    props.onTaskClick &&
      props.onTaskClick({
        type,
        id,
        name: props.doctorFullname,
        doctorID: props.doctorID,
      });
    handleClose(e);
  };

  const icon = getIconByKey(props.variant);

  return (
    <>
      <RoundButton
        onClick={handleButtonClick}
        content={
          <>
            <div className={`task-button__wrapper ${props.variant}`}>
              <img src={icon} alt="" className={`task-button__icon`} />
            </div>
            {props.count && props.count > 1 ? (
              <span className="task-button__count">{props.count}</span>
            ) : (
              ''
            )}
          </>
        }
        classNames={['task-button']}
      />
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {props.tasks.map((task) => {
          const onTaskClick = (e: MouseEvent<HTMLLIElement>) =>
            handleTaskClick(e, task.id, task.type);

          return (
            <MenuItem onClick={onTaskClick} key={task.id}>
              <div className="task-button__task">
                <p className="task-button__task-type">
                  {getLocaleTaskType(task.type)}
                </p>
                <span className="task-button__task-date">
                  Задание от {moment(task.createdAt).format('D MMMM YYYY')}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default TaskButton;

import { Button, Menu } from '@mui/material';
import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IDeleteConfirmMenu {
  text?: string;
  deleteText?: string;
  onConfirm: () => void;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  classNames?: string[];
  open: boolean;
  disabled?: boolean;
}

const DeleteConfirmMenu = (props: IDeleteConfirmMenu) => {
  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
      className="delete-confirm-menu"
      classes={{ list: 'delete-confirm-menu__list' }}
    >
      <div
        className={classNamesParser(
          'delete-confirm-menu__menu-content',
          props.classNames
        )}
      >
        <p className="delete-confirm-menu__cancel-text">{props.text}</p>
        <Button
          className="delete-confirm-menu__cancel"
          onClick={props.onConfirm}
          disabled={props.disabled}
        >
          {props.deleteText}
        </Button>
      </div>
    </Menu>
  );
};

export default DeleteConfirmMenu;

import { TaskFiltersEnum } from './task-filters.enum';
import FacilityIcon from '../../assets/vector/facility-filter.svg';
import SpecialityIcon from '../../assets/vector/speciality-filter.svg';

export const getFilterIconByKey = (key: TaskFiltersEnum) => {
  const icons = {
    [TaskFiltersEnum.LPU_NAME]: FacilityIcon,
    [TaskFiltersEnum.SPECIALTY_NAME]: SpecialityIcon,
  };

  return icons[key as keyof object];
};

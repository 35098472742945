import React, { CSSProperties } from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';
import { ReactComponent as IconTaskList } from '../../assets/vector/sidebar_task_list.svg';

interface INoDataPlaceholderProps {
  classNames?: string[];
  label?: string;
  isHaveTabOffset?: boolean;
  styles?: CSSProperties;
}

const NoDataPlaceholder = (props: INoDataPlaceholderProps) => {
  const styles: CSSProperties = {
    height: props.isHaveTabOffset ? `calc(100% - 48px - 24px)` : '100%',
    marginTop: props.isHaveTabOffset ? 24 : 0,
  };

  return (
    <div
      className={classNamesParser('no-data-placeholder', props.classNames)}
      style={{ ...styles, ...props.styles }}
    >
      <div className="no-data-placeholder__content">
        <div className="no-data-placeholder__icon-wrapper">
          <IconTaskList className="no-data-placeholder__icon" />
        </div>
        <h3 className="no-data-placeholder__label">
          {props.label ? props.label : 'Список пуст'}
        </h3>
      </div>
    </div>
  );
};

export default NoDataPlaceholder;

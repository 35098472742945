import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import { SelectChangeEvent } from '@mui/material';
import {
  setDoctorsId,
  setExecutors,
  setIsEditingNote,
  setNote,
  setTaskType,
  setNoteInput,
  setSelectedExecutors,
} from './store/create-task-form.slice';
import { UsersService } from '../../services/users.service';
import { TaskVariantTypes } from '../../types/tasks.types';
import { TaskService } from '../../services/tasks.service';

export class CreateFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  usersService: UsersService = new UsersService();
  taskService: TaskService = new TaskService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  setDoctorsId = (doctorsId: number[]) => {
    this.dispatch(setDoctorsId(doctorsId));
  };

  onTaskTypeChange = (e: SelectChangeEvent) => {
    this.dispatch(setTaskType(e.target.value as TaskVariantTypes));
  };

  onExecutorsChange = (e: SelectChangeEvent<string[]>) => {
    const state = this.getState().createTaskForm;
    const executorId = String(e.target.value[e.target.value.length - 1]);

    if (state.selectedExecutors.includes(executorId))
      this.dispatch(
        setSelectedExecutors(
          state.selectedExecutors.filter((id) => id !== String(executorId))
        )
      );
    else {
      this.dispatch(
        setSelectedExecutors([...state.selectedExecutors, String(executorId)])
      );
    }
  };

  onAcceptNoteClick = () => {
    const state = this.getState().createTaskForm;
    this.dispatch(setNote(state.noteInput));
    this.dispatch(setIsEditingNote(false));
  };
  onCancelNoteClick = () => {
    this.dispatch(setIsEditingNote(false));
  };
  onNoteClick = () => {
    const state = this.getState().createTaskForm;
    this.dispatch(setIsEditingNote(true));
    this.dispatch(setNoteInput(state.note));
  };

  onNoteInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setNoteInput(e.target.value));
  };

  onCreateTask = async (callback?: () => void) => {
    const state = this.getState().createTaskForm;
    if (state.doctorsId.length === 0) return;
    const data = await this.taskService.createTask(
      state.type,
      state.doctorsId,
      state.note,
      state.selectedExecutors
    );
    if (data !== null) {
      callback && callback();
    }
  };

  getExecutors = async () => {
    const data = await this.usersService.getUsers();
    if (data !== null) this.dispatch(setExecutors(data.data));
  };

  clearState = async () => {
    this.dispatch(setNote(''));
    this.dispatch(setNoteInput(''));
    this.dispatch(setIsEditingNote(false));
    this.dispatch(setSelectedExecutors([]));
    this.dispatch(setTaskType('meetup'));
    this.dispatch(setDoctorsId([]));
  };
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDoctorInfo, IMeetupFeedback, IMeetupTask } from '../interfaces';
import { IFeedback } from '../../../interfaces/api.catalogs.interfaces';

interface MeetupDetailsState {
  isLoading: boolean;
  tasks: IMeetupTask[];
  doctorId?: number;
  doctor?: IDoctorInfo;
  isTasksLoading: boolean;
  isEditingNote: boolean;
  noteInput: string;
  isEditingPhoneNumber: boolean;
  phoneInput: string;
  idEditPhone: number | null;
  isContactsMobileVisible: boolean;
  isNoteEditModalVisible: boolean;
  phoneNumberErrors: string[];
  editPhoneListError: string;
  feedbacks: IMeetupFeedback[];
}

const initialState: MeetupDetailsState = {
  isLoading: false,
  isTasksLoading: false,
  tasks: [],
  isEditingNote: false,
  isEditingPhoneNumber: false,
  phoneInput: '',
  noteInput: '',
  idEditPhone: null,
  isContactsMobileVisible: false,
  isNoteEditModalVisible: false,
  phoneNumberErrors: [],
  editPhoneListError: '',
  feedbacks: [],
};

export const meetupDetailsSlice = createSlice({
  name: 'meetup-details',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTasksLoading: (state, action: PayloadAction<boolean>) => {
      state.isTasksLoading = action.payload;
    },
    setDoctorId: (state, action: PayloadAction<number>) => {
      state.doctorId = action.payload;
    },
    setTasks: (state, action: PayloadAction<IMeetupTask[]>) => {
      state.tasks = action.payload;
    },
    setDoctor: (state, action: PayloadAction<IDoctorInfo>) => {
      state.doctor = action.payload;
    },
    setIsEditingNote: (state, action: PayloadAction<boolean>) => {
      state.isEditingNote = action.payload;
    },
    setNoteInput: (state, action: PayloadAction<string>) => {
      state.noteInput = action.payload;
    },
    setIsEditingPhone: (state, action: PayloadAction<boolean>) => {
      state.isEditingPhoneNumber = action.payload;
    },

    setPhoneInput: (state, action: PayloadAction<string>) => {
      state.phoneInput = action.payload;
    },
    setIdEditPhone: (state, action: PayloadAction<number | null>) => {
      state.idEditPhone = action.payload;
    },
    setIsContactsMobileVisible: (state, action: PayloadAction<boolean>) => {
      state.isContactsMobileVisible = action.payload;
    },

    pushPhoneNumber: (state, action: PayloadAction<string>) => {
      state.doctor?.contactPhones.push(action.payload);
    },

    deletePhoneNumber: (state, action: PayloadAction<number>) => {
      state.doctor?.contactPhones.splice(action.payload, 1);
    },

    updatePhoneNumberById: (
      state,
      action: PayloadAction<{ id: number; value: string }>
    ) => {
      state.doctor?.contactPhones.forEach((prev, i, arr) => {
        arr[i] = action.payload.id === i ? action.payload.value : prev;
      });
    },

    setNoteEditModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isNoteEditModalVisible = action.payload;
    },
    setPhoneNumberErrors: (state, action: PayloadAction<string[]>) => {
      state.phoneNumberErrors = action.payload;
    },
    setEditPhoneListError: (state, action: PayloadAction<string>) => {
      state.editPhoneListError = action.payload;
    },
    setFeedbacks: (state, action: PayloadAction<IMeetupFeedback[]>) => {
      state.feedbacks = action.payload;
    },
  },
});

export const {
  setDoctorId,
  setTasks,
  setDoctor,
  setLoading,
  setTasksLoading,
  setIsEditingNote,
  setIsEditingPhone,
  setNoteInput,
  setPhoneInput,
  setIsContactsMobileVisible,
  setIdEditPhone,
  pushPhoneNumber,
  deletePhoneNumber,
  updatePhoneNumberById,
  setPhoneNumberErrors,
  setEditPhoneListError,
  setNoteEditModalVisible,
  setFeedbacks,
} = meetupDetailsSlice.actions;

export const meetupDetailsReducer = meetupDetailsSlice.reducer;

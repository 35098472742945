import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferenceTypes } from '../../../modules/ReferenceTable';
import { IApiSpecialityItem } from '../../../interfaces/api.catalogs.interfaces';
import { UploadDataPageTabValues } from '../index.interfaces';

interface IUploadDataPageState {
  currentTab: UploadDataPageTabValues;
  isUploadShow: boolean;
  uploadId: number | null;
  isNewUpload: boolean;
  date: string;
  filename: string;
  filenameInput: string;
  isEditingFilename: boolean;
  uploadDate: string;
  isCreatingTask: boolean;
}

const initialState: IUploadDataPageState = {
  currentTab: 'history',
  isUploadShow: false,
  uploadId: null,
  isNewUpload: false,
  filename: '',
  date: '',
  filenameInput: '',
  isEditingFilename: false,
  uploadDate: '',
  isCreatingTask: false,
};

export const uploadDataPageSlice = createSlice({
  name: 'upload-data-page',
  initialState,
  reducers: {
    setUploadDate: (state, action: PayloadAction<string>) => {
      state.uploadDate = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<UploadDataPageTabValues>) => {
      state.currentTab = action.payload;
    },
    setIsUploadShow: (state, action: PayloadAction<boolean>) => {
      state.isUploadShow = action.payload;
    },
    setUploadId: (state, action: PayloadAction<number>) => {
      state.uploadId = action.payload;
    },
    setIsNewUpload: (state, action: PayloadAction<boolean>) => {
      state.isNewUpload = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setFilename: (state, action: PayloadAction<string>) => {
      state.filename = action.payload;
    },
    setFilenameInput: (state, action: PayloadAction<string>) => {
      state.filenameInput = action.payload;
    },
    setIsEditingFilename: (state, action: PayloadAction<boolean>) => {
      state.isEditingFilename = action.payload;
    },
    setCreatingTask: (state, action: PayloadAction<boolean>) => {
      state.isCreatingTask = action.payload;
    },
  },
});

export const {
  setCurrentTab,
  setIsUploadShow,
  setUploadId,
  setIsNewUpload,
  setDate,
  setFilename,
  setFilenameInput,
  setIsEditingFilename,
  setCreatingTask,
  setUploadDate,
} = uploadDataPageSlice.actions;
export default uploadDataPageSlice.reducer;

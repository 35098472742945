import { RoundedIconTypes } from '../types';
import EnvelopeGivenIcon from '../../../assets/vector/envelope-given-rounded.svg';
import HandshakeIcon from '../../../assets/vector/handshake.svg';
import EnvelopeSuccessIcon from '../../../assets/vector/envelope-success.svg';
import { RoundedIconVariantsEnum } from '../enums';

export const getIconByKey = (key: RoundedIconVariantsEnum) => {
  const icons = {
    [RoundedIconVariantsEnum.MEETUP_DONE]: HandshakeIcon,
    [RoundedIconVariantsEnum.MEETUP_PROGRESS]: HandshakeIcon,
    [RoundedIconVariantsEnum.ENVELOPE_DONE]: EnvelopeSuccessIcon,
    [RoundedIconVariantsEnum.ENVELOPE_PROGRESS]: EnvelopeGivenIcon,
  };

  return icons[key];
};

import { MeetupFeedbackTypes } from '../../../types/tasks.types';

export const getLocaleFeedbackType = (key: MeetupFeedbackTypes) => {
  const types = {
    'mobile_app': 'Мобильное приложение',
    'common': 'Общие вопросы',
    'update_personal_data': 'Внести изменения в личные данные',
  };

  return types[key];
};

import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import moment from 'moment';
import React, { MouseEvent } from 'react';
import { ReactComponent as DownloadIcon } from '../../../../assets/vector/download-icon.svg';
import { DownloadReportsVariants } from '../../enums';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { capitalize } from '../../../../helpers/capitalize';
interface IHistoryItemProps {
  id: number;
  name: string;
  date: string;
  loads: number;
  onClick: (id: number, isNew: boolean, date: string, filename: string) => void;
  onCreateTask: (id: number) => void;
  onDownloadExcel: (id: number, filename: string) => void;
  onDownloadPDF: (id: number, filename: string) => void;
  handleCancelDownload?: (e?: any) => void;
  loadedToStatistics?: boolean;
  downloadingID: number | null;
}

const HistoryItem = (props: IHistoryItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleItemClick = () => {
    props.onClick(props.id, false, props.date, props.name);
  };

  const handleCreateTaskClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onCreateTask && props.onCreateTask(props.id);
  };

  const handleDownloadExcelReport = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onDownloadExcel && props.onDownloadExcel(props.id, props.name);
    handleClose(e);
  };

  const isDownloading = props.downloadingID !== null;
  const isCurrentDownloading = props.id === props.downloadingID;

  return (
    <div className="history-item" onClick={handleItemClick}>
      <div className="">
        <h4 className="history-item__title">{props.name}</h4>
        <div className="history-item__info-wrapper">
          <span className="history-item__date">
            {capitalize(moment(props.date).format('MMMM YYYY'))},{' '}
          </span>
          <span className="history-item__load-counter">
            {props.loadedToStatistics
              ? `Загружено: ${props.loads}`
              : 'В обработке'}
          </span>
        </div>
      </div>

      {!isDownloading && (
        <div className="history-item__buttons-wrapper">
          <Button
            className="history-item__create-task"
            onClick={handleCreateTaskClick}
            disabled={isDownloading}
          >
            Создать задание
          </Button>

          <Button
            className="history-item__report"
            onClick={handleDownloadExcelReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
            Отчет
          </Button>
          <RoundButton
            onClick={props.handleCancelDownload}
            content={<CloseIcon style={{ width: 16, height: 16 }} />}
            classNames={['history-item__cancel-download']}
            dataAttrs={{
              'data-id': props.id,
              'data-filename': props.name,
            }}
          />
          {/* <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        > */}
          {/* <MenuItem onClick={handleDownloadExcelReport}>XLSX</MenuItem> */}
          {/* <MenuItem onClick={handleDownloadPDFReport}>PDF</MenuItem> */}
          {/* </Menu> */}
        </div>
      )}
      {isCurrentDownloading && (
        <CircularProgress style={{ marginRight: 18 }} size={'2rem'} />
      )}
    </div>
  );
};

export default HistoryItem;

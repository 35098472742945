import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REFERENCE_TABLE_LIMIT } from '../constants';
import { TaskVariantTypes } from '../../../types/tasks.types';
import { ITaskExecutor } from '../../../interfaces/tasks.interfaces';
import { ReferenceTypes } from '../types';

interface IReferenceTableState {
  isLoading: boolean;
  currentTab: ReferenceTypes;
  list: any[];
  total: number;
  limit: number;
  pages: number;
  offset: number;
  currentPage: number;
  searchText: string;
  selectedCardId: number;
  openSearch: boolean;
  searchedValue: string;
  filters: {
    lpuIDs: number[] | null;
    specialtyIDs: number[] | null;
    isArchived: boolean;
    appAvailability: boolean | null;
    lastMeetupDate: string | null;
    address: any[] | null;
    monthStart: string | null;
    monthEnd: string | null;
    yearStart: number | null;
    yearEnd: number | null;
    dynamicFrom: number | null;
    dynamicTo: number | null;
  };
  showTasksModal: boolean;
  executors: ITaskExecutor[];
  taskProps: {
    taskType: string;
    selectedExecutors: string[];
    remark: string;
  };
  isApplyFilters: boolean;
  isShowUnactive: boolean;
  isApplyDynamic: boolean;
  selectedLpu: any[];
  selectedSpecialities: any[];
  isNoteEdit: boolean;
  isTasksCreating: boolean;
}

const initialState: IReferenceTableState = {
  isLoading: false,
  currentTab: 'doctors',
  list: [],
  total: 0,
  limit: REFERENCE_TABLE_LIMIT,
  pages: 0,
  offset: 0,
  currentPage: 1,
  searchText: '',
  selectedCardId: 0,
  openSearch: false,
  searchedValue: '',
  filters: {
    lpuIDs: null,
    specialtyIDs: null,
    isArchived: false,
    appAvailability: null,
    lastMeetupDate: null,
    address: null,
    monthStart: null,
    monthEnd: null,
    yearStart: null,
    yearEnd: null,
    dynamicFrom: null,
    dynamicTo: null,
  },
  selectedLpu: [],
  selectedSpecialities: [],
  showTasksModal: false,
  executors: [],
  taskProps: {
    taskType: '',
    selectedExecutors: [],
    remark: '',
  },
  isApplyFilters: false,
  isApplyDynamic: false,
  isNoteEdit: false,
  isTasksCreating: false,
  isShowUnactive: false,
};

export const referenceTableSlice = createSlice({
  name: 'reference-table',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setTabValue: (state, action: PayloadAction<ReferenceTypes>) => {
      state.currentTab = action.payload;
    },
    setList: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setPages: (state, action: PayloadAction<number>) => {
      state.pages = Math.ceil(action.payload / state.limit);
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSelectedCardId: (state, action: PayloadAction<number>) => {
      state.selectedCardId = action.payload;
    },
    clearTable: (state) => {
      state.list = [];
      state.total = 0;
      state.offset = 0;
      state.limit = REFERENCE_TABLE_LIMIT;
      state.pages = 0;
      state.currentPage = 1;
    },
    setOpenSearch: (state, action: PayloadAction<boolean>) => {
      state.openSearch = action.payload;
    },
    setFiltersLpuIds: (state, action: PayloadAction<number[] | null>) => {
      state.filters.lpuIDs = action.payload;
    },
    setFiltersSpecialityIds: (
      state,
      action: PayloadAction<number[] | null>
    ) => {
      state.filters.specialtyIDs = action.payload;
    },
    setShowTasksModal: (state, action: PayloadAction<boolean>) => {
      state.showTasksModal = action.payload;
    },
    setTaskType: (state, action: PayloadAction<string>) => {
      state.taskProps.taskType = action.payload;
    },
    setExecutors: (state, action: PayloadAction<ITaskExecutor[]>) => {
      state.executors = action.payload;
    },
    setSelectedExecutors: (state, action: PayloadAction<string[]>) => {
      state.taskProps.selectedExecutors = action.payload;
    },
    setApplyFilters: (state, action: PayloadAction<boolean>) => {
      state.isApplyFilters = action.payload;
    },
    setApplyDynamic: (state, action: PayloadAction<boolean>) => {
      state.isApplyDynamic = action.payload;
    },
    setSelectedLpu: (state, action: PayloadAction<any[]>) => {
      state.selectedLpu = action.payload;
    },
    setSelectedSpecialities: (state, action: PayloadAction<any[]>) => {
      state.selectedSpecialities = action.payload;
    },
    setTaskRemark: (state, action: PayloadAction<string>) => {
      state.taskProps.remark = action.payload;
    },
    setIsNoteEdit: (state, action: PayloadAction<boolean>) => {
      state.isNoteEdit = action.payload;
    },
    clearFiltersValues: (state) => {
      state.taskProps = {
        taskType: '',
        selectedExecutors: [],
        remark: '',
      };
      state.filters = {
        ...state.filters,
        lpuIDs: null,
        specialtyIDs: null,
        lastMeetupDate: null,
        appAvailability: null,
        isArchived: false,
        address: null,
      };
      state.selectedLpu = [];
      state.selectedSpecialities = [];
      state.executors = [];
    },
    clearDynamicValues: (state) => {
      state.taskProps = {
        taskType: '',
        selectedExecutors: [],
        remark: '',
      };
      state.filters = {
        ...state.filters,
        monthStart: null,
        monthEnd: null,
        yearStart: null,
        yearEnd: null,
        dynamicFrom: null,
        dynamicTo: null,
      };
    },
    setLastMeetupDate: (state, action: PayloadAction<string | null>) => {
      state.filters.lastMeetupDate = action.payload;
    },
    setAppAvailability: (state, action: PayloadAction<boolean | null>) => {
      state.filters.appAvailability = action.payload;
    },
    setIsArchived: (state, action: PayloadAction<boolean>) => {
      state.filters.isArchived = action.payload;
    },
    setIsTasksCreating: (state, action: PayloadAction<boolean>) => {
      state.isTasksCreating = action.payload;
    },
    setFilterAddress: (state, action: PayloadAction<any[] | null>) => {
      state.filters.address = action.payload;
    },
    setMonthStart: (state, action: PayloadAction<string | null>) => {
      state.filters.monthStart = action.payload;
    },
    setMonthEnd: (state, action: PayloadAction<string | null>) => {
      state.filters.monthEnd = action.payload;
    },
    setYearStart: (state, action: PayloadAction<number | null>) => {
      state.filters.yearStart = action.payload;
    },
    setYearEnd: (state, action: PayloadAction<number | null>) => {
      state.filters.yearEnd = action.payload;
    },
    setDynamicFrom: (state, action: PayloadAction<number | null>) => {
      state.filters.dynamicFrom = action.payload;
    },
    setDynamicTo: (state, action: PayloadAction<number | null>) => {
      state.filters.dynamicTo = action.payload;
    },
    setSearchedValue: (state, action: PayloadAction<string>) => {
      state.searchedValue = action.payload;
    },
    setShowUnactive: (state, action: PayloadAction<boolean>) => {
      state.isShowUnactive = action.payload;
    },
  },
});

export const {
  setLoading,
  setList,
  setTotal,
  setTabValue,
  clearTable,
  setLimit,
  setPages,
  setOffset,
  setCurrentPage,
  setSearchText,
  setSelectedCardId,
  setOpenSearch,
  setFiltersLpuIds,
  setFiltersSpecialityIds,
  setShowTasksModal,
  setTaskType,
  setExecutors,
  setSelectedExecutors,
  setApplyFilters,
  setSelectedLpu,
  setSelectedSpecialities,
  setTaskRemark,
  setIsNoteEdit,
  setLastMeetupDate,
  setAppAvailability,
  setIsArchived,
  setIsTasksCreating,
  setFilterAddress,
  setMonthStart,
  setMonthEnd,
  setYearStart,
  setYearEnd,
  setDynamicFrom,
  setDynamicTo,
  setApplyDynamic,
  clearDynamicValues,
  setSearchedValue,
  clearFiltersValues,
  setShowUnactive,
} = referenceTableSlice.actions;

export const referenceTableReducer = referenceTableSlice.reducer;

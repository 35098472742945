import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@mui/material';
import { classNamesParser } from '../../helpers/classNamesParser';
import RoundButton from '../RoundButton';
import { IRoundMenuButtonItem } from './index.interfaces';
import RequiredRole from '../../components/RequiredRole';

interface IMenuButton {
  classNames?: string[];
  onStateChange?: (value: any) => void;
  menuItems?: IRoundMenuButtonItem[];
  onMenuClick?: (value: string) => void;
  backgroundColor?: string;
  disableMenuIds?: number[];
}

const RoundMenuButton = (props: IMenuButton) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    props.onStateChange && props.onStateChange(!!event.currentTarget);
  };
  const handleClose = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    props.onStateChange && props.onStateChange(false);
  };

  const onMenuClick = (value: string) => {
    props.onMenuClick && props.onMenuClick(value);
    handleClose();
  };

  return (
    <div className={classNamesParser('round-menu-button', props.classNames)}>
      <RoundButton
        content={<MoreHorizIcon />}
        backgroundColor={
          props.backgroundColor ? props.backgroundColor : 'rgb(243 243 243)'
        }
        onClick={
          handleClick as (
            event: React.MouseEvent<HTMLElement | HTMLButtonElement>
          ) => void
        }
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {props.menuItems &&
          props.menuItems.map((menuItem) => (
            <RequiredRole
              roles={menuItem.roles || ['admin', 'manager', 'medical_agent']}
            >
              <MenuItem
                key={menuItem.id}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onMenuClick(menuItem.text);
                }}
                style={{
                  color: menuItem.color,
                  //pointerEvents: 'none',
                }}
                disabled={
                  props.disableMenuIds === undefined
                    ? false
                    : !!props.disableMenuIds?.find(
                        (item) => menuItem.id === item
                      )
                }
              >
                {menuItem.text}
              </MenuItem>
            </RequiredRole>
          ))}
      </Menu>
    </div>
  );
};

export default RoundMenuButton;

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import { CatalogsService } from '../../services/catalogs.service';
import { MeetupsService } from '../../services/meetups.service';
import { IDoctorInfo } from './interfaces';
import {
  setDoctor,
  setDoctorId,
  setLoading,
  setTasks,
  setTasksLoading,
  setIsEditingNote,
  setNoteInput,
  setPhoneInput,
  setIsEditingPhone,
  setIdEditPhone,
  setIsContactsMobileVisible,
  pushPhoneNumber,
  deletePhoneNumber,
  updatePhoneNumberById,
  setNoteEditModalVisible,
  setPhoneNumberErrors,
  setEditPhoneListError,
  setFeedbacks,
} from './store/meetup-details.slice';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import Validator from 'validator';
import { removeLetters } from '../../helpers/removeLetters';

export class MeetupDetailsController {
  dispatch: AppDispatch;
  getState: () => RootState;
  meetupService: MeetupsService = new MeetupsService();
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  setDoctorId = async (id: number) => {
    this.dispatch(setLoading(true));
    this.dispatch(setIsEditingNote(false));
    this.dispatch(setIsEditingPhone(false));
    this.dispatch(setDoctorId(id));
    const data = await this.catalogsService.getDoctorByIdAsAgent(id);
    if (data) {
      this.dispatch(setDoctor(data));
      this.dispatch(setNoteInput(data.note));
      this.dispatch(
        setPhoneNumberErrors(new Array(data.contactPhones.length).fill(''))
      );
    }
    this.dispatch(setLoading(false));
  };

  getTasksByDoctor = async () => {
    this.dispatch(setTasksLoading(true));
    this.dispatch(setTasks([]));
    const state = this.getState().meetupDetails;
    if (!state.doctorId) return;
    const data = await this.meetupService.getTasksByDoctor(state.doctorId);

    console.log(data);

    if (data) {
      this.dispatch(setFeedbacks(data.feedbacks));
      this.dispatch(setTasks(data.tasks));
    }
    this.dispatch(setTasksLoading(false));
  };

  onEditNoteClick = async () => {
    this.dispatch(setIsEditingNote(true));
    this.dispatch(setIsEditingPhone(false));
  };

  onCancelEditNote = () => {
    this.dispatch(setIsEditingNote(false));
    this.dispatch(setIsEditingNote(false));
    const state = this.getState().meetupDetails;
    this.dispatch(setNoteInput(state.doctor?.note || ''));
  };

  updateDoctorInState = async () => {
    const state = this.getState().meetupDetails;
    if (!state.doctorId) return;
    const doctor = await this.catalogsService.getDoctorByIdAsAgent(
      state.doctorId
    );
    if (doctor) {
      this.dispatch(setDoctor(doctor));
      this.dispatch(
        setPhoneNumberErrors(new Array(doctor.contactPhones.length).fill(''))
      );
    }
  };

  onSaveNote = async () => {
    const state = this.getState().meetupDetails;
    if (!state.doctorId) return;
    await this.catalogsService.updateDoctorById(
      state.doctorId,
      state.noteInput.length > 0 ? state.noteInput : null
    );
    await this.updateDoctorInState();
    this.dispatch(setIsEditingNote(false));
    this.dispatch(setNoteEditModalVisible(false));
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_LIST);
  };

  onNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setNoteInput(e.target.value));
  };

  onEditPhoneNumber = (phoneId: number | null) => {
    const state = this.getState().meetupDetails;
    this.dispatch(setIsEditingNote(false));
    this.dispatch(setIsEditingPhone(true));
    this.dispatch(setIdEditPhone(phoneId));
    const phoneNumber =
      phoneId !== null ? state.doctor?.contactPhones?.[phoneId] : '';

    this.dispatch(setEditPhoneListError(''));
    this.dispatch(setPhoneInput(phoneNumber || ''));
  };

  onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPhoneInput(removeLetters(e.target.value)));
  };

  onCancelEditingNumber = () => {
    this.dispatch(setIsEditingPhone(false));
    this.dispatch(setEditPhoneListError(''));
  };

  onSavePhoneNumber = async () => {
    const state = this.getState().meetupDetails;
    if (!state.doctorId) return;
    const isCreatingPhone = state.doctor?.contactPhones.length === 0;
    let phoneNumbers: string[] = [];
    // if (!Validator.isMobilePhone(state.phoneInput, 'ru-RU')) {
    //   this.dispatch(
    //     setEditPhoneListError('Указан неверный формат номера телефона')
    //   );
    //   return;
    // }
    // this.dispatch(setEditPhoneListError(''));
    if (isCreatingPhone) {
      phoneNumbers = [state.phoneInput];
    } else {
      phoneNumbers =
        state.doctor?.contactPhones.map((phone, i) => {
          if (i === state.idEditPhone) return state.phoneInput;
          return phone;
        }) || [];
    }
    let result = await this.catalogsService.updateDoctorById(
      state.doctorId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      phoneNumbers
    );
    await this.updateDoctorInState();

    if (result?.length > 0) {
      //TODO: Обрабатываем ошибку
      this.dispatch(
        setEditPhoneListError('Указан неверный формат номера телефона')
      );
      return;
    }
    this.dispatch(setEditPhoneListError(''));
    this.dispatch(setIsEditingPhone(false));
  };

  onCloseMobileContacts = async () => {
    this.dispatch(setIsContactsMobileVisible(false));
    this.updateDoctorInState();
  };
  onOpenContactsMobile = () => {
    this.dispatch(setIsContactsMobileVisible(true));
  };

  onAddPhoneNumber = () => {
    this.dispatch(pushPhoneNumber(''));
  };

  onDeletePhoneNumber = (id: number) => {
    this.dispatch(deletePhoneNumber(id));
  };

  onMobileInputChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    this.dispatch(
      updatePhoneNumberById({ id, value: removeLetters(e.target.value) })
    );
  };

  onMobileContactsSave = async () => {
    const state = this.getState().meetupDetails;
    if (!state.doctorId) return;

    console.log(state.doctor?.contactPhones);

    const regEx = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

    const errors =
      state.doctor?.contactPhones.map((number) =>
        regEx.test(number) ? '' : 'Указан неверный формат номера телефона'
      ) || [];

    this.dispatch(setPhoneNumberErrors(errors));
    if (errors.find((error) => error.length > 0)) return;
    const data = await this.catalogsService.updateDoctorById(
      state.doctorId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      state.doctor?.contactPhones
    );

    if (data?.length > 0) {
      const error = data.split('_');
      const phoneError = error[error.length - 1];
      this.dispatch(
        setPhoneNumberErrors(
          errors.map((err, i) => {
            if (phoneError === state.doctor?.contactPhones[i]) {
              return 'Указан неверный формат номера телефона';
            } else return err;
          })
        )
      );

      return;
    }
    this.dispatch(setIsContactsMobileVisible(false));
    this.updateDoctorInState();
  };

  onMobileNoteFormClose = async () => {
    const state = this.getState().meetupDetails;
    this.dispatch(setNoteEditModalVisible(false));
    this.dispatch(setNoteInput(state.doctor?.note || ''));
  };

  onMobileNoteFormOpen = () => {
    this.dispatch(setNoteEditModalVisible(true));
  };
}

import React, { JSX } from 'react';
import { Skeleton } from '@mui/material';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import './styles.scss';
const ReferenceSkeleton = () => {
  return (
    <div className={classNamesParser('reference-skeleton')}>
      {Array.from(Array(10).keys()).map((item) => (
        <Skeleton
          key={item}
          height={64}
          className="reference-skeleton__skeleton"
        />
      ))}
    </div>
  );
};

export default ReferenceSkeleton;

import { AppDispatch, RootState, store } from '../../app.store';
import { IDynamicPeriod, IReportMonthStatistic } from './interfaces';
import {
  pushCityDynamic,
  pushDoctorsDynamic,
  setEndDate,
  setHaveMore,
  setHeadingDates,
  setLoading,
  pushLpuDynamic,
  setOffset,
  setStartDate,
  setTabValue,
  clearDoctorsDynamic,
  clearCityDynamic,
  clearLpuDynamic,
} from './store/dynamic-table.slice';
import { DynamicTableTabs } from './enums';
import moment from 'moment';
import { ReportsService } from '../../services/reports.service';
import { capitalize } from '../../helpers/capitalize';
import { ExportService } from '../../services/export.service';
import { getFormattedDates } from './helpers';
import { saveBase64AsFile } from '../../helpers/saveFile';
import { ViewController } from '../../interfaces/general.interfaces';

export class DynamicTableController extends ViewController {
  reportsService: ReportsService = new ReportsService();
  exportService: ExportService = new ExportService();

  onTabValueChange = (_: any, value: DynamicTableTabs) => {
    this.clearCurrentStats();
    this.dispatch(setTabValue(value));
  };
  getStats = () => {
    const currentTab = this.getState().dynamicTable.tabValue;

    const funcMap = {
      [DynamicTableTabs.DOCTORS]: this.getDoctorsStatistic,
      [DynamicTableTabs.FACILITY]: this.getLpuStatistics,
      [DynamicTableTabs.CITY]: this.getCityStatistics,
    };

    funcMap[currentTab]();
  };

  clearCurrentStats = () => {
    const currentTab = this.getState().dynamicTable.tabValue;
    this.dispatch(setOffset(0));
    this.dispatch(setHaveMore(true));
    this.dispatch(setLoading(false));
    const funcMap = {
      [DynamicTableTabs.DOCTORS]: () => this.dispatch(clearDoctorsDynamic()),
      [DynamicTableTabs.FACILITY]: () => this.dispatch(clearLpuDynamic()),
      [DynamicTableTabs.CITY]: () => this.dispatch(clearCityDynamic()),
    };

    funcMap[currentTab]();
  };

  getLpuStatistics = async () => {
    const state = this.getState().dynamicTable;
    if (
      !state.startDate ||
      !state.endDate ||
      !state.haveMore ||
      state.isLoading
    )
      return;
    this.dispatch(setLoading(true));
    const startMonth = moment(state.startDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const endMonth = moment(state.endDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const startYear = Number(moment(state.startDate).format('YYYY'));
    const endYear = Number(moment(state.endDate).format('YYYY'));

    try {
      const response = await this.reportsService.getLpuDynamicReport(
        startMonth,
        endMonth,
        startYear,
        endYear,
        state.limit,
        state.offset
      );

      if (response !== null) {
        this.dispatch(pushLpuDynamic(response.records));
        this.dispatch(setOffset(state.offset + state.limit));
        const haveMore =
          response.total > state.lpuDynamic.length + response.records.length;
        this.dispatch(setHaveMore(haveMore));
        const units = response.records?.[0]?.units;
        this.dispatch(setHeadingDates(this.convertUnitsToDates(units)));
        moment.locale('ru');
      }
    } catch (e) {
      console.error(e);
    }
    this.dispatch(setLoading(false));
  };

  getCityStatistics = async () => {
    const state = this.getState().dynamicTable;
    if (
      !state.startDate ||
      !state.endDate ||
      !state.haveMore ||
      state.isLoading
    )
      return;
    const startMonth = moment(state.startDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const endMonth = moment(state.endDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const startYear = Number(moment(state.startDate).format('YYYY'));
    const endYear = Number(moment(state.endDate).format('YYYY'));

    try {
      const response = await this.reportsService.getCityDynamicReport(
        startMonth,
        endMonth,
        startYear,
        endYear,
        state.limit,
        state.offset
      );

      if (response !== null) {
        this.dispatch(pushCityDynamic(response.records));
        this.dispatch(setOffset(state.offset + state.limit));
        const haveMore =
          response.total > state.cityDynamic.length + response.records.length;
        this.dispatch(setHaveMore(haveMore));
        const units = response.records?.[0]?.units;
        this.dispatch(setHeadingDates(this.convertUnitsToDates(units)));
        moment.locale('ru');
      }
    } catch (e) {
      console.error(e);
    }
  };

  getDoctorsStatistic = async () => {
    const state = this.getState().dynamicTable;
    if (
      !state.startDate ||
      !state.endDate ||
      !state.haveMore ||
      state.isLoading
    )
      return;
    const startMonth = moment(state.startDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const endMonth = moment(state.endDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const startYear = Number(moment(state.startDate).format('YYYY'));
    const endYear = Number(moment(state.endDate).format('YYYY'));

    const response = await this.reportsService.getDoctorDynamicReport(
      startMonth,
      endMonth,
      startYear,
      endYear,
      state.limit,
      state.offset
    );
    if (response !== null) {
      this.dispatch(pushDoctorsDynamic(response.records));
      this.dispatch(setOffset(state.offset + state.limit));
      const haveMore =
        response.total > state.doctorsDynamic.length + response.records.length;
      this.dispatch(setHaveMore(haveMore));
      const units = response.records?.[0]?.units;
      this.dispatch(setHeadingDates(this.convertUnitsToDates(units)));
      moment.locale('ru');
    }
  };

  convertUnitsToDates = (units: IReportMonthStatistic[]) => {
    return (
      units?.map((date) => {
        moment.locale('en-US');
        const momentDate = moment(
          `${date.scale.year} ${capitalize(date.scale.month)} `,
          'YYYY MMMM'
        ).locale('ru');
        return capitalize(momentDate.format('MMMM YYYY'));
      }) || []
    );
  };

  onStartDateChange = (date: string) => {
    const state = this.getState().dynamicTable;
    if (moment(date).isAfter(moment(state.endDate)))
      this.dispatch(setEndDate(date));
    this.dispatch(setStartDate(date));
    this.refreshList();
  };

  onEndDateChange = (date: string) => {
    const state = this.getState().dynamicTable;
    if (moment(state.startDate).isAfter(moment(date)))
      this.dispatch(setStartDate(date));
    this.dispatch(setEndDate(date));
    this.refreshList();
  };

  handleDownloadDoctorDynamic = async () => {
    const state = this.getState().dynamicTable;

    try {
      const dates = getFormattedDates(state);
      const result = await this.exportService.downloadDoctorsDynamics(
        dates.startMonth,
        dates.endMonth,
        dates.startYear,
        dates.endYear
      );

      if (result !== null) {
        const periodString = `С ${moment(state.startDate).format(
          'DD MMMM YYYY'
        )} по ${moment(state.endDate).format('DD MMMM YYYY')}`;
        const fileName = `Отчет по врачам. ${periodString}`;
        const mediaType =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        saveBase64AsFile(`${fileName}.xls`, mediaType + result);
      }
    } catch (e) {
      console.error(e);
    }
  };
  handleDownloadCityDynamic = async () => {
    const state = this.getState().dynamicTable;

    try {
      const dates = getFormattedDates(state);
      const result = await this.exportService.downloadCityDynamics(
        dates.startMonth,
        dates.endMonth,
        dates.startYear,
        dates.endYear
      );

      if (result !== null) {
        const periodString = `С ${moment(state.startDate).format(
          'DD MMMM YYYY'
        )} по ${moment(state.endDate).format('DD MMMM YYYY')}`;
        const fileName = `Отчет по населённым пунктам. ${periodString}`;
        const mediaType =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        saveBase64AsFile(`${fileName}.xls`, mediaType + result);
      }
    } catch (e) {
      console.error(e);
    }
  };
  handleDownloadLpuDynamic = async () => {
    const state = this.getState().dynamicTable;

    try {
      const dates = getFormattedDates(state);
      const result = await this.exportService.downloadLpuDynamics(
        dates.startMonth,
        dates.endMonth,
        dates.startYear,
        dates.endYear
      );

      if (result !== null) {
        const periodString = `C ${moment(state.startDate).format(
          'DD MMMM YYYY'
        )} по ${moment(state.endDate).format('DD MMMM YYYY')}`;
        const fileName = `Отчет по ЛПУ. ${periodString}`;
        const mediaType =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        saveBase64AsFile(`${fileName}.xls`, mediaType + result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  refreshList = () => {
    this.clearCurrentStats();
    this.getStats();
  };
}

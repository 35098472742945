import React, { ReactElement } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { UserRolesTypes } from '../../types/users.types';
import { JsxElement } from 'typescript';

interface IRequiredRoleProps {
  roles: UserRolesTypes[];
  children: any | any[];
}

const RequiredRole = (props: IRequiredRoleProps) => {
  const user = useAppSelector((state) => state.user);

  if (!props.roles.includes(user.role as UserRolesTypes)) return null;
  else return props.children;
};

export default RequiredRole;

import React from 'react';
import { ReactComponent as LPUIcon } from '../../assets/vector/facility-filter.svg';
import { ReactComponent as SpecialtyIcon } from '../../assets/vector/speciality-filter.svg';
import { capitalize } from '@mui/material';
import { classNamesParser } from '../../helpers/classNamesParser';

interface ISubinfoDoctorSpanProps {
  variant: 'specialty' | 'lpu';
  value?: string;
  classNames?: string[];
}
const SubinfoDoctorSpan = (props: ISubinfoDoctorSpanProps) => {
  const image =
    props.variant === 'lpu' ? (
      <LPUIcon className="subinfo-doctor-span__icon" />
    ) : (
      <SpecialtyIcon className="subinfo-doctor-span__icon" />
    );

  return !!props.value ? (
    <span className={classNamesParser('subinfo-doctor-span', props.classNames)}>
      {image}
      {capitalize(props.value)}
    </span>
  ) : null;
};

export default SubinfoDoctorSpan;

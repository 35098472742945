import { AppDispatch, RootState, store } from '../../app.store';
import { ChangelogService } from '../../services/changelog.service';
import {
  setItems,
  setLoading,
  setOffset,
  setPage,
  setSearchValue,
  setTotal,
} from './store/changelog-list.slice';

export class ChangelogListController {
  dispatch: AppDispatch;
  getState: () => RootState;
  changelogService: ChangelogService = new ChangelogService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  getDoctorsChanges = async () => {
    const store = this.getState().changelogList;
    this.dispatch(setLoading(true));
    const data = await this.changelogService.getDoctorsChangelog(
      store.limit,
      store.offset,
      !!store.searchValue ? store.searchValue : null
    );
    if (data) {
      this.dispatch(setTotal(data.total));
      this.dispatch(setItems(data.data));
    }
    this.dispatch(setLoading(false));
  };

  onPageChange = async (_: any, page: number) => {
    const state = this.getState().changelogList;
    this.dispatch(setPage(page));
    this.dispatch(setOffset((page - 1) * state.limit));
    this.getDoctorsChanges();
  };

  onSearchByFullname = async (searchValue: string) => {
    this.dispatch(setSearchValue(searchValue));
    this.onPageChange(null, 1);
  };
}

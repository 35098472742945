import React from 'react';
import { Modal } from '@mui/material';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IAppModalProps {
  classNames?: string[];
  open?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClose?: () => void;
  hideBackdrop?: boolean;
}

const AppModal = (props: IAppModalProps) => {
  return (
    <Modal
      onClose={props.onClose}
      open={!!props.open}
      className={classNamesParser('app-modal', props.classNames)}
      hideBackdrop={props.hideBackdrop}
    >
      <div className="app-modal__container">{props.children}</div>
    </Modal>
  );
};

export default AppModal;

import { Skeleton } from '@mui/material';
import React from 'react';

const MeetupTaskSkeleton = () => {
  return (
    <div className="meetup-task-skeleton">
      <Skeleton className="meetup-task-skeleton__title" />
      <Skeleton className="meetup-task-skeleton__date" />
    </div>
  );
};

export default MeetupTaskSkeleton;

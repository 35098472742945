import {
  Attributes,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

export const withProps = (
  children: ReactNode[],
  props: (Partial<any> & Attributes) | undefined
) => {
  return children.map((child, index) => [
    isValidElement(child)
      ? cloneElement(child as ReactElement, { ...props, key: index })
      : child,
  ]);
};

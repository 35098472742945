import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MEDIA } from '../constants/app.constants';

export interface IUseAdaptivePaginationLimit {
  itemLgHeight: number;
  itemSmHeight: number;
  lgOffset: number;
  smOffset: number;
}

const PAGINATION_HEIGHT = 32;

const useAdaptivePaginationLimit = ({
  itemLgHeight,
  itemSmHeight,
  lgOffset,
  smOffset,
}: IUseAdaptivePaginationLimit) => {
  const matchSm = useMediaQuery(`(max-width:${MEDIA.SM}px)`);
  const calculateLimit = () => {
    const offset = matchSm ? smOffset : lgOffset;
    const itemHeight = matchSm ? itemSmHeight : itemLgHeight;
    const freeWindowSpace = window.innerHeight - offset - PAGINATION_HEIGHT;
    return Math.floor(freeWindowSpace / itemHeight);
  };
  const defaultLimit = calculateLimit();
  const [limit, setLimit] = useState(defaultLimit);

  useEffect(() => {
    setLimit(calculateLimit);
  }, [matchSm]);

  return limit;
};

export default useAdaptivePaginationLimit;

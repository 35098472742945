import React, { CSSProperties, ChangeEvent } from 'react';
import CheckboxMUI from '@mui/material/Checkbox';
import { classNamesParser } from '../../../../helpers/classNamesParser';
// import UncheckedIcon from '@mui/icons-material/SquareRounded';
import CheckedDisabledIcon from '@mui/icons-material/CheckBoxOutlined';
import DoneIcon from '@mui/icons-material/Done';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import MeetupIcon from '../../../../assets/vector/handshake.svg';
import EnvelopeIcon from '../../../../assets/vector/envelope-success.svg';

interface ICheckBoxProps {
  classNames?: string[];
  type?: TaskVariantTypes;
  completed: boolean;
}

const TaskCompleteIndicator = (props: ICheckBoxProps) => {
  const icon =
    props.type && (props.type === 'meetup' ? MeetupIcon : EnvelopeIcon);

  const indicatorStyles: CSSProperties = {
    backgroundColor: props.completed ? '#2F875D' : 'rgba(38, 50, 56, 0.10)',
  };
  return (
    <div
      className={classNamesParser('task-complete-indicator', props.classNames)}
    >
      <div
        className="task-complete-indicator__img-wrapper"
        style={indicatorStyles}
      >
        {icon && <img src={icon} className="task-complete-indicator__icon" />}
      </div>
    </div>
  );
};

export default TaskCompleteIndicator;

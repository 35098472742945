import moment from 'moment/moment';

export const getFormattedDates = (state: any) => {
  const startMonth = moment(state.startDate)
    .locale('en')
    .format('MMMM')
    .toLowerCase();
  const endMonth = moment(state.endDate)
    .locale('en')
    .format('MMMM')
    .toLowerCase();
  const startYear = Number(moment(state.startDate).format('YYYY'));
  const endYear = Number(moment(state.endDate).format('YYYY'));

  return { startMonth, endMonth, startYear, endYear };
};

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../app.store';
import {
  setFullName,
  setPhoneNumber,
  setEmail,
  setLogin,
  setPassword,
  setRole,
  setError,
  clearState,
} from '../store/add-user-form.slice';

export class AddUserFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  //   addUserFormService: AddUserFormService = new AddUserFormService();
  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }
  handleFullNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setFullName(e.target.value));
  };
  handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPhoneNumber(e.target.value));
  };
  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setEmail(e.target.value));
  };
  handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setLogin(e.target.value));
  };
  handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPassword(e.target.value));
  };
  handleIsAdminChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setRole('admin'));
  };
  handleIsManagerChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setRole('manager'));
  };
  handleIsMedicalAgentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setRole('medical_agent'));
  };
  hasEmptyFields = () => {
    const addUserForm = this.getState().addUserForm;
    const fields = {
      fullName: addUserForm.fullName,
      login: addUserForm.login,
      password: addUserForm.password,
    };
    let fieldKeys: string[] = [];

    for (const [key, value] of Object.entries(fields)) {
      if (!value) {
        fieldKeys.push(key);
        this.dispatch(
          setError({
            type: key,
            message: 'Обязательное поле',
          })
        );
      } else {
        this.dispatch(
          setError({
            type: key,
            message: '',
          })
        );
      }
    }

    return !!fieldKeys.length;
  };

  hasErrors = () => {
    const errors = this.getState().editUserForm.errors;
    let result = false;

    for (const [key, value] of Object.entries(errors)) {
      if (key === 'addUser') continue;
      if (value) {
        result = true;
      }
    }

    return result;
  };

  clearState = () => {
    this.dispatch(clearState());
  };

  onCreateUser = (result: number | null) => {
    if (!result) {
      this.dispatch(
        setError({
          type: 'addUser',
          message: 'Не удалось создать пользователя',
        })
      );
    }
  };
}

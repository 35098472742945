import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';

interface ILogoProps {
  classNames?: string[];
}

const Logo = (props: ILogoProps) => {
  return (
    <div className={classNamesParser('logo', props.classNames)}>
      <h3 className="logo__title">ОВУМ</h3>
      <span className="logo__description">Медицинские представители</span>
    </div>
  );
};

export default Logo;

import React from 'react';
import { ReactComponent as BirthdayIcon } from '../../../../assets/vector/birthday.svg';
import moment from 'moment';

interface IBirthdayLabelProps {
  date?: string | null;
}

const BirthdayLabel = (props: IBirthdayLabelProps) => {
  return (
    <div className="birthday-label">
      <BirthdayIcon />

      {props.date ? moment(props.date).format('D MMMM') : 'не указана'}
    </div>
  );
};

export default BirthdayLabel;

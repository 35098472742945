import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferenceTypes } from '../../../modules/ReferenceTable';
import { IApiSpecialityItem } from '../../../interfaces/api.catalogs.interfaces';
import { UploadHistoryItem } from '../interfaces.index';
import { HISTORY_LIST_LIMIT } from '../constants';

interface IUploadHistoryState {
  history: UploadHistoryItem[];
  isLoading: boolean;
  downloadingID: number | null;
  total: number;
  offset: number;
  limit: number;
  page: number;
}

const initialState: IUploadHistoryState = {
  history: [],
  isLoading: false,
  downloadingID: null,
  total: 0,
  limit: HISTORY_LIST_LIMIT,
  offset: 0,
  page: 1,
};

export const uploadHistroySlice = createSlice({
  name: 'upload-history',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setHistory: (state, action: PayloadAction<UploadHistoryItem[]>) => {
      state.history = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setDownloadingID: (state, action: PayloadAction<number | null>) => {
      state.downloadingID = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setHistory,
  setTotal,
  setOffset,
  setPage,
  setLimit,
  setDownloadingID,
} = uploadHistroySlice.actions;
export const uploadHistoryReducer = uploadHistroySlice.reducer;

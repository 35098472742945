import { ViewController } from '../../interfaces/general.interfaces';
import { FeedbackService } from '../../services/feedback.service';
import { FeedbackStatusTypes } from '../../types/feedbacks.types';
import {
  pushFeedbacks,
  setOffset,
  setHaveMore,
  setTabValue,
  setFeedbackStatus,
  setLoading,
  clearFeedbacks,
} from './store/feedback-list.slice';
import { FeedbackListTabsTypes } from './types';

export class FeedbackListController extends ViewController {
  feedbackService: FeedbackService = new FeedbackService();

  onTabChange = (_: any, value: FeedbackListTabsTypes) => {
    this.dispatch(setTabValue(value));
    this.refreshList();
  };

  getFeedbackList = async () => {
    const state = this.getState().feedbackList;
    if (!state.haveMore || state.isLoading) return;
    this.dispatch(setLoading(true));
    const response = await this.feedbackService.getFeedbacks(
      state.limit,
      state.offset,
      state.tabValue
    );

    if (response !== null) {
      this.dispatch(pushFeedbacks(response.data));
      this.dispatch(setOffset(state.offset + state.limit));
      const haveMore =
        response.total > state.feedbacks.length + response.data.length;
      this.dispatch(setHaveMore(haveMore));
    } else {
      this.dispatch(setHaveMore(false));
    }
    this.dispatch(setLoading(false));
  };

  updateFeedbackStatus = (data: {
    id: number;
    status: FeedbackStatusTypes;
  }) => {
    this.dispatch(setFeedbackStatus({ id: data.id, status: data.status }));
  };

  clearList = () => {
    this.dispatch(setOffset(0));
    this.dispatch(setLoading(false));
    this.dispatch(setHaveMore(true));
    this.dispatch(clearFeedbacks());
  };

  refreshList = () => {
    this.clearList();
    this.getFeedbackList();
  };
}

import { IHistoryReportItem } from '../../interfaces';
import { TableCell } from '@mui/material';
import { TableHeadEnum } from '../../enums';
import { getTableCellByKey, getTableCellWidthByKey } from '../../helpers';

interface IHistoryReportRowProps {
  data: IHistoryReportItem;
  index: number;
}

const HistoryReportRow = (props: IHistoryReportRowProps) => {
  return (
    <>
      {Object.keys(TableHeadEnum).map((key) => {
        const keyValue = key.toLowerCase() as TableHeadEnum;
        const CellComponent = getTableCellByKey(keyValue);
        return (
          <TableCell
            width={getTableCellWidthByKey(keyValue)}
            className="history-report-row"
          >
            <CellComponent
              value={props.data[keyValue]}
              status={props.data.meetupStatus}
              className={`history-report-row__${keyValue}`}
            />
          </TableCell>
        );
      })}
    </>
  );
};

export default HistoryReportRow;

import { capitalize } from './capitalize';

export const objectKeysSnakeToCamel = (object: any) => {
  return Object.entries(object).reduce(
    (result, [key, value]) => ({
      ...result,
      [snakeToCamel(key)]: value,
    }),
    {}
  ) as any;
};
function snakeToCamel(string: string) {
  const [start, ...rest] = string.split('_');
  return start + rest.map(capitalize).join('');
}

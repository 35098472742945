import moment from 'moment';
import { AppDispatch, RootState, store } from '../../app.store';
import {
  setDynamicFrom,
  setDynamicTo,
  setEndDate,
  setIsShowDynamicValues,
  setStartDate,
} from './store/reference-dynamic.store';
import { ChangeEvent } from 'react';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';

export class ReferenceDynamicController {
  dispatch: AppDispatch;
  getState: () => RootState;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  handleDynamicSwitchClick = () => {
    const isShowDynamicValues =
      this.getState().referenceDynamic.isShowDynamicValues;
    this.dispatch(setIsShowDynamicValues(!isShowDynamicValues));
  };

  handleStartDateChange = (date: string) => {
    const state = this.getState().referenceDynamic;
    if (moment(date).isAfter(moment(state.endDate))) {
      this.dispatch(setEndDate(date));
    }
    this.dispatch(setStartDate(date));
  };

  handleEndDateChange = (date: string) => {
    const state = this.getState().referenceDynamic;
    if (moment(state.startDate).isAfter(moment(date))) {
      this.dispatch(setStartDate(date));
    }
    this.dispatch(setEndDate(date));
  };

  handleDynamicFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regEx = new RegExp('^0$|^-?[1-9]\\d*(\\.\\d+)?$');

    if (value.match(regEx) || !value || value === '-') {
      this.dispatch(setDynamicFrom(value));
    }
  };

  handleDynamicToChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regEx = new RegExp('^0$|^-?[1-9]\\d*(\\.\\d+)?$');

    if (value.match(regEx) || !value || value === '-') {
      this.dispatch(setDynamicTo(value));
    }
  };

  handleClearFilters = () => {
    this.dispatch(
      setStartDate(moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'))
    );
    this.dispatch(setEndDate(moment(new Date()).format('YYYY-MM-DD')));
    this.dispatch(setIsShowDynamicValues(false));
    this.dispatch(setDynamicFrom('0'));
    this.dispatch(setDynamicTo('0'));

    EventEmitter.emit(EMITTER_EVENTS.CLEAR_DOCTORS_DYNAMIC);
  };

  handleApplyClick = (callback?: () => void) => {
    const store = this.getState().referenceDynamic;

    const monthStart = moment(store.startDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const monthEnd = moment(store.endDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const yearStart = Number(moment(store.startDate).format('YYYY'));
    const yearEnd = Number(moment(store.endDate).format('YYYY'));
    const dynamicFrom = Number(store.dynamicFrom);
    const dynamicTo = Number(store.dynamicTo);

    EventEmitter.emit(EMITTER_EVENTS.UPDATE_DOCTORS_WITH_DYNAMIC, {
      monthStart,
      monthEnd,
      yearStart,
      yearEnd,
      dynamicFrom,
      dynamicTo,
    });

    callback && callback();
  };
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SendEnvelopeFormState {
  isSuccess: boolean;
  comment: string;
  doctorID?: number;
  taskID?: number;
  doctorName: string;
  meetupID: number | null;
}

const initialState: SendEnvelopeFormState = {
  isSuccess: true,
  comment: '',
  doctorName: '',
  meetupID: null,
};

export const sendEnvelopeFormSlice = createSlice({
  name: 'send-envelope-form',
  initialState,
  reducers: {
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
    setDoctorID: (state, action: PayloadAction<number>) => {
      state.doctorID = action.payload;
    },
    setTaskID: (state, action: PayloadAction<number>) => {
      state.taskID = action.payload;
    },
    setDoctorName: (state, action: PayloadAction<string>) => {
      state.doctorName = action.payload;
    },
    setMeetupID: (state, action: PayloadAction<number | null>) => {
      state.meetupID = action.payload;
    },
  },
});

export const {
  setComment,
  setSuccess,
  setMeetupID,
  setDoctorID,
  setTaskID,
  setDoctorName,
} = sendEnvelopeFormSlice.actions;

export const sendEnvelopeFormReducer = sendEnvelopeFormSlice.reducer;

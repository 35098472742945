import moment from 'moment';
import { AppDispatch, RootState, store } from '../../app.store';
import { capitalize } from '../../helpers/capitalize';
import { ReportsService } from '../../services/reports.service';
import {
  setData,
  setEndDate,
  setHeadingDates,
  setStartDate,
} from './store/meetups-report.slice';
import { IReportMonthStatistic } from './interfaces';

export class MeetupsReportController {
  dispatch: AppDispatch;
  getState: () => RootState;
  reportsService: ReportsService = new ReportsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  onStartDateChange = (date: string) => {
    const state = this.getState().meetupsReport;
    if (moment(date).isAfter(moment(state.endDate)))
      this.dispatch(setEndDate(date));
    this.dispatch(setStartDate(date));
    this.getStatistic();
  };

  onEndDateChange = (date: string) => {
    const state = this.getState().meetupsReport;
    if (moment(state.startDate).isAfter(moment(date)))
      this.dispatch(setStartDate(date));
    this.dispatch(setEndDate(date));
    this.getStatistic();
  };

  getStatistic = async () => {
    const state = this.getState().meetupsReport;
    if (!state.startDate || !state.endDate) return;
    const startMonth = moment(state.startDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const endMonth = moment(state.endDate)
      .locale('en')
      .format('MMMM')
      .toLowerCase();
    const startYear = Number(moment(state.startDate).format('YYYY'));
    const endYear = Number(moment(state.endDate).format('YYYY'));

    const data = await this.reportsService.getMeetupsReport(
      'done',
      startMonth,
      endMonth,
      startYear,
      endYear
    );
    if (data !== null) {
      this.dispatch(setData(data.records));
      const units = data.records?.[0]?.units;
      this.dispatch(setHeadingDates(this.convertUnitsToDates(units)));
      moment.locale('ru');
    }
  };

  convertUnitsToDates = (units: IReportMonthStatistic[]) => {
    return (
      units?.map((date) => {
        moment.locale('en-US');
        const momentDate = moment(
          `${date.scale.year} ${capitalize(date.scale.month)} `,
          'YYYY MMMM'
        ).locale('ru');
        return capitalize(momentDate.format('MMMM YYYY'));
      }) || []
    );
  };
}

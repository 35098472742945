import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Input from '../../../../ui/Input';
import { useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';

const FORM_HEIGHT_OFFSET = 228;

interface IFailureCommentaryProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FailureCommentary = (props: IFailureCommentaryProps) => {
  const [formHeight, setFormHeight] = useState('100%');
  const matchSm = useMediaQuery('(max-width:768px)');
  const inputRef = useRef<HTMLInputElement>(null);
  const resizeForm = async () => {
    if (!matchSm) {
      setFormHeight('100%');
      return;
    }
    setFormHeight(
      `${(visualViewport?.height || 0) - FORM_HEIGHT_OFFSET}px` || '100%'
    );
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef?.current]);

  useEffect(() => {
    visualViewport?.addEventListener('resize', resizeForm);
    return () => {
      visualViewport?.removeEventListener('resize', resizeForm);
    };
  }, []);

  return (
    <div className="failure-commentary" style={{ height: formHeight }}>
      <h5 className="failure-commentary__title">Добавьте комментарий:</h5>
      <TextField
        inputRef={inputRef}
        className="failure-commentary__input"
        multiline
        value={props.value}
        onChange={props.onChange}
        autoFocus
        onFocus={resizeForm}
        placeholder="Добавьте комментарий"
        rows={1}
      />
    </div>
  );
};

export default FailureCommentary;

import React, { useEffect, useRef, useState } from 'react';
import { IDoctorCandidate } from '../../index.interfaces';
import { Button, Menu, MenuItem } from '@mui/material';
import ErrorAlert from '../../../../ui/ErrorAlert';
import DoctorSearchButton from '../DoctorSearchButton';

const MATCH_MENU_MAX_HEIGHT = '350px';

interface ISuggestionDoctorProps {
  suggestions: IDoctorCandidate[];
  findedDoctors: IDoctorCandidate[];
  fullName?: string | null;
  onChooseSuggestion: (id: number, doctorFullname: string) => void;
  clearSearchedDoctors: () => void;
}

const SuggestionDoctor = (props: ISuggestionDoctorProps) => {
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const menuWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleSearchOpen = () => {
    setIsSearchOpened(true);
  };
  const handleCloseSearch = () => {
    setIsSearchOpened(false);
    props.clearSearchedDoctors();
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (props.suggestions.length === 0) {
      setIsSearchOpened(true);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    props.clearSearchedDoctors();
  };

  const handleChooseSuggestion = (id: number, doctorFullname: string) => {
    props.onChooseSuggestion(id, doctorFullname);
    handleCloseSearch();
    handleCloseMenu();
  };

  useEffect(() => {
    menuWrapperRef.current?.removeAttribute('tabindex');
  }, [menuWrapperRef.current]);

  return (
    <div className="suggestion-doctor">
      <Button className="suggestion-doctor__btn" onClick={handleOpenMenu}>
        {!!props.fullName ? (
          props.fullName
        ) : props.suggestions.length > 0 ? (
          <ErrorAlert
            error={`Найдено ${props.suggestions.length} возможных совпадений`}
          />
        ) : (
          <ErrorAlert error="Соответствие не найдено" />
        )}
      </Button>
      <Menu
        open={isMenuOpened}
        onClose={handleCloseMenu}
        style={{ maxHeight: MATCH_MENU_MAX_HEIGHT }}
        anchorEl={anchorEl}
      >
        <div className="suggestion-doctor__menu-wrapper" ref={menuWrapperRef}>
          <div className="suggestion-doctor__menu-header">
            {!isSearchOpened && (
              <p className="suggestion-doctor__menu-title">
                Выберите соответствие
              </p>
            )}
            <DoctorSearchButton
              onClose={handleCloseSearch}
              open={isSearchOpened}
              onOpen={handleSearchOpen}
            />
          </div>
          {isSearchOpened &&
            props.findedDoctors.map((doctor) => {
              const doctorFullname = `${doctor?.lastName || ''} ${
                doctor?.firstName || ''
              }
              ${doctor?.middleName || ''}`;
              const onChooseSuggestion = () =>
                handleChooseSuggestion(doctor.id, doctorFullname);
              return (
                <MenuItem key={doctor.id} onClick={onChooseSuggestion}>
                  <div>
                    <p className="upload-row__doctor-fullname">
                      {doctorFullname}
                    </p>
                    <p className="upload-row__facility">
                      {doctor.medInstitutions?.[0]?.name}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
          {!isSearchOpened &&
            props.suggestions.map((suggestion) => {
              const doctorFullname = `${suggestion?.lastName || ''} ${
                suggestion?.firstName || ''
              }
              ${suggestion?.middleName || ''}`;
              const onChooseSuggestion = () =>
                handleChooseSuggestion(suggestion.id, doctorFullname);
              return (
                <MenuItem key={suggestion.id} onClick={onChooseSuggestion}>
                  <div>
                    <p className="upload-row__doctor-fullname">
                      {doctorFullname}
                    </p>
                    <p className="upload-row__facility">
                      {suggestion.medInstitutions?.[0]?.name}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
        </div>
      </Menu>
    </div>
  );
};

export default SuggestionDoctor;

export enum DoctorChangedFieldEnum {
  LASTNAME = 'lastName',
  FIRSTNAME = 'firstName',
  MIDDLENAME = 'middleName',
  BIRTHDAY = 'birthday',
  HAS_INSTALLED_APP = 'hasInstalledApp',
  NOTE = 'note',
  SPECIALTY = 'specialty',
  LPU = 'lpu',
  CONTACT_PHONE = 'contactPhone',
  ARCHIVED = 'archived',
}

export enum DoctorChangeActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

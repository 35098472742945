import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';
import { ReactComponent as ErrorIcon } from '../../assets/vector/input-error.svg';

interface IErrorAlertProps {
  classNames?: string[];
  error?: string;
}

const ErrorAlert = (props: IErrorAlertProps) => {
  return !!props.error ? (
    <div className={classNamesParser('error-alert', props.classNames)}>
      <ErrorIcon className="error-alert__icon" />{' '}
      <span className="error-alert__text">{props.error}</span>
    </div>
  ) : null;
};

export default ErrorAlert;

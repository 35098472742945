import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IColorCounterProps {
  classNames?: string[];
  value?: number | string;
  backgroundColor?: string;
}

const ColorCounter = (props: IColorCounterProps) => {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
      }}
      className={classNamesParser('color-counter', props.classNames)}
    >
      {props.value}
    </div>
  );
};

export default ColorCounter;

import { FEEDBACK } from '../api/api';
import axios from '../axios/axios';
import { FeedbackStatusTypes } from '../types/feedbacks.types';

export class FeedbackService {
  getFeedbacks = async (
    limit?: number,
    offset?: number,
    status?: FeedbackStatusTypes
  ) => {
    let result = null;
    try {
      const response = await axios.get(FEEDBACK.GET_FEEDBACK_LIST, {
        params: {
          limit,
          offset,
          status,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
  getFeedbackById = async (id: number) => {
    let result = null;
    try {
      const response = await axios.get(`${FEEDBACK.GET_FEEDBACK_BY_ID}/${id}`);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  getCommentsByFeedback = async (
    id: number,
    limit?: number,
    offset?: number
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${FEEDBACK.GET_COMMENTS_BY_FEEDBACK}/${id}/comments`,
        {
          params: {
            limit,
            offset,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
  setLiableForFeedback = async (feedbackID: number, userID: number) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${FEEDBACK.SET_FEEDBACK_LIABLE}/${feedbackID}/liable`,
        {},
        {
          params: {
            userID,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  updateFeedback = async (id: number, status: FeedbackStatusTypes) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${FEEDBACK.UPDATE_FEEDBACK_STATUS}/${id}`,
        { status }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  addComment = async (id: number, comment: string) => {
    let result = null;
    try {
      const response = await axios.post(
        `${FEEDBACK.ADD_COMMENT}/${id}/comments`,
        { comment }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
}

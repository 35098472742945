import { SelectChangeEvent } from '@mui/material';
import { AppDispatch, RootState, store } from '../../app.store';

import { FeedbackService } from '../../services/feedback.service';
import { UsersService } from '../../services/users.service';
import {
  setComment,
  setComments,
  setCreatedAt,
  setCreatedBy,
  setCurrentFeedbackId,
  setLiable,
  setLiables,
  setPage,
  setStatus,
  setTotal,
  setType,
  setUpdatedBy,
  setOffset,
  setDoctorName,
  setIsAddingComment,
  setCommentInput,
  setCommentLoading,
  setPhotos,
} from './store/feedback-menu.slice';

import { ChangeEvent } from 'react';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { FeedbackStatusTypes } from '../../types/feedbacks.types';
import { ResponseImage } from '../../types/images.types';
import { getPhotoFullURL } from '../../helpers/getPhotoFullURL';

export class FeedbackMenuController {
  dispatch: AppDispatch;
  getState: () => RootState;
  feedbackService: FeedbackService = new FeedbackService();
  usersService: UsersService = new UsersService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  setFeedbackId = (id: number) => {
    this.dispatch(setCurrentFeedbackId(id));
  };

  getLiables = async () => {
    const data = await this.usersService.getUsers(null, null, 'active');
    if (data) {
      this.dispatch(setLiables(data.data));
    }
  };

  getFeedbackDetails = async () => {
    const state = this.getState().feedbackMenu;
    if (!state.currentFeedbackId) return;
    const data = await this.feedbackService.getFeedbackById(
      state.currentFeedbackId
    );

    if (data) {
      this.dispatch(setComment(data.comment));
      this.dispatch(setCreatedAt(data.createdAt));
      this.dispatch(setCreatedBy(data.createdBy));
      this.dispatch(setLiable(data.liable));
      this.dispatch(setStatus(data.status));
      this.dispatch(setType(data.type));
      this.dispatch(setUpdatedBy(data.updatedBy));
      this.dispatch(
        setPhotos(
          data.meetup?.photos.map((photo: ResponseImage) => ({
            id: photo.id,
            filename: getPhotoFullURL(photo.filename),
          })) || []
        )
      );
      this.dispatch(
        setDoctorName(
          `${data.doctor?.lastName || ''} ${data.doctor?.firstName || ''} ${
            data.doctor?.middleName || ''
          }`
        )
      );
    }
  };

  getComments = async () => {
    const state = this.getState().feedbackMenu;
    if (!state.currentFeedbackId) return;
    const data = await this.feedbackService.getCommentsByFeedback(
      state.currentFeedbackId
    );
    if (data) {
      this.dispatch(setTotal(data.total));
      this.dispatch(setComments(data.data));
    }
  };

  onLiableChange = async (e: SelectChangeEvent) => {
    const state = this.getState().feedbackMenu;
    if (!state.currentFeedbackId || !e.target.value) return;
    await this.feedbackService.setLiableForFeedback(
      state.currentFeedbackId,
      Number(e.target.value)
    );
    this.getFeedbackDetails();
  };

  onPageChange = async (_: any, page: number) => {
    const state = this.getState().feedbackMenu;
    this.dispatch(setPage(page));
    this.dispatch(setOffset((page - 1) * state.limit));
    this.getComments();
  };

  onFeedbackStatusChange = async (e: SelectChangeEvent) => {
    const state = this.getState().feedbackMenu;
    if (!state.currentFeedbackId) return;
    const status = e.target.value as FeedbackStatusTypes;
    await this.feedbackService.updateFeedback(state.currentFeedbackId, status);
    this.getFeedbackDetails();
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_FEEDBACK, {
      id: state.currentFeedbackId,
      status,
    });
  };

  onStartAddComment = () => {
    this.dispatch(setIsAddingComment(true));
  };

  onCancelAddComment = () => {
    this.dispatch(setIsAddingComment(false));
    this.dispatch(setCommentInput(''));
  };

  onSaveComment = async () => {
    this.dispatch(setCommentLoading(true));
    const state = this.getState().feedbackMenu;
    if (!state.currentFeedbackId) return;
    await this.feedbackService.addComment(
      state.currentFeedbackId,
      state.commentInput
    );
    this.getComments();
    this.onCancelAddComment();
    this.dispatch(setCommentLoading(false));
  };

  onCommentInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setCommentInput(e.target.value));
  };

  clearState = () => {
    this.dispatch(setPhotos([]));
  };
}

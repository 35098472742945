import { IApiLpuItem } from '../../../interfaces/api.catalogs.interfaces';
import { ICustomizedHookItem } from '../../../components/CustomizedHook/customized-hook.interfaces';
import { TaskVariantTypes } from '../../../types/tasks.types';

export const parseEditDoctorLpuIds = (
  lpuList: IApiLpuItem[] | ICustomizedHookItem[] | any[]
): number[] | [] => {
  return lpuList.filter((item: any) => !!item.id).map((item: any) => item.id);
};

export const parseEditDoctorPhones = (phones: string[]) => {
  return phones.filter((phone) => !!phone.length);
};

export const birthDateReverse = (
  birthDate: string,
  startSeparator: string = '',
  endSeparator: string = ''
) => {
  return birthDate.split(startSeparator).reverse().join(endSeparator);
};

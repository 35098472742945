import { MouseEvent } from 'react';
import { MeetupFeedbackTypes } from '../../../../types/tasks.types';
import { FeedbackStatusTypes } from '../../../../types/feedbacks.types';
import { IFeedbackAuthor, IFeedbackDoctor } from '../../interfaces';
import { getLocaleFeedbackType } from '../../helpers/getLocaleFeedbackType';
import moment from 'moment';
import { ReactComponent as CheckedIcon } from '../../../../assets/vector/feedback-checked.svg';
import { ReactComponent as UncheckedIcon } from '../../../../assets/vector/feedback-unchecked.svg';

import { Checkbox } from '@mui/material';
import RoundedLabel from '../../../../ui/RoundedLabel';
import { ITaskExecutor } from '../../../../interfaces/tasks.interfaces';
import SubinfoDoctorSpan from '../../../../ui/SubinfoDoctorSpan';

interface IFeedbackItemProps {
  onClick?: (id: number) => void;
  type: MeetupFeedbackTypes;
  status: FeedbackStatusTypes;
  isSelecting?: boolean;
  onSelect: (id: number) => void;
  isSelected?: boolean;
  id: number;
  author: IFeedbackAuthor;
  comment: string;
  createdAt: string;
  doctor: IFeedbackDoctor;
  liable: ITaskExecutor;
  isCurrentFeedback?: boolean;
}

const FeedbackItem = (props: IFeedbackItemProps) => {
  const type = getLocaleFeedbackType(props.type);
  const handleFeedbackLick = () => {
    props.onClick && props.onClick(props.id);
  };

  const onSelectItem = (e: MouseEvent<HTMLButtonElement, Event>) => {
    e.preventDefault();
    e.stopPropagation();

    props.onSelect && props.doctor?.id && props.onSelect(props.doctor.id);
  };
  const doctorFullName = `${props.doctor?.lastName || ''} ${
    props.doctor?.firstName || ''
  } ${props.doctor?.middleName || ''}`;
  return (
    <div
      className={`feedback-item ${
        props.isCurrentFeedback ? 'feedback-item_current' : ''
      }`}
      onClick={handleFeedbackLick}
    >
      {props.isSelecting ? (
        <div className="feedback-item__checkbox-wrapper">
          <Checkbox
            disableRipple
            className="feedback-item__checkbox"
            checkedIcon={
              <CheckedIcon className="feedback-item__checked-icon" />
            }
            icon={<UncheckedIcon className="feedback-item__unchecked-icon" />}
            onClick={onSelectItem}
            checked={props.isSelected}
          />
        </div>
      ) : (
        <div
          className={`feedback-item__indicator ${
            props.status === 'done' ? '' : 'done'
          }`}
        />
      )}

      <div className="feedback-item__info">
        <div className="feedback-item__doctor">
          <h4 className="feedback-item__doctor-name">{doctorFullName}</h4>
          <div className="feedback-item__subinfo">
            <div className="feedback-item__doctor-info">
              {props.doctor?.medInstitutions?.map((lpu) => (
                <SubinfoDoctorSpan variant="lpu" value={lpu?.name} />
              ))}
              <SubinfoDoctorSpan
                variant="specialty"
                value={props.doctor?.specialty?.name}
              />
            </div>
            <div className="feedback-item__author-info">
              <RoundedLabel
                classNames={['feedback-item__rounded-label']}
                fullname={props?.author?.fullName || ''}
              />
              <span className="feedback-item__author-fullname">
                {props.author?.fullName},
              </span>{' '}
              {moment(props.createdAt).format('D MMMM YYYY')}
            </div>
          </div>
        </div>
        <div className="feedback-item__content">
          {/* <NoteIcon className="feedback-item__note-icon" /> */}
          <span className="feedback-item__type">{type}</span>
          <p className="feedback-item__feedback">
            {props.comment || 'Комментарий не указан'}
          </p>
          {}
        </div>
      </div>
    </div>
  );
};

export default FeedbackItem;

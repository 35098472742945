import React, { useEffect } from 'react';
import RoundButton from '../../../../ui/RoundButton';
import { Close } from '@mui/icons-material';
import Input from '../../../../ui/Input';
import AppSelect from '../../../../ui/AppSelect';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import { feedbackTypeOptions } from '../../history-filters.constants';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { HistoryFiltersController } from '../../history-filters.controller';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';

interface IHistoryFiltersProps {
  onCloseModal?: () => void;
}

export const HistoryFilters = (props: IHistoryFiltersProps) => {
  const historyFilters = useAppSelector((state) => state.historyFilters);
  const dispatch = useAppDispatch();
  const controller = new HistoryFiltersController(dispatch);

  return (
    <div className="history-filters">
      <div className="history-filters__heading">
        <h3 className="history-filters__title">Фильтр</h3>
        <RoundButton
          onClick={props.onCloseModal}
          classNames={['history-filters__close-modal']}
          content={<Close />}
        />
      </div>
      <div className="history-filters__filters-container">
        <Input
          placeholder={'Введите ФИО'}
          label="ФИО врача"
          classNames={['history-filters__form-input']}
          value={historyFilters.searchedName}
          onInput={controller.onSearchedNameChange}
          showCloseIcon
          onIconClick={controller.onSearchedNameClear}
          //   error={referenceFilters.errors.lastMeetupDate}
          fullWidth
        />

        <Input
          label={'Комментарий'}
          placeholder="Введите комментарий"
          classNames={['history-filters__form-input']}
          value={historyFilters.searchedComment}
          onInput={controller.onSearchedCommentChange}
          showCloseIcon
          onIconClick={controller.onSearchedCommentClear}
          //   value={referenceFilters.lastMeetupDate || ''}
          //   onInput={controller.handleLastMeetupDate}
          //   onFocus={onLastMeetupFocus}
          //   onBlur={onLastMeetupBlur}
          //   showCloseIcon={!referenceFilters.lastMeetupDate}
          //   onIconClick={controller.handleClearMeetupDate}
          //   error={referenceFilters.errors.lastMeetupDate}
          fullWidth
        />

        <AppSelect
          classNames={['history-filters__form-input']}
          placeholder="Тип обратной связи"
          label="Тип обратной связи"
          options={feedbackTypeOptions}
          value={historyFilters.feedbackType || ''}
          handleChange={controller.onFeedbackTypeChange}
          renderText
        />
      </div>

      <div className="history-filters__buttons">
        <Button
          className="history-filters__clear"
          onClick={controller.onClearFilters}
        >
          Очистить
        </Button>
        <Button
          className="history-filters__apply"
          onClick={controller.onApplyFilters}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

import React from 'react';
import { Skeleton } from '@mui/material';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import './styles.scss';
const AddDoctorsTasksSkeleton = () => {
  return (
    <div className={classNamesParser('add-doctors-tasks-skeleton')}>
      {Array.from(Array(4).keys()).map((item) => (
        <Skeleton key={item} className="add-doctors-tasks-skeleton__skeleton" />
      ))}
    </div>
  );
};

export default AddDoctorsTasksSkeleton;

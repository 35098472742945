import React, { ChangeEvent, useState } from 'react';
import { Switch as MUISwitch } from '@mui/material';

interface ISwitchProps {
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Switch = (props: ISwitchProps) => {
  return (
    <MUISwitch
      checked={props.checked}
      onChange={props.onChange}
      disableRipple
      disableTouchRipple
      className="switch"
      classes={{
        track: `switch__track ${props.checked ? 'checked' : ''}`,
        thumb: `switch__thumb ${props.checked ? 'checked' : ''}`,
        switchBase: 'switch__switch-base',
        root: 'switch__root',
      }}
    />
  );
};

export default Switch;

import React, { useEffect, useRef, useState } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import Input from '../../../../ui/Input';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  debounce,
  Menu,
  MenuItem,
  Pagination,
  Typography,
  useMediaQuery,
} from '@mui/material';
import RoundButton from '../../../../ui/RoundButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Form } from 'react-router-dom';
import Notice from '../../../../components/Notice';
import CheckBox from '../../../../ui/CheckBox';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { EditDoctorFormController } from '../../edit-doctor-form.controller';
import {
  clearErrors,
  setIsEdit,
  setLoading,
  setReceivedDoctorId,
} from '../../store/edit-doctor-form.slice';
import EditDoctorFormSkeleton from '../Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import RoundMenuButton from '../../../../ui/RoundMenuButton';
import { IRoundMenuButtonItem } from '../../../../ui/RoundMenuButton/index.interfaces';
import AppAutocomplete from '../../../../components/AppAutocomplete';
import { store } from '../../../../app.store';
import {
  MAX_LPU_COUNT,
  MAX_MEETUPS_COUNT,
  MAX_PHONES_COUNT,
} from '../../constants';
import AppDatePicker from '../../../../ui/AppDatePicker';
import moment from 'moment';
import { showFormAddIcon } from '../../../AddDoctorForm';
import { MEDIA } from '../../../../constants/app.constants';
import EditableNote from '../../../../components/EditableNote';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MeetupCard from '../MeetupCard';
import { IApiDoctorMeetupsDone } from '../../../../interfaces/api.catalogs.interfaces';
import AppSelect from '../../../../ui/AppSelect';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import MeetupHistoryItem from '../../../../components/MeetupHistoryItem';
import RequiredRole from '../../../../components/RequiredRole';
import AutoCompleteSearchList from '../../../../components/AutoCompleteSearchList';
interface IEditDoctorFormProps {
  classNames?: string[];
  onCloseModal?: () => void;
  editData?: { id: number } | any;
  createMeetup?: () => void;
  onMeetupCardClick?: (taskID: number, type: TaskVariantTypes) => void;
}

export const EditDoctorForm = (props: IEditDoctorFormProps) => {
  const editDoctorForm = useAppSelector((state) => state.editDoctorForm);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const controller = new EditDoctorFormController(dispatch);
  const matchXsm = useMediaQuery(`(max-width:${MEDIA.XSM}px)`);
  const matchFormWidth = useMediaQuery(`(max-width:${500}px)`);
  const formRef = useRef<any>(null);
  const [birthdayFocused, setBirthdayFocused] = useState(false);

  const handleFixMeetup = () => {
    const doctorFullname = `${editDoctorForm.info.lastName} ${editDoctorForm.info.firstName} ${editDoctorForm.info.middleName}`;
    props.createMeetup && props.createMeetup();
  };

  const fetchStartData = async () => {
    dispatch(setLoading(true));
    await controller.fetchDoctorById();
    await controller.fetchSpecialities();
    await controller.fetchDoctorMeetupsDone();
    dispatch(setLoading(false));
  };

  const onBirthdateFocus = () => setBirthdayFocused(true);
  const onBirthdateBlur = () => setBirthdayFocused(false);

  const handleSpecialtyInputDebounce = debounce(
    controller.handleSpecialtyInput,
    500
  );
  const handleLpuInput = debounce(controller.handleLpuInput, 500);

  const hasLpuErrors = () => {
    const errorsArray = store.getState().editDoctorForm.errors.lpu;
    return !!errorsArray.join('').length;
  };

  const isAnyNamesEmpty = () => {
    const doctorInfo = store.getState().editDoctorForm.editableFields;
    return !doctorInfo.firstName || !doctorInfo.lastName;
  };

  useEffect(() => {
    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_DOCTOR_MEETUPS_DONE,
      controller.fetchDoctorMeetupsDone
    );
    dispatch(setIsEdit(false));
    dispatch(clearErrors());
    dispatch(setReceivedDoctorId(props?.editData?.id));
    fetchStartData();

    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_DOCTOR_MEETUPS_DONE);
    };
  }, [props.editData]);

  const formMaxHeight = `calc(100vh - ${editDoctorForm.isEdit ? 320 : 300}px)`;
  const formMaxHeightDesktop = `calc(100vh - ${
    editDoctorForm.isEdit ? 220 : 300
  }px)`;

  const roundButtonItems: IRoundMenuButtonItem[] = [
    {
      id: 2,
      text: editDoctorForm.info?.archived ? 'Активировать' : 'Деактивировать',
      color: editDoctorForm.info?.archived ? '#2574D0' : '#FF4C06',
    },
    {
      id: 3,
      text: 'Удалить',
      color: '#FF4C06',
      roles: ['admin'],
    },
  ];

  const hasMobileAppValues: IAppSelectOption[] = [
    {
      id: 1,
      text: 'Использует',
      value: String(true),
    },
    {
      id: 2,
      text: 'Не использует',
      value: String(false),
    },
  ];

  const handleTasksExpanded = () => {
    if (formRef) {
      setTimeout(() => formRef.current.scrollTo(0, 10000), 20);
    }
  };

  return (
    <div
      ref={formRef}
      className={classNamesParser('edit-doctor-form', props.classNames)}
    >
      <div className="edit-doctor-form__heading">
        <div className="edit-doctor-form__title-container">
          <h3 className="edit-doctor-form__title">Личные данные</h3>
          {editDoctorForm.info.archived && (
            <Chip
              label="Запись не активна"
              className="edit-doctor-form__status"
            />
          )}
        </div>
        {!editDoctorForm.isEdit && (
          <div className="edit-doctor-form__heading-controls">
            <RoundMenuButton
              classNames={['edit-doctor-form__menu']}
              menuItems={roundButtonItems}
              onMenuClick={(value: string) =>
                controller.handleMenuClick(value, props.onCloseModal)
              }
              disableMenuIds={editDoctorForm.info.archived ? [0] : [3]}
            />

            <RoundButton
              content={<EditNoteIcon />}
              backgroundColor="rgb(243 243 243)"
              onClick={controller.onEditClick}
              classNames={['edit-doctor-form__edit']}
            />
            <RoundButton
              classNames={['edit-doctor-form__close-modal']}
              onClick={props.onCloseModal}
              content={<CloseIcon />}
            />
          </div>
        )}
      </div>
      {editDoctorForm.isLoading ? (
        <EditDoctorFormSkeleton />
      ) : (
        <div
          style={{
            marginBottom: 24,
            maxHeight: matchXsm ? formMaxHeight : formMaxHeightDesktop,
          }}
          className="edit-doctor-form__form"
        >
          <EditableNote
            classNames={['edit-doctor-form__notice']}
            note={
              editDoctorForm.info?.note
                ? editDoctorForm.info?.note
                : 'Добавить заметку о враче'
            }
            isEditing={editDoctorForm.isNoteEdit}
            noteInput={editDoctorForm.noteInput || ''}
            onNoteInputChange={controller.handleNoteChange}
            onConfirm={controller.handleNoteAcceptClick}
            onCancelEdit={controller.handleNoteCancelClick}
            onNoteClick={controller.handleNoteClick}
          />
          {editDoctorForm.isEdit || (
            <Input
              label="ФИО"
              placeholder="ФИО"
              disabled={!editDoctorForm.isEdit}
              classNames={['edit-doctor-form__form-input']}
              value={`${String(editDoctorForm?.info?.lastName)} ${String(
                editDoctorForm?.info?.firstName
              )} ${String(editDoctorForm?.info?.middleName)}`}
              fullWidth
            />
          )}
          {editDoctorForm.isEdit && (
            <>
              <Input
                label={editDoctorForm.isEdit ? '' : 'Фамилия'}
                placeholder={editDoctorForm.isEdit ? 'Фамилия' : ''}
                disabled={!editDoctorForm.isEdit}
                classNames={['edit-doctor-form__form-input']}
                value={String(
                  editDoctorForm.isEdit
                    ? editDoctorForm.editableFields.lastName
                    : editDoctorForm?.info?.lastName
                )}
                onInput={controller.handleLastNameChange}
                fullWidth
              />
              <Input
                label={editDoctorForm.isEdit ? '' : 'Имя'}
                placeholder={editDoctorForm.isEdit ? 'Имя' : ''}
                disabled={!editDoctorForm.isEdit}
                classNames={['edit-doctor-form__form-input']}
                value={String(
                  editDoctorForm.isEdit
                    ? editDoctorForm.editableFields.firstName
                    : editDoctorForm?.info?.firstName
                )}
                onInput={controller.handleFirstNameChange}
                fullWidth
              />
              <Input
                label={editDoctorForm.isEdit ? '' : 'Отчество'}
                placeholder={editDoctorForm.isEdit ? 'Отчество' : ''}
                disabled={!editDoctorForm.isEdit}
                classNames={['edit-doctor-form__form-input']}
                value={String(
                  editDoctorForm.isEdit
                    ? editDoctorForm.editableFields.middleName
                    : editDoctorForm?.info?.middleName
                )}
                onInput={controller.handleMiddleNameChange}
                fullWidth
              />
            </>
          )}
          {/*<AppDatePicker*/}
          {/*  classNames={['edit-doctor-form__form-input']}*/}
          {/*  disabled={!editDoctorForm.isEdit}*/}
          {/*  placeholder={editDoctorForm.isEdit ? 'Дата рождения' : ''}*/}
          {/*  label={editDoctorForm.isEdit ? '' : 'Дата рождения'}*/}
          {/*  value={*/}
          {/*    editDoctorForm?.info?.birthday*/}
          {/*      ? moment(editDoctorForm.info.birthday)*/}
          {/*      : editDoctorForm.isEdit*/}
          {/*      ? moment()*/}
          {/*      : null*/}
          {/*  }*/}
          {/*  onChange={controller.handleBirthDayChange}*/}
          {/*  format="YYYY-MM-DD"*/}
          {/*/>*/}
          {(editDoctorForm.info.birthday || editDoctorForm.isEdit) && (
            <Input
              label={editDoctorForm.isEdit ? '' : 'Дата рождения'}
              placeholder={
                editDoctorForm.isEdit
                  ? birthdayFocused
                    ? 'ДД.ММ.ГГГГ'
                    : 'Дата рождения'
                  : ''
              }
              disabled={!editDoctorForm.isEdit}
              classNames={['edit-doctor-form__form-input']}
              value={
                editDoctorForm.isEdit
                  ? editDoctorForm.editableFields.birthday || ''
                  : editDoctorForm?.info?.birthday || ''
              }
              onInput={controller.handleBirthDayChange}
              error={editDoctorForm.errors.birthDate}
              onFocus={onBirthdateFocus}
              onBlur={onBirthdateBlur}
              showCloseIcon={editDoctorForm.isEdit}
              onIconClick={controller.handleBirthDayDelete}
              fullWidth
            />
          )}
          <AppAutocomplete
            label={editDoctorForm.isEdit ? '' : 'Специальность'}
            placeholder={editDoctorForm.isEdit ? 'Специальность' : ''}
            disabled={!editDoctorForm.isEdit}
            classNames={['edit-doctor-form__form-input']}
            value={String(
              (editDoctorForm.isEdit
                ? editDoctorForm.editableFields.specialty?.name
                : editDoctorForm?.info?.specialty?.name) || ''
            )}
            options={editDoctorForm.specialities}
            onChange={controller.handleSpecialityChange}
            onInput={handleSpecialtyInputDebounce}
            error={editDoctorForm.errors.specialty}
          />
          {editDoctorForm.isEdit ||
            (!!editDoctorForm?.info?.contactPhones?.length && (
              <Input
                label={'Контакты'}
                disabled={!editDoctorForm.isEdit}
                classNames={['edit-doctor-form__form-input']}
                value={editDoctorForm.info.contactPhones.join(', ')}
                fullWidth
                multiline={matchFormWidth}
                styles={{
                  paddingTop: matchFormWidth ? 6 : 0,
                }}
              />
            ))}
          {!!editDoctorForm?.editableFields?.contactPhones?.length &&
            editDoctorForm.isEdit &&
            editDoctorForm.editableFields.contactPhones.map(
              (phone: string, index: number, arr) => {
                const isLast = index === arr.length - 1;
                return (
                  <div className="edit-doctor-form__phones-container">
                    <Input
                      showCloseIcon={editDoctorForm.isEdit}
                      label={
                        editDoctorForm.isEdit ? '' : `Телефон ${index + 1}`
                      }
                      placeholder={
                        editDoctorForm.isEdit ? `Телефон ${index + 1}` : ''
                      }
                      error={editDoctorForm.errors.phoneErrors[index]}
                      disabled={!editDoctorForm.isEdit}
                      fullWidth
                      onIconClick={() =>
                        controller.handleRemovePhoneClick(index)
                      }
                      classNames={[
                        'edit-doctor-form__input',
                        'edit-doctor-form__form-input',
                      ]}
                      focusAfterRender={index > 0}
                      value={phone}
                      onInput={(event: any) =>
                        controller.handlePhoneChange(event, index)
                      }
                    />
                    {isLast &&
                      index + 1 < MAX_PHONES_COUNT &&
                      editDoctorForm.isEdit &&
                      showFormAddIcon(index, arr) && (
                        <RoundButton
                          content={'+'}
                          classNames={['edit-doctor-form__add-phone']}
                          onClick={controller.handleAddPhoneClick}
                        />
                      )}
                  </div>
                );
              }
            )}
          {editDoctorForm.isEdit ||
            (!!editDoctorForm?.info?.medInstitutions?.length && (
              <Input
                label={'ЛПУ'}
                disabled={!editDoctorForm.isEdit}
                classNames={['edit-doctor-form__form-input']}
                value={editDoctorForm.info.medInstitutions
                  .map((item) => item.name)
                  .join(', ')}
                fullWidth
                multiline
                styles={{
                  paddingTop: matchFormWidth ? 6 : 0,
                }}
              />
            ))}
          {!!editDoctorForm?.editableFields?.medInstitutions?.length &&
            editDoctorForm.isEdit &&
            editDoctorForm.editableFields.medInstitutions.map((lpu, i, arr) => {
              const isLast = i === arr.length - 1;

              return (
                <div className="edit-doctor-form__lpu-container">
                  <AppAutocomplete
                    label={editDoctorForm.isEdit ? '' : `ЛПУ ${i + 1}`}
                    placeholder={editDoctorForm.isEdit ? `ЛПУ ${i + 1}` : ''}
                    disabled={!editDoctorForm.isEdit}
                    classNames={[
                      'edit-doctor-form__form-input',
                      'edit-doctor-form__lpu-input',
                    ]}
                    value={lpu.name}
                    onInput={(event: any) => handleLpuInput(event, i)}
                    onFocus={(event: any) =>
                      controller.handleLpuFocus(event, i)
                    }
                    options={editDoctorForm.lpuList}
                    onChange={(event: any, newValue: any) =>
                      controller.handleLpuChange(event, newValue, i)
                    }
                    error={editDoctorForm.errors.lpu[i]}
                  />
                  {isLast &&
                    i + 1 < MAX_LPU_COUNT &&
                    editDoctorForm.isEdit &&
                    showFormAddIcon(i, arr, true) && (
                      <RoundButton
                        content={'+'}
                        classNames={['edit-doctor-form__add-lpu']}
                        onClick={controller.handleAddLpu}
                      />
                    )}
                </div>
              );
            })}
          {!editDoctorForm.isEdit && editDoctorForm.info?.hasInstalledApp && (
            <Input
              label="Наличие мобильного приложения"
              placeholder="ФИО"
              disabled={!editDoctorForm.isEdit}
              classNames={['edit-doctor-form__form-input']}
              value="Использует"
              fullWidth
            />
          )}
          {editDoctorForm.isEdit && (
            <AppSelect
              placeholder="Наличие мобильного приложения"
              label="Наличие мобильного приложения"
              renderText
              handleChange={controller.handleHasMobileAppChange}
              options={hasMobileAppValues}
              value={String(editDoctorForm.editableFields?.hasInstalledApp)}
            />
          )}
          {!editDoctorForm.isEdit && (
            <Button
              className="edit-doctor-form__create-meetup"
              onClick={handleFixMeetup}
            >
              Зафиксировать встречу
            </Button>
          )}
        </div>
      )}
      {/* {editDoctorForm.isLoading ||
        (editDoctorForm.info?.hasInstalledApp && (
          <div className="edit-doctor-form__footer"> */}
      {/* <CheckBox
              label="Мобильное приложение"
              onChange={controller.handleInstalledApp}
              checked={!!editDoctorForm.info.hasInstalledApp}
              disabled={!editDoctorForm.isEdit}
            /> */}

      {/* <Input
              label="Наличие мобильного приложения"
              placeholder="ФИО"
              disabled={!editDoctorForm.isEdit}
              classNames={['edit-doctor-form__form-input']}
              value="Использует"
              fullWidth
            />
          </div>
        ))} */}

      {editDoctorForm.isEdit || (
        <Accordion
          TransitionProps={{
            timeout: 0,
          }}
          className="edit-doctor-form__meetups-accordion"
          onChange={(e: any, expanded: boolean) => {
            if (expanded) {
              handleTasksExpanded();
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="edit-doctor-form__meetups-summary"
            onClick={controller.handleMeetupsClick}
          >
            <span className="edit-doctor-form__meetups-title">
              Посмотреть историю работы
            </span>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: '16px 0 0 0',
            }}
          >
            {!!editDoctorForm.meetups.length ? (
              editDoctorForm.meetups.map((meetup: IApiDoctorMeetupsDone) => {
                return (
                  <MeetupHistoryItem
                    meetups={[
                      {
                        ...meetup,
                        editable: meetup.createdBy?.id === user.id,
                      },
                    ]}
                    onMeetupClick={props.onMeetupCardClick}
                  />
                );
              })
            ) : (
              <span className="edit-doctor-form__meetup-empty">
                Нет записей
              </span>
            )}
            {editDoctorForm.meetupsTotal > MAX_MEETUPS_COUNT && (
              <div className="edit-doctor-form__pagination-container">
                <Pagination
                  count={editDoctorForm.meetupsPages}
                  page={editDoctorForm.meetupsCurrentPage}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    value: number
                  ) => controller.handlePageClick(event, value, formRef)}
                />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {editDoctorForm.isEdit && (
        <div className="edit-doctor-form__buttons">
          <Button
            className="edit-doctor-form__cancel"
            // onClick={props.onCloseModal}
            onClick={controller.handleCancelEditClick}
          >
            Отмена
          </Button>
          <Button
            className="edit-doctor-form__save"
            disabled={
              !!editDoctorForm.errors.specialty ||
              hasLpuErrors() ||
              isAnyNamesEmpty()
            }
            onClick={controller.handleSaveClick}
          >
            Сохранить
          </Button>
        </div>
      )}
    </div>
  );
};

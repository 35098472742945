import React, { useEffect, useMemo, useRef, useState } from 'react';
import AutoCompleteFilter from '../AutoCompleteFilter';
import { HistoryReportController } from '../../history-report.controller';
import { IHistoryReportState } from '../../store/history-report.slice';
import { debounce } from '@mui/material';
import SelectFilter from '../SelectFilter';
import {
  agentValues,
  commentValues,
  dateValues,
  feedbackValues,
  meetupTypeValues,
} from '../../constants';

interface IHistoryReportFilterProps {
  controller: HistoryReportController;
  state: IHistoryReportState;
}

const HistoryLpuFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  const debounceFetch = useMemo(
    () => debounce(controller.fetchLpuList, 500),
    []
  );

  const handleInput = (event: any) => {
    controller.handleLpuInput(event);
    debounceFetch();
  };

  return (
    <div>
      <AutoCompleteFilter
        onInput={handleInput}
        options={state.lpuList}
        selectedOption={state.selectedLpu}
        onChange={controller.onLpuChange}
        placeholder="Введите ЛПУ"
        onDelete={controller.onLpuDelete}
        inputValue={state.lpuSearchText}
        onListAcceptButtonClick={() => {}}
      />
    </div>
  );
};

const HistorySpecialtyFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  const debounceFetch = useMemo(
    () => debounce(controller.fetchSpecialties, 500),
    []
  );

  const handleInput = (event: any) => {
    controller.handleSpecialtyInput(event);
    debounceFetch();
  };

  return (
    <div>
      <AutoCompleteFilter
        onInput={handleInput}
        options={state.specialtyList}
        selectedOption={state.selectedSpecialty}
        onChange={controller.onSpecialtyChange}
        onDelete={controller.onSpecialtyDelete}
        placeholder="Введите специальность"
        inputValue={state.specialtySearchText}
        onListAcceptButtonClick={() => {}}
      />
    </div>
  );
};

const HistoryTypeFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  return (
    <SelectFilter
      options={meetupTypeValues}
      value={state.meetupType}
      onClear={controller.onMeetupTypeClear}
      onChange={controller.handleMeetupTypeChange}
    />
  );
};

const HistoryDateFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  return (
    <SelectFilter
      options={dateValues}
      onClear={controller.onDateFilterClear}
      value={state.dateFilter}
      onChange={controller.handleDateFilterChange}
    />
  );
};

const HistoryFeedbackFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  return (
    <SelectFilter
      options={feedbackValues}
      value={state.feedbackFilter}
      onClear={controller.onFeedbackFilterClear}
      onChange={controller.handleFeedbackFilterChange}
    />
  );
};

const HistoryCommentFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  return (
    <SelectFilter
      options={commentValues}
      onClear={controller.onCommentFilterClear}
      value={state.commentFilter}
      onChange={controller.handleCommentFilterChange}
    />
  );
};

const HistoryDoctorFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  const debounceFetch = useMemo(
    () => debounce(controller.fetchDoctors, 500),
    []
  );

  const handleInput = (event: any) => {
    controller.handleDoctorInput(event);
    debounceFetch();
  };

  return (
    <div>
      <AutoCompleteFilter
        onInput={handleInput}
        options={state.searchedDoctors}
        selectedOption={state.selectedDoctor}
        onChange={controller.onDoctorChange}
        onDelete={controller.onDoctorDelete}
        placeholder="Введите ФИО"
        inputValue={state.doctorSearchText}
        onListAcceptButtonClick={() => {}}
      />
    </div>
  );
};

const HistoryAgentFilter = (props: IHistoryReportFilterProps) => {
  const { controller, state } = props;

  return (
    <SelectFilter
      options={[...agentValues, ...state.searchedAgents]}
      onClear={controller.onAgentFilterClear}
      value={state.agentFilter}
      onChange={controller.handleAgentFilterChange}
    />
  );
};

export {
  HistoryLpuFilter,
  HistorySpecialtyFilter,
  HistoryTypeFilter,
  HistoryCommentFilter,
  HistoryDateFilter,
  HistoryFeedbackFilter,
  HistoryDoctorFilter,
  HistoryAgentFilter,
};

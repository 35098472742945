import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TaskFiltersEnum,
  TaskVariantFiltersEnum,
} from '../../../components/TaskFilters/task-filters.enum';
import { IApiTaskDoctor } from '../../../interfaces/api.tasks.interfaces';
import { TaskVariantTypes } from '../../../types/tasks.types';
import { getMeetupFiltersFromLocalstorage } from '../../../helpers/getMeetupFiltersFromLocalstorage';
interface IMeetupsPageState {
  isLoading: boolean;
  isEnvelopeModalVisible: boolean;
  isDetailsOpened: boolean;
  isMeetupResultVisible: boolean;
  isSelecting: boolean;
  selectedDoctors: IApiTaskDoctor[];
  currentDoctorId?: number;
  currentTaskId?: number;
  filterParam: TaskFiltersEnum;
  doctorEnvelopeName?: string;
  page: number;
  isSearchOpened: boolean;
  searchInputValue: string;
  searchValue: string;
  previousPage: number;
  showCloseEnvelopsForm?: boolean;
  taskVariantFilter: TaskVariantFiltersEnum;
  isTitleHidden: boolean;
}

const initialState: IMeetupsPageState = {
  isLoading: false,
  isEnvelopeModalVisible: false,
  isDetailsOpened: false,
  isMeetupResultVisible: false,
  isSelecting: false,
  selectedDoctors: [],
  searchInputValue: '',
  searchValue: '',
  isSearchOpened: false,
  filterParam: TaskFiltersEnum.DOCTOR_FULLNAME,
  previousPage: 1,
  page: 1,
  showCloseEnvelopsForm: false,
  taskVariantFilter: TaskVariantFiltersEnum.ALL,
  isTitleHidden: false,
};

export const meetupsPageSlice = createSlice({
  name: 'meetups-page',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsEnvelopeModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isEnvelopeModalVisible = action.payload;
    },
    setIsDetailsOpened: (state, action: PayloadAction<boolean>) => {
      state.isDetailsOpened = action.payload;
    },
    setIsMeetupResultVisible: (state, action: PayloadAction<boolean>) => {
      state.isMeetupResultVisible = action.payload;
    },
    setCurrentDoctorId: (state, action: PayloadAction<number | undefined>) => {
      state.currentDoctorId = action.payload;
    },
    setCurrentTaskId: (state, action: PayloadAction<number>) => {
      state.currentTaskId = action.payload;
    },
    setSelecting: (state, action: PayloadAction<boolean>) => {
      state.isSelecting = action.payload;
    },
    pushSelectedDoctor: (state, action: PayloadAction<IApiTaskDoctor>) => {
      state.selectedDoctors.push(action.payload);
    },
    spliceSelectedDoctorByIndex: (state, action: PayloadAction<number>) => {
      state.selectedDoctors.splice(action.payload, 1);
    },
    setSelectedDoctors: (
      state,
      action: PayloadAction<IApiTaskDoctor[] | any[]>
    ) => {
      state.selectedDoctors = action.payload;
    },
    setMeetupListFilters: (state, action: PayloadAction<TaskFiltersEnum>) => {
      state.filterParam = action.payload;
    },
    setDoctorEnvelopeName: (state, action: PayloadAction<string>) => {
      state.doctorEnvelopeName = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchInputValue: (state, action: PayloadAction<string>) => {
      state.searchInputValue = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setSearchOpened: (state, action: PayloadAction<boolean>) => {
      state.isSearchOpened = action.payload;
    },
    setPreviousPage: (state, action: PayloadAction<number>) => {
      state.previousPage = action.payload;
    },
    setShowCloseEnvelopsForm: (state, action: PayloadAction<boolean>) => {
      state.showCloseEnvelopsForm = action.payload;
    },
    setTaskVariantFilter: (
      state,
      action: PayloadAction<TaskVariantFiltersEnum>
    ) => {
      state.taskVariantFilter = action.payload;
    },
    setTitleHidden: (state, action: PayloadAction<boolean>) => {
      state.isTitleHidden = action.payload;
    },
  },
});

export const {
  setLoading,
  setIsEnvelopeModalVisible,
  setIsDetailsOpened,
  setIsMeetupResultVisible,
  setCurrentDoctorId,
  setCurrentTaskId,
  setDoctorEnvelopeName,
  setMeetupListFilters,
  setSelecting,
  pushSelectedDoctor,
  setSearchOpened,
  setPage,
  setSearchValue,
  setSearchInputValue,
  setPreviousPage,
  spliceSelectedDoctorByIndex,
  setSelectedDoctors,
  setShowCloseEnvelopsForm,
  setTaskVariantFilter,
  setTitleHidden,
} = meetupsPageSlice.actions;
export const meetupsPageReducer = meetupsPageSlice.reducer;

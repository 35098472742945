import { AppDispatch, RootState, store } from '../../app.store';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { MeetupsListFilters } from '../../pages/Meetups/enums';
import { MeetupsService } from '../../services/meetups.service';
import { TaskVariantTypes } from '../../types/tasks.types';
import {
  setLoading,
  setMeetups,
  setOffset,
  setPage,
  setStatus,
  setTotal,
  setFilter,
  setSelectedDoctorId,
  setSearchValue,
  setCurrentTasks,
  setLimit,
  setScrollLoading,
  setTaskVariant,
} from './store/meetups-list.slice';
import { MeetupsListVariantTypes } from './types';
import { FilterParamsTypes } from './types';
import { MEETUPS_LIST_LIMIT } from './constants';
import { sleep } from '../../helpers/sleep';

export class MeetupsListController {
  dispatch: AppDispatch;
  getState: () => RootState;
  meetupsService: MeetupsService = new MeetupsService();
  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  getMeetupsList = async () => {
    this.dispatch(setLoading(true));
    const state = this.getState().meetupsList;

    const data = await this.meetupsService.getMeetupsList(
      state.limit,
      state.offset,
      state.status === 'done' ? 'completed' : 'active',
      state.filter,
      state.searchValue,
      state.taskVariant
    );
    if (data) {
      console.log(data);

      this.dispatch(
        setMeetups(
          data.data.map((item: any) => ({
            ...item,
            reactRenderKey: Math.random() * Math.random(),
          }))
        )
      );
      this.dispatch(setTotal(data.total));
    }
    this.dispatch(setLoading(false));
  };

  getMeetupsListOnScroll = async () => {
    this.dispatch(setScrollLoading(true));
    const state = this.getState().meetupsList;

    const data = await this.meetupsService.getMeetupsList(
      state.limit,
      state.offset + MEETUPS_LIST_LIMIT,
      state.status === 'done' ? 'completed' : 'active',
      state.filter,
      state.searchValue,
      state.taskVariant
    );

    if (data) {
      this.dispatch(setOffset(state.offset + MEETUPS_LIST_LIMIT));
      this.dispatch(
        setMeetups([
          ...state.meetups,
          ...data.data.map((item: any) => ({
            ...item,
            reactRenderKey: Math.random() * Math.random(),
          })),
        ])
      );
    }

    this.dispatch(setScrollLoading(false));
  };

  onPageChange = (_: any, page: number) => {
    const state = this.getState().meetupsList;
    this.dispatch(setPage(page));
    this.dispatch(setOffset((page - 1) * state.limit));
    this.getMeetupsList();
  };

  setPage = (page: number) => {
    const state = this.getState().meetupsList;
    this.dispatch(setPage(page));
    this.dispatch(setOffset((page - 1) * state.limit));
  };

  setMeetupStatus = (status: MeetupsListVariantTypes) => {
    this.dispatch(setStatus(status));
  };

  setFilter = (filter: FilterParamsTypes) => {
    this.dispatch(setFilter(filter));
  };
  setLimit = (limit: number) => {
    this.dispatch(setLimit(limit));
  };

  onDoctorClick = (id: number) => {
    this.dispatch(setSelectedDoctorId(id));
  };

  setSearchValue = (value: string) => {
    this.dispatch(setSearchValue(value));
  };

  getShortTasks = async (
    type: TaskVariantTypes,
    doctorID: number,
    doctorFullname: string
  ) => {
    this.dispatch(setCurrentTasks([]));

    const data = await this.meetupsService.getTasksByDoctor(
      doctorID,
      true,
      type
    );

    const activeTasks = data?.tasks?.filter(
      (task: any) => task.meetupStatus === null
    );

    if (activeTasks.length === 1) {
      EventEmitter.emit(EMITTER_EVENTS.OPEN_COMPLETE_TASK_MENU, {
        type,
        id: activeTasks[0].id,
        name: doctorFullname,
        doctorID,
      });
    } else {
      this.dispatch(setCurrentTasks(activeTasks));
    }
    return;
  };

  setTaskVariant = (variant: TaskVariantTypes | null) => {
    this.dispatch(setTaskVariant(variant));
  };
}

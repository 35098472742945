import React, {
  ChangeEvent,
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RoundButton from '../RoundButton';
import { classNamesParser } from '../../helpers/classNamesParser';
import { SEARCH_BUTTON_WIDTH } from './constants';
import { useMediaQuery } from '@mui/material';
import { MEDIA } from '../../constants/app.constants';

interface ISearchButtonProps {
  classNames?: string[];
  onInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onSearch?: () => Promise<void> | void;
  onClose?: () => void;
  onOpen?: () => void;
  placeholder?: string;
  open?: boolean;
  styles?: CSSProperties;
  inputStyles?: CSSProperties;
  iconType?: 'search' | 'close';
  disableAutoFocus?: boolean;
}

const SearchButton = (props: ISearchButtonProps) => {
  const [width, setWidth] = useState(SEARCH_BUTTON_WIDTH.CLOSED);
  const matchesXsm = useMediaQuery(`(max-width:${MEDIA.XSM}px)`);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setWidth(
      props.open
        ? matchesXsm
          ? SEARCH_BUTTON_WIDTH.XSM_OPEN
          : SEARCH_BUTTON_WIDTH.OPEN
        : SEARCH_BUTTON_WIDTH.CLOSED
    );

    if (props.open && !props.disableAutoFocus) {
      inputRef.current.focus();
    }
  }, [props.open]);

  const onEnterPress = useMemo(
    () => (event: KeyboardEvent) => {
      if (event.key === 'Enter' && props.open) {
        props.onSearch && props.onSearch();
      }
    },
    [props.open]
  );

  useEffect(() => {
    document.addEventListener('keypress', onEnterPress);
    return () => {
      document.removeEventListener('keypress', onEnterPress);
    };
  }, [props.open]);

  const getIconByType = (type: 'search' | 'close') => {
    const iconsMap = {
      'search': <SearchIcon className={'search-button__round-button-icon'} />,
      'close': <CloseIcon className={'search-button__round-button-icon'} />,
    };
    return iconsMap[type as keyof object];
  };

  const getIconByWidth = (width: number) => {
    return width === SEARCH_BUTTON_WIDTH.CLOSED ? (
      <SearchIcon className={'search-button__round-button-icon'} />
    ) : (
      <CloseIcon className={'search-button__round-button-icon'} />
    );
  };

  const handleInputClick = () => {
    inputRef.current.focus();
  };

  return (
    <div
      className={classNamesParser('search-button', props.classNames)}
      style={{
        width,
        ...props.styles,
      }}
    >
      <input
        onClick={handleInputClick}
        style={{ ...props.inputStyles, padding: !props.open ? 0 : undefined }}
        ref={inputRef}
        className="search-button__input"
        onInput={props.onInput}
        value={props.value}
        placeholder={props.placeholder}
        readOnly={!props.open}
      />
      <RoundButton
        onClick={
          width === SEARCH_BUTTON_WIDTH.CLOSED ? props.onOpen : props.onClose
        }
        classNames={['search-button__round-button']}
        content={
          props.iconType ? getIconByType(props.iconType) : getIconByWidth(width)
        }
      />
    </div>
  );
};

export default SearchButton;

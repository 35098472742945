import { AppDispatch, RootState, store } from '../../app.store';
import {
  addLpuByIndex,
  addLpuField,
  popPhone,
  pushToPhones,
  removeLpu,
  removeLpuErrorByIndex,
  setArchived,
  setBirthDay,
  setDoctorInfo,
  setIsEdit,
  setIsNoteEdit,
  setLoading,
  setLpuError,
  setLpuList,
  setLpuSearchText,
  setMeetups,
  setMeetupsOpen,
  setMeetupsTotal,
  setNoteValue,
  setPhoneByIndex,
  setSpecialities,
  setSpecialty,
  setSpecialtyError,
  setSpecialtySearchText,
  slicePhone,
  toggleHasInstalledApp,
  setMeetupsCurrentPage,
  setMeetupsOffset,
  setMeetupsPages,
  setLastName,
  setMiddleName,
  setFirstName,
  setBirthDateError,
  setHasInstalledApp,
  setNoteInputValue,
  setPhoneErrors,
  removePhoneError,
  setEditableFields,
  setEditableBirthday,
  clearErrors,
} from './store/edit-doctor-form.slice';
import { CatalogsService } from '../../services/catalogs.service';
import React, { ChangeEvent } from 'react';
import { IApiSpecialityItem } from '../../interfaces/api.catalogs.interfaces';
import {
  birthDateReverse,
  parseEditDoctorLpuIds,
  parseEditDoctorPhones,
} from './helpers';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import validator from 'validator';
import isNumeric = validator.isNumeric;
import { SelectChangeEvent } from '@mui/material';
import { showAppSnackBar } from '../../store/app.slice';

export class EditDoctorFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  onEditClick = () => {
    const store = this.getState().editDoctorForm;
    this.dispatch(setIsEdit(!store.isEdit));
  };

  fetchDoctorById = async () => {
    const receivedDoctorId = this.getState().editDoctorForm.receivedDoctorId;

    try {
      const response = await this.catalogsService.getDoctorById(
        receivedDoctorId
      );

      if (response) {
        const birthDate = !!response.birthday
          ? birthDateReverse(response.birthday, '-', '.')
          : '';
        this.dispatch(setDoctorInfo(response));
        this.dispatch(setEditableFields(response));
        this.dispatch(setIsNoteEdit(false));
        this.dispatch(setNoteInputValue(response.note || ''));
        this.dispatch(setBirthDay(birthDate));
        this.dispatch(setEditableBirthday(birthDate));
        this.dispatch(
          setPhoneErrors(new Array(response.contactPhones?.length).fill(''))
        );

        if (!response.contactPhones?.length) {
          this.dispatch(pushToPhones(''));
        }

        this.addLpuField();
      }
    } catch (e) {
      console.error(e);
    }
  };

  addLpuField = () => {
    const lpuList =
      this.getState().editDoctorForm.editableFields.medInstitutions;

    if (!lpuList || !lpuList.length) {
      this.dispatch(addLpuField());
    }
  };

  handlePhoneChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;

    this.dispatch(
      setPhoneByIndex({
        index,
        value,
      })
    );
  };

  handleAddPhoneClick = () => {
    this.dispatch(pushToPhones(''));
  };

  handleRemovePhoneClick = (index: number) => {
    const phones = this.getState().editDoctorForm.editableFields.contactPhones;

    if (phones && phones.length > 1) {
      this.dispatch(slicePhone(index));
      this.dispatch(removePhoneError(index));
    } else {
      this.dispatch(setPhoneByIndex({ index: 0, value: '' }));
    }
  };

  handleInstalledApp = () => {
    this.dispatch(toggleHasInstalledApp());
  };

  handleBirthDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regEx = new RegExp(
      '(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[0-2]).(19|20)\\d{2}'
    );
    const errorText = 'Формат даты должен быть таким 31.12.2022';

    if (value.length > 10) return;

    if (!value.length) {
      return this.dispatch(setEditableBirthday(value));
    }

    if (isNumeric(value) || value.split('').includes('.') || !value.length) {
      const prevStoreValue =
        this.getState().editDoctorForm.editableFields.birthday || '';
      this.dispatch(setEditableBirthday(value));
      const currStoreValue =
        this.getState().editDoctorForm.editableFields.birthday;
      const formattedValue =
        (currStoreValue?.length === 2 || currStoreValue?.length === 5) &&
        prevStoreValue?.length < value.length
          ? `${value}.`
          : value;
      this.dispatch(setEditableBirthday(formattedValue));
    }

    if (!value.match(regEx) && value.length >= 10) {
      //this.dispatch(setBirthDay(''));
      this.dispatch(setBirthDateError(errorText));
    } else if (value.length > 10) {
      //this.dispatch(setBirthDay(''));
      this.dispatch(setBirthDateError(errorText));
    } else {
      this.dispatch(setBirthDateError(''));
    }
  };

  handleBirthDayDelete = () => {
    this.dispatch(setEditableBirthday(''));
  };

  fetchSpecialities = async () => {
    const searchText = this.getState().editDoctorForm.specialtySearchText;

    try {
      const response = await this.catalogsService.getSpecialities(
        null,
        searchText
      );

      if (response) {
        const formattedSpecialities = response.data.map((item) => ({
          ...item,
          title: item.name,
        }));
        this.dispatch(setSpecialities(formattedSpecialities));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchLpuList = async () => {
    const searchText = this.getState().editDoctorForm.lpuSearchText;

    try {
      const response = await this.catalogsService.getLpuList(null, searchText);

      if (response) {
        const formattedList = response.data.map((item) => ({
          ...item,
          title: item.name,
        }));

        this.dispatch(setLpuList(formattedList));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchDoctorMeetupsDone = async () => {
    try {
      const store = this.getState().editDoctorForm;
      const response = await this.catalogsService.getDoctorMeetupsDoneById(
        store.info.id,
        store.meetupsLimit,
        store.meetupsOffset
      );

      if (response) {
        this.dispatch(setMeetups(response?.data));
        this.dispatch(setMeetupsTotal(response?.total));
        this.dispatch(setMeetupsPages(response?.total));
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleSpecialityChange = (event: any, newValue: any) => {
    if (newValue) {
      const specialty: IApiSpecialityItem = {
        name: newValue.name,
        deleted: newValue.deleted,
        id: newValue.id,
      };
      this.dispatch(setSpecialty(specialty));
      this.dispatch(setSpecialtySearchText(newValue.name));
    } else {
      this.dispatch(setSpecialty(null));
      this.dispatch(setSpecialtySearchText(''));
    }

    this.validateSpecialty();
    this.fetchSpecialities();
  };

  handleSpecialtyInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setSpecialtySearchText(event.target.value));
    this.validateSpecialty();
    this.fetchSpecialities();

    if (!event.target.value.length) {
      this.dispatch(setSpecialty(null));
    }
  };

  validateSpecialty = () => {
    const specialtyText = this.getState().editDoctorForm.specialtySearchText;
    const isExist = this.getState().editDoctorForm.specialities.find(
      (item) => item.name === specialtyText
    );

    if (isExist) {
      this.dispatch(setSpecialtyError(''));
    } else {
      this.dispatch(setSpecialtyError('Специальность не найдена'));
    }
  };

  handleAddLpu = () => {
    this.dispatch(addLpuField());
  };

  handleRemoveLpu = (index: number) => {
    this.dispatch(removeLpu(index));
    this.dispatch(removeLpuErrorByIndex(index));
  };

  handleLpuInput = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const value: string = event.target.value;
    this.dispatch(setLpuSearchText(value));
    this.validateLpu(index);
    this.fetchLpuList();
  };

  handleLpuFocus = async (event: any, index: number) => {
    const value: string = event.target.value;
    this.dispatch(setLpuSearchText(value));
    await this.fetchLpuList();
    this.validateLpu(index);
  };

  handleLpuChange = async (event: any, newValue: any, index: number) => {
    event.stopPropagation();
    const mockLpu = {
      id: 0,
      name: '',
      deleted: false,
      address: {
        id: 0,
        placeName: '',
        district: '',
        street: '',
      },
    };
    const lpuList =
      this.getState().editDoctorForm.editableFields.medInstitutions;
    if (newValue) {
      this.dispatch(
        addLpuByIndex({
          value: newValue,
          index,
        })
      );
      this.dispatch(setLpuSearchText(newValue.name));
    } else if (lpuList && lpuList.length > 1 && event.type === 'click') {
      this.dispatch(setLpuSearchText(''));
      this.dispatch(removeLpuErrorByIndex(index));
      this.dispatch(removeLpu(index));
    } else {
      this.dispatch(
        addLpuByIndex({
          value: mockLpu,
          index,
        })
      );
      this.dispatch(setLpuSearchText(''));
    }

    await this.fetchLpuList();

    this.validateLpu(index);
  };

  validateLpu = (index: number) => {
    const lpuSearchText = this.getState().editDoctorForm.lpuSearchText;
    const isExist = this.getState().editDoctorForm.lpuList.find(
      (item) => item.name === lpuSearchText
    );

    if (isExist || !lpuSearchText) {
      this.dispatch(
        setLpuError({
          index,
          text: '',
        })
      );
    } else {
      this.dispatch(
        setLpuError({
          index,
          text: 'ЛПУ не найден',
        })
      );
    }
  };

  handleSaveClick = async () => {
    this.dispatch(setLoading(true));
    const info = this.getState().editDoctorForm.info;
    const updatedInfo = this.getState().editDoctorForm.editableFields;

    let lpuIds: number[] = [];
    let updatedLpuIds: number[] = [];

    let phones: string[] = [];
    let updatedPhones: string[] = [];

    if (info.medInstitutions)
      lpuIds = parseEditDoctorLpuIds(info.medInstitutions);

    if (updatedInfo.medInstitutions)
      updatedLpuIds = parseEditDoctorLpuIds(updatedInfo.medInstitutions);

    if (info.contactPhones) phones = parseEditDoctorPhones(info.contactPhones);

    if (updatedInfo.contactPhones)
      updatedPhones = parseEditDoctorPhones(updatedInfo.contactPhones);

    const regEx = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

    const errors =
      updatedPhones.map((number) =>
        regEx.test(number) ? '' : 'Указан неверный формат номера телефона'
      ) || [];

    this.dispatch(setPhoneErrors(errors));

    let birthDate: string | undefined | null = undefined;

    if (updatedInfo.birthday !== info.birthday) {
      birthDate = !!updatedInfo.birthday
        ? birthDateReverse(updatedInfo.birthday, '.', '-')
        : null;
    }

    const response = await this.catalogsService.updateDoctorById(
      info.id,
      undefined,
      undefined,
      info.specialty?.id !== updatedInfo.specialty?.id
        ? updatedInfo.specialty?.id
        : undefined,
      info.lastName !== updatedInfo.lastName ? updatedInfo.lastName : undefined,
      info.firstName !== updatedInfo.firstName
        ? updatedInfo.firstName
        : undefined,
      info.middleName !== updatedInfo.middleName
        ? updatedInfo.middleName
        : undefined,
      birthDate,
      info.hasInstalledApp !== updatedInfo.hasInstalledApp
        ? updatedInfo.hasInstalledApp
        : undefined,
      JSON.stringify(lpuIds) !== JSON.stringify(updatedLpuIds)
        ? updatedLpuIds
        : undefined,
      JSON.stringify(phones) !== JSON.stringify(updatedPhones)
        ? updatedPhones
        : undefined
    );
    this.dispatch(setLoading(false));

    if (response?.length > 0) {
      const error = response.split('_');
      const phoneError = error[error.length - 1];
      this.dispatch(
        setPhoneErrors(
          errors.map((err, i) => {
            if (phoneError === phones[i]) {
              return 'Указан неверный формат номера телефона';
            } else return err;
          })
        )
      );
      return;
    }
    this.dispatch(setIsEdit(false));
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
    this.fetchDoctorById();
  };

  handleNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setNoteInputValue(e.target.value));
  };
  handleNoteAcceptClick = async () => {
    const state = this.getState().editDoctorForm;

    try {
      await this.catalogsService.updateDoctorNoteById(
        state.info.id,
        state.noteInput
      );
      this.dispatch(setNoteValue(state.noteInput));
      this.dispatch(setIsNoteEdit(false));
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
    } catch (e) {
      console.error(e);
    }
  };
  handleNoteCancelClick = () => {
    const state = this.getState().editDoctorForm;
    this.dispatch(setNoteInputValue(state.info.note || ''));
    this.dispatch(setIsNoteEdit(false));
  };
  handleNoteClick = () => {
    this.dispatch(setIsNoteEdit(true));
  };

  handleMenuClick = (value: string, callback?: () => void) => {
    if (value.toLowerCase() === 'деактивировать') {
      this.handleDeactivate(true);
    }

    if (value.toLowerCase() === 'активировать') {
      this.handleDeactivate(false);
    }
    if (value.toLowerCase() === 'удалить') {
      this.handleDeleteDoctor(callback);
    }
  };

  handleDeactivate = async (archived: boolean) => {
    const info = this.getState().editDoctorForm.info;

    try {
      await this.catalogsService.updateDoctorById(info.id, undefined, archived);
      this.dispatch(setIsEdit(false));
      this.dispatch(setArchived(archived));
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      this.fetchDoctorById();
    } catch (e) {
      console.error(e);
    }
  };

  handleDeleteDoctor = async (callback?: () => void) => {
    const info = this.getState().editDoctorForm.info;
    const data = await this.catalogsService.deleteDoctorById(info.id);
    if (data) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      callback && callback();
    } else {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось удалить врача',
        })
      );
    }
  };

  handleMeetupsClick = () => {
    const meetupsOpen = this.getState().editDoctorForm.meetupsOpen;
    this.dispatch(setMeetupsOpen(!meetupsOpen));
  };

  handlePageClick = async (
    event: React.ChangeEvent<unknown>,
    value: number,
    formRef?: any
  ) => {
    const limit = this.getState().editDoctorForm.meetupsLimit;

    this.dispatch(setMeetupsOffset((value - 1) * limit));
    this.dispatch(setMeetupsCurrentPage(value));
    await this.fetchDoctorMeetupsDone();

    if (formRef?.current) {
      formRef.current.scrollTo(0, 100000);
    }
  };

  handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setFirstName(e.target.value));
  };
  handleMiddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setMiddleName(e.target.value));
  };
  handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setLastName(e.target.value));
  };

  handleCancelEditClick = async () => {
    await this.fetchDoctorById();
    this.dispatch(setIsEdit(false));
    this.dispatch(clearErrors());
  };

  handleHasMobileAppChange = async (e: SelectChangeEvent) => {
    this.dispatch(setHasInstalledApp(e.target.value === 'true'));
  };
}

export const ROUTES = {
  SIGNIN: 'sign-in',
  REFERENCE_CATALOG: 'reference-catalog',
  USERS: 'users',
  TASKS_PAGE: '/',
  UPLOAD: 'upload',
  MEETUPS: 'meetups',
  COMPLETED_MEETUPS: 'completed-meetups',
  FEEDBACK: 'feedback',
  DYNAMIC: 'dynamic',
  MEETUPS_REPORT: 'meetups-report',
  MEETUP_HISTORY: 'meetup-history',
  CHANGELOG: 'changelog',
  HISTORY_REPORT: 'history-report',
};

import { Skeleton } from '@mui/material';
import React from 'react';

const CompletedMeetupSkeleton = () => {
  return (
    <div className="completed-meetup-skeleton">
      <Skeleton
        classes={{
          root: 'completed-meetup-skeleton__title',
        }}
      />
      <Skeleton
        classes={{
          root: 'completed-meetup-skeleton__subtitle',
        }}
      />

      <div className="completed-meetup-skeleton__info">
        <Skeleton
          classes={{
            root: 'completed-meetup-skeleton__type',
          }}
        />
        <Skeleton
          classes={{
            root: 'completed-meetup-skeleton__meetup-info',
          }}
        />
      </div>
    </div>
  );
};

export default CompletedMeetupSkeleton;

import { IconButton, Modal } from '@mui/material';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { ResponseImage } from '../../types/images.types';
import { ArrowBackIos, ArrowForwardIos, Close } from '@mui/icons-material';
import { ServerImage } from '../ServerImage';

export interface IImageSliderHandler {
  showSlide: (id: string) => void;
}

interface IImageSliderProps {
  images: ResponseImage[];
}

export const ImageSlider = forwardRef<IImageSliderHandler, IImageSliderProps>(
  (props, ref) => {
    const [currentImageID, setCurrentImageID] = useState<string | null>();

    useImperativeHandle(
      ref,
      () => ({
        showSlide(id) {
          setCurrentImageID(id);
        },
      }),
      [props.images]
    );

    const image = useMemo(
      () => props.images.find((image) => image.id === currentImageID),
      [props.images, currentImageID]
    );

    const onClose = () => {
      setCurrentImageID(null);
    };

    const onNextClick = () => {
      if (!currentImageID) return;
      const currentImageIndex = props.images.findIndex(
        (image) => image.id === currentImageID
      );
      const nextImage =
        props.images[currentImageIndex + 1]?.id ?? props.images[0].id;
      setCurrentImageID(nextImage);
    };

    const onPrevClick = () => {
      if (!currentImageID) return;
      const currentImageIndex = props.images.findIndex(
        (image) => image.id === currentImageID
      );
      const nextImage =
        props.images[currentImageIndex - 1]?.id ?? props.images.at(-1)?.id;
      setCurrentImageID(nextImage);
    };

    return (
      <Modal open={Boolean(currentImageID)} onClose={onClose}>
        <div className="image-slider">
          <IconButton className="image-slider__close" onClick={onClose}>
            <Close className="image-slider__icon" />
          </IconButton>
          <div className="image-slider__image-wrapper">
            <ServerImage
              width={0}
              height={0}
              src={image?.filename || ''}
              className="image-slider__image"
            />
          </div>

          <button
            className="image-slider__control image-slider__control--prev"
            onClick={onPrevClick}
          >
            <ArrowBackIos />
          </button>
          <button
            className="image-slider__control image-slider__control--next"
            onClick={onNextClick}
          >
            <ArrowForwardIos />
          </button>
        </div>
      </Modal>
    );
  }
);

import { useEffect } from 'react';
import BirthdayLabel from '../BirthdayLabel';
import RoundButton from '../../../../ui/RoundButton';
import { Close } from '@mui/icons-material';
import { Skeleton, useMediaQuery } from '@mui/material';
import MeetupTask from '../MeetupTask';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import moment from 'moment';
import { MeetupDetailsController } from '../../meetup-details.controller';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import MeetupTaskSkeleton from '../MeetupTaskSkeleton';
import { MEDIA } from '../../../../constants/app.constants';
import AppModal from '../../../../components/AppModal';
import ContactsMobileForm from '../ContactsMobileForm';
import EditableNote from '../../../../components/EditableNote';
import EditablePhonesList from '../EditablePhonesList';
import PhoneButtonMobile from '../PhoneButtonMobile';
import NoteMobileForm from '../NoteMobileForm';
import FeedbackMeetupItem from '../FeedbackMeetupItem';
import { FeedbacksList } from '../FeedbacksList';

interface IMeetupDetailsProps {
  onClose: () => void;
  doctorId?: number;
  onClick?: (params: {
    type: TaskVariantTypes;
    id: number;
    name: string;
    doctorID?: number;
  }) => void;
}

export const MeetupDetails = (props: IMeetupDetailsProps) => {
  const dispatch = useAppDispatch();
  const meetupDetails = useAppSelector((state) => state.meetupDetails);
  const user = useAppSelector((state) => state.user);
  const controller = new MeetupDetailsController(dispatch);
  const isMobile = useMediaQuery(`(max-width:${MEDIA.SM}px)`);

  useEffect(() => {
    if (props.doctorId) {
      controller.setDoctorId(props.doctorId);
      controller.getTasksByDoctor();
    }
    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_MEETUP_DETAILS,
      controller.getTasksByDoctor
    );

    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_MEETUP_DETAILS);
    };
  }, [props.doctorId]);

  const activeTasks = meetupDetails.tasks.filter(
    (task) => task.meetupStatus === null
  );

  const finishedTasks = meetupDetails.tasks.filter(
    (task) => task.meetupStatus === 'done' || task.meetupStatus === 'failed'
  );

  const doctorFullname = `${meetupDetails.doctor?.lastName || ''} ${
    meetupDetails.doctor?.firstName || ''
  } ${meetupDetails.doctor?.middleName || ''}`;

  const handleTaskClick = (type: TaskVariantTypes, id: number) => {
    props.onClick &&
      meetupDetails.doctor &&
      props.onClick({
        type,
        id,
        name: doctorFullname,
      });
  };

  return (
    <div className="meetup-details">
      <div className="meetup-details__header">
        <div className="meetup-details__doctor-info">
          <h4 className="meetup-details__doctor-name">
            {meetupDetails.isLoading ? (
              <Skeleton height={20} className="meetup-details__skeleton-name" />
            ) : (
              doctorFullname
            )}{' '}
            <RoundButton onClick={props.onClose} content={<Close />} />
          </h4>
          <span className="meetup-details__doctor-subinfo">
            <span className="meetup-details__facility">
              {meetupDetails.isLoading ? (
                <Skeleton
                  height={17}
                  className="meetup-details__skeleton-facility"
                />
              ) : (
                <>
                  {meetupDetails.doctor?.medInstitutions
                    ?.map((medInstitution) => medInstitution.name)
                    .join(', ')}
                  {!!meetupDetails.doctor?.medInstitutions?.length &&
                    meetupDetails.doctor?.specialty?.name &&
                    ', '}
                  {meetupDetails.doctor?.specialty?.name}
                </>
              )}{' '}
            </span>
            {meetupDetails.doctor?.birthday && (
              <BirthdayLabel date={meetupDetails.doctor?.birthday} />
            )}
          </span>
        </div>
      </div>
      {meetupDetails.isLoading ? (
        <>
          <Skeleton
            height={48}
            style={{
              marginBottom: 8,
            }}
          />
          <Skeleton
            height={48}
            style={{
              marginBottom: 8,
            }}
          />
        </>
      ) : (
        <>
          {!isMobile && (
            <EditablePhonesList
              onPhoneNumberClick={controller.onEditPhoneNumber}
              editInput={meetupDetails.phoneInput}
              isEditing={meetupDetails.isEditingPhoneNumber}
              onCancel={controller.onCancelEditingNumber}
              onSave={controller.onSavePhoneNumber}
              onPhoneNumberChange={controller.onPhoneNumberChange}
              phones={meetupDetails.doctor?.contactPhones}
              error={meetupDetails.editPhoneListError}
            />
          )}
          <EditableNote
            isEditing={meetupDetails.isEditingNote}
            onNoteInputChange={controller.onNoteChange}
            onNoteClick={
              isMobile
                ? controller.onMobileNoteFormOpen
                : controller.onEditNoteClick
            }
            onCancelEdit={controller.onCancelEditNote}
            onConfirm={controller.onSaveNote}
            noteInput={meetupDetails.noteInput}
            note={meetupDetails.doctor?.note}
            classNames={['meetup-details__note']}
          />
        </>
      )}
      {isMobile && (
        <PhoneButtonMobile
          onOpenEditForm={controller.onOpenContactsMobile}
          phones={meetupDetails.doctor?.contactPhones}
          onAddPhone={controller.onAddPhoneNumber}
        />
      )}
      {/* <FeedbacksList /> */}
      <div className="meetup-details__active-tasks">
        {meetupDetails.feedbacks.length > 0 && (
          <>
            <h4 className="meetup-details__tasks-header">Обратная связь</h4>

            <div className="meetup-details__active-list meetup-details__active-list_feedbacks">
              <FeedbacksList feedbacks={meetupDetails.feedbacks} />

              {/* {meetupDetails.feedbacks.map((feedback, i) => (
                <FeedbackMeetupItem
                  id={feedback.id}
                  description={feedback.comment}
                  comments={[]}
                  type={feedback.type}
                  createdAt={feedback.createdAt}
                />
              ))} */}
            </div>
          </>
        )}

        {activeTasks.length > 0 && (
          <h4 className="meetup-details__tasks-header">Задания в работе</h4>
        )}
        <div className="meetup-details__active-list">
          {meetupDetails.isTasksLoading ? (
            <>
              <MeetupTaskSkeleton />
              <MeetupTaskSkeleton />
            </>
          ) : (
            activeTasks.map((task, i) => (
              <MeetupTask
                onClick={handleTaskClick}
                id={task.id}
                key={task.id}
                variant={task.type}
                meetupStatus={task.meetupStatus}
                completed={false}
                note={task.description}
                date={moment(task.createdAt).format('DD MMMM YYYY')}
              />
            ))
          )}
        </div>
      </div>
      {finishedTasks.length > 0 && (
        <div className="meetup-details__completed-tasks">
          <h4 className="meetup-details__tasks-header">Завершенные задания</h4>
          <div className="meetup-details__completed-list">
            {finishedTasks.map((task) => (
              <MeetupTask
                id={task.id}
                key={task.id}
                onClick={handleTaskClick}
                meetupStatus={task.meetupStatus}
                meetupFinishedAt={moment(task.meetupFinishedAt).format(
                  'DD MMMM YYYY'
                )}
                isOwnFinished={task.meetupFinishedBy?.id === user?.id}
                finishedBy={task.meetupFinishedBy}
                variant={task.type}
                completed={true}
                note={task.description}
                date={moment(task.createdAt).format('DD MMMM YYYY')}
              />
            ))}
          </div>
        </div>
      )}
      <AppModal
        hideBackdrop
        open={meetupDetails.isContactsMobileVisible}
        classNames={['meetup-details__mobile-modal']}
      >
        <ContactsMobileForm
          phoneNumbers={meetupDetails.doctor?.contactPhones}
          onClose={controller.onCloseMobileContacts}
          onDeleteNumber={controller.onDeletePhoneNumber}
          onAddNumber={controller.onAddPhoneNumber}
          onInputChage={controller.onMobileInputChange}
          onSave={controller.onMobileContactsSave}
          phoneNumberErrors={meetupDetails.phoneNumberErrors}
        />
      </AppModal>
      <AppModal
        hideBackdrop
        open={meetupDetails.isNoteEditModalVisible}
        classNames={['meetup-details__mobile-modal']}
      >
        <NoteMobileForm
          inputValue={meetupDetails.noteInput}
          onInputChange={controller.onNoteChange}
          onCancel={controller.onMobileNoteFormClose}
          onSave={controller.onSaveNote}
        />
      </AppModal>
    </div>
  );
};

import { AppDispatch, RootState, store } from '../../app.store';
import { MeetupsService } from '../../services/meetups.service';
import { IApiTaskDoctor } from '../../interfaces/api.tasks.interfaces';
import {
  clearState,
  setComment,
  setDoctorIds,
  setSuccessMeetup,
} from './store/close-envelops-form.slice';
import { ChangeEvent } from 'react';
import { showAppSnackBar } from '../../store/app.slice';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { SelectChangeEvent } from '@mui/material';
import { ENVELOPE_VALUE_NAMES } from './constants';

export class CloseEnvelopsController {
  dispatch: AppDispatch;
  getState: () => RootState;
  meetupsService: MeetupsService = new MeetupsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  updateDoctorIds = (doctors: IApiTaskDoctor[]) => {
    if (doctors.length) {
      const ids = doctors.map((doctor) => doctor.doctorID);
      this.dispatch(setDoctorIds(ids));
    }
  };

  handleDoneClick = async () => {
    try {
      const store = this.getState().closeEnvelopsForm;
      const response = await this.meetupsService.closeMassMeetups(
        store.meetupType,
        store.doctorIds,
        store.comment,
        store.isSuccessMeetup === ENVELOPE_VALUE_NAMES.ENVELOPE_DONE
          ? 'done'
          : 'failed'
      );

      if (response !== null) {
        EventEmitter.emit(EMITTER_EVENTS.SUCCESS_CLOSE_MASS_ENVELOPES);
        EventEmitter.emit(EMITTER_EVENTS.UPDATE_MEETUP_LIST);
        this.dispatch(
          showAppSnackBar({
            snackBarSeverity: 'success',
            text: 'Успешно!',
          })
        );
        this.clearState();
      } else {
        this.dispatch(
          showAppSnackBar({
            snackBarSeverity: 'error',
            text: 'Ошибка. Что-то пошло не так.',
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setComment(e.target.value));
  };

  clearState = () => {
    this.dispatch(clearState());
  };

  onSuccessMeetupChange = (e: SelectChangeEvent) => {
    this.dispatch(setSuccessMeetup(e.target.value));
  };
}

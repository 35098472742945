import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiLpuItem } from '../../../interfaces/api.catalogs.interfaces';

interface IAddDoctorFormState {
  isLoading: boolean;
  birthDay: string;
  contactPhones: string[];
  firstName: string;
  hasInstalledApp: boolean;
  lastName: string;
  medInstitutions: IApiLpuItem[];
  middleName: string;
  note: string;
  specialityId: number;
  specialitySearchText: string;
  lpuSearchText: string;
  specialities: any[];
  errors: {
    speciality: string;
    lpu: string[];
    birthDay: string;
    phoneErrors: string[];
  };
  lpuList: any[];
}

const initialState: IAddDoctorFormState = {
  isLoading: false,
  birthDay: '',
  contactPhones: [],
  firstName: '',
  note: '',
  hasInstalledApp: false,
  lastName: '',
  medInstitutions: [],
  middleName: '',
  specialityId: 0,
  specialitySearchText: '',
  lpuSearchText: '',
  specialities: [],
  errors: {
    speciality: '',
    lpu: [],
    birthDay: '',
    phoneErrors: [],
  },
  lpuList: [],
};

export const addDoctorFormSlice = createSlice({
  name: 'add-doctor-form',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBirthDay: (state, action: PayloadAction<string>) => {
      state.birthDay = action.payload;
    },
    setContactPhones: (state, action: PayloadAction<string[]>) => {
      state.contactPhones = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    setHasInstalledApp: (state, action: PayloadAction<boolean>) => {
      state.hasInstalledApp = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setMedInstitutions: (state, action: PayloadAction<IApiLpuItem[]>) => {
      state.medInstitutions = action.payload;
    },
    setMiddleName: (state, action: PayloadAction<string>) => {
      state.middleName = action.payload;
    },
    setSpecialtyId: (state, action: PayloadAction<number>) => {
      state.specialityId = action.payload;
    },
    setSpecialitySearchText: (state, action: PayloadAction<string>) => {
      state.specialitySearchText = action.payload;
    },
    setSpecialities: (state, action: PayloadAction<any[]>) => {
      state.specialities = action.payload;
    },
    setPhoneErrors: (state, action: PayloadAction<string[]>) => {
      state.errors.phoneErrors = action.payload;
    },
    removePhoneError: (state, action: PayloadAction<number>) => {
      state.errors.phoneErrors.splice(action.payload, 1);
    },
    clearStore: (state) => {
      for (const [key] of Object.entries(initialState)) {
        state[key as keyof object] = initialState[key as keyof object];
      }
    },
    setSpecialityError: (state, action: PayloadAction<string>) => {
      state.errors.speciality = action.payload;
    },
    splicePhoneByIndex: (state, action: PayloadAction<number>) => {
      state.contactPhones.splice(action.payload, 1);
    },
    setPhoneByIndex: (
      state,
      action: PayloadAction<{
        index: number;
        value: string;
      }>
    ) => {
      state.contactPhones[action.payload.index] = action.payload.value;
    },
    setLpuSearchText: (state, action: PayloadAction<string>) => {
      state.lpuSearchText = action.payload;
    },
    setLpuList: (state, action: PayloadAction<any[]>) => {
      state.lpuList = action.payload;
    },
    addLpuField: (state) => {
      const mockLpu = {
        id: 0,
        name: '',
        deleted: false,
        address: {
          id: 0,
          placeName: '',
          district: '',
          street: '',
        },
      };

      if (state.medInstitutions) {
        state.medInstitutions.push(mockLpu);
      }
    },
    removeLpu: (state, action: PayloadAction<number>) => {
      const index = action.payload;

      if (state.medInstitutions) {
        state.medInstitutions.splice(index, 1);
      }
    },
    addLpuByIndex: (
      state,
      action: PayloadAction<{ value: any; index: number }>
    ) => {
      if (state.medInstitutions) {
        state.medInstitutions[action.payload.index] = action.payload.value;
      }
    },
    setLpuError: (
      state,
      action: PayloadAction<{ index: number; text: string }>
    ) => {
      state.errors.lpu[action.payload.index] = action.payload.text;
    },
    setBirthDayError: (state, action: PayloadAction<string>) => {
      state.errors.birthDay = action.payload;
    },
  },
});

export const {
  setLoading,
  setBirthDay,
  setContactPhones,
  setFirstName,
  setNote,
  setHasInstalledApp,
  setLastName,
  setMedInstitutions,
  setMiddleName,
  setSpecialtyId,
  setSpecialitySearchText,
  setSpecialities,
  clearStore,
  setSpecialityError,
  splicePhoneByIndex,
  setPhoneByIndex,
  setLpuList,
  addLpuField,
  removeLpu,
  addLpuByIndex,
  setLpuError,
  setPhoneErrors,
  setLpuSearchText,
  setBirthDayError,
} = addDoctorFormSlice.actions;

export const addDoctorFormReducer = addDoctorFormSlice.reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IChangelogPageState {
  searchName: string;
  isSearching: boolean;
  searchOpened: boolean;
}

const initialState: IChangelogPageState = {
  searchName: '',
  isSearching: false,
  searchOpened: false,
};

const changelogPageSlice = createSlice({
  name: 'changelog-page',
  initialState,
  reducers: {
    setSearchName: (state, action: PayloadAction<string>) => {
      state.searchName = action.payload;
    },
    setSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    setSearchOpened: (state, action: PayloadAction<boolean>) => {
      state.searchOpened = action.payload;
    },
  },
});

export const { setSearchName, setSearching, setSearchOpened } =
  changelogPageSlice.actions;
export const changelogPageReducer = changelogPageSlice.reducer;

import React from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import MonthYearPicker from '../../../../ui/MonthYearPicker';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import Switch from '../../../../ui/Switch';
import Input from '../../../../ui/Input';
import { ReferenceDynamicController } from '../../reference-dynamic.controller';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

interface IReferenceDynamicProps {
  classNames?: string[];
  onCloseModal: () => void;
}

export const ReferenceDynamic = (props: IReferenceDynamicProps) => {
  const referenceDynamic = useAppSelector((state) => state.referenceDynamic);
  const dispatch = useAppDispatch();
  const controller = new ReferenceDynamicController(dispatch);
  return (
    <div className={classNamesParser('reference-dynamic', props.classNames)}>
      <div className="reference-filters__heading">
        <h3 className="reference-filters__title">Динамика назначений</h3>
        <RoundButton
          onClick={props.onCloseModal}
          classNames={['reference-filters__close-modal']}
          content={<CloseIcon />}
        />
      </div>
      <div className="reference-dynamic__dynamic-container">
        <div className="reference-dynamic__wrapper">
          {/* <div className="reference-filters__dynamic-header">
            <span className="reference-filters__dynamic-title">
              Выберите значения
            </span>
            <Switch
              checked={referenceDynamic.isShowDynamicValues}
              onChange={controller.handleDynamicSwitchClick}
            />
          </div> */}
          <div className="reference-dynamic__dynamic-date-container">
            <span className="reference-dynamic__dynamic-date-title">
              Начальная дата
            </span>
            <MonthYearPicker
              onChange={controller.handleStartDateChange}
              date={referenceDynamic.startDate}
              //   disabled={!referenceDynamic.isShowDynamicValues}
            />
          </div>
          <div className="reference-dynamic__dynamic-date-container">
            <span className="reference-dynamic__dynamic-date-title">
              Конечная дата
            </span>
            <MonthYearPicker
              onChange={controller.handleEndDateChange}
              date={referenceDynamic.endDate}
              //   disabled={!referenceDynamic.isShowDynamicValues}
            />
          </div>
          <div className="reference-dynamic__dynamic-values">
            <Input
              value={referenceDynamic.dynamicFrom}
              inputStyles={{
                backgroundColor: 'white',
              }}
              label="Начальное значение, %"
              fullWidth
              type="tel"
              onInput={controller.handleDynamicFromChange}
              //   disabled={!referenceDynamic.isShowDynamicValues}
            />
            <Input
              value={referenceDynamic.dynamicTo}
              fullWidth
              type="tel"
              inputStyles={{
                backgroundColor: 'white',
              }}
              label="Конечное значение, %"
              onInput={controller.handleDynamicToChange}
              //   disabled={!referenceDynamic.isShowDynamicValues}
            />
          </div>
        </div>
      </div>
      <div className="reference-dynamic__buttons">
        <Button
          className="reference-dynamic__clear"
          onClick={controller.handleClearFilters}
        >
          Очистить
        </Button>
        <Button
          className="reference-dynamic__apply"
          onClick={() => controller.handleApplyClick(props.onCloseModal)}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskVariantTypes } from '../../../types/tasks.types';
import { ENVELOPE_VALUE_NAMES } from '../constants';
interface ICloseEnvelopsState {
  doctorIds: number[];
  meetupType: TaskVariantTypes;
  comment: string;
  isSuccessMeetup: string;
}

const initialState: ICloseEnvelopsState = {
  doctorIds: [],
  meetupType: 'envelope',
  isSuccessMeetup: ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
  comment: '',
};

export const closeEnvelopsForm = createSlice({
  name: 'close-envelops-form',
  initialState,
  reducers: {
    setDoctorIds: (state, action: PayloadAction<any[]>) => {
      state.doctorIds = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },

    setSuccessMeetup: (state, action: PayloadAction<string>) => {
      state.isSuccessMeetup = action.payload;
    },
    clearState: (state) => {
      state.doctorIds = [];
      state.meetupType = 'envelope';
      state.comment = '';
      state.isSuccessMeetup = ENVELOPE_VALUE_NAMES.ENVELOPE_DONE;
    },
  },
});

export const { setDoctorIds, setComment, clearState, setSuccessMeetup } =
  closeEnvelopsForm.actions;
export const closeEnvelopsFormReducer = closeEnvelopsForm.reducer;

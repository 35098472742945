import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISignInState {
  email: string;
  password: string;
  error: string;
}

const initialState: ISignInState = {
  email: '',
  password: '',
  error: '',
};

export const signInSlice = createSlice({
  name: 'sign-in',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },

    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },

    clearState: (state) => {
      state.email = '';
      state.password = '';
      state.error = '';
    },
  },
});

export const { setPassword, setEmail, setError, clearState } =
  signInSlice.actions;

export const signInReducer = signInSlice.reducer;

import { SelectChangeEvent } from '@mui/material';
import { ICustomizedHookItem } from '../../components/CustomizedHook/customized-hook.interfaces';
import { sleep } from '../../helpers/sleep';
import { ViewController } from '../../interfaces/general.interfaces';
import { CatalogsService } from '../../services/catalogs.service';
import { MeetupsService } from '../../services/meetups.service';
import { ReportsService } from '../../services/reports.service';
import {
  HistoryReportFilterDirectionEnum,
  HistoryReportSortParams,
  TableHeadEnum,
} from './enums';
import { IHistoryReportItem } from './interfaces';
import {
  clearCommentFilter,
  clearDateFilter,
  clearFeedbackFilter,
  clearList,
  clearMeetupType,
  clearSelectedLpu,
  clearSelectedSpecialty,
  deleteLpu,
  deleteSpecialty,
  setCommentFilter,
  setDateFilter,
  setDoctorSearchText,
  setFeedbackFilter,
  setFilter,
  setFilterDirection,
  setHaveMore,
  setList,
  setLoading,
  setLpuList,
  setLpuSearchText,
  setMeetupType,
  setOffset,
  setSearchedAgents,
  setSearchedDoctors,
  setAgentFilter,
  setSelectedDoctor,
  setSelectedFeedbackValue,
  setSelectedLpu,
  setSelectedSpecialty,
  setSpecialtyList,
  setSpecialtySearchText,
  clearAgentFilter,
} from './store/history-report.slice';
import { IAppSelectOption } from '../../ui/AppSelect/app-select.interface';
import { TaskVariantTypes } from '../../types/tasks.types';
import { UsersService } from '../../services/users.service';
import moment from 'moment';
import { getSortParamName } from './helpers';

export class HistoryReportController extends ViewController {
  meetupsService: MeetupsService = new MeetupsService();
  catalogsService: CatalogsService = new CatalogsService();
  usersService: UsersService = new UsersService();

  fetchReport = async () => {
    const state = this.getState().historyReport;
    if (state.isLoading || !state.haveMore) return;
    this.dispatch(setLoading(true));
    const dateFirst = moment(new Date());
    const dateLast = moment(new Date()).subtract(
      Number(state.dateFilter.value),
      'days'
    );
    const sortParam = getSortParamName(state.filter);
    const res = await this.meetupsService.getCompletedMeetups(
      state.limit,
      state.offset,
      null,
      null,
      null,
      state.selectedLpu ? [state.selectedLpu.id] : null,
      state.selectedSpecialty ? [state.selectedSpecialty.id] : null,
      !!state.meetupType?.value
        ? (state.meetupType.value as TaskVariantTypes)
        : null,
      state.selectedDoctor ? state.selectedDoctor.title : null,
      !!state.agentFilter.value ? [Number(state.agentFilter.value)] : null,
      !!state.dateFilter.value ? dateLast.format('YYYY-MM-DD') : null,
      !!state.dateFilter.value ? dateFirst.format('YYYY-MM-DD') : null,
      !!state.feedbackFilter.value ? true : null,
      !!state.commentFilter.value ? true : null,
      sortParam,
      state.filterDirection
    );
    if (res) {
      const data: IHistoryReportItem[] = res.data.map((item) => ({
        id: 0,
        fullname: item.doctorFullname,
        lpu: item.lpus?.[0]?.name || '',
        specialty: item.specialty?.name,
        date: item.meetupDate
          .split('-')
          .map((date) => date.slice(-2))
          .reverse()
          .join('.'),
        executor: item.agent?.fullName || '',
        feedback: item.feedbackComments?.[0]?.comment,
        comment: item.meetupComment || '',
        type: item.taskType,
        meetupStatus: item.meetupStatus,
      }));
      this.dispatch(setList(data));
      const haveMore = res.data.length + state.list.length < res.total;
      this.dispatch(setHaveMore(haveMore));
      this.dispatch(setOffset(state.offset + state.limit));
    } else {
      this.dispatch(setHaveMore(false));
    }
    this.dispatch(setLoading(false));
  };

  handleFilterClick = (filter: HistoryReportSortParams) => {
    const state = this.getState().historyReport;
    const isCurrentFilter = filter === state.filter;

    if (isCurrentFilter) {
      this.dispatch(
        setFilterDirection(
          state.filterDirection === HistoryReportFilterDirectionEnum.ASC
            ? HistoryReportFilterDirectionEnum.DESC
            : HistoryReportFilterDirectionEnum.ASC
        )
      );
    } else {
      this.dispatch(setFilter(filter));
      this.dispatch(setFilterDirection(HistoryReportFilterDirectionEnum.ASC));
    }
    this.refreshList();
  };

  fetchSpecialties = async () => {
    const store = this.getState().historyReport;
    try {
      const response = await this.catalogsService.getSpecialities(
        false,
        store.specialtySearchText,
        null,
        null,
        true
      );
      const formattedData = response?.data?.map<ICustomizedHookItem>(
        (item) => ({
          id: item.id,
          title: String(item?.name),
        })
      );
      this.dispatch(setSpecialtyList(formattedData as ICustomizedHookItem[]));
    } catch (e) {
      console.error(e);
    }
  };

  fetchDoctors = async () => {
    const store = this.getState().historyReport;
    try {
      const response = await this.catalogsService.getDoctorsList(
        null,
        null,
        null,
        null,
        null,
        null,
        store.doctorSearchText
      );

      const formattedData = response?.data?.map<ICustomizedHookItem>(
        (item: any) => ({
          id: item.id,
          title: String(
            `${item.lastName || ''} ${item.firstName || ''} ${
              item.middleName || ''
            }`
          ),
        })
      );
      this.dispatch(setSearchedDoctors(formattedData as ICustomizedHookItem[]));
    } catch (e) {
      console.error(e);
    }
  };

  fetchLpuList = async () => {
    const searchText = this.getState().historyReport.lpuSearchText;

    try {
      const response = await this.catalogsService.getLpuList(
        null,
        searchText,
        null,
        null,
        null,
        true
      );

      if (response) {
        const formattedList = response.data.map((item) => ({
          ...item,
          title: item.name,
        }));

        this.dispatch(setLpuList(formattedList));
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchAgents = async () => {
    const store = this.getState().historyReport;
    try {
      const response = await this.usersService.getUsers(
        null,
        null,
        null,
        null,
        ['medical_agent']
      );
      const formattedData: IAppSelectOption[] = response?.data?.map(
        (item: any) => ({
          id: item.id,
          text: String(item?.fullName),
          value: item.id,
        })
      );
      this.dispatch(setSearchedAgents(formattedData));
    } catch (e) {
      console.error(e);
    }
  };

  handleSpecialtyInput = (event: any) => {
    this.dispatch(setSpecialtySearchText(event.target.value));
  };

  handleLpuInput = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    this.dispatch(setLpuSearchText(value));
  };

  handleDoctorInput = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    this.dispatch(setDoctorSearchText(value));
  };

  onSpecialtyChange = (item: ICustomizedHookItem) => {
    this.dispatch(setSelectedSpecialty({ id: item.id, title: item.title }));
    this.dispatch(setSpecialtySearchText(''));
    this.fetchSpecialties();
    this.refreshList();
  };

  onLpuChange = (item: ICustomizedHookItem) => {
    this.dispatch(setSelectedLpu({ id: item.id, title: item.title }));
    this.dispatch(setLpuSearchText(''));
    this.fetchLpuList();
    this.refreshList();
  };

  onDoctorChange = (item: ICustomizedHookItem) => {
    this.dispatch(setSelectedDoctor({ id: item.id, title: item.title }));
    this.refreshList();
  };

  handleAgentFilterChange = (item: IAppSelectOption) => {
    this.dispatch(setAgentFilter(item));
    this.refreshList();
  };

  onSpecialtyDelete = () => {
    this.dispatch(clearSelectedSpecialty());
    this.refreshList();
  };

  onLpuDelete = () => {
    this.dispatch(clearSelectedLpu());
    this.refreshList();
  };

  handleMeetupTypeChange = (item: IAppSelectOption) => {
    this.dispatch(setMeetupType(item));
    this.refreshList();
  };

  handleDateFilterChange = (item: IAppSelectOption) => {
    this.dispatch(setDateFilter(item));
    this.refreshList();
  };

  handleCommentFilterChange = (item: IAppSelectOption) => {
    this.dispatch(setCommentFilter(item));
    this.refreshList();
  };
  handleFeedbackFilterChange = (item: IAppSelectOption) => {
    this.dispatch(setFeedbackFilter(item));
    this.refreshList();
  };

  refreshList = () => {
    this.dispatch(clearList());
    this.fetchReport();
  };

  onDoctorDelete = () => {
    this.dispatch(setDoctorSearchText(''));
    this.dispatch(setSelectedDoctor(null));
    this.dispatch(setSearchedDoctors([]));
    this.refreshList();
  };

  onAgentFilterClear = () => {
    this.dispatch(clearAgentFilter());
    this.refreshList();
  };
  onMeetupTypeClear = () => {
    this.dispatch(clearMeetupType());
    this.refreshList();
  };
  onFeedbackFilterClear = () => {
    this.dispatch(clearFeedbackFilter());
    this.refreshList();
  };
  onCommentFilterClear = () => {
    this.dispatch(clearCommentFilter());
    this.refreshList();
  };
  onDateFilterClear = () => {
    this.dispatch(clearDateFilter());
    this.refreshList();
  };
}

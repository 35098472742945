import React from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';
import ColorCounter from '../../ui/ColorCounter';
import GridViewIcon from '@mui/icons-material/GridView';

interface ISidebarMenuItemProps {
  classNames?: string[];
  text?: string;
  count?: string | number;
  counterColor?: string;
  isActive?: boolean;
  onClick?: (event: any) => void;
  icon?: JSX.Element;
}

const SidebarMenuItem = (props: ISidebarMenuItemProps) => {
  const classNames = props.classNames ? props.classNames : [];
  const activeClass = props.isActive ? 'sidebar-menu-item_active' : '';

  return (
    <div
      onClick={props.onClick}
      className={classNamesParser('sidebar-menu-item', [
        ...classNames,
        activeClass,
      ])}
    >
      {props.icon ? (
        props.icon
      ) : (
        <GridViewIcon className="sidebar-menu-item__icon" />
      )}
      <span className="sidebar-menu-item__text">{props.text}</span>
      {!!props.count && (
        <ColorCounter
          value={props.count}
          backgroundColor={props.counterColor}
        />
      )}
    </div>
  );
};

export default SidebarMenuItem;

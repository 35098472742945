import { REFERENCE_TYPES } from '../index';
import {
  IApiFormattedDoctorItem,
  IApiLpuItem,
  IApiSpecialityItem,
} from '../../../interfaces/api.catalogs.interfaces';

export const formCardDataByType = (
  tabType: string,
  itemData: IApiSpecialityItem | IApiLpuItem | IApiFormattedDoctorItem | any
) => {
  let result: any = {};

  if (tabType === REFERENCE_TYPES.SPECIALITY) {
    result.id = itemData.id;
    result.name = itemData.name;
    result.deleted = itemData.deleted;
  }

  if (tabType === REFERENCE_TYPES.LPU) {
    result.id = itemData.id;
    result.name = itemData.name;
    result.deleted = itemData.deleted;
    result.address = itemData.address;
  }

  if (tabType === REFERENCE_TYPES.DOCTORS) {
    result.id = itemData.id;
  }

  return result;
};

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, debounce, useMediaQuery } from '@mui/material';
import { Form } from 'react-router-dom';
import Input from '../../../../ui/Input';
import AppAutocomplete from '../../../../components/AppAutocomplete';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { AddDoctorFormController } from '../../add-doctor-form.controller';
import {
  addLpuField,
  clearStore,
  setContactPhones,
} from '../../store/add-doctor-form.slice';
import { MAX_LPU_COUNT, MAX_PHONE_COUNT } from '../../constants';
import { showFormAddIcon } from '../../helpers';
import { store } from '../../../../app.store';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import AppSelect from '../../../../ui/AppSelect';
import { MEDIA } from '../../../../constants/app.constants';
import AutoCompleteSearchList from '../../../../components/AutoCompleteSearchList';
import MobileEditContactsForm from '../../../../components/MobileEditContactsForm';

interface IAddDoctorFormProps {
  classNames?: string[];
  onCloseModal?: () => void;
}

export const AddDoctorForm = (props: IAddDoctorFormProps) => {
  const dispatch = useAppDispatch();
  const addDoctorForm = useAppSelector((state) => state.addDoctorForm);
  const controller = new AddDoctorFormController(dispatch);
  const [birthdayFocused, setBirthdayFocused] = useState(false);
  const matchSm = useMediaQuery(`(max-width: ${MEDIA.SM}px)`);
  const matchXsm = useMediaQuery(`(max-width:${MEDIA.XSM}px)`);
  const [isMobileLpuOpen, setIsMobileLpuOpen] = useState(false);
  const [isMobileSpecialtiesOpen, setIsMobileSpecialtiesOpen] = useState(false);
  const [isMobileContactsOpen, setIsMobileContactsOpen] = useState(false);
  const [mobileLpuId, setMobileLpuId] = useState<number | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    dispatch(setContactPhones(['']));
    dispatch(addLpuField());
    controller.fetchSpecialities();

    return () => {
      dispatch(clearStore());
    };
  }, []);

  const handleSpecialtyInput = debounce(controller.handleSpecialtyInput, 500);
  const handleLpuInput = debounce(controller.handleLpuInput, 500);

  const hasLpuErrors = () => {
    const errorsArray = store.getState().addDoctorForm.errors.lpu;
    return !!errorsArray.join('').length;
  };

  const anyNamesEmpty = () =>
    !addDoctorForm.firstName || !addDoctorForm.lastName;

  const onBirthdateFocus = () => setBirthdayFocused(true);
  const onBirthdateBlur = () => setBirthdayFocused(false);

  const hasMobileAppValues: IAppSelectOption[] = [
    {
      id: 1,
      text: 'Использует',
      value: String(true),
    },
    {
      id: 2,
      text: 'Не использует',
      value: String(false),
    },
  ];

  const handleFocusSpecialty = async () => {
    setIsMobileSpecialtiesOpen(true);
  };

  const handleLpuFocus = (event: any, i: number) => {
    controller.handleLpuFocus(event, i);
    if (!matchXsm) return;
    setMobileLpuId(i);
    setIsMobileLpuOpen(true);
  };

  const handleCloseMobileSearch = () => {
    setIsMobileLpuOpen(false);
    setIsMobileSpecialtiesOpen(false);
    setIsMobileContactsOpen(false);
    setMobileLpuId(null);
  };

  const handleMobileContactsClick = () => {
    setIsMobileContactsOpen(true);
  };

  const handleMobileContactsSave = (contacts: string[]) => {
    handleCloseMobileSearch();
    controller.handleMobileContactsSave(!!contacts.length ? contacts : ['']);
  };

  return (
    <div className={classNamesParser('add-doctor-form', props.classNames)}>
      <div className="add-doctor-form__heading">
        <h3 className="add-doctor-form__title">Добавить врача</h3>
        <RoundButton
          classNames={['add-doctor-form__close-modal']}
          onClick={props.onCloseModal}
          content={<CloseIcon />}
        />
      </div>
      <Form
        ref={formRef}
        style={{
          marginBottom: 24,
          overflowY: 'scroll',
        }}
      >
        <Input
          placeholder="Фамилия"
          classNames={['add-doctor-form__form-input']}
          fullWidth
          value={addDoctorForm.lastName}
          onInput={controller.handleLastNameChange}
        />
        <Input
          placeholder="Имя"
          classNames={['add-doctor-form__form-input']}
          fullWidth
          value={addDoctorForm.firstName}
          onInput={controller.handleFirstNameChange}
        />
        <Input
          placeholder="Отчество"
          classNames={['add-doctor-form__form-input']}
          fullWidth
          value={addDoctorForm.middleName}
          onInput={controller.handleMiddleNameChange}
        />
        {/*<AppDatePicker*/}
        {/*  classNames={['add-doctor-form__form-input']}*/}
        {/*  placeholder={'Дата рождения'}*/}
        {/*  value={*/}
        {/*    addDoctorForm?.birthDay ? moment(addDoctorForm?.birthDay) : moment()*/}
        {/*  }*/}
        {/*  onChange={controller.handleBirthDayChange}*/}
        {/*  format="YYYY-MM-DD"*/}
        {/*  onClear={controller.handleBirthDayClear}*/}
        {/*/>*/}
        <Input
          placeholder={birthdayFocused ? 'ДД.ММ.ГГГГ' : 'Дата рождения'}
          classNames={['add-doctor-form__form-input']}
          value={addDoctorForm?.birthDay || ''}
          onInput={controller.handleBirthDayChange}
          error={addDoctorForm.errors.birthDay}
          onFocus={onBirthdateFocus}
          onBlur={onBirthdateBlur}
          showCloseIcon={!!addDoctorForm?.birthDay?.length}
          onIconClick={controller.handleBirthDayClear}
          fullWidth
        />
        <AppAutocomplete
          placeholder="Выберите специальность"
          classNames={['add-doctor-form__form-input']}
          onInput={handleSpecialtyInput}
          onChange={(event, newValue, reason) =>
            controller.handleSpecialtyChange(event, newValue, reason)
          }
          onFocus={handleFocusSpecialty}
          value={addDoctorForm.specialitySearchText}
          options={addDoctorForm.specialities}
          error={addDoctorForm.errors.speciality}
        />
        {matchXsm && (
          <Button
            className={`add-doctor-form__mobile-phone-container ${
              addDoctorForm.contactPhones.length > 0 &&
              !!addDoctorForm.contactPhones[0]
                ? 'add-doctor-form__mobile-phone-container_have-value'
                : ''
            }`}
            onClick={handleMobileContactsClick}
          >
            {addDoctorForm.contactPhones.length === 1 &&
            addDoctorForm.contactPhones[0] === ''
              ? 'Добавить контакт'
              : addDoctorForm.contactPhones.join(', ')}
          </Button>
        )}
        {!matchXsm &&
          addDoctorForm.contactPhones.map((item, i, arr) => {
            const isLast = i === arr.length - 1;

            return (
              <div className="add-doctor-form__phone-container">
                <Input
                  placeholder="Добавить контакт"
                  classNames={[
                    'add-doctor-form__form-input',
                    'add-doctor-form__phone-input',
                  ]}
                  error={addDoctorForm.errors.phoneErrors[i]}
                  value={item}
                  onInput={(event: ChangeEvent<HTMLInputElement>) =>
                    controller.handlePhoneChange(event, i)
                  }
                  focusAfterRender={i > 0}
                  showCloseIcon
                  onIconClick={() => controller.handleRemovePhoneClick(i)}
                />
                {isLast &&
                  i + 1 < MAX_PHONE_COUNT &&
                  showFormAddIcon(i, arr) && (
                    <RoundButton
                      content={'+'}
                      classNames={['add-doctor-form__add-phone']}
                      onClick={controller.handleAddPhoneClick}
                    />
                  )}
              </div>
            );
          })}
        {addDoctorForm.medInstitutions.map((lpu, i, arr) => {
          const isLast = i === arr.length - 1;

          return (
            <div className="add-doctor-form__lpu-container">
              <AppAutocomplete
                placeholder="Добавить ЛПУ"
                classNames={['add-doctor-form__form-input']}
                value={lpu.name}
                onInput={(event: any) => handleLpuInput(event, i)}
                onFocus={(event: any) => handleLpuFocus(event, i)}
                options={addDoctorForm.lpuList}
                onChange={(event: any, newValue: any, reason: any) =>
                  controller.handleLpuChange(event, newValue, i, reason)
                }
                error={addDoctorForm.errors.lpu[i]}
              />
              {isLast &&
                i + 1 < MAX_LPU_COUNT &&
                showFormAddIcon(i, arr, true) && (
                  <RoundButton
                    content={'+'}
                    classNames={['add-doctor-form__add-phone']}
                    onClick={controller.handleAddLpuClick}
                  />
                )}
            </div>
          );
        })}
        <AppSelect
          placeholder="Наличие мобильного приложения"
          label="Наличие мобильного приложения"
          renderText
          handleChange={controller.handleHasInstalledAppChange}
          options={hasMobileAppValues}
          value={String(addDoctorForm.hasInstalledApp)}
        />
      </Form>
      {matchXsm && isMobileLpuOpen && (
        <AutoCompleteSearchList
          options={addDoctorForm.lpuList}
          className="add-doctor-form__mobile-search"
          inputValue={addDoctorForm.lpuSearchText}
          showListAcceptButton
          onInput={(event) =>
            controller.handleLpuInput(event, mobileLpuId || 0)
          }
          onChange={(newValue) =>
            controller.handleLpuChange(null, newValue, mobileLpuId || 0)
          }
          onListAcceptButtonClick={handleCloseMobileSearch}
          selectedOptions={[addDoctorForm.medInstitutions?.[mobileLpuId || 0]]}
        />
      )}

      {matchXsm && isMobileSpecialtiesOpen && (
        <AutoCompleteSearchList
          options={addDoctorForm.specialities}
          className="add-doctor-form__mobile-search"
          inputValue={addDoctorForm.specialitySearchText}
          showListAcceptButton
          onInput={controller.handleSpecialtyInput}
          onChange={(value) => controller.handleSpecialtyChange(null, value)}
          onListAcceptButtonClick={handleCloseMobileSearch}
          selectedOptions={[{ id: addDoctorForm.specialityId }]}
        />
      )}

      {matchXsm && isMobileContactsOpen && (
        <MobileEditContactsForm
          classNames={['add-doctor-form__mobile-contacts']}
          contacts={addDoctorForm.contactPhones}
          onSave={handleMobileContactsSave}
          onClose={handleCloseMobileSearch}
        />
      )}
      <div className="add-doctor-form__buttons">
        <Button
          className="add-doctor-form__cancel"
          onClick={props.onCloseModal}
        >
          Отмена
        </Button>
        <Button
          className="add-doctor-form__save"
          onClick={controller.handleSaveClick}
          disabled={
            hasLpuErrors() ||
            !!addDoctorForm.errors.speciality ||
            anyNamesEmpty()
          }
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

import React from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { Form } from 'react-router-dom';
import Input from '../../../../ui/Input';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { AddSpecialityFormController } from '../../add-speciality-form.controller';

interface IAddLpuFormProps {
  classNames?: string[];
  onCloseModal?: () => void;
}

export const AddSpecialityForm = (props: IAddLpuFormProps) => {
  const addSpecialityForm = useAppSelector((state) => state.addSpecialityForm);
  const dispatch = useAppDispatch();
  const controller = new AddSpecialityFormController(dispatch);

  const onCancel = () => {
    props.onCloseModal && controller.onCancelClick(props.onCloseModal);
  };

  const onSave = () => {
    props.onCloseModal && controller.onSaveClick(props.onCloseModal);
  };

  return (
    <div className={classNamesParser('add-speciality-form', props.classNames)}>
      <div className="add-speciality-form__heading">
        <h3 className="add-speciality-form__title">Добавить специальность</h3>
        <RoundButton
          classNames={['add-speciality-form__close-modal']}
          onClick={props.onCloseModal}
          content={<CloseIcon />}
        />
      </div>
      <Form style={{ marginBottom: 24 }}>
        <Input
          value={addSpecialityForm.name}
          onInput={controller.handleNameChange}
          placeholder="Наименование"
          classNames={['add-speciality-form__form-input']}
          fullWidth
        />
      </Form>
      <div className="add-speciality-form__buttons">
        <Button className="add-speciality-form__cancel" onClick={onCancel}>
          Отмена
        </Button>
        <Button onClick={onSave} className="add-speciality-form__save">
          Сохранить
        </Button>
      </div>
    </div>
  );
};

import { createTheme } from '@mui/material/styles';

const MuiTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ':disabled': {
            color: 'rgb(255 255 255 / 25%) !important',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiTab: {
      defaultProps: {
        style: {
          fontWeight: 600,
          color: 'black',
          fontFamily: 'Nunito, sans-serif',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { borderRadius: 8, padding: 0 },
        // list: { paddingLeft: 12, paddingRight: 12 },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 15,
          fontFamily: 'Nunito, sans-serif',
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          outline: 'none',
          border: 'none',
          backgroundColor: '#f3f3f3',
          borderRadius: 6,
          height: 31,
          minHeight: 31,
        },
        notchedOutline: {
          outline: 'none',
          border: 'none',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        style: {
          height: 47,
          overflow: 'hidden',
          borderRadius: 6,
          width: '100%',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          color: 'white',
        },
        filled: {
          borderRadius: 6,
          backgroundColor: '#D9D9D9',
          height: 28,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 0,
          backgroundColor: '#F3F3F3',
          borderRadius: 6,
        },
        input: {
          height: 32,
          padding: '7.5px 4px 7.5px 14px !important',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: 'Nunito, sans-serif',
          fontWeight: 400,
          fontSize: 15,
          color: 'black',
          opacity: 0.9,
          letterSpacing: 'unset',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito, sans-serif',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Nunito, sans-serif',
          fontSize: 14,
        },
      },
    },
  },
});

export default MuiTheme;

import { TASKS } from '../api/api';
import axios from '../axios/axios';
import { IUpdateTaskBody } from '../interfaces/api.tasks.interfaces';
import { TaskStatusTypes, TaskVariantTypes } from '../types/tasks.types';
import { ITaskExecutor } from '../interfaces/tasks.interfaces';

export class TaskService {
  getTaskList = async (
    limit?: number,
    offset?: number,
    status?: TaskStatusTypes
  ) => {
    let result = null;
    try {
      const response = await axios.get(TASKS.GET_TASKS_LIST, {
        params: {
          limit,
          offset,
          status,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getTaskByID = async (id: number) => {
    let result = null;
    try {
      const response = await axios.get(`${TASKS.GET_TASK}/${id}`);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };
  deleteTask = async (id: number) => {
    let result = null;
    try {
      const response = await axios.delete(`${TASKS.DELETE_TASK}/${id}`);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };
  updateTask = async (id: number, body: IUpdateTaskBody) => {
    let result = null;
    try {
      const response = await axios.patch(`${TASKS.UPDATE_TASK}/${id}`, body);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  addDoctorToTask = async (taskID: number, doctorID: number) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${TASKS.ADD_DOCTOR_TASK}/${taskID}/doctor-add`,
        {},
        {
          params: {
            doctorID,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  removeDoctorFromTask = async (taskID: number, doctorID: number) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${TASKS.REMOVE_DOCTOR_TASK}/${taskID}/doctor-remove`,
        {},
        {
          params: {
            doctorID,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  getDoctors = async (
    taskID: number,
    limit: number,
    offset: number,
    orderBy: 'lpu_name' | 'specialty_name' | 'doctor_fullname',
    searchByDoctor: string | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${TASKS.GET_DOCTORS}/${taskID}/doctors`,
        {
          params: {
            limit,
            offset,
            orderBy,
            searchByDoctor,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  addExecutor = async (taskID: number, executorID: number) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${TASKS.ADD_EXECUTOR}/${taskID}/executor-add`,
        {},
        {
          params: {
            executorID,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };
  removeExecutor = async (taskID: number, executorID: number) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${TASKS.REMOVE_EXECUTOR}/${taskID}/executor-remove`,
        {},
        {
          params: {
            executorID,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  createTask = async (
    type: TaskVariantTypes,
    doctors: number[],
    description?: string,
    executors?: string[]
  ) => {
    let result = null;
    try {
      const response = await axios.post(TASKS.CREATE_TASK, {
        type,
        doctors,
        description,
        executors,
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };
}

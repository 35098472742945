import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import {
  setLoading,
  setUsers,
  setTabValue,
  setIsAddModalOpen,
  setIsEditModalOpen,
  setHaveMore,
  setOffset,
  setCurrentUser,
  setCurrentUserLoading,
  pushUsers,
  updateUser,
  deleteUserById,
} from './store/users-list.slice';
import { UsersService } from '../../services/users.service';
import { UserActiveTypes, UserRolesTypes } from '../../types/users.types';
import { ICreateUserBody, IEditUserBody } from './index.interfaces';

export class UsersListController {
  dispatch: AppDispatch;
  getState: () => RootState;
  usersListService: UsersService = new UsersService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  handleTabChange = (_: any, value: UserActiveTypes) => {
    this.dispatch(setTabValue(value));
    this.refreshList();
  };

  refreshList = () => {
    this.dispatch(setOffset(0));
    this.dispatch(setUsers([]));
    this.dispatch(setHaveMore(true));
    this.getUsers();
  };

  openAddModal = () => {
    this.dispatch(setIsAddModalOpen(true));
  };

  closeAddModal = () => {
    this.dispatch(setIsAddModalOpen(false));
  };

  openEditModal = () => {
    this.dispatch(setIsEditModalOpen(true));
  };

  onUserClick = (id: number) => {
    this.getUserById(id);
    this.openEditModal();
  };

  closeEditModal = () => {
    this.dispatch(setIsEditModalOpen(false));
  };

  getUsers = async () => {
    const state = this.getState().usersList;
    if (state.isLoading || !state.haveMore) return;
    this.dispatch(setLoading(true));
    const data = await this.usersListService.getUsers(
      state.limit,
      state.offset,
      state.tabValue
    );

    if (data) {
      const haveMore = data.total > data.data.length + state.users.length;
      this.dispatch(pushUsers(data.data));
      this.dispatch(setOffset(state.offset + state.limit));
      this.dispatch(setHaveMore(haveMore));
    } else {
      this.dispatch(setHaveMore(false));
    }
    this.dispatch(setLoading(false));
  };

  getUserById = async (id: number) => {
    this.dispatch(setCurrentUserLoading(true));
    const data = await this.usersListService.getUserById(id);
    if (data) {
      this.dispatch(setCurrentUser(data));
    } else {
    }
    this.dispatch(setCurrentUserLoading(false));
  };

  onEditUser = async (
    id: number,
    fullName: string | null,
    phoneNumber: string | null,
    email: string | null,
    login: string | null,
    role: UserRolesTypes | null
  ) => {
    const data = await this.usersListService.editUser(id, {
      fullName,
      contactPhone: phoneNumber,
      email,
      login,
      role,
    });

    if (data) {
      this.dispatch(updateUser(data));
      this.dispatch(setCurrentUser(data));
    }
    // this.refreshList();

    return data;
  };

  onCreateUser = async (
    fullName: string,
    email: string,
    login: string,
    password: string,
    contactPhone: string,
    role: UserRolesTypes
  ) => {
    const user: ICreateUserBody = {
      fullName,
      email,
      login,
      password,
      role,
      contactPhone,
    };
    const data = await this.usersListService.createUser(user);
    if (data) {
      this.closeAddModal();
    }
    this.refreshList();
    return data;
  };

  onChangeUserState = async (id: number, state: UserActiveTypes) => {
    const data = await this.usersListService.setUserState(id, state);
    if (data) {
      this.dispatch(setCurrentUser(data));
    }
    this.refreshList();
  };
  onDeleteUser = async (id: number) => {
    const data = await this.usersListService.deleteUser(id);
    if (data !== null) {
      this.dispatch(deleteUserById(id));
    }
    this.closeEditModal();
  };

  onPasswordChange = async (id: number, password: string) => {
    const data = await this.usersListService.changePassword(id, password);

    return data;
  };
}

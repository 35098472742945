import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useDeferredValue,
  useCallback,
  useRef,
} from 'react';
import RoundButton from '../../../../ui/RoundButton';
import { ReactComponent as SearchIcon } from '../../../../assets/vector/search.svg';
import Input from '../../../../ui/Input';
import { Close } from '@mui/icons-material';
import { useAppDispatch } from '../../../../hooks/redux';
import { UploadTableController } from '../../upload-table.controller';
import { debounce } from '@mui/material';

interface IDoctorSearchButton {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
}

const DoctorSearchButton = (props: IDoctorSearchButton) => {
  const dispatch = useAppDispatch();
  const controller = new UploadTableController(dispatch);
  const [value, setValue] = useState('');
  const width = props.open ? '100%' : 0;
  const padding = props.open ? '0 4px 0px 4px' : '0';
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchDoctors = useCallback((value: string) => {
    controller.onSearchDoctors(value);
  }, []);

  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchDoctors, 500);
  }, [handleSearchDoctors]);

  const onSearchBtnClick = () => {
    inputRef.current && inputRef.current.focus();
    props.onOpen();
  };

  return (
    <div
      className="doctor-search-button"
      style={{ width: props.open ? 'calc(100% - 12px)' : '32px' }}
    >
      <RoundButton
        content={<SearchIcon />}
        classNames={['doctor-search-button__round-btn']}
        onClick={onSearchBtnClick}
      />
      <div className="doctor-search-button__input-wrapper">
        <input
          autoFocus
          ref={inputRef}
          value={value}
          onChange={onInputChange}
          style={{ width, padding }}
          placeholder="Введите ФИО"
          className={'doctor-search-button__input'}
        />
        {props.open && (
          <RoundButton
            classNames={['doctor-search-button__round-btn']}
            onClick={props.onClose}
            content={<Close className="doctor-search-button__close-icon" />}
          />
        )}
      </div>
    </div>
  );
};

export default DoctorSearchButton;

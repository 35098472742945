import { AppDispatch, RootState, store } from '../../app.store';
import {
  setData,
  setIsEdit,
  setLoading,
  setName,
} from './store/edit-speciality-form.slice';
import { ChangeEvent } from 'react';
import { CatalogsService } from '../../services/catalogs.service';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { showAppSnackBar } from '../../store/app.slice';
export class EditSpecialityFormController {
  dispatch: AppDispatch;
  getState: () => RootState;
  catalogsService: CatalogsService = new CatalogsService();

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  onEditClick = () => {
    const store = this.getState().editSpecialityForm;
    this.dispatch(setIsEdit(!store.isEdit));
  };

  handleEditName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.dispatch(setName(value));
  };

  onSaveClick = async (callback?: () => void) => {
    this.dispatch(setLoading(true));

    const store = this.getState().editSpecialityForm;

    try {
      await this.catalogsService.updateSpeciality(store.id as number, {
        name: store.name,
      });

      // callback && callback();
      this.dispatch(setIsEdit(false));
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
    } catch (e: any) {
      console.error(e.message);
    }

    this.dispatch(setLoading(false));
  };

  onDeleteClick = async (callback?: () => void) => {
    this.dispatch(setLoading(true));
    const specialityID = this.getState().editSpecialityForm.id;
    const success = await this.catalogsService.deleteSpeciality(
      specialityID as number
    );
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
    if (success) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      callback && callback();
    } else {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось удалить специальность',
        })
      );
    }
  };

  onMenuClick = async (value: string, callback?: () => void) => {
    if (value.toLowerCase() === 'удалить') {
      await this.onDeleteClick(callback);
      return;
    }
    if (value.toLowerCase() === 'активировать') {
      await this.onActivateClick();
      return;
    } else await this.onDeactivateClick();
  };

  fetchSpecialityById = async (id: number) => {
    const response = await this.catalogsService.getSpeciality(id);
    if (response) {
      console.log(response);
      this.dispatch(setData(response));
    }
  };

  handleCancelEditClick = async () => {
    const state = this.getState().editSpecialityForm;
    if (state.id) await this.fetchSpecialityById(state.id);
    this.dispatch(setIsEdit(false));
  };

  onDeactivateClick = async () => {
    const specialityID = this.getState().editSpecialityForm.id;
    const data = await this.catalogsService.updateSpeciality(
      specialityID as number,
      { archived: true }
    );

    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      this.fetchSpecialityById(specialityID as number);
    } else {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось деактивировать специальность',
        })
      );
    }
  };

  onActivateClick = async () => {
    const specialityID = this.getState().editSpecialityForm.id;
    const data = await this.catalogsService.updateSpeciality(
      specialityID as number,
      { archived: false }
    );

    if (data !== null) {
      EventEmitter.emit(EMITTER_EVENTS.UPDATE_CATALOG);
      this.fetchSpecialityById(specialityID as number);
    } else {
      this.dispatch(
        showAppSnackBar({
          snackBarSeverity: 'error',
          text: 'Не удалось активировать специальность',
        })
      );
    }
  };
}

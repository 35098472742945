export const showFormAddIcon = (
  index: number,
  data: any[],
  isLpu?: boolean
) => {
  let result = true;

  if (data.length === 1 && !data[0].length && !isLpu) {
    result = false;
  }

  if (isLpu && data.length === 1 && !data[0].name) {
    result = false;
  }

  return result;
};

import React, { useEffect } from 'react';
import Input from '../../ui/Input';
import CheckBox from '../../ui/CheckBox';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { Button, Chip, Menu, MenuItem, Skeleton } from '@mui/material';
import RoundButton from '../../ui/RoundButton';
import RoundMenuButton from '../../ui/RoundMenuButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import { EditUserFormController } from '../../controllers/edit-user-form.controller';
import { IUserItem } from '../../modules/UsersList/index.interfaces';
import { UserActiveTypes, UserRolesTypes } from '../../types/users.types';
import { getRoleByKey } from '../../helpers/getRoleByKey';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorAlert from '../../ui/ErrorAlert';
import AppModal from '../AppModal';

interface IEditUserFormProps {
  onClose?: () => void;
  onConfirm?: (
    id: number,
    fullName: string | null,
    phoneNumber: string | null,
    email: string | null,
    login: string | null,
    role: UserRolesTypes | null
  ) => Promise<any>;
  onPasswordChange?: (id: number, password: string) => Promise<any>;
  onDelete?: (id: number) => void;
  onChangeState?: (id: number, state: UserActiveTypes) => void;
  isLoading: boolean;
  user?: IUserItem;
}

const roundButtonItems = [
  {
    id: 1,
    text: 'Активировать',
    color: '#263238',
  },
  {
    id: 2,
    text: 'Удалить',
    color: '#FF4C06',
  },
];

const EditUserForm = (props: IEditUserFormProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const editUserForm = useAppSelector((state) => state.editUserForm);
  const controller = new EditUserFormController(dispatch);

  useEffect(() => {
    controller.clearState();
    props.user && controller.setUser(props.user);
    controller.handleCancelEdit();
  }, [props.user]);

  const handleCancelEdit = () => {
    props.user && controller.setUser(props.user);
    controller.handleCancelEdit();
  };
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement | HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = async () => {
    if (!controller.hasEmptyFields() && props.onConfirm && props.user)
      controller.onEditUser(
        await props.onConfirm(
          props.user?.id,
          editUserForm.fullName,
          editUserForm.phoneNumber,
          editUserForm.email,
          editUserForm.login,
          editUserForm.role
        )
      );
  };

  const handleDeleteUser = async () => {
    props.onDelete && props.user && props.onDelete(props.user.id);
  };

  const handleSetUserActive = async () => {
    props.onChangeState &&
      props.user &&
      props.onChangeState(props.user?.id, 'active');

    handleMenuClose();
  };
  const handleSetUserUnactive = async () => {
    props.onChangeState &&
      props.user &&
      props.onChangeState(props.user?.id, 'not_active');
    handleMenuClose();
  };

  const handlePasswordChange = async () => {
    if (controller.validatePassword() && props.onPasswordChange && props.user)
      controller.onPasswordChange(
        await props.onPasswordChange(props.user.id, editUserForm.password)
      );
  };

  return (
    <div className="edit-user-form">
      <div className="edit-user-form__header">
        <h3 className="edit-user-form__title">
          {editUserForm.isEditing ? 'Редактирование' : 'Пользователь'}
        </h3>
        {!editUserForm.isEditing && (
          <>
            {props.user?.state === 'not_active' && (
              <Chip
                label="Запись не активна"
                className="edit-user-form__chip"
              />
            )}
            <div className="edit-user-form__header-controls">
              <RoundButton
                classNames={['edit-user-form__more-btn']}
                onClick={handleMenuOpen}
                content={<MoreHorizIcon />}
              />
              {/* <RoundMenuButton
                classNames={['edit-user-form__more-btn']}
                menuItems={roundButtonItems}
              /> */}
              <RoundButton
                content={<EditNoteIcon />}
                classNames={['edit-user-form__edit-btn']}
                onClick={controller.handleEdit}
              />
              <RoundButton
                classNames={['edit-user-form__close-btn']}
                onClick={props.onClose}
                content={<CloseIcon />}
              />
            </div>
          </>
        )}
      </div>
      {props.user?.state === 'not_active' && (
        <Chip
          label="Запись не активна"
          className="edit-user-form__mobile-chip"
        />
      )}
      {props.isLoading ? (
        <div className="edit-user-form__loaders">
          <Skeleton height={48} width={'100%'} />
          <Skeleton height={48} width={'100%'} />
          <Skeleton height={48} width={'100%'} />
          <Skeleton height={35} width={161} />
        </div>
      ) : (
        <div className="edit-user-form__fields">
          <Input
            classNames={['edit-user-form__input']}
            value={editUserForm.fullName}
            onInput={controller.handleFullNameChange}
            disabled={!editUserForm.isEditing}
            label={!editUserForm.isEditing ? 'ФИО' : undefined}
            error={editUserForm.errors.fullName}
            placeholder="ФИО"
          />
          <Input
            classNames={['edit-user-form__input']}
            value={editUserForm.phoneNumber}
            onInput={controller.handlePhoneNumberChange}
            disabled={!editUserForm.isEditing}
            label={!editUserForm.isEditing ? 'Телефон' : undefined}
            placeholder="Телефон"
          />
          <Input
            classNames={['edit-user-form__input']}
            value={editUserForm.login}
            label={!editUserForm.isEditing ? 'Логин' : undefined}
            placeholder="Логин"
            error={editUserForm.errors.login}
            onInput={controller.handleLoginChange}
            disabled={!editUserForm.isEditing}
          />
          {editUserForm.isEditing && (
            <Input
              classNames={['edit-user-form__input']}
              value={editUserForm.email}
              onInput={controller.handleEmailChange}
              disabled={!editUserForm.isEditing}
              placeholder="Электронная почта"
            />
          )}
          {!editUserForm.isEditing ? (
            <div className="edit-user-form__info-controls">
              <Button
                className="edit-user-form__change-password-btn"
                onClick={controller.openChangePasswordModal}
              >
                Изменить пароль
              </Button>
              <CheckBox
                classNames={['edit-user-form__check-box']}
                label={props.user?.role ? getRoleByKey(props.user?.role) : ''}
                disabled
                checked={true}
              />
            </div>
          ) : (
            <div className="edit-user-form__checkbox-wrapper">
              <CheckBox
                classNames={['edit-user-form__check-box']}
                label={'Администратор'}
                onChange={controller.handleIsAdminChange}
                checked={editUserForm.role === 'admin'}
              />
              <CheckBox
                classNames={['edit-user-form__check-box']}
                label={'Менеджер'}
                onChange={controller.handleIsManagerChange}
                checked={editUserForm.role === 'manager'}
              />
              <CheckBox
                classNames={['edit-user-form__check-box']}
                label={'Представитель'}
                onChange={controller.handleIsMedicalAgentChange}
                checked={editUserForm.role === 'medical_agent'}
              />
            </div>
          )}
        </div>
      )}
      <ErrorAlert
        classNames={['edit-user-form__error-alert']}
        error={editUserForm.errors.editUser}
      />
      {editUserForm.isEditing && (
        <div className="edit-user-form__controls">
          <Button className="edit-user-form__cancel" onClick={handleCancelEdit}>
            Отмена
          </Button>
          <Button className="edit-user-form__save" onClick={handleEdit}>
            Сохранить
          </Button>
        </div>
      )}
      <Menu open={open} onClose={handleMenuClose} anchorEl={anchorEl}>
        {props.user?.state === 'active' ? (
          <MenuItem
            style={{
              color: '#263238',
            }}
            onClick={handleSetUserUnactive}
          >
            Деактивировать
          </MenuItem>
        ) : (
          <MenuItem
            style={{
              color: '#263238',
            }}
            onClick={handleSetUserActive}
          >
            Активировать
          </MenuItem>
        )}

        <MenuItem
          style={{
            color: '#FF4C06',
          }}
          onClick={handleDeleteUser}
        >
          Удалить
        </MenuItem>
      </Menu>
      <AppModal
        open={editUserForm.isChangePasswordModalVisible}
        onClose={controller.closeChangePasswordModal}
        classNames={['edit-user-form__change-password-modal']}
      >
        <div className="edit-user-form__change-password">
          <h2 className="edit-user-form__change-password-title">
            Смена пароля
          </h2>
          <Input
            classNames={['edit-user-form__input']}
            value={editUserForm.password}
            placeholder="Новый пароль"
            onInput={controller.handlePasswordChange}
            error={editUserForm.errors.password}
            type="password"
          />
          <ErrorAlert
            classNames={['edit-user-form__change-password-alert']}
            error={editUserForm.errors.changePassword}
          />
          <Button
            className="edit-user-form__password-confirm"
            onClick={handlePasswordChange}
          >
            Сохранить
          </Button>
        </div>
      </AppModal>
    </div>
  );
};

export default EditUserForm;

import React, { useEffect, useMemo, useState } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from 'react-router-dom';
import Input from '../../../../ui/Input';
import { Button, debounce, TextField, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { ReferenceFiltersController } from '../../reference-filters.controller';
import SelectChip from '../../../../components/SelectChip';
import AppAutocomplete from '../../../../components/AppAutocomplete';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CustomizedHook from '../../../../components/CustomizedHook';
import { ICustomizedHookItem } from '../../../../components/CustomizedHook/customized-hook.interfaces';
import AppDatePicker from '../../../../ui/AppDatePicker';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import {
  setActiveType,
  setSelectedApp,
} from '../../store/reference-filters.slice';
import moment from 'moment';
import Switch from '../../../../ui/Switch';
import MonthYearPicker from '../../../../ui/MonthYearPicker';
import { ACTIVE_TYPE_NAMES, APP_AVAILABILITY_NAMES } from '../../constants';
import AppSelect from '../../../../ui/AppSelect';
import { IAppSelectOption } from '../../../../ui/AppSelect/app-select.interface';
import RequiredRole from '../../../../components/RequiredRole';
import { MEDIA } from '../../../../constants/app.constants';
import AutoCompleteSearchList from '../../../../components/AutoCompleteSearchList';

interface IReferenceFiltersProps {
  classNames?: string[];
  onCloseModal?: () => void;
}
export const ReferenceFilters = (props: IReferenceFiltersProps) => {
  const referenceFilters = useAppSelector((state) => state.referenceFilters);
  const dispatch = useAppDispatch();
  const controller = new ReferenceFiltersController(dispatch);
  const matchXsm = useMediaQuery(`(max-width:${MEDIA.XSM}px)`);

  const [specialityOpen, setSpecialityOpen] = useState(false);
  const [lpuOpen, setLpuOpen] = useState(false);
  const [citiesOpen, setCitiesOpen] = useState(false);
  const [lastMeetupFocused, setLastMeetupFocused] = useState(false);
  const onLastMeetupFocus = () => setLastMeetupFocused(true);
  const onLastMeetupBlur = () => setLastMeetupFocused(false);
  const debounceDelayed = useMemo(
    () => debounce(controller.fetchSpecialities, 500),
    []
  );

  const debounceFetchSpeciality = async (event: any) => {
    controller.onSpecialityInput(event);
    debounceDelayed();
  };

  useEffect(() => {
    (async () => {
      await controller.fetchSpecialities();
      await controller.fetchLpuList();
      await controller.fetchCities();
    })();

    controller.setDefaultsFilters();
  }, []);

  const debounceFetchLpu = useMemo(
    () => debounce(controller.fetchLpuList, 500),
    []
  );

  const debounceFetchCities = useMemo(
    () => debounce(controller.fetchCities, 500),
    []
  );
  const handleLpuInput = (event: any) => {
    controller.handleLpuInput(event);
    debounceFetchLpu();
  };

  const handleCityInput = (event: any) => {
    controller.handleCityInput(event);
    debounceFetchCities();
  };

  const activeValues = [
    {
      id: 1,
      text: ACTIVE_TYPE_NAMES.ACTIVE,
      value: ACTIVE_TYPE_NAMES.ACTIVE,
      helpText: 'Показать всех активных',
    },
    {
      id: 2,
      text: ACTIVE_TYPE_NAMES.INACTIVE,
      value: ACTIVE_TYPE_NAMES.INACTIVE,
      helpText: 'Показать не активных',
    },
  ];

  const hasMobileAppValues: IAppSelectOption[] = [
    {
      id: 1,
      text: APP_AVAILABILITY_NAMES.ALL,
      value: APP_AVAILABILITY_NAMES.ALL,
      helpText: 'Показать всех',
    },
    {
      id: 2,
      text: APP_AVAILABILITY_NAMES.YES,
      value: APP_AVAILABILITY_NAMES.YES,
      helpText: 'Показать у кого есть',
    },
    {
      id: 3,
      text: APP_AVAILABILITY_NAMES.NO,
      value: APP_AVAILABILITY_NAMES.NO,
      helpText: 'Показать у кого нету',
    },
  ];

  const onListClick = (e: any) => {
    if (e.target.closest('.reference-filters__mobile-search')) return;

    if (!e.target.closest('.reference-filters__speciality-input')) {
      setSpecialityOpen(false);
    } else if (e.target.closest('.reference-filters__speciality-input')) {
      setSpecialityOpen(true);
    }

    if (!e.target.closest('.reference-filters__lpu-input')) {
      setLpuOpen(false);
    } else if (e.target.closest('.reference-filters__lpu-input')) {
      setLpuOpen(true);
    }

    if (!e.target.closest('.reference-filters__cities-input')) {
      setCitiesOpen(false);
    } else if (e.target.closest('.reference-filters__cities-input')) {
      setCitiesOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onListClick);
    return () => {
      document.removeEventListener('click', onListClick);
    };
  }, []);

  return (
    <div className={classNamesParser('reference-filters', props.classNames)}>
      <div className="reference-filters__heading">
        <h3 className="reference-filters__title">Фильтр</h3>
        <RoundButton
          onClick={props.onCloseModal}
          classNames={['reference-filters__close-modal']}
          content={<CloseIcon />}
        />
      </div>
      <div
        style={{ marginBottom: 24 }}
        className="reference-filters__filters-container"
      >
        <CustomizedHook
          selectedOptions={referenceFilters.selectedSpecialities}
          onChange={controller.onSpecialityChange}
          options={referenceFilters.specialities}
          placeholder="Выберите специальность"
          label="Специальность"
          onDelete={controller.onSpecialityDelete}
          classNames={[
            'reference-filters__form-input',
            'reference-filters__speciality-input',
            'reference-filters__customized-hook',
          ]}
          onInput={debounceFetchSpeciality}
          inputValue={referenceFilters.specialitySearchText}
          open={!matchXsm && specialityOpen}
          showInputInList
          listWrapperStyles={{
            maxHeight: 150,
          }}
          showListAcceptButton
          onListAcceptButtonClick={() => setSpecialityOpen(false)}
        />
        <CustomizedHook
          classNames={[
            'reference-filters__form-input',
            'reference-filters__lpu-input',
            'reference-filters__customized-hook',
          ]}
          placeholder="Выберите ЛПУ"
          label="ЛПУ"
          onInput={handleLpuInput}
          options={referenceFilters.lpuList as ICustomizedHookItem[]}
          selectedOptions={referenceFilters.selectedLpu}
          onChange={controller.onLpuChange}
          onDelete={controller.onLpuDelete}
          inputValue={referenceFilters.lpuSearchText}
          open={!matchXsm && lpuOpen}
          showInputInList
          listWrapperStyles={{
            maxHeight: 150,
          }}
          showListAcceptButton
          onListAcceptButtonClick={() => setLpuOpen(false)}
        />
        <RequiredRole roles={['admin', 'manager']}>
          <CustomizedHook
            classNames={[
              'reference-filters__form-input',
              'reference-filters__cities-input',
              'reference-filters__customized-hook',
            ]}
            placeholder="Выберите город/район"
            label="Город/район"
            onInput={handleCityInput}
            options={referenceFilters.cityList as ICustomizedHookItem[]}
            selectedOptions={referenceFilters.selectedCities}
            onChange={controller.onCityChange}
            onDelete={controller.onCityDelete}
            inputValue={referenceFilters.citySearchText}
            open={citiesOpen}
            showInputInList
            handleTagClick={controller.handleCityTagClick}
            subOptions={referenceFilters.districts}
            listWrapperStyles={{
              maxHeight: 150,
            }}
            handleSubOptionClick={controller.handleDistrictClick}
            showSubMenu
            showListAcceptButton
            onListAcceptButtonClick={() => setCitiesOpen(false)}
          />
          <AppSelect
            classNames={['reference-filters__form-input']}
            placeholder="Наличие мобильного приложения"
            label="Наличие мобильного приложения"
            options={hasMobileAppValues}
            value={referenceFilters.selectedAppValue || ''}
            handleChange={controller.handleSelectedAppChange}
          />
          <Input
            placeholder={
              lastMeetupFocused ? 'ДД.ММ.ГГГГ' : 'Дата последней встречи'
            }
            label="Дата последней встречи"
            classNames={['reference-filters__form-input']}
            value={referenceFilters.lastMeetupDate || ''}
            onInput={controller.handleLastMeetupDate}
            onFocus={onLastMeetupFocus}
            onBlur={onLastMeetupBlur}
            showCloseIcon={!referenceFilters.lastMeetupDate}
            onIconClick={controller.handleClearMeetupDate}
            error={referenceFilters.errors.lastMeetupDate}
            fullWidth
          />
        </RequiredRole>
        <AppSelect
          classNames={['reference-filters__form-input']}
          placeholder="Признак активности"
          label="Признак активности"
          options={activeValues}
          value={referenceFilters.activeType}
          handleChange={controller.handleActiveTypeChange}
        />

        {specialityOpen && matchXsm && (
          <AutoCompleteSearchList
            placeholder="Выберите специальность"
            className="reference-filters__mobile-search"
            showListAcceptButton
            onInput={debounceFetchSpeciality}
            onChange={controller.onSpecialityChange}
            selectedOptions={referenceFilters.selectedSpecialities}
            inputValue={referenceFilters.specialitySearchText}
            options={referenceFilters.specialities}
            onListAcceptButtonClick={() => setSpecialityOpen(false)}
          />
        )}
        {lpuOpen && matchXsm && (
          <AutoCompleteSearchList
            placeholder="Выберите ЛПУ"
            className="reference-filters__mobile-search"
            showListAcceptButton
            onInput={handleLpuInput}
            onChange={controller.onLpuChange}
            selectedOptions={referenceFilters.selectedLpu}
            inputValue={referenceFilters.lpuSearchText}
            options={referenceFilters.lpuList}
            onListAcceptButtonClick={() => setLpuOpen(false)}
          />
        )}
      </div>

      <div className="reference-filters__buttons">
        <Button
          className="reference-filters__clear"
          onClick={controller.handleClearFilters}
        >
          Очистить
        </Button>
        <Button
          className="reference-filters__apply"
          onClick={() => controller.handleApplyClick(props.onCloseModal)}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

import React from 'react';
import { Skeleton } from '@mui/material';

const EditDoctorFormSkeleton = () => {
  return (
    <div style={{ marginBottom: 18 }}>
      <Skeleton height={48} style={{ marginBottom: 8 }} />
      <Skeleton height={48} style={{ marginBottom: 8 }} />
      <Skeleton height={48} style={{ marginBottom: 8 }} />
      <Skeleton height={48} style={{ marginBottom: 8 }} />
      <Skeleton height={48} style={{ marginBottom: 8 }} />
      <Skeleton height={48} style={{ marginBottom: 8 }} />
      <Skeleton height={48} />
    </div>
  );
};

export default EditDoctorFormSkeleton;

import React, { useCallback, useEffect } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import { CompletedMeetupsController } from '../../completed-meetups.controller';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import CompletedMeetupCard from '../CompletedMeetupCard';
import { Pagination } from '@mui/material';
import CompletedMeetupSkeleton from '../CompletedMeetupSkeleton';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import MeetupHistoryItem from '../../../../components/MeetupHistoryItem';
import {
  COMPLETED_MEETUPS_LG_HEIGHT,
  COMPLETED_MEETUPS_LG_LIMIT,
  COMPLETED_MEETUPS_MD_HEIGHT,
  COMPLETED_MEETUPS_MD_LIMIT,
  COMPLETED_MEETUPS_SM_LIMIT,
} from '../../constants';
import { setLimit } from '../../store/completed-meetups.slice';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import { LogLevel, Virtuoso } from 'react-virtuoso';

interface ICompletedMeetupsProps {
  classNames?: string[];
}

export const CompletedMeetups = (props: ICompletedMeetupsProps) => {
  const dispatch = useAppDispatch();
  const completedMeetups = useAppSelector((state) => state.completedMeetups);
  const controller = new CompletedMeetupsController(dispatch);

  const loadMore = useCallback(
    () => setTimeout(() => controller.getCompletedMeetups(), 0),
    [controller.getCompletedMeetups]
  );

  useEffect(() => {
    const timeout = loadMore();

    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_HISTORY_WITH_FILTERS,
      controller.onFiltersChange
    );

    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_HISTORY_WITH_FILTERS);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={classNamesParser('completed-meetups', props.classNames)}>
      <div className="completed-meetups__list-container">
        {completedMeetups.list.length === 0 &&
        !completedMeetups.isLoading ? null : (
          <Virtuoso
            data={completedMeetups.list}
            endReached={loadMore}
            itemContent={(i, item) => (
              <MeetupHistoryItem doctor={item.doctor} meetups={item.meetups} />
            )}
            components={{
              Header: () => <div style={{ marginBottom: 16 }}></div>,
              Footer: () => {
                return (
                  <>
                    {completedMeetups.haveMore
                      ? new Array(3)
                          .fill(null)
                          .map(() => <CompletedMeetupSkeleton />)
                      : null}
                  </>
                );
              },
            }}
          />
        )}
        {!completedMeetups.isLoading && completedMeetups.list.length === 0 && (
          <NoDataPlaceholder />
        )}
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompletedMeetupsListFilters } from '../enums';
import {
  TaskFiltersEnum,
  TaskVariantFiltersEnum,
} from '../../../components/TaskFilters/task-filters.enum';
import { getMeetupFiltersFromLocalstorage } from '../../../helpers/getMeetupFiltersFromLocalstorage';
interface ICompletedMeetupsPageState {
  isLoading: boolean;
  isEnvelopeModalVisible: boolean;
  isDetailsOpened: boolean;
  isMeetupResultVisible: boolean;
  isSelecting: boolean;
  selectedDoctors: number[];
  currentDoctorId?: number;
  currentTaskId?: number;
  filterParam: TaskFiltersEnum;
  doctorEnvelopeName?: string;
  page: number;
  isSearchOpened: boolean;
  searchInputValue: string;
  searchValue: string;
  previousPage: number;
  taskVariantFilter: TaskVariantFiltersEnum;
}

const initialState: ICompletedMeetupsPageState = {
  isLoading: false,
  isEnvelopeModalVisible: false,
  isDetailsOpened: false,
  isMeetupResultVisible: false,
  isSelecting: false,
  selectedDoctors: [],
  filterParam: TaskFiltersEnum.DOCTOR_FULLNAME,
  page: 1,
  searchInputValue: '',
  searchValue: '',
  isSearchOpened: false,
  previousPage: 1,
  taskVariantFilter: TaskVariantFiltersEnum.ALL,
};

export const completedMeetupsPageSlice = createSlice({
  name: 'completed-meetups-page',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsEnvelopeModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isEnvelopeModalVisible = action.payload;
    },
    setIsDetailsOpened: (state, action: PayloadAction<boolean>) => {
      state.isDetailsOpened = action.payload;
    },
    setIsMeetupResultVisible: (state, action: PayloadAction<boolean>) => {
      state.isMeetupResultVisible = action.payload;
    },
    setCurrentDoctorId: (state, action: PayloadAction<number | undefined>) => {
      state.currentDoctorId = action.payload;
    },
    setCurrentTaskId: (state, action: PayloadAction<number>) => {
      state.currentTaskId = action.payload;
    },
    setSelecting: (state, action: PayloadAction<boolean>) => {
      state.isSelecting = action.payload;
    },
    addSelectedId: (state, action: PayloadAction<number>) => {
      state.selectedDoctors.push(action.payload);
    },
    setMeetupListFilters: (state, action: PayloadAction<TaskFiltersEnum>) => {
      state.filterParam = action.payload;
    },
    setDoctorEnvelopeName: (state, action: PayloadAction<string>) => {
      state.doctorEnvelopeName = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSearchInputValue: (state, action: PayloadAction<string>) => {
      state.searchInputValue = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setSearchOpened: (state, action: PayloadAction<boolean>) => {
      state.isSearchOpened = action.payload;
    },
    setPreviousPage: (state, action: PayloadAction<number>) => {
      state.previousPage = action.payload;
    },
    setTaskVariantFilter: (
      state,
      action: PayloadAction<TaskVariantFiltersEnum>
    ) => {
      state.taskVariantFilter = action.payload;
    },
  },
});

export const {
  setLoading,
  setIsEnvelopeModalVisible,
  setIsDetailsOpened,
  setIsMeetupResultVisible,
  setCurrentDoctorId,
  setCurrentTaskId,
  addSelectedId,
  setDoctorEnvelopeName,
  setMeetupListFilters,
  setPage,
  setSearchInputValue,
  setSearchOpened,
  setPreviousPage,
  setTaskVariantFilter,
  setSearchValue,
} = completedMeetupsPageSlice.actions;
export const completedMeetupsPageReducer = completedMeetupsPageSlice.reducer;

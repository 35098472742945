import { useCallback, useEffect } from 'react';
import { ICityDynamicData } from '../../interfaces';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import DynamicTableRow from '../DynamicTableRow';
import { TableVirtuoso } from 'react-virtuoso';
import DynamicTableComponent from '../DynamicTableComponent';
import { DynamicFixedHeader } from '../DynamicFixedHeader';

interface ICityTableProps {
  data: ICityDynamicData[];
  headingDates: string[];
  loadFunc: () => void;
  clearTable: () => void;
}

const CityDynamicTable = (props: ICityTableProps) => {
  const loadMore = useCallback(
    () => setTimeout(() => props.loadFunc(), 0),
    [props.loadFunc]
  );

  useEffect(() => {
    const timeout = loadMore();

    return () => {
      props.clearTable();
      clearTimeout(timeout);
    };
  }, []);
  return props.data.length > 0 ? (
    <TableVirtuoso
      components={DynamicTableComponent}
      fixedHeaderContent={() => (
        <DynamicFixedHeader
          title={'Населенный пункт'}
          headingDates={props.headingDates}
        />
      )}
      data={props.data}
      endReached={loadMore}
      itemContent={(i, item: ICityDynamicData) => {
        return (
          <DynamicTableRow
            key={item.city.id}
            id={item.city.id}
            name={item.city.name}
            units={item.units}
            dynamic={item.dynamic}
          />
        );
      }}
    />
  ) : (
    <NoDataPlaceholder styles={{ margin: '32px 0' }} />
  );
};

export default CityDynamicTable;

import React, { useEffect, useMemo, useState } from 'react';
import { Button, TextField, useMediaQuery } from '@mui/material';
import { IApiTaskDoctor } from '../../../../interfaces/api.tasks.interfaces';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import Switch from '../../../../ui/Switch';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { CloseEnvelopsController } from '../../close-envelops.controller';
import {
  setComment,
  setDoctorIds,
} from '../../store/close-envelops-form.slice';
import {
  ENVELOPE_VALUE_NAMES,
  MAX_DOCTORS_SHOW,
  envelopeOptions,
} from '../../constants';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import AppSelect from '../../../../ui/AppSelect';
interface ICloseEnvelopsFormProps {
  classNames?: string[];
  doctors?: IApiTaskDoctor[];
  onCloseForm?: () => void;
}

export const CloseEnvelopsForm = (props: ICloseEnvelopsFormProps) => {
  const dispatch = useAppDispatch();
  const controller = new CloseEnvelopsController(dispatch);
  const closeEnvelopForm = useAppSelector((state) => state.closeEnvelopsForm);

  useEffect(() => {
    controller.updateDoctorIds(props.doctors || []);

    return () => {
      controller.clearState();
    };
  }, []);

  const successMeetup = useMemo(
    () =>
      closeEnvelopForm.isSuccessMeetup === ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
    [closeEnvelopForm.isSuccessMeetup]
  );

  return (
    <div className={classNamesParser('close-envelops-form ', props.classNames)}>
      <div className="close-envelops-form__header">
        <h3 className="close-envelops-form__title">Передать конверт</h3>
        <RoundButton onClick={props.onCloseForm} content={<CloseIcon />} />
      </div>
      {props.doctors?.length && (
        <div className="close-envelops-form__doctors">
          {props.doctors
            .filter((_, i) => i < MAX_DOCTORS_SHOW)
            .map((doc) => `${doc.lastName}`)
            .join(', ') +
            `${
              props.doctors.length - MAX_DOCTORS_SHOW > 0
                ? ` +еще ${props.doctors.length - MAX_DOCTORS_SHOW}`
                : ''
            }`}
        </div>
      )}
      <div className="close-envelops-form__envelop-status-container">
        <div className="meetup-result__personal-meetup"></div>
        <AppSelect
          options={envelopeOptions}
          value={closeEnvelopForm.isSuccessMeetup}
          classNames={['close-envelops-form__select']}
          handleChange={controller.onSuccessMeetupChange}
        />
      </div>
      <TextField
        autoFocus
        value={closeEnvelopForm.comment}
        className="close-envelops-form__comment"
        placeholder="Комментарий"
        onChange={controller.handleCommentChange}
        multiline
        rows={1}
      />
      <Button
        className={`close-envelops-form__send`}
        style={{
          backgroundColor: successMeetup ? '' : '#263238',
          opacity: successMeetup ? '1' : '0.75',
        }}
        onClick={controller.handleDoneClick}
      >
        {successMeetup ? 'Конверт отдан' : 'Встреча не состоялась'}
      </Button>
    </div>
  );
};

import { configureStore } from '@reduxjs/toolkit';
import { signInReducer } from './modules/SignInForm';
import { referenceTableReducer } from './modules/ReferenceTable';
import { usersListReducer } from './modules/UsersList';
import { userReducer } from './store/user.slice';
import referenceCatalogPageReducer from './pages/ReferenceCatalog/store/reference-catalog.slice';
import { tasksPageReducer } from './pages/Tasks/store/tasks-page.slice';
import { editDoctorFormReducer } from './modules/EditDoctorForm';
import { addDoctorFormReducer } from './modules/AddDoctorForm';
import { editLpuFormReducer } from './modules/EditLpuForm';
import { addLpuFormReducer } from './modules/AddLpuForm';
import usersPageReducer from './pages/UsersPage/store/users-page.slice';
import { editUserFormReducer } from './store/edit-user-form.slice';
import { addUserFormReducer } from './store/add-user-form.slice';
import { editSpecialityFormReducer } from './modules/EditSpecialityForm';
import { addSpecialityFormReducer } from './modules/AddSpecialityForm';
import { referenceFiltersReducer } from './modules/ReferenceFilters';
import { uploadHistoryReducer } from './modules/UploadHistory';
import uploadDataPageReducer from './pages/UploadDataPage/store/upload-data-page.slice';
import { uploadTableReducer } from './modules/UploadTable';
import { tasksListReducer } from './modules/TasksList';
import { taskDetailReducer } from './modules/TaskDetail';
import { meetupsPageReducer } from './pages/Meetups/store/meetups-page.slice';
import { meetupResultReducer } from './modules/MeetupResult';
import { meetupsListReducer } from './modules/MeetupsList';
import { meetupDetailsReducer } from './modules/MeetupDetails';
import { sendEnvelopeFormReducer } from './modules/SendEnvelopeForm';
import { feedbackListReducer } from './modules/FeedbackList';
import { feedbackPageReducer } from './pages/Feedback/store/feedback-page.slice';
import { uploadFieldReducer } from './modules/UploadField';
import { dynamicTableReducer } from './modules/DynamicTable';
import { meetupsReportReducer } from './modules/MeetupsReport';
import { feedbackMenuReducer } from './modules/FeedbackMenu/store/feedback-menu.slice';
import { completedMeetupsPageReducer } from './pages/CompletedMeetups/store/completed-meetups-page.slice';
import { createTaskFormReducer } from './modules/CreateTaskForm';
import { closeEnvelopsFormReducer } from './modules/CloseEnvelopsForm';
import { completedMeetupsReducer } from './modules/CompletedMeetups';
import { appReducer } from './store/app.slice';
import { referenceDynamicReducer } from './modules/ReferenceDynamic';
import { changelogListReducer } from './modules/ChangelogList';
import { meetupHistoryPageReducer } from './pages/MeetupHistory/meetup-history.slice';
import { historyFiltersSliceReducer } from './modules/HistoryFilters';
import { changelogPageReducer } from './pages/Changelog/store/changelog-page.slice';
import { historyReportReducer } from './modules/HistoryReport';

export const store = configureStore({
  reducer: {
    signIn: signInReducer,
    referenceTable: referenceTableReducer,
    usersList: usersListReducer,
    user: userReducer,
    referenceCatalogPage: referenceCatalogPageReducer,
    editDoctorForm: editDoctorFormReducer,
    addDoctorForm: addDoctorFormReducer,
    editLpuForm: editLpuFormReducer,
    addLpuForm: addLpuFormReducer,
    addUserForm: addUserFormReducer,
    usersPage: usersPageReducer,
    editUserForm: editUserFormReducer,
    editSpecialityForm: editSpecialityFormReducer,
    addSpecialityForm: addSpecialityFormReducer,
    referenceFilters: referenceFiltersReducer,
    uploadHistory: uploadHistoryReducer,
    uploadDataPage: uploadDataPageReducer,
    uploadTable: uploadTableReducer,
    tasksPage: tasksPageReducer,
    tasksList: tasksListReducer,
    taskDetail: taskDetailReducer,
    meetupsPage: meetupsPageReducer,
    meetupResult: meetupResultReducer,
    meetupsList: meetupsListReducer,
    meetupDetails: meetupDetailsReducer,
    sendEnvelopeForm: sendEnvelopeFormReducer,
    feedbackList: feedbackListReducer,
    feedbackPage: feedbackPageReducer,
    uploadField: uploadFieldReducer,
    dynamicTable: dynamicTableReducer,
    meetupsReport: meetupsReportReducer,
    feedbackMenu: feedbackMenuReducer,
    completedMeetupsPage: completedMeetupsPageReducer,
    createTaskForm: createTaskFormReducer,
    app: appReducer,
    closeEnvelopsForm: closeEnvelopsFormReducer,
    completedMeetups: completedMeetupsReducer,
    referenceDynamic: referenceDynamicReducer,
    changelogList: changelogListReducer,
    meetupHistoryPage: meetupHistoryPageReducer,
    historyFilters: historyFiltersSliceReducer,
    changelogPage: changelogPageReducer,
    historyReport: historyReportReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { createBrowserRouter, Outlet } from 'react-router-dom';
import App from '../App';
import { ROUTES } from '../constants/routes';
import { lazy, Suspense } from 'react';
import PrivateRoute from '../components/PrivateRoute';
import FullscreenSpinner from '../ui/FullscreenSpinner';

const SignInPage = lazy(() => import('../pages/SignInPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const ReferenceCatalogPage = lazy(() => import('../pages/ReferenceCatalog'));
const UsersPage = lazy(() => import('../pages/UsersPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const TasksPage = lazy(() => import('../pages/Tasks'));
const UploadDataPage = lazy(() => import('../pages/UploadDataPage'));
const MeetupsPage = lazy(() => import('../pages/Meetups'));
const CompletedMeetupsPage = lazy(() => import('../pages/CompletedMeetups'));
const FeedbackPage = lazy(() => import('../pages/Feedback'));
const DynamicPage = lazy(() => import('../pages/Dynamic'));
const MeetupsReportPage = lazy(() => import('../pages/MeetupsReport'));
const MeetupHistoryPage = lazy(() => import('../pages/MeetupHistory'));
const ChangelogPage = lazy(() => import('../pages/Changelog'));
const HistoryReportPage = lazy(() => import('../pages/HistoryReport'));

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<FullscreenSpinner />}>
        <App />
      </Suspense>
    ),
    children: [
      {
        // path: ROUTES.TASKS_PAGE,
        index: true,
        element: (
          <PrivateRoute roles={['admin', 'manager']}>
            <TasksPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.SIGNIN,
        element: (
          <PrivateRoute reverseAuth>
            <SignInPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.MEETUP_HISTORY,
        element: (
          <PrivateRoute roles={['admin', 'manager']}>
            <MeetupHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.REFERENCE_CATALOG,
        element: (
          <PrivateRoute roles={['admin', 'manager', 'medical_agent']}>
            <ReferenceCatalogPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.USERS,
        element: (
          <PrivateRoute roles={['admin']}>
            <UsersPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.UPLOAD,
        element: (
          <PrivateRoute roles={['admin', 'manager']}>
            <UploadDataPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.MEETUPS,
        element: (
          <PrivateRoute roles={['medical_agent']}>
            <MeetupsPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.COMPLETED_MEETUPS,
        element: (
          <PrivateRoute roles={['medical_agent']}>
            <CompletedMeetupsPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.FEEDBACK,
        element: (
          <PrivateRoute roles={['admin', 'manager', 'medical_agent']}>
            <FeedbackPage />
          </PrivateRoute>
        ),
      },

      {
        path: ROUTES.DYNAMIC,
        element: (
          <PrivateRoute roles={['admin', 'manager']}>
            <DynamicPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.MEETUPS_REPORT,
        element: (
          <PrivateRoute roles={['admin', 'manager']}>
            <MeetupsReportPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.CHANGELOG,
        element: (
          <PrivateRoute roles={['admin']}>
            <ChangelogPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.HISTORY_REPORT,
        element: (
          <PrivateRoute roles={['admin', 'manager']}>
            <HistoryReportPage />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: (
          <PrivateRoute roles={['admin', 'manager', 'medical_agent']}>
            <NotFoundPage />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

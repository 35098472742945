import React, { SyntheticEvent, useEffect, MouseEvent, useState } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import { Menu, Pagination, Tab, Tabs, useMediaQuery } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import RoundButton from '../../../../ui/RoundButton';
import { ReferenceTableController } from '../../reference-table.controller';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { TableCard } from '../TableCard';
import { ReferenceTypes } from '../../types';
import { REFERENCE_TYPES, TAB_REFERENCE_TYPES } from '../../constants';
import ReferenceSkeleton from '../ReferenceSkeleton';
import {
  clearTable,
  setAppAvailability,
  setApplyFilters,
  setFilterAddress,
  setFiltersLpuIds,
  setFiltersSpecialityIds,
  setIsArchived,
  setLastMeetupDate,
  setSelectedCardId,
  setSelectedLpu,
  setSelectedSpecialities,
  setMonthStart,
  setMonthEnd,
  setYearStart,
  setYearEnd,
  setDynamicFrom,
  setDynamicTo,
  setApplyDynamic,
  clearDynamicValues,
  clearFiltersValues,
  setTabValue,
} from '../../store/reference-table.slice';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import { formCardDataByType } from '../../helpers/formCardDataByType';
import SearchButton from '../../../../ui/SearchButton';
import RoundMenuButton from '../../../../ui/RoundMenuButton';
import { IRoundMenuButtonItem } from '../../../../ui/RoundMenuButton/index.interfaces';
import AppModal from '../../../../components/AppModal';
import AddDoctorsTasksModal from '../AddDoctorsTasksModal';
import RequiredRole from '../../../../components/RequiredRole';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import CheckBox from '../../../../ui/CheckBox';
import { MEDIA } from '../../../../constants/app.constants';

interface IReferenceTableProps {
  classNames?: string[];
  onTableCardClick?: (cardData: any) => void;
  onFiltersClick?: () => void;
  onDynamicClick?: () => void;
  tabValue?: ReferenceTypes;
}

export const ReferenceTable = (props: IReferenceTableProps) => {
  const referenceTable = useAppSelector((state) => state.referenceTable);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const controller = new ReferenceTableController(dispatch);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const matchXSM = useMediaQuery(`(max-width: ${MEDIA.XSM}px)`);

  const menuOpened = Boolean(anchorEl);

  const handleMenuOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(clearTable());
    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_CATALOG,
      controller.fetchDataByCategory
    );

    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_DOCTORS_WITH_FILTERS,
      async (filters: any) => {
        dispatch(clearTable());
        dispatch(setFiltersLpuIds(filters ? filters?.lpuIds : null));
        dispatch(
          setFiltersSpecialityIds(filters ? filters?.specialityIds : null)
        );
        dispatch(setSelectedLpu(filters ? filters?.selectedLpu : []));
        dispatch(
          setSelectedSpecialities(filters ? filters?.selectedSpecialities : [])
        );
        dispatch(setLastMeetupDate(filters ? filters?.date : null));
        dispatch(setAppAvailability(filters ? filters?.appAvailability : null));
        dispatch(setIsArchived(filters ? filters?.activeType : false));
        dispatch(setFilterAddress(filters ? filters.formattedAddresses : null));
        // dispatch(setMonthStart(filters ? filters.monthStart : null));
        // dispatch(setMonthEnd(filters ? filters.monthEnd : null));
        // dispatch(setYearStart(filters ? filters.yearStart : null));
        // dispatch(setYearEnd(filters ? filters.yearEnd : null));
        // dispatch(setDynamicFrom(filters ? filters.dynamicFrom : null));
        // dispatch(setDynamicTo(filters ? filters.dynamicTo : null));

        dispatch(setApplyFilters(!!filters));
        await controller.fetchDoctors();

        if (!filters) {
          dispatch(clearFiltersValues());
        }
      }
    );

    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_DOCTORS_WITH_DYNAMIC,
      async (filters: any) => {
        dispatch(setMonthStart(filters ? filters.monthStart : null));
        dispatch(setMonthEnd(filters ? filters.monthEnd : null));
        dispatch(setYearStart(filters ? filters.yearStart : null));
        dispatch(setYearEnd(filters ? filters.yearEnd : null));
        dispatch(setDynamicFrom(filters ? filters.dynamicFrom : null));
        dispatch(setDynamicTo(filters ? filters.dynamicTo : null));
        dispatch(setApplyDynamic(!!filters));
        await controller.fetchDoctors();
        if (!filters) {
          dispatch(clearDynamicValues());
        }
      }
    );

    EventEmitter.subscribe(EMITTER_EVENTS.CLEAR_DOCTORS_FILTERS, async () => {
      dispatch(clearTable());
      dispatch(setFiltersLpuIds(null));
      dispatch(setFiltersSpecialityIds(null));
      dispatch(setSelectedLpu([]));
      dispatch(setSelectedSpecialities([]));
      dispatch(setApplyFilters(false));
      dispatch(setLastMeetupDate(null));
      dispatch(setAppAvailability(null));
      dispatch(setIsArchived(false));
      dispatch(setFilterAddress(null));
      dispatch(clearFiltersValues());
      await controller.fetchDoctors();
    });

    EventEmitter.subscribe(EMITTER_EVENTS.CLEAR_DOCTORS_DYNAMIC, async () => {
      dispatch(setApplyDynamic(false));
      dispatch(clearTable());
      dispatch(setMonthStart(null));
      dispatch(setMonthEnd(null));
      dispatch(setYearStart(null));
      dispatch(setYearEnd(null));
      dispatch(setDynamicFrom(null));
      dispatch(setDynamicTo(null));
      dispatch(clearDynamicValues());
      await controller.fetchDoctors();
    });

    EventEmitter.subscribe(EMITTER_EVENTS.CLEAR_SELECTED_CARD, () =>
      dispatch(setSelectedCardId(0))
    );

    EventEmitter.subscribe(
      EMITTER_EVENTS.SET_SELECTED_CARD,
      controller.handleCardClick
    );

    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_CATALOG);
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_DOCTORS_WITH_FILTERS);
      EventEmitter.unsubscribe(EMITTER_EVENTS.CLEAR_DOCTORS_FILTERS);
      EventEmitter.unsubscribe(EMITTER_EVENTS.CLEAR_SELECTED_CARD);
      EventEmitter.unsubscribe(EMITTER_EVENTS.SET_SELECTED_CARD);
    };
  }, []);

  useEffect(() => {
    if (!props.tabValue) return;
    controller.handleTabChange(props.tabValue);
    controller.fetchDataByCategory();
    if (props.tabValue === referenceTable.currentTab) return;
    dispatch(setSelectedCardId(0));
  }, [props.tabValue]);

  const roundButtonItems: IRoundMenuButtonItem[] = [
    {
      id: 1,
      text: `Добавить все${
        referenceTable.isApplyFilters ||
        referenceTable.isApplyDynamic ||
        !!referenceTable.searchedValue
          ? ` (${referenceTable.total})`
          : ''
      }`,
      color: '#2574D0',
    },
  ];

  const isAllowedCreateTask =
    (referenceTable.isApplyFilters ||
      referenceTable.isApplyDynamic ||
      !!referenceTable.searchedValue) &&
    referenceTable.total > 0;

  return (
    <div className={classNamesParser('reference-table', props.classNames)}>
      <AppModal
        hideBackdrop
        classNames={['reference-table__app-modal']}
        open={referenceTable.showTasksModal}
        onClose={controller.handleTasksModalClose}
      >
        <AddDoctorsTasksModal
          onCloseModal={controller.handleTasksModalClose}
          handleMeetupChange={controller.handleMeetupChange}
          taskTypeValue={referenceTable.taskProps.taskType}
          executors={referenceTable.executors}
          //@ts-ignore
          selectedExecutors={referenceTable.taskProps.selectedExecutors}
          handleExecutorChange={controller.handleExecutorChange}
          handleCreateClick={controller.handleCreateDoctorsTasks}
        />
      </AppModal>
      <div className="reference-table__tab-container">
        {/* <Tabs
          value={referenceTable.currentTabValue}
          className="reference-table__tabs"
          onChange={onTabChange}
        >
          <Tab label="Врачи" value={0} />
          <Tab label="ЛПУ" value={1} />
          <Tab label="Специальности" value={2} />
        </Tabs> */}

        <div className="reference-table__filters">
          {referenceTable.currentTab === REFERENCE_TYPES.DOCTORS ? (
            <>
              <RequiredRole roles={['admin', 'manager']}>
                <RoundMenuButton
                  classNames={['reference-table__filter']}
                  backgroundColor="white"
                  menuItems={roundButtonItems}
                  onMenuClick={controller.handleMenuClick}
                  disableMenuIds={[!isAllowedCreateTask ? 1 : 0]}
                />
              </RequiredRole>
              <RequiredRole roles={['admin', 'manager', 'medical_agent']}>
                <RoundButton
                  classNames={['reference-table__filter']}
                  content={
                    <FilterListIcon
                      style={{
                        fill: referenceTable.isApplyFilters ? 'white' : 'black',
                      }}
                    />
                  }
                  onClick={props.onFiltersClick}
                  backgroundColor={
                    referenceTable.isApplyFilters ? 'black' : 'white'
                  }
                />
              </RequiredRole>
              <RequiredRole roles={['admin', 'manager']}>
                <RoundButton
                  classNames={['reference-table__filter']}
                  content={
                    <span
                      style={{
                        color: referenceTable.isApplyDynamic
                          ? 'white'
                          : 'black',
                      }}
                    >
                      %
                    </span>
                  }
                  onClick={props.onDynamicClick}
                  backgroundColor={
                    referenceTable.isApplyDynamic ? 'black' : 'white'
                  }
                />
              </RequiredRole>
            </>
          ) : null}

          {referenceTable.currentTab !== REFERENCE_TYPES.DOCTORS ? (
            <RequiredRole roles={['admin', 'manager']}>
              <RoundButton
                classNames={['reference-table__filter']}
                content={
                  <FilterListIcon
                    style={{
                      fill: referenceTable.isShowUnactive ? 'white' : 'black',
                    }}
                  />
                }
                onClick={handleMenuOpen}
                backgroundColor={
                  referenceTable.isShowUnactive ? 'black' : 'white'
                }
              />

              <Menu
                open={menuOpened}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
              >
                <div className="reference-table__unactive-filter">
                  <CheckBox
                    label="Показать неактивные"
                    checked={referenceTable.isShowUnactive}
                    onChange={controller.handleShowUnactiveCheck}
                  />
                </div>
              </Menu>
            </RequiredRole>
          ) : null}
          <SearchButton
            onInput={controller.onSearchInput}
            onSearch={controller.onEnterSearch}
            onClose={controller.onCloseSearch}
            onOpen={controller.onOpenSearch}
            open={referenceTable.openSearch}
            value={referenceTable.searchText}
            placeholder="Поиск..."
            classNames={['reference-table__search']}
          />
        </div>
      </div>
      <SearchButton
        onInput={controller.onSearchInput}
        onSearch={controller.onEnterSearch}
        onClose={controller.onCloseSearch}
        onOpen={controller.onOpenSearch}
        open={true}
        disableAutoFocus
        value={referenceTable.searchText}
        classNames={['reference-table__mobile-search']}
        placeholder="Поиск..."
        styles={{
          width: '100%',
        }}
      />

      <section
        className={`reference-table__content-wrapper ${
          user.role === 'admin' || user.role === 'manager'
            ? 'reference-table__content-wrapper_admin'
            : ''
        }`}
      >
        <div className="reference-table__content">
          {referenceTable.isLoading ? (
            <ReferenceSkeleton />
          ) : (
            referenceTable.list.map((item) => {
              const isActive = item.id === referenceTable.selectedCardId;
              return (
                <TableCard
                  isActive={isActive}
                  variant={referenceTable.currentTab}
                  classNames={['reference-table__card']}
                  key={item.id}
                  title={item.name}
                  specialty={item.specialty}
                  medInstitutions={item.medInstitutions}
                  subTitle={item?.specialty?.name || ''}
                  onClick={() => {
                    controller.handleCardClick(item.id);
                    props.onTableCardClick &&
                      props.onTableCardClick(
                        formCardDataByType(referenceTable.currentTab, item)
                      );
                  }}
                />
              );
            })
          )}
        </div>
        {!referenceTable.list.length && (
          <NoDataPlaceholder
            styles={{ marginTop: 24, height: 'calc(100% - 24px)' }}
          />
        )}
      </section>
      <div className="reference-table__pagination-container">
        <Pagination
          count={referenceTable.pages}
          onChange={controller.onPageClick}
          page={referenceTable.currentPage}
        />
      </div>
    </div>
  );
};

const EMITTER_EVENT = 'emitter-event';
export const EMITTER_EVENTS = {
  UPDATE_CATALOG: `${EMITTER_EVENT}:update-catalog`,
  UPDATE_TASKS: `${EMITTER_EVENT}:update-tasks`,
  UPDATE_MEETUP_DETAILS: `${EMITTER_EVENT}:update-meetup-details`,
  UPDATE_MEETUP_LIST: `${EMITTER_EVENT}:update-meetup-list`,
  UPDATE_FEEDBACK: `${EMITTER_EVENT}:update-feedback`,
  UPDATE_MIS_DOWNLOADS: `${EMITTER_EVENT}:update-mis-downloads`,
  UPDATE_DOCTORS_WITH_FILTERS: `${EMITTER_EVENT}:update-doctors-with-filters`,
  CLEAR_DOCTORS_FILTERS: `${EMITTER_EVENT}:clear-doctors-filters`,
  CLEAR_DOCTORS_DYNAMIC: `${EMITTER_EVENT}:clear-doctors-dynamic`,
  CREATE_DOCTOR_SUCCESS: `${EMITTER_EVENT}:create-doctor-success`,
  CREATE_LPU_SUCCESS: `${EMITTER_EVENT}:create-lpu-success`,
  CREATE_SPECIALTY_SUCCESS: `${EMITTER_EVENT}:create-specialty-success`,

  UPDATE_CHANGELOG_WITH_SEARCH: `${EMITTER_EVENT}:update-changelog-with-search`,

  UPDATE_HISTORY_WITH_FILTERS: `${EMITTER_EVENT}:update-history-with-filters`,
  APPLY_HISTORY_FILTERS: `${EMITTER_EVENT}:apply-history-filters`,
  CLEAR_HISTORY_FILTERS: `${EMITTER_EVENT}:clear-history-filters`,

  OPEN_COMPLETE_TASK_MENU: `${EMITTER_EVENT}:open-complete-task-menu`,
  UPDATE_DOCTORS_WITH_DYNAMIC: `${EMITTER_EVENT}:update-doctors-with-dynamic`,

  UPDATE_DOCTOR_MEETUPS_DONE: `${EMITTER_EVENT}:update-doctor-meetup-done`,

  SET_SERVER_ERROR: `${EMITTER_EVENT}:set-server-error`,
  SET_TOKENS_ERROR: `${EMITTER_EVENT}:set-tokens-error`,
  SET_AUTH_ERROR: `${EMITTER_EVENT}:set-auth-error`,

  CLEAR_SELECTED_CARD: `${EMITTER_EVENT}:clear-selected-card`,
  SET_SELECTED_CARD: `${EMITTER_EVENT}:set-selected-card`,

  SUCCESS_CLOSE_MASS_ENVELOPES: `${EMITTER_EVENT}:success-close-mass-envelops`,
};

import React, { CSSProperties } from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';
import { CircularProgress } from '@mui/material';

interface IPercentageProgressProps {
  value: number;
  classNames?: string[];
  styles?: CSSProperties;
  thickness?: number;
}

const PercentageProgress = (props: IPercentageProgressProps) => {
  return (
    <div className={classNamesParser('percentage-progress', props.classNames)}>
      <CircularProgress
        className="percentage-progress__background"
        value={100}
        style={props.styles}
        variant={'determinate'}
        thickness={props.thickness}
      />
      <CircularProgress
        className="percentage-progress__progress"
        value={props.value}
        style={props.styles}
        variant="determinate"
        thickness={props.thickness}
      />
      <span className="percentage-progress__percents">{props.value}%</span>
    </div>
  );
};

export default PercentageProgress;

import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '../Drawer';
import Sidebar from '../../components/Sidebar';
import { classNamesParser } from '../../helpers/classNamesParser';

interface IBurgerMenuProps {
  classNames?: string[];
}

export const BurgerMenu = (props: IBurgerMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const onMenuClick = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <div className={classNamesParser('burger-menu', props.classNames)}>
      <div className="burger-menu__icon-container" onClick={onMenuClick}>
        <MenuIcon />
      </div>
      <Drawer
        open={open}
        anchor="left"
        onClose={onClose}
        classNames={['burger-menu__drawer']}
      >
        <Sidebar classNames={['burger-menu__sidebar']} />
      </Drawer>
    </div>
  );
};

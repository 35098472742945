import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackStatusTypes } from '../../../types/feedbacks.types';
import { MeetupFeedbackTypes } from '../../../types/tasks.types';
import { IFeedbackComment, IFeedbackUser } from '../interfaces';
import { FEEDBACK_COMMENTS_LIMIT } from '../constants';
import { ResponseImage } from '../../../types/images.types';
interface IFeedbackMenuState {
  isLoading: boolean;
  isCommentLoading: boolean;
  currentFeedbackId?: number;
  comment: string;
  liable?: IFeedbackUser;
  liables: IFeedbackUser[];
  createdBy?: IFeedbackUser;
  updatedBy?: IFeedbackUser;
  status?: FeedbackStatusTypes | string;
  type?: MeetupFeedbackTypes;
  createdAt: string;
  comments: IFeedbackComment[];
  page: number;
  offset: number;
  limit: number;
  total: number;
  doctorName?: string;
  isAddingComment: boolean;
  commentInput: string;
  photos: ResponseImage[];
}

const initialState: IFeedbackMenuState = {
  isLoading: false,
  isCommentLoading: false,
  comment: '',
  createdAt: '',
  liables: [],
  comments: [],
  page: 1,
  offset: 0,
  total: 0,
  limit: FEEDBACK_COMMENTS_LIMIT,
  isAddingComment: false,
  commentInput: '',
  status: '',
  photos: [],
};

export const feedbackMenuSlice = createSlice({
  name: 'feedback-menu',
  initialState,
  reducers: {
    setCommentLoading: (state, action: PayloadAction<boolean>) => {
      state.isCommentLoading = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCurrentFeedbackId: (state, action: PayloadAction<number>) => {
      state.currentFeedbackId = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setLiable: (state, action: PayloadAction<IFeedbackUser>) => {
      state.liable = action.payload;
    },
    setCreatedBy: (state, action: PayloadAction<IFeedbackUser>) => {
      state.createdBy = action.payload;
    },
    setUpdatedBy: (state, action: PayloadAction<IFeedbackUser>) => {
      state.updatedBy = action.payload;
    },
    setStatus: (state, action: PayloadAction<FeedbackStatusTypes | string>) => {
      state.status = action.payload;
    },
    setType: (state, action: PayloadAction<MeetupFeedbackTypes>) => {
      state.type = action.payload;
    },
    setCreatedAt: (state, action: PayloadAction<string>) => {
      state.createdAt = action.payload;
    },
    setLiables: (state, action: PayloadAction<IFeedbackUser[]>) => {
      state.liables = action.payload;
    },
    setComments: (state, action: PayloadAction<IFeedbackComment[]>) => {
      state.comments = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setDoctorName: (state, action: PayloadAction<string>) => {
      state.doctorName = action.payload;
    },
    setIsAddingComment: (state, action: PayloadAction<boolean>) => {
      state.isAddingComment = action.payload;
    },
    setCommentInput: (state, action: PayloadAction<string>) => {
      state.commentInput = action.payload;
    },
    setPhotos: (state, action: PayloadAction<ResponseImage[]>) => {
      state.photos = action.payload;
    },
  },
});

export const {
  setLoading,
  setCurrentFeedbackId,
  setComment,
  setCreatedAt,
  setCreatedBy,
  setLiable,
  setStatus,
  setType,
  setUpdatedBy,
  setPhotos,
  setComments,
  setLiables,
  setOffset,
  setPage,
  setDoctorName,
  setTotal,
  setIsAddingComment,
  setCommentInput,
  setCommentLoading,
} = feedbackMenuSlice.actions;
export const feedbackMenuReducer = feedbackMenuSlice.reducer;

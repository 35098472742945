import Compressor from 'compressorjs';

const sizes = {
  big: 4000000,
  normal: 2000000,
  small: 1000000,
};

async function compressFile(file: File[], quality?: number): Promise<File[]>;
async function compressFile(file: File, quality?: number): Promise<File>;
async function compressFile(
  data: File[] | File,
  quality?: number
): Promise<File[] | File> {
  const files = Array.isArray(data) ? data : [data];
  const compressedFiles = await Promise.all(
    files.map((file) => compress(file, quality))
  );
  return Array.isArray(data) ? compressedFiles : compressedFiles[0];
}

const compress = async (file: File, quality?: number): Promise<File> => {
  return new Promise<File>((res) => {
    new Compressor(file, {
      quality: quality ?? getQuality(file.size),
      success(compressedFile) {
        res(compressedFile as File);
      },
      error() {
        res(file);
      },
    });
  });
};

export const strictCompress = async (file: File): Promise<File> => {
  let resultFile: File = file;
  for (
    let quality = getQuality(file.size);
    resultFile.size > sizes.small;
    quality -= 0.1
  ) {
    if (quality <= 0) {
      throw new Error('Unable to compress file');
    }
    resultFile = await compress(file, quality);
  }
  return resultFile;
};

const getQuality = (size: number) => {
  if (size > sizes.big) {
    return 0.5;
  }
  if (size > sizes.normal) {
    return 0.6;
  }
  if (size > sizes.small) {
    return 0.7;
  }

  return 1;
};

export default compressFile;

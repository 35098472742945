import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomizedHookItem } from '../../../components/CustomizedHook/customized-hook.interfaces';
import moment from 'moment/moment';

interface IReferenceFiltersState {
  isLoading: boolean;
  specialities: ICustomizedHookItem[];
  selectedSpecialities: ICustomizedHookItem[];
  specialitySearchText: string;
  lpuSearchText: string;
  citySearchText: string;
  lpuList: any[];
  selectedLpu: ICustomizedHookItem[];
  cityList: any[];
  selectedAppValue: string | null;
  lastMeetupDate: string | null;
  activeType: string;
  selectedCities: ICustomizedHookItem[];
  districts: any[];
  startDate: string;
  endDate: string;
  isShowDynamicValues: boolean;
  dynamicFrom: string;
  dynamicTo: string;
  errors: {
    lastMeetupDate: string;
  };
}

const initialState: IReferenceFiltersState = {
  isLoading: false,
  specialities: [],
  selectedSpecialities: [],
  specialitySearchText: '',
  lpuSearchText: '',
  citySearchText: '',
  lpuList: [],
  selectedLpu: [],
  cityList: [],
  selectedAppValue: '',
  lastMeetupDate: null,
  activeType: '',
  selectedCities: [],
  districts: [],
  startDate: moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'),
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  isShowDynamicValues: false,
  dynamicFrom: '0',
  dynamicTo: '0',
  errors: {
    lastMeetupDate: '',
  },
};

export const referenceFiltersSlice = createSlice({
  name: 'reference-filters',
  initialState,
  reducers: {
    setLastMeetupDateError: (state, action: PayloadAction<string>) => {
      state.errors.lastMeetupDate = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSpecialities: (state, action: PayloadAction<ICustomizedHookItem[]>) => {
      state.specialities = action.payload;
    },
    setSelectedSpeciality: (
      state,
      action: PayloadAction<ICustomizedHookItem>
    ) => {
      state.selectedSpecialities.push(action.payload);
    },
    setSpecialitySearchText: (state, action: PayloadAction<string>) => {
      state.specialitySearchText = action.payload;
    },
    deleteSpeciality: (state, action: PayloadAction<ICustomizedHookItem>) => {
      state.selectedSpecialities = state.selectedSpecialities.filter(
        (item) => item.id !== action.payload.id
      );
    },
    clearSelectedSpecialities: (state) => {
      state.selectedSpecialities = [];
    },
    deleteCity: (state, action: PayloadAction<ICustomizedHookItem>) => {
      state.selectedCities = state.selectedCities.filter(
        (item) => item.id !== action.payload.id
      );
    },
    setSelectedLpu: (
      state,
      action: PayloadAction<ICustomizedHookItem | any>
    ) => {
      state.selectedLpu.push(action.payload);
    },
    setLpuSearchText: (state, action: PayloadAction<string>) => {
      state.lpuSearchText = action.payload;
    },
    setLpuList: (state, action: PayloadAction<any[]>) => {
      state.lpuList = action.payload;
    },
    deleteLpu: (state, action: PayloadAction<ICustomizedHookItem>) => {
      state.selectedLpu = state.selectedLpu.filter(
        (item) => item.id !== action.payload.id
      );
    },
    clearSelectedLpu: (state) => {
      state.selectedLpu = [];
    },
    setCityList: (state, action: PayloadAction<any[]>) => {
      state.cityList = action.payload;
    },
    setSelectedApp: (state, action: PayloadAction<string | null>) => {
      state.selectedAppValue = action.payload;
    },
    setLastMeetupDate: (state, action: PayloadAction<string | null>) => {
      state.lastMeetupDate = action.payload;
    },
    setActiveType: (state, action: PayloadAction<string>) => {
      state.activeType = action.payload;
    },
    setSelectedCity: (state, action: PayloadAction<ICustomizedHookItem>) => {
      state.selectedCities.push(action.payload);
    },
    setSelectedDistrict: (
      state,
      action: PayloadAction<{
        cityIndex: number;
        district?: string;
      }>
    ) => {
      const subOptions =
        state.selectedCities[action.payload.cityIndex]?.subOptions || [];

      if (subOptions && subOptions.includes(action.payload.district)) {
        const filteredOptions = subOptions.filter(
          (item) => item !== action.payload.district
        );
        state.selectedCities[action.payload.cityIndex] = {
          ...state.selectedCities[action.payload.cityIndex],
          subOptions: filteredOptions,
        };
      } else {
        subOptions && subOptions.push(action.payload.district);
        state.selectedCities[action.payload.cityIndex] = {
          ...state.selectedCities[action.payload.cityIndex],
          subOptions,
        };
      }
    },
    setCitySearchText: (state, action: PayloadAction<string>) => {
      state.citySearchText = action.payload;
    },
    setDistricts: (state, action: PayloadAction<any[]>) => {
      state.districts = action.payload;
    },
    setSelectedCities: (state, action: PayloadAction<any[]>) => {
      state.selectedCities = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    setIsShowDynamicValues: (state, action: PayloadAction<boolean>) => {
      state.isShowDynamicValues = action.payload;
    },
    setDynamicFrom: (state, action: PayloadAction<string>) => {
      state.dynamicFrom = action.payload;
    },
    setDynamicTo: (state, action: PayloadAction<string>) => {
      state.dynamicTo = action.payload;
    },
  },
});

export const {
  setLoading,
  setSelectedSpeciality,
  setSelectedLpu,
  setSpecialities,
  deleteSpeciality,
  setSpecialitySearchText,
  setLpuSearchText,
  setLpuList,
  deleteLpu,
  setCityList,
  clearSelectedSpecialities,
  clearSelectedLpu,
  setSelectedApp,
  setLastMeetupDate,
  setActiveType,
  setSelectedCity,
  setCitySearchText,
  setDistricts,
  deleteCity,
  setSelectedDistrict,
  setSelectedCities,
  setStartDate,
  setEndDate,
  setIsShowDynamicValues,
  setDynamicFrom,
  setDynamicTo,
  setLastMeetupDateError,
} = referenceFiltersSlice.actions;
export const referenceFiltersReducer = referenceFiltersSlice.reducer;

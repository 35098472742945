import { IAppSelectOption } from '../../ui/AppSelect/app-select.interface';

export const feedbackTypeOptions: IAppSelectOption[] = [
  {
    id: 1,
    text: 'Все',
    value: '',
    helpText: 'Показать все',
  },
  {
    id: 2,
    text: 'Общие вопросы',
    value: 'common',
  },
  {
    id: 3,
    text: 'Мобильное приложение',
    value: 'mobile_app',
  },
  {
    id: 4,
    text: 'Внести изменения в личные данные',
    value: 'update_personal_data',
  },
];

import { SelectChangeEvent } from '@mui/material';
import { AppDispatch, RootState, store } from '../../app.store';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';
import { TaskService } from '../../services/tasks.service';
import { TaskStatusTypes } from '../../types/tasks.types';
import { TaskDetailFiltersType } from './index.types';
import {
  setAvailableExecutors,
  setCreatedAt,
  setDescription,
  setDescriptionInput,
  pushDoctors,
  setExecutors,
  clearDoctors,
  setFilter,
  setHeader,
  setIsEditingDescription,
  setLoading,
  setOffset,
  setHaveMore,
  setSearchInput,
  setSearchValue,
  setStatus,
  setTaskId,
  setType,
  removeDoctorByIndex,
} from './store/task-detail.slice';
import { UsersService } from '../../services/users.service';
import { TaskDetailFilters } from './enums';
import { ChangeEvent } from 'react';
import { ITaskExecutor } from './interfaces';
import { TaskFiltersEnum } from '../../components/TaskFilters/task-filters.enum';
import { ViewController } from '../../interfaces/general.interfaces';

export class TaskDetailController extends ViewController {
  taskService: TaskService = new TaskService();
  usersService: UsersService = new UsersService();

  onFilterParamChange = (param: TaskFiltersEnum) => {
    this.dispatch(setFilter(param));
    localStorage.taskDetailParam = param;
    this.refreshList();
  };

  setTaskId = (id: number) => {
    this.dispatch(setTaskId(id));
  };

  getTask = async () => {
    const state = this.getState().taskDetail;
    if (!state.taskId) return;
    const data = await this.taskService.getTaskByID(state.taskId);
    if (data) {
      this.getExecutors();
      this.getDoctors();
      this.dispatch(setStatus(data.status));
      this.dispatch(setType(data.type));
      this.dispatch(setCreatedAt(data.createdAt));
      this.dispatch(setDescriptionInput(data.description));
      this.dispatch(setIsEditingDescription(false));
      this.dispatch(setDescription(data.description));
      this.dispatch(
        setExecutors(
          data.executors?.map((executor: ITaskExecutor) => String(executor.id))
        )
      );
      this.dispatch(setHeader(data.header));
    }
  };

  getExecutors = async () => {
    const data = await this.usersService.getUsers(null, null, 'active');
    if (data) {
      this.dispatch(setAvailableExecutors(data.data));
    }
  };

  getDoctors = async () => {
    const state = this.getState().taskDetail;
    if (!state.taskId || !state.haveMore || state.isLoading) return;
    this.dispatch(setLoading(true));
    const data = await this.taskService.getDoctors(
      state.taskId,
      state.limit,
      state.offset,
      state.filter,
      !!state.searchValue ? state.searchValue : null
    );
    if (data) {
      this.dispatch(pushDoctors(data.data));
      this.dispatch(setOffset(state.offset + state.limit));
      const haveMore = data.total > state.doctors.length + data.data.length;
      this.dispatch(setHaveMore(haveMore));
    }
    this.dispatch(setLoading(false));
  };

  onDeleteDoctor = async (id: number, index: number) => {
    const state = this.getState().taskDetail;
    if (!state.taskId) return;
    const response = await this.taskService.removeDoctorFromTask(
      state.taskId,
      id
    );
    if (response !== null) {
      this.dispatch(removeDoctorByIndex(index));
    }
  };

  onChangeStatus = async (status: TaskStatusTypes) => {
    const state = this.getState().taskDetail;
    if (!state.taskId) return;
    await this.taskService.updateTask(state.taskId, { status });
    this.getTask();
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_TASKS);
  };

  onDeleteTask = async () => {
    const state = this.getState().taskDetail;
    if (!state.taskId) return;
    await this.taskService.deleteTask(state.taskId);
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_TASKS);
  };

  onExecutorsChange = async (e: SelectChangeEvent<string[]>) => {
    const state = this.getState().taskDetail;
    if (!state.taskId) return;
    const executorID = Number(e.target.value[e.target.value.length - 1]);
    if (state.executors.includes(String(executorID)))
      await this.taskService.removeExecutor(state.taskId, executorID);
    else await this.taskService.addExecutor(state.taskId, executorID);
    const data = await this.taskService.getTaskByID(state.taskId);
    if (data) {
      this.dispatch(
        setExecutors(
          data.executors?.map((executor: ITaskExecutor) => String(executor.id))
        )
      );
    }
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_TASKS);
  };

  checkParamInLocalstorage = () => {
    const state = this.getState().taskDetail;

    const param = localStorage.taskDetailParam;
    const isNeedToChange =
      param && param !== state.filter && param.toUpperCase() in TaskFiltersEnum;

    if (isNeedToChange) {
      this.onFilterParamChange(param);
    }
  };

  clearState = () => {
    this.dispatch(setHaveMore(true));
    this.dispatch(clearDoctors());
    this.dispatch(setOffset(0));
    this.dispatch(setSearchInput(''));
    this.dispatch(setSearchValue(''));
  };

  onStartEditingDescription = () => {
    const state = this.getState().taskDetail;
    this.dispatch(setIsEditingDescription(true));
    this.dispatch(setDescriptionInput(state.description || ''));
  };
  onCancelEditingDescription = () => {
    this.dispatch(setIsEditingDescription(false));
  };
  onChangeDescriptionInput = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setDescriptionInput(e.target.value));
  };

  onEditDescription = async () => {
    const state = this.getState().taskDetail;
    if (!state.taskId) return;
    const data = await this.taskService.updateTask(state.taskId, {
      description: state.descriptionInput,
    });
    if (data === null) return;
    this.dispatch(setIsEditingDescription(false));
    this.dispatch(setDescription(state.descriptionInput));
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_TASKS);
  };

  onSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setSearchInput(e.target.value));
  };

  onSearchClear = () => {
    const state = this.getState().taskDetail;
    if (state.searchValue === '') return;
    this.dispatch(setSearchInput(''));
    this.dispatch(setSearchValue(''));
    this.refreshList();
  };

  onSearch = () => {
    const state = this.getState().taskDetail;
    this.dispatch(setSearchValue(state.searchInput));
    this.refreshList();
  };

  refreshList = async () => {
    this.dispatch(setHaveMore(true));
    this.dispatch(clearDoctors());
    this.dispatch(setOffset(0));
    this.dispatch(setLoading(false));
    await this.getDoctors();
    await this.getDoctors();
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferenceTypes } from '../../../modules/ReferenceTable';
import {
  IApiFormattedDoctorItem,
  IApiLpuItem,
  IApiSpecialityItem,
} from '../../../interfaces/api.catalogs.interfaces';
import { TaskVariantTypes } from '../../../types/tasks.types';

interface ICurrentEditTaskData {
  taskId: number;
  type: TaskVariantTypes;
}
interface IReferenceCatalogPageState {
  editDoctorModal: boolean;
  addDoctorModal: boolean;
  editLpuModal: boolean;
  addLpuModal: boolean;
  editSpecialityModal: boolean;
  addSpecialityModal: boolean;
  referenceFiltersModal: boolean;
  referenceDynamicModal: boolean;
  meetupResultModal: boolean;
  currentTab: ReferenceTypes;
  currentEditData:
    | IApiSpecialityItem
    | IApiLpuItem
    | IApiFormattedDoctorItem
    | any;

  currentEditTaskData?: ICurrentEditTaskData;
}

const initialState: IReferenceCatalogPageState = {
  editDoctorModal: false,
  addDoctorModal: false,
  editLpuModal: false,
  addLpuModal: false,
  editSpecialityModal: false,
  addSpecialityModal: false,
  referenceFiltersModal: false,
  referenceDynamicModal: false,
  currentTab: 'doctors',
  currentEditData: {},
  meetupResultModal: false,
};

export const referenceCatalogPageSlice = createSlice({
  name: 'reference-catalog-page',
  initialState,
  reducers: {
    setShowEditDoctorModal: (state, action: PayloadAction<boolean>) => {
      state.editDoctorModal = action.payload;
    },
    setShowAddDoctorModal: (state, action: PayloadAction<boolean>) => {
      state.addDoctorModal = action.payload;
    },
    setShowEditLpuModal: (state, action: PayloadAction<boolean>) => {
      state.editLpuModal = action.payload;
    },
    setShowAddLpuModal: (state, action: PayloadAction<boolean>) => {
      state.addLpuModal = action.payload;
    },
    setShowEditSpecialityModal: (state, action: PayloadAction<boolean>) => {
      state.editSpecialityModal = action.payload;
    },
    setShowAddSpecialityModal: (state, action: PayloadAction<boolean>) => {
      state.addSpecialityModal = action.payload;
    },
    setShowReferenceFiltersModal: (state, action: PayloadAction<boolean>) => {
      state.referenceFiltersModal = action.payload;
    },
    setShowReferenceDynamicModal: (state, action: PayloadAction<boolean>) => {
      state.referenceDynamicModal = action.payload;
    },
    setShowMeetupResultModal: (state, action: PayloadAction<boolean>) => {
      state.meetupResultModal = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<ReferenceTypes>) => {
      state.currentTab = action.payload;
    },
    setCloseModals: (state) => {
      state.editDoctorModal = false;
      state.addDoctorModal = false;
      state.editLpuModal = false;
      state.addLpuModal = false;
      state.editSpecialityModal = false;
      state.addSpecialityModal = false;
      state.referenceFiltersModal = false;
    },
    setCurrentEditData: (
      state,
      action: PayloadAction<
        IApiSpecialityItem | IApiLpuItem | IApiFormattedDoctorItem | any
      >
    ) => {
      state.currentEditData = action.payload;
    },

    setCurrentEditTaskData: (
      state,
      action: PayloadAction<ICurrentEditTaskData | undefined>
    ) => {
      state.currentEditTaskData = action.payload;
    },
  },
});

export const {
  setShowEditDoctorModal,
  setShowAddDoctorModal,
  setShowEditLpuModal,
  setShowAddLpuModal,
  setShowEditSpecialityModal,
  setShowAddSpecialityModal,
  setShowReferenceFiltersModal,
  setCurrentTab,
  setCurrentEditData,
  setShowReferenceDynamicModal,
  setCloseModals,
  setShowMeetupResultModal,
  setCurrentEditTaskData,
} = referenceCatalogPageSlice.actions;
export default referenceCatalogPageSlice.reducer;

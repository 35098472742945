import React from 'react';
import Logo from '../../ui/Logo';
import { classNamesParser } from '../../helpers/classNamesParser';
import SidebarMenuItem from '../SidebarMenuItem';
import {
  matchPath,
  matchRoutes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { ReactComponent as UserIcon } from '../../assets/vector/user.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import RequiredRole from '../RequiredRole';
import { UserController } from '../../controllers/user.controller';
import DeleteConfirmMenu from '../DeleteConfirmMenu';
import { ReactComponent as IconWorkProgress } from '../../assets/vector/sidebar_work_progress.svg';
import { ReactComponent as IconWorkComplete } from '../../assets/vector/sidebar_work_complete.svg';
import { ReactComponent as IconTaskList } from '../../assets/vector/sidebar_task_list.svg';
import { ReactComponent as IconFeedback } from '../../assets/vector/sidebar_feedback.svg';
import { ReactComponent as IconImportMis } from '../../assets/vector/sidebar_import_mis.svg';
import { ReactComponent as IconRefs } from '../../assets/vector/sidebar_refs.svg';
import { ReactComponent as IconUsers } from '../../assets/vector/sidebar_users.svg';
import { ReactComponent as IconReportDynamic } from '../../assets/vector/sidebar_report_dynamic.svg';
import { ReactComponent as IconReportMeetups } from '../../assets/vector/sidebar_report_meetups.svg';

interface ISidebarProps {
  classNames?: string[];
}

const Sidebar = (props: ISidebarProps) => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userController = new UserController(dispatch);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isLogoutMenuOpen = Boolean(anchorEl);
  const handleOpenLogoutMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseLogoutMenu = () => {
    setAnchorEl(null);
  };

  const onNavigate = (path: string) => {
    const navigatePath = path === '/' ? path : `/${path}`;
    navigate(navigatePath);
  };

  return (
    <aside className={classNamesParser('sidebar', props.classNames)}>
      <Logo classNames={['sidebar__logo']} />
      <RequiredRole roles={['medical_agent']}>
        <div className="sidebar__meetups">
          <h3 className="sidebar__heading">Встречи</h3>
          <SidebarMenuItem
            text="В работе"
            // count={9}
            counterColor="#FF4C06"
            classNames={['sidebar__sidebar-menu-item']}
            isActive={!!matchPath(ROUTES.MEETUPS, location.pathname)}
            onClick={() => onNavigate(ROUTES.MEETUPS)}
            icon={<IconWorkProgress className="sidebar-menu-item__icon" />}
          />
          <SidebarMenuItem
            text="Завершены"
            // count={32}
            isActive={!!matchPath(ROUTES.COMPLETED_MEETUPS, location.pathname)}
            onClick={() => onNavigate(ROUTES.COMPLETED_MEETUPS)}
            classNames={['sidebar__sidebar-menu-item']}
            icon={<IconWorkComplete className="sidebar-menu-item__icon" />}
          />
          <SidebarMenuItem
            text="Обратная связь"
            // count={32}
            isActive={!!matchPath(ROUTES.FEEDBACK, location.pathname)}
            onClick={() => onNavigate(ROUTES.FEEDBACK)}
            classNames={['sidebar__sidebar-menu-item']}
            icon={<IconFeedback className="sidebar-menu-item__icon" />}
          />
        </div>
      </RequiredRole>

      <RequiredRole roles={['admin', 'manager']}>
        <div className="sidebar__tasks">
          <h3 className="sidebar__heading">Задания и обратная связь</h3>
          <SidebarMenuItem
            text="В работе"
            // count={9}
            counterColor="#FF4C06"
            classNames={['sidebar__sidebar-menu-item']}
            isActive={!!matchPath(ROUTES.TASKS_PAGE, location.pathname)}
            onClick={() => onNavigate(ROUTES.TASKS_PAGE)}
            icon={<IconTaskList className="sidebar-menu-item__icon" />}
          />
          <SidebarMenuItem
            text="История встреч"
            // count={32}
            isActive={!!matchPath(ROUTES.MEETUP_HISTORY, location.pathname)}
            onClick={() => onNavigate(ROUTES.MEETUP_HISTORY)}
            classNames={['sidebar__sidebar-menu-item']}
            icon={<IconFeedback className="sidebar-menu-item__icon" />}
          />{' '}
          <SidebarMenuItem
            text="Обратная связь"
            // count={32}
            isActive={!!matchPath(ROUTES.FEEDBACK, location.pathname)}
            onClick={() => onNavigate(ROUTES.FEEDBACK)}
            classNames={['sidebar__sidebar-menu-item']}
            icon={<IconFeedback className="sidebar-menu-item__icon" />}
          />
        </div>
      </RequiredRole>
      <RequiredRole roles={['admin', 'medical_agent', 'manager']}>
        <div className="sidebar__menu">
          <h3 className="sidebar__heading">Справочники и данные</h3>
          <RequiredRole roles={['admin', 'manager']}>
            <SidebarMenuItem
              text="Загрузка из МИС"
              classNames={['sidebar__sidebar-menu-item']}
              onClick={() => onNavigate(ROUTES.UPLOAD)}
              isActive={!!matchPath(ROUTES.UPLOAD, location.pathname)}
              icon={<IconImportMis className="sidebar-menu-item__icon" />}
            />
          </RequiredRole>
          <RequiredRole roles={['admin', 'medical_agent', 'manager']}>
            <SidebarMenuItem
              text="Справочники"
              classNames={['sidebar__sidebar-menu-item']}
              onClick={() => onNavigate(ROUTES.REFERENCE_CATALOG)}
              isActive={
                !!matchPath(ROUTES.REFERENCE_CATALOG, location.pathname)
              }
              icon={<IconRefs className="sidebar-menu-item__icon" />}
            />
          </RequiredRole>
          <RequiredRole roles={['admin']}>
            <SidebarMenuItem
              text="Пользователи"
              classNames={['sidebar__sidebar-menu-item']}
              onClick={() => onNavigate(ROUTES.USERS)}
              isActive={!!matchPath(ROUTES.USERS, location.pathname)}
              icon={<IconUsers className="sidebar-menu-item__icon" />}
            />
          </RequiredRole>
        </div>
      </RequiredRole>

      <RequiredRole roles={['admin', 'manager']}>
        <div className="sidebar__reports">
          <h3 className="sidebar__heading">Отчеты</h3>
          <SidebarMenuItem
            text="Динамика назначений"
            classNames={['sidebar__sidebar-menu-item']}
            onClick={() => onNavigate(ROUTES.DYNAMIC)}
            isActive={!!matchPath(ROUTES.DYNAMIC, location.pathname)}
            icon={<IconReportDynamic className="sidebar-menu-item__icon" />}
          />
          <SidebarMenuItem
            text="Статистика по встречам"
            classNames={['sidebar__sidebar-menu-item']}
            onClick={() => onNavigate(ROUTES.MEETUPS_REPORT)}
            isActive={!!matchPath(ROUTES.MEETUPS_REPORT, location.pathname)}
            icon={<IconReportMeetups className="sidebar-menu-item__icon" />}
          />
          <SidebarMenuItem
            text="История встреч"
            classNames={['sidebar__sidebar-menu-item']}
            onClick={() => onNavigate(ROUTES.HISTORY_REPORT)}
            isActive={!!matchPath(ROUTES.HISTORY_REPORT, location.pathname)}
            icon={<IconReportMeetups className="sidebar-menu-item__icon" />}
          />
          <RequiredRole roles={['admin']}>
            <SidebarMenuItem
              text="Журнал изменений"
              classNames={['sidebar__sidebar-menu-item']}
              onClick={() => onNavigate(ROUTES.CHANGELOG)}
              isActive={!!matchPath(ROUTES.CHANGELOG, location.pathname)}
              icon={<IconReportMeetups className="sidebar-menu-item__icon" />}
            />
          </RequiredRole>
        </div>
      </RequiredRole>

      <div className="sidebar__user">
        <div className="sidebar__user-info" onClick={handleOpenLogoutMenu}>
          <UserIcon className="sidebar__user-icon" />
          <span className="sidebar__username">{user.fullName}</span>
        </div>
      </div>
      <DeleteConfirmMenu
        open={isLogoutMenuOpen}
        onClose={handleCloseLogoutMenu}
        onConfirm={userController.logout}
        anchorEl={anchorEl}
        text="Вы уверены, что хотите выйти?"
        deleteText="Выйти"
      />
    </aside>
  );
};

export default Sidebar;

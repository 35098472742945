import React, { ChangeEvent, ReactNode, useMemo, useRef } from 'react';
import { withProps } from '../../helpers/withProps';

interface IUploadFileProps {
  asChild?: boolean;
  children?: ReactNode;
  onChange?: (value: File[]) => void;
  multiple?: boolean;
}

export const UploadFile = (props: IUploadFileProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    const files = props.multiple ? e.target.files : [e.target.files[0]];

    props.onChange && props.onChange(files as File[]);

    if (inputRef.current) inputRef.current.value = '';
  };

  const Component = useMemo(() => {
    if (!props.asChild) return props.children;
    else {
      const child = withProps([props.children], {
        onClick: () => inputRef.current?.click(),
      });
      return child;
    }
  }, [props.asChild]);

  return (
    <>
      <input
        type="file"
        multiple={props.multiple}
        accept="image/*"
        ref={inputRef}
        onChange={handleUpload}
        hidden
      />
      {Component}
    </>
  );
};

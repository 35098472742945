import { Button } from '@mui/material';
import React, { useState, MouseEvent, ChangeEvent } from 'react';
import Input from '../../ui/Input';
import RoundButton from '../../ui/RoundButton';
import { ReactComponent as PhoneIcon } from '../../assets/vector/phone.svg';
import { Close } from '@mui/icons-material';
import DeleteConfirmMenu from '../DeleteConfirmMenu';
import { classNamesParser } from '../../helpers/classNamesParser';
import { removeLetters } from '../../helpers/removeLetters';
import { getPhoneValidationErrors } from './helpers';
import { VALIDATION_TEXT_ERROR } from './constants';

interface IMobileEditContactsForm {
  classNames?: string[];
  contacts: string[];
  onClose?: () => void;
  onSave: (contacts: string[]) => void;
}

const MobileEditContactsForm = (props: IMobileEditContactsForm) => {
  const [contacts, setContacts] = useState<string[]>(props.contacts);
  const [errors, setErrors] = useState<string[]>(
    new Array(props.contacts.length)
  );
  const [phoneIdToDelete, setPhoneIdToDelete] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>, id: number) => {
    setPhoneIdToDelete(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddContact = () => {
    setContacts((prev) => [...prev, '']);
  };
  const onDeleteConfirm = () => {
    handleClose();
    if (phoneIdToDelete === null) {
      return;
    }
    setContacts((prev) => prev.filter((_, i) => i !== phoneIdToDelete));
  };

  const handleContactChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const _contacts = [...contacts];
    _contacts[id] = removeLetters(e.target.value);
    setContacts(_contacts);
  };

  const handleSave = () => {
    const errors = getPhoneValidationErrors(contacts);
    setErrors(errors);
    const haveError = errors.includes(VALIDATION_TEXT_ERROR);

    if (haveError) {
      return;
    }
    props.onSave(contacts);
  };

  return (
    <div
      className={classNamesParser(
        'mobile-edit-contacts-form',
        props.classNames
      )}
    >
      <div className="mobile-edit-contacts-form__header">
        <Button
          className="mobile-edit-contacts-form__close"
          onClick={props.onClose}
        >
          Отменить
        </Button>
        <h5 className="mobile-edit-contacts-form__title">Контакты</h5>
        <Button
          className="mobile-edit-contacts-form__close"
          onClick={handleSave}
        >
          Готово
        </Button>
      </div>
      <div className="mobile-edit-contacts-form__inputs">
        {contacts?.map((phone, id: number) => (
          <div className="mobile-edit-contacts-form__input-wrapper">
            <Input
              fullWidth
              type="tel"
              value={phone}
              error={errors[id]}
              key={id}
              autoFocus={id === (contacts.length || 1) - 1}
              onInput={(e) => handleContactChange(e, id)}
              classNames={['mobile-edit-contacts-form__input']}
            />
            <RoundButton
              content={<Close />}
              onClick={(e) => handleClick(e, id)}
              classNames={['mobile-edit-contacts-form__delete-btn']}
            />
          </div>
        ))}
      </div>
      {contacts.length < 3 && (
        <Button
          className="mobile-edit-contacts-form__add"
          onClick={handleAddContact}
        >
          <PhoneIcon />
          Добавить
        </Button>
      )}

      <DeleteConfirmMenu
        onClose={handleClose}
        open={open}
        text="Вы действительно хотите удалить контакт?"
        deleteText="Удалить"
        anchorEl={anchorEl}
        onConfirm={onDeleteConfirm}
      />
    </div>
  );
};

export default MobileEditContactsForm;

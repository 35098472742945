import React from 'react';
import {
  TaskStatusTypes,
  TaskVariantTypes,
} from '../../../../types/tasks.types';
import { ReactComponent as CompletedIcon } from '../../../../assets/vector/checkbox-checked.svg';
import { ReactComponent as NavigateNextIcon } from '../../../../assets/vector/next.svg';
import { ReactComponent as MeetupIcon } from '../../../../assets/vector/handshake.svg';
import { ReactComponent as EnvelopeProgress } from '../../../../assets/vector/envelope-given-rounded.svg';
import { ReactComponent as EnvelopeDone } from '../../../../assets/vector/envelope-success.svg';
import { MeetupStatusTypes } from '../../../../types/tasks.types';
import { ICreatedBy } from '../../../../interfaces/api.catalogs.interfaces';
import { getInitialsFromFullName } from '../../../../helpers/getInitialsFromFullName';

interface IMeetupTaskProps {
  completed: boolean;
  variant: TaskVariantTypes;
  note?: string;
  date: string;
  id: number;
  onClick?: (type: TaskVariantTypes, id: number) => void;
  meetupFinishedAt?: string;
  isOwnFinished?: boolean;
  meetupStatus: MeetupStatusTypes;
  finishedBy?: ICreatedBy;
}

const MeetupTask = (props: IMeetupTaskProps) => {
  const handleTaskClick = () => {
    if (props.completed && !props.isOwnFinished) return;
    props.onClick && props.onClick(props.variant, props.id);
  };

  const getIconClassnameByStatus = () =>
    `meetup-task__icon ${props.meetupStatus === 'failed' ? 'failed' : ''}`;

  const finishedInitials = getInitialsFromFullName(
    props.finishedBy?.fullName || ''
  );

  return (
    <div
      className={`meetup-task ${props.completed ? 'completed' : ''}`}
      onClick={handleTaskClick}
      style={{
        pointerEvents:
          props.completed && !props.isOwnFinished ? 'none' : 'unset',
      }}
    >
      {/* {props.completed && (
        <CompletedIcon className="meetup-task__completed-icon" />
      )} */}
      <div
        className={`meetup-task__icon-wrapper ${
          props.meetupStatus === 'failed'
            ? 'failed'
            : props.completed
            ? 'completed'
            : ''
        }`}
      >
        {props.variant === 'envelope' ? (
          props.completed ? (
            <EnvelopeDone className={getIconClassnameByStatus()} />
          ) : (
            <EnvelopeProgress className={getIconClassnameByStatus()} />
          )
        ) : (
          <MeetupIcon className={getIconClassnameByStatus()} />
        )}
      </div>
      <div className="meetup-task__info">
        <div className="meetup-task__info-content">
          <h4 className="meetup-task__variant">
            {!!props.finishedBy?.fullName && (
              <span className="meetup-task__finished-initials">
                {finishedInitials}
              </span>
            )}
            {props.variant === 'envelope' ? 'Конверт' : 'Встреча'}
          </h4>
          <div className="meetup-task__date-wrapper">
            <p className="meetup-task__date">
              {props.completed
                ? `${props.meetupFinishedAt}`
                : `Задание от ${props.date}`}{' '}
            </p>
          </div>
        </div>
        {props.note && <p className="meetup-task__note">{props.note}</p>}
      </div>

      {props.completed && !props.isOwnFinished ? null : (
        <NavigateNextIcon className="meetup-task__next-icon" />
      )}
    </div>
  );
};

export default MeetupTask;

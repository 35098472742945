import React, { useCallback, useEffect } from 'react';
import { ILpuDynamicData } from '../../interfaces';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import DynamicTableRow from '../DynamicTableRow';
import { TableVirtuoso } from 'react-virtuoso';
import DynamicTableComponent from '../DynamicTableComponent';
import { DynamicFixedHeader } from '../DynamicFixedHeader';

interface ILpuTableProps {
  data: ILpuDynamicData[];
  headingDates: string[];
  loadFunc: () => void;
  clearTable: () => void;
}

const LpuDynamicTable = (props: ILpuTableProps) => {
  const loadMore = useCallback(
    () => setTimeout(() => props.loadFunc(), 0),
    [props.loadFunc]
  );

  useEffect(() => {
    const timeout = loadMore();

    return () => {
      props.clearTable();
      clearTimeout(timeout);
    };
  }, []);
  return props.data.length > 0 ? (
    <TableVirtuoso
      components={DynamicTableComponent}
      fixedHeaderContent={() => (
        <DynamicFixedHeader title={'ЛПУ'} headingDates={props.headingDates} />
      )}
      data={props.data}
      endReached={loadMore}
      itemContent={(i, item: ILpuDynamicData) => {
        return (
          <DynamicTableRow
            key={item.lpu.id}
            id={item.lpu.id}
            name={item.lpu.name}
            units={item.units}
            dynamic={item.dynamic}
          />
        );
      }}
    />
  ) : (
    <NoDataPlaceholder styles={{ margin: '32px 0' }} />
  );
};

export default LpuDynamicTable;

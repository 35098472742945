import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Switch from '../../../../ui/Switch';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { MeetupResultController } from '../../meetup-result.controller';
import { ReactComponent as RadioCheckedIcon } from '../../../../assets/vector/radio-checked.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../../../assets/vector/radio-unchecked.svg';
import { ReactComponent as NoteIcon } from '../../../../assets/vector/note.svg';
import FailureCommentary from '../FailureCommentary';
import AppModal from '../../../../components/AppModal';
import CommentEdit from '../CommentEdit';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import AppSelect from '../../../../ui/AppSelect';
import {
  ENVELOPE_VALUE_NAMES,
  envelopeOptions,
  MEETUP_VALUE_NAMES,
  meetupOptions,
} from '../../constants';
import {
  setError,
  setIsSuccessMeetup,
  setType,
} from '../../store/meetup-result.store';
import ErrorAlert from '../../../../ui/ErrorAlert';
import { AttachImage } from '../AttachImage';
import compressFile, { strictCompress } from '../../../../helpers/compressFile';
interface IMeetupResultProps {
  onClose: () => void;
  taskId?: number;
  doctorId?: number;
  onSuccess?: () => void;
  type?: TaskVariantTypes;
  isUnscheduled?: boolean;
}

export const MeetupResult = (props: IMeetupResultProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const dispatch = useAppDispatch();
  const meetupResult = useAppSelector((state) => state.meetupResult);
  const controller = new MeetupResultController(dispatch);

  useEffect(() => {
    dispatch(setType(props.type || ''));

    if (props.doctorId) {
      controller.setDoctorId(props.doctorId);
      controller.getDoctorInfo();
    }

    if (props.taskId) {
      controller.setTaskId(props.taskId);
      controller.getMeetupResult();
    }

    dispatch(
      setIsSuccessMeetup(
        props.type !== 'envelope'
          ? MEETUP_VALUE_NAMES.MEETUP_DONE
          : ENVELOPE_VALUE_NAMES.ENVELOPE_DONE
      )
    );

    return () => {
      controller.clearState();
    };
  }, [props.taskId, props.doctorId]);

  const handleConfirmClick = () => {
    controller.onConfirmMeetup(files, props.onSuccess, props.isUnscheduled);
  };

  const onDeleteResult = () => controller.deleteMeetupResult(props.onSuccess);

  const getDoctorName = (): string => {
    let result = '';

    if (meetupResult.doctorInfo) {
      const { firstName, lastName, middleName } = meetupResult.doctorInfo;
      result = `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    }

    return result;
  };

  const getMeetupResult = () => {
    let result = false;
    const resultMeetupValue = meetupResult.isSuccessMeetup;

    if (props.type === 'meetup') {
      result = resultMeetupValue === MEETUP_VALUE_NAMES.MEETUP_DONE;
    }

    if (props.type === 'envelope') {
      result = resultMeetupValue === ENVELOPE_VALUE_NAMES.ENVELOPE_DONE;
    }

    return result;
  };

  const onAddFiles = async (value: File[]) => {
    const strictCompressResult = await Promise.allSettled(
      [...value].map((file) => strictCompress(file))
    ).then((res) =>
      res.map((res) => {
        if (res.status === 'fulfilled') {
          return res.value;
        } else {
          return 'Не удалось сжать изображение, выберите другое';
        }
      })
    );

    //@ts-ignore
    const successCompressedFiles: File[] = strictCompressResult.filter(
      (result) => typeof result !== 'string'
    );

    const error = strictCompressResult.find((res) => typeof res === 'string');

    if (error) {
      dispatch(setError(error as string));
    }

    setFiles((p) => [...p, ...successCompressedFiles]);
  };

  return (
    <div
      className={`meetup-result ${meetupResult.meetupID ? 'completed' : ''}`}
    >
      <div className="meetup-result__header">
        <h3 className="meetup-result__title">{getDoctorName()}</h3>
        <RoundButton onClick={props.onClose} content={<CloseIcon />} />
      </div>

      <div className="meetup-result__content">
        <div className="meetup-result__personal-meetup">
          <AppSelect
            options={
              props.type !== 'envelope' ? meetupOptions : envelopeOptions
            }
            value={meetupResult.isSuccessMeetup}
            handleChange={controller.onSuccessMeetupChange}
          />
        </div>

        {getMeetupResult() ? (
          <>
            {props.type !== 'envelope' && (
              <div className="meetup-result__meetup-score">
                <h5 className="meetup-result__score-title">Оценка встречи</h5>
                <div className="meetup-result__score-wrapper">
                  <RadioGroup
                    value={meetupResult.score}
                    onChange={controller.onScoreChange}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checkedIcon={<RadioCheckedIcon />}
                          icon={<RadioUncheckedIcon />}
                        />
                      }
                      value={'good'}
                      label="Хорошо"
                    />
                    <FormControlLabel
                      value={'neutral'}
                      control={
                        <Radio
                          checkedIcon={<RadioCheckedIcon />}
                          icon={<RadioUncheckedIcon />}
                        />
                      }
                      label="Удовлетворительно"
                    />
                    <FormControlLabel
                      value={'bad'}
                      control={
                        <Radio
                          checkedIcon={<RadioCheckedIcon />}
                          icon={<RadioUncheckedIcon />}
                        />
                      }
                      label="Плохо"
                    />
                  </RadioGroup>
                </div>
              </div>
            )}
            <div
              className="meetup-result__comment-wrapper"
              onClick={controller.openCommentEditModal}
            >
              <h5 className="meetup-result__comment-title">
                {meetupResult.comment ? 'Комментарий' : 'Добавьте комментарий'}
              </h5>
              <NoteIcon className="meetup-result__comment-icon" />
              {meetupResult.comment && (
                <p className="meetup-result__comment">{meetupResult.comment}</p>
              )}
            </div>
            <div className="meetup-result__feedback-container">
              <div className="meetup-result__feedback-wrapper">
                <div className="meetup-result__feedback-header">
                  <h5 className="meetup-result__feedback-title">
                    Добавьте обратную связь
                  </h5>
                  <Switch
                    checked={meetupResult.isFeedbackChecked}
                    onChange={controller.onFeedbackCheck}
                  />
                </div>
                {meetupResult.isFeedbackChecked && (
                  <div className="meetup-result__feedback">
                    <RadioGroup
                      value={meetupResult.feedbackType}
                      onChange={controller.onFeedbackTypeChange}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checkedIcon={<RadioCheckedIcon />}
                            icon={<RadioUncheckedIcon />}
                          />
                        }
                        value={'common'}
                        label="Общие вопросы"
                      />
                      <FormControlLabel
                        value={'mobile_app'}
                        control={
                          <Radio
                            checkedIcon={<RadioCheckedIcon />}
                            icon={<RadioUncheckedIcon />}
                          />
                        }
                        label="Мобильное приложение"
                      />
                      <FormControlLabel
                        value={'update_personal_data'}
                        control={
                          <Radio
                            checkedIcon={<RadioCheckedIcon />}
                            icon={<RadioUncheckedIcon />}
                          />
                        }
                        label="Внести изменения в личные данные"
                      />
                    </RadioGroup>
                  </div>
                )}
              </div>
              {meetupResult.isFeedbackChecked && (
                <AttachImage
                  images={meetupResult.images}
                  files={files}
                  removedImages={meetupResult.removedImages}
                  onRemoveImage={controller.onRemoveImage}
                  onAddFiles={onAddFiles}
                  onRemoveFile={(index) =>
                    setFiles((p) => p.filter((_, i) => i !== index))
                  }
                />
              )}
            </div>
          </>
        ) : (
          <FailureCommentary
            value={meetupResult.failComment}
            onChange={controller.onFailureCommentChange}
          />
        )}

        <ErrorAlert
          error={meetupResult.error}
          classNames={['meetup-result__error']}
        />

        {getMeetupResult() ? (
          <Button
            className="meetup-result__confirm-btn"
            onClick={handleConfirmClick}
            disabled={meetupResult.isLoading}
          >
            {props.type === 'envelope' ? 'Конверт отдан' : 'Завершить'}
          </Button>
        ) : (
          <Button
            className="meetup-result__failure-btn"
            onClick={handleConfirmClick}
            disabled={meetupResult.isLoading}
          >
            Встреча не состоялась
          </Button>
        )}

        {meetupResult.meetupID && !props.isUnscheduled && (
          <Button
            className="meetup-result__delete-result"
            onClick={onDeleteResult}
            disabled={meetupResult.isLoading}
          >
            Отменить завершение
          </Button>
        )}
      </div>

      <AppModal
        hideBackdrop
        onClose={controller.closeCommentEditModal}
        open={meetupResult.isCommentEditModalVisible}
        classNames={['meetup-result__comment-edit-modal']}
      >
        <CommentEdit
          onClose={controller.closeCommentEditModal}
          onSave={controller.onCommentSave}
          comment={meetupResult.commentInput}
          onChange={controller.onCommentChange}
        />
      </AppModal>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHANGELOG_LIST_LIMIT } from '../constants';
import { IDoctorChangesItem } from '../interfaces';

interface IChangelogListState {
  isLoading: boolean;
  items: IDoctorChangesItem[];
  limit: number;
  offset: number;
  page: number;
  total: number;
  searchValue: string;
}

const initialState: IChangelogListState = {
  isLoading: false,
  items: [],
  limit: CHANGELOG_LIST_LIMIT,
  offset: 0,
  page: 1,
  total: 0,
  searchValue: '',
};

export const changelogList = createSlice({
  name: 'changelog-list',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setItems: (state, action: PayloadAction<IDoctorChangesItem[]>) => {
      state.items = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const {
  setItems,
  setLimit,
  setLoading,
  setOffset,
  setPage,
  setTotal,
  setSearchValue,
} = changelogList.actions;
export const changelogListReducer = changelogList.reducer;

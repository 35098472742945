import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { Button, IconButton, Pagination } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ResultAlert from '../ResultAlert';
import { MoreHoriz } from '@mui/icons-material';
import UploadRow from '../UploadRow';
import { UploadTableController } from '../../upload-table.controller';
import Switch from '../../../../ui/Switch';
import SearchButton from '../../../../ui/SearchButton';
import { Virtuoso } from 'react-virtuoso';

interface IUploadTableProps {
  fileId: number | null;
  onUploadToStats?: () => Promise<void> | void;
}

export const UploadTable = (props: IUploadTableProps) => {
  const uploadTable = useAppSelector((state) => state.uploadTable);
  const dispatch = useAppDispatch();
  const controller = new UploadTableController(dispatch);

  const loadData = useCallback(
    () => setTimeout(() => controller.getFileData(), 0),
    [controller.getFileData]
  );

  useEffect(() => {
    if (props.fileId) {
      controller.clearState();
      controller.setCurrentFileId(props.fileId);
      controller.getFileData();
    }

    const timeout = loadData();

    return () => {
      controller.clearState();
      clearTimeout(timeout);
    };
  }, []);

  const onUploadToStats = async () => {
    props.onUploadToStats && (await props.onUploadToStats());
    controller.getFileData();
  };

  return (
    <div className="upload-table">
      <div className="upload-table__header">
        <div className="upload-table__show-match-wrapper">
          <Switch
            checked={uploadTable.isHideMatched}
            onChange={controller.onChangeHideMatched}
          />
          <span className="upload-table__match-label">
            Показать только без соответствия{' '}
            {uploadTable.mismatchedCount > 0 &&
              `(${uploadTable.mismatchedCount})`}
          </span>
        </div>

        <div className="upload-table__controls-wrapper">
          <SearchButton
            open={uploadTable.isSearchOpened}
            value={uploadTable.searchInput}
            onOpen={controller.onOpenSearch}
            placeholder="Введите ФИО"
            onClose={controller.onCloseSearch}
            onSearch={controller.onSearch}
            onInput={controller.onSearchInput}
          />
          {uploadTable.isLoadCountVisible &&
            (uploadTable.loadedToStatistics ? (
              <ResultAlert
                count={uploadTable.total}
                text={`Загружено ${uploadTable.total} записей.`}
                onClose={controller.hideResultAlert}
                classNames={['upload-table__result-alert']}
              />
            ) : (
              <Button onClick={onUploadToStats}>Обработать записи</Button>
            ))}
        </div>
      </div>
      <div className="upload-table__list">
        <div className="upload-table__header">
          <h4 className="upload-table__header-title">ФИО из МИС</h4>
          <p className="upload-table__header-title">Сумма назначений</p>
          <h4 className="upload-table__header-title">ФИО в системе</h4>
          <div></div>
        </div>
        <Virtuoso
          data={uploadTable.data}
          endReached={loadData}
          itemContent={(i, item) => (
            <UploadRow
              key={item.id}
              id={item.id}
              index={i}
              searchedDoctors={uploadTable.searchedDoctors}
              fullName={item.misDoctorName}
              localFullName={item.doctorFullname}
              amount={item.sum}
              doctorCandidates={item.doctorCandidates}
              onMatchDoctor={controller.onMatchDoctor}
              clearSearchedDoctors={controller.clearSearchedDoctors}
              onClearReport={controller.clearDoctorReport}
            />
          )}
          components={{
            Header: () => <div style={{ marginBottom: 16 }}></div>,
            Footer: () => <div style={{ padding: '32px 0' }}></div>,
          }}
        />
      </div>
    </div>
  );
};

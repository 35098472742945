import { Skeleton } from '@mui/material';
import React from 'react';

const MeetupSkeleton = () => {
  return (
    <div className="meetup-skeleton">
      <Skeleton className="meetup-skeleton__name" />
      <Skeleton className="meetup-skeleton__info" />
    </div>
  );
};

export default MeetupSkeleton;

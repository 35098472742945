import { Skeleton } from '@mui/material';
import React from 'react';

const TaskSkeletonLoader = () => {
  return (
    <div className="task-skeleton-loader">
      <Skeleton height={42} />
    </div>
  );
};

export default TaskSkeletonLoader;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRolesTypes } from '../types/users.types';
import { IErrorUI } from '../interfaces/general.interfaces';

interface IErrors {
  fullName: string;
  login: string;
  password: string;
  addUser: string;
}

interface IAddUserFormState {
  fullName: string;
  phoneNumber: string;
  email: string;
  login: string;
  password: string;
  role: UserRolesTypes;
  errors: IErrors;
}

const initialState: IAddUserFormState = {
  fullName: '',
  phoneNumber: '',
  email: '',
  login: '',
  password: '',
  role: 'admin',
  errors: {
    fullName: '',
    login: '',
    password: '',
    addUser: '',
  },
};

export const addUserFormSlice = createSlice({
  name: 'add-user-form',
  initialState,
  reducers: {
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setLogin: (state, action: PayloadAction<string>) => {
      state.login = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setRole: (state, action: PayloadAction<UserRolesTypes>) => {
      state.role = action.payload;
    },
    setError: (state, action: PayloadAction<IErrorUI>) => {
      state.errors[action.payload.type as keyof IErrors] =
        action.payload.message;
    },
    clearState: (state: any) => {
      for (const [key] of Object.entries(initialState)) {
        state[key] = initialState[key as keyof {}];
      }
    },
  },
});

export const {
  setEmail,
  setFullName,
  setLogin,
  setPassword,
  setPhoneNumber,
  setRole,
  setError,
  clearState,
} = addUserFormSlice.actions;
export const addUserFormReducer = addUserFormSlice.reducer;

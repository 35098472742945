import React, { useEffect } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Form } from 'react-router-dom';
import Input from '../../../../ui/Input';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { EditSpecialityFormController } from '../../edit-speciality-form.controller';
import RoundMenuButton from '../../../../ui/RoundMenuButton';
import { IRoundMenuButtonItem } from '../../../../ui/RoundMenuButton/index.interfaces';
import { clearData, setData } from '../../store/edit-speciality-form.slice';
import { IApiSpecialityItem } from '../../../../interfaces/api.catalogs.interfaces';
import RequiredRole from '../../../../components/RequiredRole';

interface IEditLpuFormProps {
  classNames?: string[];
  onCloseModal?: () => void;
  editData?: IApiSpecialityItem;
}

const roundButtonItems: IRoundMenuButtonItem[] = [
  {
    id: 1,
    text: 'Деактивировать',
  },
  {
    id: 2,
    text: 'Удалить',
    color: '#FF4C06',
  },
];

export const EditSpecialityForm = (props: IEditLpuFormProps) => {
  const editSpecialityForm = useAppSelector(
    (state) => state.editSpecialityForm
  );
  const dispatch = useAppDispatch();
  const controller = new EditSpecialityFormController(dispatch);

  useEffect(() => {
    dispatch(clearData());

    if (props.editData && !!props.editData?.name) {
      console.log(props.editData);

      dispatch(setData(props.editData));
    } else {
      props.editData?.id && controller.fetchSpecialityById(props.editData?.id);
    }
  }, [props.editData]);

  const onSave = () => controller.onSaveClick(props.onCloseModal);

  const onCancel = () => {
    dispatch(clearData());
    props.onCloseModal && props.onCloseModal();
  };

  const onMenuClick = (value: string) =>
    controller.onMenuClick(value, props.onCloseModal);

  const roundButtonItems: IRoundMenuButtonItem[] = [
    {
      id: 2,
      text: editSpecialityForm?.isDeleted ? 'Активировать' : 'Деактивировать',
      color: editSpecialityForm?.isDeleted ? '#2574D0' : '#FF4C06',
    },
    {
      id: 3,
      text: 'Удалить',
      color: '#FF4C06',
      roles: ['admin'],
    },
  ];

  return (
    <div className={classNamesParser('edit-speciality-form', props.classNames)}>
      <div className="edit-speciality-form__heading">
        <div className="edit-speciality-form__title-container">
          <h3 className="edit-speciality-form__title">Специальность</h3>
          {editSpecialityForm.isDeleted ? (
            <Chip label="Запись не активна" className="edit-lpu-form__status" />
          ) : (
            ''
          )}
        </div>
        <div className="edit-speciality-form__heading-controls">
          {!editSpecialityForm.isEdit && (
            <>
              <RoundMenuButton
                classNames={['edit-speciality-form__menu']}
                menuItems={roundButtonItems}
                onMenuClick={onMenuClick}
              />

              <RoundButton
                content={<EditNoteIcon />}
                backgroundColor="rgb(243 243 243)"
                onClick={controller.onEditClick}
                classNames={['edit-speciality-form__edit']}
              />
              <RoundButton
                classNames={['edit-speciality-form__close-modal']}
                onClick={props.onCloseModal}
                content={<CloseIcon />}
              />
            </>
          )}
        </div>
      </div>
      <Form style={{ marginBottom: 24 }}>
        <Input
          label={editSpecialityForm.isEdit ? '' : 'Наименование'}
          placeholder={editSpecialityForm.isEdit ? 'Наименование' : ''}
          disabled={!editSpecialityForm.isEdit}
          classNames={['edit-speciality-form__form-input']}
          fullWidth
          value={editSpecialityForm.name}
          onInput={controller.handleEditName}
        />
      </Form>
      {editSpecialityForm.isEdit && (
        <div className="edit-speciality-form__buttons">
          <Button
            className="edit-speciality-form__cancel"
            //  onClick={onCancel}
            onClick={controller.handleCancelEditClick}
          >
            Отмена
          </Button>
          <Button className="edit-speciality-form__save" onClick={onSave}>
            Сохранить
          </Button>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import RoundedLabel from '../../ui/RoundedLabel';
import SubinfoDoctorSpan from '../../ui/SubinfoDoctorSpan';
import {
  IApiDoctor,
  IApiDoctorMeetupsDone,
} from '../../interfaces/api.catalogs.interfaces';
import {
  MeetupStatusTypes,
  TaskStatusTypes,
  TaskVariantTypes,
} from '../../types/tasks.types';
import {
  FEEDBACK_RESULT_MARKS_MAP,
  MEETUP_RESULT_MARKS_MAP,
  MEETUP_WORD_MAP,
} from '../../constants/app.constants';
import { ReactComponent as MeetupIcon } from '../../assets/vector/handshake.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/vector/envelope-given-rounded.svg';
import { ITaskExecutor } from '../../interfaces/tasks.interfaces';
import moment from 'moment';
import { NavigateNext } from '@mui/icons-material';

interface IMeetupResult extends IApiDoctorMeetupsDone {
  meetupStatus: MeetupStatusTypes;
  editable?: boolean;
}

interface IMeetupHistoryItemProps {
  doctor?: IApiDoctor;
  meetups: IMeetupResult[];
  onMeetupClick?: (taskID: number, type: TaskVariantTypes) => void;
}

const MeetupHistoryItem = (props: IMeetupHistoryItemProps) => {
  const doctorFullName = `${props.doctor?.lastName || ''} ${
    props.doctor?.firstName || ''
  } ${props.doctor?.middleName || ''}`;

  const onMeetupClick = (taskID: number, type: TaskVariantTypes) => {
    props.onMeetupClick && props.onMeetupClick(taskID, type);
  };

  return (
    <div
      className={`meetup-history-item ${
        props.doctor ? 'meetup-history-item_have-doctor' : ''
      }`}
    >
      {props.doctor && (
        <div className="meetup-history-item__doctor">
          {/* //TODO: Добавить инфу о докторе */}
          <h4 className="meetup-history-item__doctor-name">{doctorFullName}</h4>
          <div className="meetup-history-item__doctor-info">
            {props.doctor?.medInstitutions?.map((lpu) => (
              <SubinfoDoctorSpan variant="lpu" value={lpu?.name || ''} />
            ))}
            {!!props.doctor?.specialty?.name && (
              <SubinfoDoctorSpan
                variant="specialty"
                value={props.doctor?.specialty?.name}
              />
            )}
          </div>
        </div>
      )}

      {props.meetups?.map((result) => {
        const executor = result.updatedBy;

        const label =
          result.resultComment || result.feedback
            ? result.feedback
              ? FEEDBACK_RESULT_MARKS_MAP[result.feedback.type]
              : 'Комментарий'
            : '';

        const modifier =
          result.resultComment || result.feedback
            ? result.feedback
              ? 'meetup-history-item__info_feedback'
              : 'meetup-history-item__info_comment'
            : '';

        const isHaveNoInfo =
          !result.resultComment && !result.resultMark && !result.feedback;

        const onClick = () => {
          if (!result.editable) return;
          onMeetupClick(result.taskID, result.type);
        };

        return (
          <div
            className={`meetup-history-item__meetup ${
              result.editable ? 'meetup-history-item__meetup_editable' : ''
            }`}
            onClick={onClick}
          >
            <div className="meetup-history-item__header">
              <div className="meetup-history-item__type-wrapper">
                <div
                  className={`meetup-history-item__icon-wrapper ${
                    result.meetupStatus === 'done'
                      ? ''
                      : 'meetup-history-item__icon-wrapper_failed'
                  }`}
                >
                  {result.type === 'envelope' ? (
                    <EnvelopeIcon
                      className={`meetup-history-item__icon ${
                        result.meetupStatus === 'done'
                          ? ''
                          : 'meetup-history-item__icon_failed'
                      }`}
                    />
                  ) : (
                    <MeetupIcon
                      className={`meetup-history-item__icon ${
                        result.meetupStatus === 'done'
                          ? ''
                          : 'meetup-history-item__icon_failed'
                      }`}
                    />
                  )}
                </div>
                <h4 className="meetup-history-item__type">
                  {MEETUP_WORD_MAP[result.type]}
                </h4>
              </div>

              <div className="meetup-history-item__executor">
                <RoundedLabel
                  classNames={[
                    'meetup-history-item__rounded-label',
                    !props.doctor
                      ? 'meetup-history-item__rounded-label_short'
                      : '',
                  ]}
                  fullname={executor?.fullName || ''}
                />

                {props.doctor && (
                  <span className="meetup-history-item__executor-name">
                    {executor?.fullName},
                  </span>
                )}

                <span>{moment(result.updatedAt).format('DD MMMM YYYY')}</span>

                {result.editable && (
                  <NavigateNext className="meetup-history-item__next-icon" />
                )}
              </div>
            </div>

            {!isHaveNoInfo && (
              <div className={`meetup-history-item__info ${modifier}`}>
                {!!label && (
                  <span className="meetup-history-item__label">{label}</span>
                )}

                {!!result.resultComment && (
                  <p className="meetup-history-item__content">
                    {result.resultComment}
                  </p>
                )}

                {result.resultMark && (
                  <p className="meetup-history-item__score">
                    Оценка: {MEETUP_RESULT_MARKS_MAP[result.resultMark]}
                  </p>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MeetupHistoryItem;

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import HistoryItem from '../HistoryItem';
import { UploadHistoryController } from '../../upload-history.controller';
import { CircularProgress, Pagination } from '@mui/material';
import useAdaptivePaginationLimit from '../../../../hooks/useAdaptivePaginationLimit';
import { UPLOAD_HISTORY_ADAPTIVE_PAGINATION } from '../../../../constants/adaptive-pagintation.constants';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';

interface IUploadHistoryListProps {
  handleCancelDownload?: (e?: any) => void;
  onUploadItemClick: (
    id: number,
    isNew: boolean,
    date: string,
    filename: string
  ) => void;
}

export const UploadHistoryList = (props: IUploadHistoryListProps) => {
  const uploadHistory = useAppSelector((state) => state.uploadHistory);
  const dispatch = useAppDispatch();
  const adapitveLimit = useAdaptivePaginationLimit(
    UPLOAD_HISTORY_ADAPTIVE_PAGINATION
  );

  const controller = new UploadHistoryController(dispatch);

  useEffect(() => {
    controller.setLimit(adapitveLimit);
  }, []);

  useEffect(() => {
    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_MIS_DOWNLOADS,
      controller.getUploadHistory
    );
    controller.getUploadHistory();

    return () => {
      EventEmitter.unsubscribe(
        EMITTER_EVENTS.UPDATE_MIS_DOWNLOADS,
        controller.getUploadHistory
      );
    };
  }, []);

  return (
    <div className="upload-history-list">
      {/* {uploadHistory.isDownloading && (
        <div className="upload-history-list__download-progress">
          <CircularProgress />
          Загрузка файла...
        </div>
      )} */}
      <div className="upload-history-list__list">
        {!uploadHistory.isLoading && uploadHistory.history.length === 0 && (
          <div>Загруженных файлов нет</div>
        )}
        {uploadHistory.history.length ? (
          uploadHistory.history.map((item) => (
            <HistoryItem
              key={item.id}
              id={item.id}
              name={item.filename}
              date={item.date}
              loads={item.successLoadedRecords}
              onClick={props.onUploadItemClick}
              onCreateTask={controller.onCreateTask}
              onDownloadPDF={controller.downloadPDF}
              onDownloadExcel={controller.downloadExcel}
              handleCancelDownload={props.handleCancelDownload}
              loadedToStatistics={item.loadedToStatistics}
              downloadingID={uploadHistory.downloadingID}
            />
          ))
        ) : (
          <span>Данных нет</span>
        )}
      </div>
      <Pagination
        className="upload-history-list__pagination"
        count={Math.ceil(uploadHistory.total / uploadHistory.limit)}
        page={uploadHistory.page}
        onChange={controller.onPageChange}
      />
    </div>
  );
};

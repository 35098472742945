import React from 'react';
import RoundButton from '../../ui/RoundButton';
import { Menu, MenuItem } from '@mui/material';
import { getFilterIconByKey } from './getFilterIconByKey';
import { TaskFiltersEnum } from './task-filters.enum';
import { ReactComponent as FacilityIcon } from '../../assets/vector/facility-filter.svg';
import { ReactComponent as SpecialityIcon } from '../../assets/vector/speciality-filter.svg';
interface ITaskFilterProps {
  classNames?: string[];
  filter: TaskFiltersEnum;
  onChangeFilter: (param: TaskFiltersEnum) => void;
}

const TaskFilters = (props: ITaskFilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterIcon = getFilterIconByKey(props.filter);

  const handleSelectFilter = (param: TaskFiltersEnum) => {
    props.onChangeFilter(param);
    handleClose();
  };

  return (
    <>
      <RoundButton
        onClick={handleClick}
        content={
          filterIcon ? (
            <img src={filterIcon} className="task-filters__filter-icon" />
          ) : (
            <span className="task-filters__alphabet-btn">АБВ</span>
          )
        }
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleSelectFilter(TaskFiltersEnum.DOCTOR_FULLNAME);
          }}
        >
          <p className="task-filters__alphabet-menu-filter">АБВ</p>
          По алфавиту
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectFilter(TaskFiltersEnum.LPU_NAME);
          }}
        >
          <FacilityIcon className="task-filters__filter-icon-menu" />
          По ЛПУ
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectFilter(TaskFiltersEnum.SPECIALTY_NAME);
          }}
        >
          <SpecialityIcon className="task-filters__filter-icon-menu" />
          По специальности
        </MenuItem>
      </Menu>
    </>
  );
};

export default TaskFilters;

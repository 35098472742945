import { VALIDATION_TEXT_ERROR } from './constants';

export const getPhoneValidationErrors = (contacts: string[]): string[] => {
  const regEx = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

  const errors =
    contacts.map((phoneNumber) =>
      regEx.test(phoneNumber) ? '' : VALIDATION_TEXT_ERROR
    ) || [];

  return errors;
};

import { PROFILE } from '../api/api';
import axios from '../axios/axios';
import { UserActiveTypes } from '../types/users.types';

export class UserService {
  getProfile = async () => {
    let result = null;
    try {
      const response = await axios.get(PROFILE.GET_PROFILE);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
}

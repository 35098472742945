import { useEffect, useState } from 'react';
import { MEDIA } from '../constants/app.constants';
import { useMediaQuery } from '@mui/material';

export const useVisualViewportOffsetHeight = (offset: number) => {
  const [inputHeight, setInputHeight] = useState('100%');
  const matchSm = useMediaQuery(`(max-width:${MEDIA.SM}px)`);

  const resizeForm = async () => {
    if (!matchSm) {
      setInputHeight('100%');
      return;
    }
    setInputHeight(`${(visualViewport?.height || 0) - offset}px` || '100%');
  };

  useEffect(() => {
    visualViewport?.addEventListener('resize', resizeForm);

    return () => {
      visualViewport?.removeEventListener('resize', resizeForm);
    };
  }, []);

  return { inputHeight, resizeForm };
};

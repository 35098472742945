import { TaskStatusTypes } from '../../../types/tasks.types';

export const getLocaleStatusByKey = (key: TaskStatusTypes | string) => {
  const icons = {
    'draft': 'Черновик',
    'in_progress': 'В работе',
    'done': 'Завершено',
  };

  return icons[key as keyof object] || '';
};

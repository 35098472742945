import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MeetupFeedbackTypes,
  MeetupResultMarkTypes,
  TaskVariantTypes,
} from '../../../types/tasks.types';
import { IApiDoctor } from '../../../interfaces/api.catalogs.interfaces';
import { ResponseImage } from '../../../types/images.types';
interface ITaskDetailState {
  score: MeetupResultMarkTypes;
  isFeedbackChecked: boolean;
  isSuccessMeetup: string;
  comment: string;
  isCommentEditModalVisible: boolean;
  commentInput: string;
  feedbackType: MeetupFeedbackTypes;
  failComment: string;
  taskID?: number;
  doctorID?: number;
  meetupID: number | null;
  doctorInfo: IApiDoctor | null;
  type: TaskVariantTypes | string;
  files: File[];
  images: ResponseImage[];
  removedImages: string[];
  isLoading: boolean;
  error: string;
}

const initialState: ITaskDetailState = {
  score: 'good',
  isFeedbackChecked: false,
  isSuccessMeetup: '',
  comment: '',
  commentInput: '',
  isCommentEditModalVisible: false,
  feedbackType: 'common',
  failComment: '',
  meetupID: null,
  type: '',
  error: '',
  isLoading: false,
  files: [],
  removedImages: [],
  images: [],
  doctorInfo: {
    id: 0,
    archived: null,
    firstName: null,
    middleName: null,
    lastName: null,
    meetupDone: null,
    specialty: null,
    birthday: null,
    contactPhones: null,
    note: null,
    medInstitutions: null,
    hasInstalledApp: null,
  },
};

export const meetupResultSlice = createSlice({
  name: 'meetup-result',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<TaskVariantTypes | string>) => {
      state.type = action.payload;
    },
    setScore: (state, action: PayloadAction<MeetupResultMarkTypes>) => {
      state.score = action.payload;
    },
    setFeedbackType: (state, action: PayloadAction<MeetupFeedbackTypes>) => {
      state.feedbackType = action.payload;
    },
    setIsFeedbackChecked: (state, action: PayloadAction<boolean>) => {
      state.isFeedbackChecked = action.payload;
    },
    setIsSuccessMeetup: (state, action: PayloadAction<string>) => {
      state.isSuccessMeetup = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setIsCommentEditModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isCommentEditModalVisible = action.payload;
    },
    setCommentInput: (state, action: PayloadAction<string>) => {
      state.commentInput = action.payload;
    },

    setFailComment: (state, action: PayloadAction<string>) => {
      state.failComment = action.payload;
    },
    setTaskID: (state, action: PayloadAction<number>) => {
      state.taskID = action.payload;
    },
    setDoctorID: (state, action: PayloadAction<number>) => {
      state.doctorID = action.payload;
    },
    setMeetupID: (state, action: PayloadAction<number | null>) => {
      state.meetupID = action.payload;
    },
    setDoctorInfo: (state, action: PayloadAction<IApiDoctor | null>) => {
      if (state.doctorInfo) {
        state.doctorInfo.id = action.payload?.id || 0;
        state.doctorInfo.archived = action.payload?.archived || null;
        state.doctorInfo.firstName = action.payload?.firstName || null;
        state.doctorInfo.middleName = action.payload?.middleName || null;
        state.doctorInfo.lastName = action.payload?.lastName || null;
        state.doctorInfo.meetupDone = action.payload?.meetupDone || null;
        state.doctorInfo.specialty = action.payload?.specialty || null;
        state.doctorInfo.birthday = action.payload?.birthday || null;
        state.doctorInfo.contactPhones = action.payload?.contactPhones || null;
        state.doctorInfo.note = action.payload?.note || null;
        state.doctorInfo.medInstitutions =
          action.payload?.medInstitutions || null;
        state.doctorInfo.hasInstalledApp =
          action.payload?.hasInstalledApp || null;
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addFiles: (state, action: PayloadAction<File[]>) => {
      state.files = [...state.files, ...action.payload];
    },
    removeFile: (state, action: PayloadAction<number>) => {
      state.files = state.files.filter((_, i) => i !== action.payload);
    },
    setImages: (state, action: PayloadAction<ResponseImage[]>) => {
      state.images = action.payload;
    },
    removeImage: (state, action: PayloadAction<string>) => {
      state.removedImages = [...state.removedImages, action.payload];
    },
    setRemovedImages: (state, action: PayloadAction<string[]>) => {
      state.removedImages = action.payload;
    },
  },
});

export const {
  setRemovedImages,
  removeImage,
  setImages,
  setScore,
  setIsFeedbackChecked,
  setIsSuccessMeetup,
  setComment,
  setCommentInput,
  setIsCommentEditModalVisible,
  setFeedbackType,
  setFailComment,
  setTaskID,
  setDoctorID,
  setMeetupID,
  setDoctorInfo,
  setType,
  addFiles,
  removeFile,
  setError,
  setLoading,
} = meetupResultSlice.actions;
export const meetupResultReducer = meetupResultSlice.reducer;

import { TableCell, TableRow } from '@mui/material';
import React from 'react';

interface IDynamicFixedHeaderProps {
  headingDates: string[];
  title: string;
}

export const DynamicFixedHeader = (props: IDynamicFixedHeaderProps) => {
  return (
    <TableRow
      style={{
        backgroundColor: '#f4f5f9',
        borderBottom: '1px solid black',
      }}
    >
      <TableCell
        className="dynamic-fixed-header__heading"
        style={{ paddingLeft: 0 }}
        width={300}
      >
        {props.title}
      </TableCell>

      {props.headingDates?.map((date) => (
        <TableCell
          className="dynamic-fixed-header__heading"
          align="right"
          width={110}
          key={date}
        >
          {date}
        </TableCell>
      ))}
      <TableCell
        className="dynamic-fixed-header__heading"
        align="right"
        width={110}
      >
        Динамика
      </TableCell>
    </TableRow>
  );
};

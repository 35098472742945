import axios from '../axios/axios';
import { blobToBase64 } from '../helpers/blobToBase64';
import ImagePlaceholder from '../assets/jpg/image-placeholder.jpg';

class ImageService {
  private cache: Map<string, string>;

  constructor() {
    this.cache = new Map();
  }

  private fetchImage = async (url: string) => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const base64 = await blobToBase64(response.data);

      return base64;
    } catch (error) {
      return ImagePlaceholder;
    }
  };

  private saveImage = (url: string, data: string) => {
    this.cache.set(url, data);
  };

  revalidateImage = (url: string) => {
    this.cache.delete(url);
  };

  getImage = async (url?: string | null): Promise<string> => {
    if (!url) return ImagePlaceholder;

    const cachedImage = this.cache.get(url);

    if (cachedImage) return cachedImage;

    const base64 = await this.fetchImage(url);

    this.saveImage(url, base64);

    return base64;
  };
}

export const imageService = new ImageService();

import React, { useEffect, useRef } from 'react';
import MeetupItem from '../MeetupItem';
import { MeetupsListController } from '../../meetups-list.controller';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  CircularProgress,
  debounce,
  Pagination,
  useMediaQuery,
} from '@mui/material';
import EventEmitter from '../../../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../../../helpers/EventEmitter/events.constants';
import MeetupSkeleton from '../MeetupSkeleton';
import { MeetupsListVariantTypes } from '../../types';
import { FilterParamsTypes } from '../../types';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import useAdaptivePaginationLimit from '../../../../hooks/useAdaptivePaginationLimit';
import { MEETUPS_ADAPTIVE_PAGINATION } from '../../../../constants/adaptive-pagintation.constants';
import { IApiTaskDoctor } from '../../../../interfaces/api.tasks.interfaces';
import { MEDIA } from '../../../../constants/app.constants';
import { isScrollToBottomClosure } from '../../../../helpers/isScrollBottomClosure';
import {
  setLimit,
  setMeetups,
  setOffset,
  setPage,
} from '../../store/meetups-list.slice';
import { MEETUPS_LIST_LIMIT } from '../../constants';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import { getMeetupFiltersFromLocalstorage } from '../../../../helpers/getMeetupFiltersFromLocalstorage';
import { TaskVariantFiltersEnum } from '../../../../components/TaskFilters/task-filters.enum';

interface IMeetupsListProps {
  onMeetupClick: (id: number) => void;
  variant: MeetupsListVariantTypes;
  taskVariant: TaskVariantTypes | null;
  filter: FilterParamsTypes;
  currentDoctorId?: number;
  onPageChange?: (page: number) => void;
  page?: number;
  searchValue?: string;
  onTaskClick?: (params: {
    type: TaskVariantTypes;
    id: number;
    name: string;
    doctorID?: number;
  }) => void;
  isSelecting?: boolean;
  handleDoctorSelect?: (doctor: IApiTaskDoctor) => void;
  selectedDoctors?: IApiTaskDoctor[];
}

export const MeetupsList = (props: IMeetupsListProps) => {
  const meetupsList = useAppSelector((state) => state.meetupsList);
  const dispatch = useAppDispatch();
  const controller = new MeetupsListController(dispatch);
  //const adaptiveLimit = useAdaptivePaginationLimit(MEETUPS_ADAPTIVE_PAGINATION);
  const listRef = useRef<HTMLDivElement>(null);
  const matchXsm = useMediaQuery(`(max-width:${MEDIA.XSM}px)`);

  useEffect(() => {
    controller.setMeetupStatus(props.variant);
    EventEmitter.subscribe(
      EMITTER_EVENTS.UPDATE_MEETUP_LIST,
      controller.getMeetupsList
    );

    return () => {
      EventEmitter.unsubscribe(EMITTER_EVENTS.UPDATE_MEETUP_LIST);
    };
  }, []);

  // useEffect(() => {
  //   controller.setLimit(adaptiveLimit);
  // }, [adaptiveLimit]);

  useEffect(() => {
    controller.setFilter(props.filter);
    controller.setTaskVariant(props.taskVariant);
    props.page && controller.setPage(props.page);

    props.searchValue !== undefined &&
      controller.setSearchValue(props.searchValue);

    controller.getMeetupsList();
  }, [props.filter, props.page, props.searchValue, props.taskVariant]);

  useEffect(() => {
    if (!matchXsm) return;
    dispatch(setLimit(MEETUPS_LIST_LIMIT));
    dispatch(setOffset(0));
    dispatch(setPage(1));
    dispatch(setMeetups([]));
    controller.getMeetupsList();
  }, [matchXsm]);

  const handlePageChange = (_: any, page: number) => {
    props.onPageChange && props.onPageChange(page);
  };

  const handleDoctorClick = (id?: number) => {
    props.onMeetupClick(id || 0);
    controller.onDoctorClick(id || 0);
  };

  const pageCount = Math.ceil(meetupsList.total / meetupsList.limit);

  const isScrollToBottom = isScrollToBottomClosure();

  const debounceMeetupFetch = debounce(controller.getMeetupsListOnScroll, 100);

  const handleListScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!matchXsm) return;
    const scrollHeight = listRef?.current?.scrollHeight || 0;
    const scrollTop =
      e.currentTarget.scrollTop + (listRef?.current?.clientHeight || 0);
    const isScrollBottom = isScrollToBottom(scrollTop);

    if (
      scrollHeight &&
      scrollTop >= (scrollHeight / 10) * 9 &&
      isScrollBottom
    ) {
      debounceMeetupFetch();
    }
  };

  return (
    <div className="meetup-list">
      <div
        ref={listRef}
        className="meetup-list__list"
        onScroll={handleListScroll}
        style={{
          overflowY: meetupsList.isScrollLoading ? 'hidden' : 'scroll',
        }}
      >
        {meetupsList.isLoading ? (
          new Array(meetupsList.limit)
            .fill(null)
            .map((_, i) => <MeetupSkeleton key={i} />)
        ) : meetupsList.meetups.length ? (
          meetupsList.meetups.map((doctor: IApiTaskDoctor) => {
            const isEnvelopeGiven = props.selectedDoctors?.length
              ? props.selectedDoctors.find(
                  (doc) => doc.doctorID === doctor.doctorID
                )
              : false;

            return (
              <MeetupItem
                //key={doctor.doctorID}
                key={doctor.reactRenderKey}
                id={doctor.doctorID}
                onClick={
                  props.isSelecting
                    ? () =>
                        props.handleDoctorSelect &&
                        props.handleDoctorSelect(doctor)
                    : handleDoctorClick
                }
                medInstitutions={doctor.medInstitutions}
                firstName={doctor.firstName}
                selected={props.currentDoctorId === doctor.doctorID}
                lastName={doctor.lastName}
                middleName={doctor.middleName}
                speciality={doctor.specialty?.name}
                note={doctor.note}
                countEnvelopes={doctor.countEnvelopes}
                completedEnvelopes={doctor.countDeliveredEnvelopes}
                completedMeetups={doctor.countFinishedMeetups}
                countMeetups={doctor.countMeetups}
                onTaskClick={props.onTaskClick}
                tasks={meetupsList.currentTasks}
                fetchTasks={controller.getShortTasks}
                isSelecting={props.isSelecting}
                isEnvelopeGiven={!!isEnvelopeGiven}
                isShowEnvelops={
                  doctor.countEnvelopes > doctor.countDeliveredEnvelopes
                }
              />
            );
          })
        ) : (
          <NoDataPlaceholder />
        )}
        {meetupsList.isScrollLoading && (
          <div className="meetup-list__scroll-progress">
            <CircularProgress />
          </div>
        )}
      </div>
      {pageCount !== 1 && !matchXsm && (
        <Pagination
          className="meetup-list__pagination"
          count={pageCount}
          page={meetupsList.page}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

import axios from '../axios/axios';
import { CATALOGS } from '../api/api';
import {
  IApiAddress,
  IApiCatalogResponse,
  IApiDoctor,
  IUpdateDoctorBody,
} from '../interfaces/api.catalogs.interfaces';
import {
  DoctorsOrderByTypes,
  DoctorsOrderDirectionTypes,
} from '../types/catalogs.types';
import { TaskVariantTypes } from '../types/tasks.types';
const qs = require('qs');
export class CatalogsService {
  /**
   * Specialities methods
   **/
  getSpecialities = async (
    deleted?: boolean | null,
    search?: string | null,
    limit?: number | null,
    offset?: number | null,
    inUse?: boolean
  ): Promise<IApiCatalogResponse | null> => {
    let result = null;
    try {
      const response = await axios.get(CATALOGS.GET_SPECIALITIES, {
        params: {
          deleted,
          search,
          limit,
          offset,
          inUse,
        },
      });
      if (response.status === 200) {
        result = response.data as IApiCatalogResponse;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  createSpeciality = async (name: string) => {
    let result = null;
    try {
      const response = await axios.post(CATALOGS.CREATE_SPECIALITY, {
        name,
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  getSpeciality = async (specialityID: number) => {
    let result = null;
    try {
      const response = await axios.get(
        `${CATALOGS.GET_SPECIALITY}/${specialityID}`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  deleteSpeciality = async (specialityID: number) => {
    let result = null;
    try {
      const response = await axios.delete(
        `${CATALOGS.DELETE_SPECIALITY}/${specialityID}`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
      return false;
    }
    return true;
  };

  updateSpeciality = async (
    specialityID: number,
    body: { name?: string | null; archived?: boolean }
  ) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${CATALOGS.UPDATE_SPECIALITY}/${specialityID}`,
        body
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  /**
   * Specialities methods END
   **/

  /**
   * Lpu methods
   **/

  getLpuList = async (
    deleted?: boolean | null,
    searchName?: string | null,
    searchAddress?: string | null,
    limit?: number | null,
    offset?: number | null,
    inUse?: boolean
  ) => {
    let result = null;
    try {
      const response = await axios.get(CATALOGS.GET_LPU_LIST, {
        params: {
          deleted,
          searchName,
          searchAddress,
          limit,
          offset,
          inUse,
        },
      });
      if (response.status === 200) {
        result = response.data as IApiCatalogResponse;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  createLpu = async (name: string, address?: IApiAddress | null) => {
    let result = null;
    try {
      const response = await axios.post(CATALOGS.CREATE_LPU, {
        name,
        address,
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  getLpuById = async (lpuID: number) => {
    let result = null;
    try {
      const response = await axios.get(`${CATALOGS.GET_LPU_BY_ID}/${lpuID}`);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  deleteLpuById = async (lpuID: number) => {
    let result = null;
    try {
      const response = await axios.delete(
        `${CATALOGS.DELETE_LPU_BY_ID}/${lpuID}`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
      return false;
    }
    return true;
  };

  updateLpuById = async (
    lpuID: number,
    body: {
      name?: string | null;
      address?: IApiAddress | null;
      archived?: boolean;
    }
  ) => {
    let result = null;
    try {
      const response = await axios.patch(
        `${CATALOGS.UPDATE_LPU_BY_ID}/${lpuID}`,
        body
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  /**
   * Lpu methods END
   **/

  /**
   * Kladr methods
   **/

  getKladrCities = async (query: string | null) => {
    let result = null;

    try {
      const response = await axios.get(`${CATALOGS.GET_KLADR_CITIES}`, {
        params: { query },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  getCityFormLpu = async (query: string | null) => {
    let result = null;

    try {
      const response = await axios.get(`${CATALOGS.GET_CITY_FROM_LPU}`, {
        params: {
          query,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  getKladrDistricts = async (cityId: number) => {
    let result = null;

    try {
      const response = await axios.get(
        `${CATALOGS.GET_KLADR_DISTRICTS}/${cityId}/districts`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  /**
   * Kladr methods END
   **/

  /**
   * Doctors methods
   **/

  getDoctorsList = async (
    hasInstalledApp?: boolean | null,
    orderBy?: DoctorsOrderByTypes | null,
    orderDirection?: DoctorsOrderDirectionTypes | null,
    specialtyIDs?: number[] | null,
    lpuIDs?: number[] | null,
    archived?: boolean | null,
    searchName?: string | null,
    limit?: number | null,
    offset?: number | null,
    hasNoFinishedMeetupsAfter?: string | null,
    address?: any[] | null,
    monthStart?: string | null,
    monthEnd?: string | null,
    yearStart?: number | null,
    yearEnd?: number | null,
    dynamicFrom?: number | null,
    dynamicTo?: number | null
  ) => {
    let result = null;

    try {
      const response = await axios.post(
        `${CATALOGS.GET_DOCTORS}`,
        {
          address,
        },
        {
          params: {
            hasInstalledApp,
            orderBy,
            orderDirection,
            specialtyIDs,
            lpuIDs,
            archived,
            searchName,
            limit,
            offset,
            hasNoFinishedMeetupsAfter,
            monthStart,
            monthEnd,
            yearStart,
            yearEnd,
            dynamicFrom,
            dynamicTo,
          },
          paramsSerializer: (params: any) => {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
              skipNulls: true,
            });
          },
        }
      );
      if (response.status === 200) {
        result = response.data as IApiCatalogResponse;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  deleteDoctorById = async (id: number): Promise<boolean> => {
    let result = null;
    try {
      const response = await axios.delete(
        `${CATALOGS.DELETE_DOCTOR_BY_ID}/${id}`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
      return false;
    }
    return true;
  };

  getDoctorById = async (id: number): Promise<IApiDoctor | null> => {
    let result = null;

    try {
      const response = await axios.get(`${CATALOGS.GET_DOCTOR_BY_ID}/${id}`);
      if (response.status === 200) {
        result = response.data as IApiDoctor;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  getDoctorByIdAsAgent = async (id: number) => {
    let result = null;

    try {
      const response = await axios.get(
        `${CATALOGS.GET_DOCTOR_AGENT_BY_ID}/${id}/agent-profile`
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  updateDoctorById = async (
    id: number,
    note?: string | null,
    archived?: boolean | null,
    specialtyID?: number | null,
    lastName?: string | null,
    firstName?: string | null,
    middleName?: string | null,
    birthday?: string | null,
    hasInstalledApp?: boolean | null,
    medInstitutions?: number[] | null,
    contactPhones?: string[] | null
  ) => {
    let result = null;

    try {
      const response = await axios.patch(
        `${CATALOGS.UPDATE_DOCTOR_BY_ID}/${id}`,
        {
          note,
          archived,
          specialtyID,
          lastName,
          firstName,
          middleName,
          birthday,
          hasInstalledApp,
          medInstitutions,
          contactPhones,
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      if (error.response.data) result = error.response.data;
    }
    return result;
  };

  updateDoctorNoteById = async (id: number, note?: string | null) => {
    let result = null;

    try {
      const response = await axios.patch(
        `${CATALOGS.UPDATE_DOCTOR_BY_ID}/${id}`,
        {
          note,
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  createDoctor = async (
    note?: string | null,
    specialtyID?: number | null,
    lastName?: string | null,
    firstName?: string | null,
    middleName?: string | null,
    birthday?: string | null,
    hasInstalledApp?: boolean | null,
    medInstitutions?: number[] | null,
    contactPhones?: string[] | null
  ) => {
    let result = null;

    try {
      const response = await axios.post(`${CATALOGS.CREATE_DOCTOR}`, {
        note,
        specialtyID,
        lastName,
        firstName,
        middleName,
        birthday,
        hasInstalledApp,
        medInstitutions,
        contactPhones,
      });

      if (response.status === 200) {
        result = response?.data;
      }
    } catch (error: any) {
      console.error(error);
      if (error.response.data) result = error.response.data;
    }

    return result;
  };

  createDoctorsTasks = async (
    hasInstalledApp?: boolean | null,
    orderBy?: DoctorsOrderByTypes | null,
    orderDirection?: DoctorsOrderDirectionTypes | null,
    specialtyIDs?: number[] | null,
    lpuIDs?: number[] | null,
    archived?: boolean | null,
    searchName?: string | null,
    limit?: number | null,
    offset?: number | null,
    hasNoFinishedMeetupsAfter?: string | null,
    executors?: string[] | null,
    taskType?: TaskVariantTypes | null,
    remark?: string | null,
    address?:
      | {
          placeName?: string | null;
          districts?: string[] | null;
        }[]
      | null
  ) => {
    let result = null;

    try {
      const response = await axios.post(
        `${CATALOGS.CREATE_DOCTORS_TASKS}`,
        {
          executors,
          taskType,
          remark,
          address,
        },
        {
          params: {
            hasInstalledApp,
            orderBy,
            orderDirection,
            specialtyIDs,
            lpuIDs,
            archived,
            searchName,
            limit,
            offset,
            hasNoFinishedMeetupsAfter,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
              skipNulls: true,
            });
          },
        }
      );

      if (response.status === 200) {
        result = response?.data;
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  };

  getDoctorMeetupsDoneById = async (
    id: number,
    limit?: number,
    offset?: number
  ) => {
    let result = null;

    try {
      const response = await axios.get(
        `${CATALOGS.DOCTOR_MEETUPS_DONE}/${id}/meetups/done`,
        {
          params: {
            limit,
            offset,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }

    return result;
  };

  /**
   * Doctors methods END
   **/
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMeetupDoctor, IShortTask } from '../interfaces';
import { MEETUPS_LIST_LIMIT } from '../constants';
import { MeetupsListVariantTypes } from '../types';
import { FilterParamsTypes } from '../types';
import { TaskVariantTypes } from '../../../types/tasks.types';

interface IMeetupsListState {
  isLoading: boolean;
  meetups: IMeetupDoctor[];
  limit: number;
  offset: number;
  page: number;
  total: number;
  status: MeetupsListVariantTypes;
  filter: FilterParamsTypes;
  selectedDoctorId?: number;
  searchValue: string;
  currentTasks: IShortTask[];
  isScrollLoading: boolean;
  taskVariant: TaskVariantTypes | null;
}

const initialState: IMeetupsListState = {
  isLoading: false,
  meetups: [],
  offset: 0,
  limit: MEETUPS_LIST_LIMIT,
  page: 1,
  total: 0,
  status: 'in_progress',
  filter: 'doctor_fullname',
  searchValue: '',
  currentTasks: [],
  isScrollLoading: false,
  taskVariant: null,
};

export const meetupsListSlice = createSlice({
  name: 'meetups-list',
  initialState,

  reducers: {
    setScrollLoading: (state, action: PayloadAction<boolean>) => {
      state.isScrollLoading = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setMeetups: (state, action: PayloadAction<IMeetupDoctor[]>) => {
      state.meetups = action.payload;
    },
    setStatus: (state, action: PayloadAction<MeetupsListVariantTypes>) => {
      state.status = action.payload;
    },
    setFilter: (state, action: PayloadAction<FilterParamsTypes>) => {
      state.filter = action.payload;
    },
    setSelectedDoctorId: (state, action: PayloadAction<number>) => {
      state.selectedDoctorId = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setCurrentTasks: (state, action: PayloadAction<IShortTask[]>) => {
      state.currentTasks = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setTaskVariant: (state, action: PayloadAction<TaskVariantTypes | null>) => {
      state.taskVariant = action.payload;
    },
  },
});

export const {
  setOffset,
  setPage,
  setTotal,
  setLoading,
  setMeetups,
  setStatus,
  setLimit,
  setFilter,
  setSearchValue,
  setCurrentTasks,
  setSelectedDoctorId,
  setScrollLoading,
  setTaskVariant,
} = meetupsListSlice.actions;
export const meetupsListReducer = meetupsListSlice.reducer;

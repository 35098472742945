import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUploadFieldState {
  isLoading: boolean;
  error: string;
  uploadDate: string;
  dateError: boolean;
}

const initialState: IUploadFieldState = {
  isLoading: false,
  error: '',
  uploadDate: '',
  dateError: false,
};

export const uploadFieldSlice = createSlice({
  name: 'upload-field',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setUploadDate: (state, action: PayloadAction<string>) => {
      state.uploadDate = action.payload;
    },
    setDateError: (state, action: PayloadAction<boolean>) => {
      state.dateError = action.payload;
    },
  },
});

export const { setIsLoading, setError, setUploadDate, setDateError } =
  uploadFieldSlice.actions;
export const uploadFieldReducer = uploadFieldSlice.reducer;

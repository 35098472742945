import { IAppSelectOption } from '../../ui/AppSelect/app-select.interface';

export const MEETUP_VALUE_NAMES = {
  MEETUP_DONE: 'Личная встреча',
  MEETUP_FAIL: 'Встреча не состоялась',
};
export const ENVELOPE_VALUE_NAMES = {
  ENVELOPE_DONE: 'Конверт отдан',
  ENVELOPE_FAIL: 'Встреча не состоялась',
};
export const meetupOptions: IAppSelectOption[] = [
  {
    id: 1,
    text: MEETUP_VALUE_NAMES.MEETUP_DONE,
    value: MEETUP_VALUE_NAMES.MEETUP_DONE,
  },
  {
    id: 2,
    text: MEETUP_VALUE_NAMES.MEETUP_FAIL,
    value: MEETUP_VALUE_NAMES.MEETUP_FAIL,
  },
];

export const envelopeOptions: IAppSelectOption[] = [
  {
    id: 1,
    text: ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
    value: ENVELOPE_VALUE_NAMES.ENVELOPE_DONE,
  },
  {
    id: 2,
    text: ENVELOPE_VALUE_NAMES.ENVELOPE_FAIL,
    value: ENVELOPE_VALUE_NAMES.ENVELOPE_FAIL,
  },
];

export const FEEDBACK_ERROR_TEXT = 'Добавьте комментарий для обратной связи';
export const ERROR_CREATE_MEETUP =
  'Не удалось зафиксировать встречу. Проверьте введенные данные';
export const ERROR_UPDATE_MEETUP =
  'Не удалось обновить результаты встречу. Проверьте введенные данные';

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import { clearState, setEmail, setPassword } from './store/sign-in.slice';
import { UserController } from '../../controllers/user.controller';

export class SignInController {
  dispatch: AppDispatch;
  getState: () => RootState;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
    this.getState = store.getState;
  }

  onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setEmail(e.target.value));
  };

  onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setPassword(e.target.value));
  };

  clearValues = () => {
    this.dispatch(clearState());
  };
  onSignIn = () => {};
}

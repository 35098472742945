import React, { CSSProperties } from 'react';
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { classNamesParser } from '../../helpers/classNamesParser';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import { ReactComponent as ErrorIcon } from '../../assets/vector/input-error.svg';
interface IAppAutocompleteProps {
  classNames?: string[];
  placeholder?: string;
  onInput?: (event: any) => void;
  onChange?: (event: any, newValue?: any, reason?: any) => void;
  options?: any[];
  error?: string;
  disabled?: boolean;
  value?: string;
  label?: string;
  styles?: CSSProperties;
  onFocus?: (event: any) => void;
}

const AppAutocomplete = (props: IAppAutocompleteProps) => {
  return (
    <div
      className={classNamesParser('app-autocomplete', props.classNames)}
      style={props.styles}
    >
      {props.label && (
        <span className="app-autocomplete__label">{props.label}</span>
      )}
      {!!props.error && (
        <Tooltip title={props.error} enterTouchDelay={60}>
          <ErrorIcon className="app-autocomplete__error-icon" />
        </Tooltip>
      )}
      <Autocomplete
        value={props.value}
        disabled={props.disabled}
        className="app-autocomplete__autocomplete"
        freeSolo
        onFocus={props.onFocus}
        renderInput={(params) => (
          <TextField
            {...params}
            value={props.value}
            placeholder={props.placeholder}
          />
        )}
        onChange={props.onChange}
        onInput={props.onInput}
        componentsProps={{
          popper: {
            // placement: 'top',
          },
        }}
        renderOption={(props, option) => (
          <li {...props} key={props?.id}>
            {option?.title}
          </li>
        )}
        // componentsProps={{
        //   popper: {
        //     modifiers: [{ name: 'flip', enabled: false }],
        //   },
        // }}
        options={props?.options?.length ? props.options : ([] as any[])}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option?.title;
        }}
      />
      {/*{props.errorText && (*/}
      {/*  <span className="app-autocomplete__error">{props.errorText}</span>*/}
      {/*)}*/}
    </div>
  );
};

export default AppAutocomplete;

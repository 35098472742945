import { CHANGELOG } from '../api/api';
import axios from '../axios/axios';

export class ChangelogService {
  getDoctorsChangelog = async (
    limit?: number | null,
    offset?: number | null,
    searchByDoctor?: string | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(CHANGELOG.GET_DOCTORS_CHANGELOG, {
        params: {
          limit,
          offset,
          searchByDoctor,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferenceTypes } from '../../../modules/ReferenceTable';

interface IReferenceCatalogPageState {
  editUserModal: boolean;
  addUserModal: boolean;
  userId: number | null;
}

const initialState: IReferenceCatalogPageState = {
  editUserModal: false,
  addUserModal: false,
  userId: null,
};

export const referenceCatalogPageSlice = createSlice({
  name: 'user-list-page',
  initialState,
  reducers: {
    setShowAddUserModal: (state, action: PayloadAction<boolean>) => {
      state.addUserModal = action.payload;
    },
    setShowEditUserModal: (state, action: PayloadAction<boolean>) => {
      state.editUserModal = action.payload;
    },
    setUserId: (state, action: PayloadAction<number | null>) => {
      state.userId = action.payload;
    },
  },
});

export const { setShowAddUserModal, setShowEditUserModal, setUserId } =
  referenceCatalogPageSlice.actions;
export default referenceCatalogPageSlice.reducer;

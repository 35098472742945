import axios from '../axios/axios';
import { AUTH } from '../api/api';

export class AuthService {
  login = async (username: string, password: string) => {
    let result = null;
    try {
      const response = await axios.post(AUTH.LOGIN, { username, password });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };

  logout = async () => {
    let result = null;
    try {
      const response = await axios.get(AUTH.LOGOUT);
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error.message);
    }
    return result;
  };
}

import { ChangeEvent } from 'react';
import { AppDispatch, RootState, store } from '../../app.store';
import { ViewController } from '../../interfaces/general.interfaces';
import {
  clearState,
  setFeedbackType,
  setSearchedComment,
  setSearchedName,
} from './store/history-filters.slice';
import { SelectChangeEvent } from '@mui/material';
import { MeetupFeedbackTypes } from '../../types/tasks.types';
import EventEmitter from '../../helpers/EventEmitter/EventEmitter';
import { EMITTER_EVENTS } from '../../helpers/EventEmitter/events.constants';

export class HistoryFiltersController extends ViewController {
  onSearchedNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setSearchedName(e.target.value));
  };

  onSearchedNameClear = () => {
    this.dispatch(setSearchedName(''));
  };

  onSearchedCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.dispatch(setSearchedComment(e.target.value));
  };

  onSearchedCommentClear = () => {
    this.dispatch(setSearchedComment(''));
  };

  onFeedbackTypeChange = (e: SelectChangeEvent) => {
    this.dispatch(setFeedbackType(e.target.value as MeetupFeedbackTypes | ''));
  };

  onClearFilters = () => {
    this.dispatch(clearState());
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_HISTORY_WITH_FILTERS, {
      searchName: null,
      comment: null,
      feedbackType: null,
    });
    EventEmitter.emit(EMITTER_EVENTS.CLEAR_HISTORY_FILTERS);
  };

  onApplyFilters = () => {
    const state = this.getState().historyFilters;
    const isHaveAnyFilter =
      !!state.feedbackType || !!state.searchedName || !!state.searchedComment;
    if (!isHaveAnyFilter) {
      this.onClearFilters();
      return;
    }
    EventEmitter.emit(EMITTER_EVENTS.UPDATE_HISTORY_WITH_FILTERS, {
      searchName: !!state.searchedName ? state.searchedName : null,
      comment: !!state.searchedComment ? state.searchedComment : null,
      feedbackType: !!state.feedbackType ? state.feedbackType : null,
    });
    EventEmitter.emit(EMITTER_EVENTS.APPLY_HISTORY_FILTERS);
  };
}

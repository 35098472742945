import React from 'react';
import { ReactComponent as NoteIcon } from '../../../../assets/vector/note.svg';
import { ReactComponent as EnvelopeGiven } from '../../../../assets/vector/envelope-given.svg';
import TaskButton from '../TaskButton';
import { RoundedIconVariantsEnum } from '../../enums';
import { TaskVariantTypes } from '../../../../types/tasks.types';
import { getIconByKey } from '../../helpers/getIconByKey';
import { IShortTask } from '../../interfaces';
import { IApiMedInstitutions } from '../../../../interfaces/api.catalogs.interfaces';
import SubinfoDoctorSpan from '../../../../ui/SubinfoDoctorSpan';

interface IMeetupItemProps {
  id: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  note?: string;
  speciality?: string;
  medInstitutions?: IApiMedInstitutions[];
  isEnvelopeGiven: boolean;
  isShowEnvelops: boolean;
  onClick?: (id?: number) => void;
  onSendEnvelopeClick?: (id: number) => void;
  countMeetups: number;
  countEnvelopes: number;
  completedMeetups: number;
  completedEnvelopes: number;
  selected: boolean;
  onTaskClick?: (params: {
    type: TaskVariantTypes;
    id: number;
    name: string;
    doctorID?: number;
  }) => void;
  tasks: IShortTask[];
  fetchTasks?: (
    type: TaskVariantTypes,
    doctorID: number,
    doctorFullname: string
  ) => Promise<void>;
  isSelecting?: boolean;
}

const MeetupItem = (props: IMeetupItemProps) => {
  const handleItemClick = () => {
    if (props.isSelecting) {
      return props.onClick && props.onClick();
    }
    props.onClick && props.onClick(props.id);
  };

  const leftEnvelopes = props.countEnvelopes - props.completedEnvelopes;
  const leftMeetups = props.countMeetups - props.completedMeetups;

  const doctorFullName = `${props.lastName || ''} ${props.firstName || ''} ${
    props.middleName || ''
  }`;

  const handleTaskButtonClick = async (type: TaskVariantTypes) => {
    if (props.isSelecting) return;
    props.fetchTasks &&
      (await props.fetchTasks(type, props.id, doctorFullName));
  };
  return (
    <div
      className={`meetup-item${props.selected ? ' selected' : ''}`}
      onClick={handleItemClick}
    >
      <div className="meetup-item__info">
        <div
          className={`meetup-item__envelope-container meetup-item__envelope-container_${
            props.isSelecting ? 'open' : 'close'
          }`}
        >
          {props.isShowEnvelops && (
            <EnvelopeGiven
              className={`meetup-item__envelope-given-icon ${
                props.isEnvelopeGiven ? '' : 'not-given'
              }`}
            />
          )}
        </div>
        <div className="meetup-item__doctor-info-wrapper">
          <h3 className="meetup-item__fullname">{doctorFullName}</h3>
          <div className="meetup-item__subinfo">
            <div className="meetup-item__doctor-info-wrapper">
              {/* <p className="meetup-item__doctor-info"> */}

              {props.medInstitutions?.map((medInstitution) => (
                <SubinfoDoctorSpan
                  classNames={['meetup-item__subinfo-span']}
                  variant="lpu"
                  value={medInstitution.name}
                />
              ))}

              <SubinfoDoctorSpan
                classNames={['meetup-item__subinfo-span']}
                variant="specialty"
                value={props.speciality}
              />
              {/* </p> */}
            </div>

            {props.note && (
              <div className="meetup-item__note-wrapper">
                <NoteIcon className="meetup-item__note-icon" />
                <span className="meetup-item__note">{props.note}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.isSelecting || (
        <div className="meetup-item__labels">
          {props.countMeetups > 0 &&
            (leftMeetups > 0 ? (
              <TaskButton
                doctorFullname={doctorFullName}
                doctorID={props.id}
                variant={RoundedIconVariantsEnum.MEETUP_PROGRESS}
                count={leftMeetups}
                tasks={props.tasks}
                onClick={handleTaskButtonClick}
                onTaskClick={props.onTaskClick}
              />
            ) : (
              <TaskButton
                doctorFullname={doctorFullName}
                doctorID={props.id}
                variant={RoundedIconVariantsEnum.MEETUP_DONE}
                count={leftMeetups}
                tasks={props.tasks}
                onClick={handleTaskButtonClick}
                onTaskClick={props.onTaskClick}
              />
            ))}
          {props.countEnvelopes > 0 &&
            (leftEnvelopes === 0 ? (
              <TaskButton
                doctorFullname={doctorFullName}
                doctorID={props.id}
                variant={RoundedIconVariantsEnum.ENVELOPE_DONE}
                tasks={props.tasks}
                onClick={handleTaskButtonClick}
                onTaskClick={props.onTaskClick}
              />
            ) : (
              <TaskButton
                doctorFullname={doctorFullName}
                doctorID={props.id}
                variant={RoundedIconVariantsEnum.ENVELOPE_PROGRESS}
                count={leftEnvelopes}
                tasks={props.tasks}
                onClick={handleTaskButtonClick}
                onTaskClick={props.onTaskClick}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default MeetupItem;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferenceTypes } from '../../../modules/ReferenceTable';
import { IApiSpecialityItem } from '../../../interfaces/api.catalogs.interfaces';
import { IDoctorCandidate, ITableRowData } from '../index.interfaces';
import { TABLE_ITEMS_LIMIT } from '../constants';

interface IUploadTableState {
  data: ITableRowData[];
  offset: number;
  limit: number;
  haveMore: boolean;
  total: number;
  currentFileId: number | null;
  isLoadCountVisible: boolean;
  isHideMatched: boolean;
  mismatchedCount: number;
  currentTotal: number;
  uploadDate: string;
  searchedDoctors: IDoctorCandidate[];
  loadedToStatistics: boolean;
  isSearchOpened: boolean;
  searchValue: string;
  searchInput: string;
  isLoading: boolean;
}

const initialState: IUploadTableState = {
  currentFileId: null,
  data: [],
  total: 0,
  currentTotal: 0,
  limit: TABLE_ITEMS_LIMIT,
  offset: 0,
  haveMore: true,
  isLoadCountVisible: true,
  isHideMatched: false,
  mismatchedCount: 0,
  uploadDate: '',
  searchedDoctors: [],
  loadedToStatistics: false,
  isSearchOpened: false,
  searchValue: '',
  searchInput: '',
  isLoading: false,
};

export const uploadTableSlice = createSlice({
  name: 'upload-table',
  initialState,
  reducers: {
    pushData: (state, action: PayloadAction<ITableRowData[]>) => {
      state.data = [...state.data, ...action.payload];
    },
    clearData: (state) => {
      state.data = [];
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setHaveMore: (state, action: PayloadAction<boolean>) => {
      state.haveMore = action.payload;
    },
    setIsLoadCountVisible: (state, action: PayloadAction<boolean>) => {
      state.isLoadCountVisible = action.payload;
    },
    setCurrentFileId: (state, action: PayloadAction<number>) => {
      state.currentFileId = action.payload;
    },
    setIsHideMatched: (state, action: PayloadAction<boolean>) => {
      state.isHideMatched = action.payload;
    },
    setMismatchedCount: (state, action: PayloadAction<number>) => {
      state.mismatchedCount = action.payload;
    },
    setCurrentTotal: (state, action: PayloadAction<number>) => {
      state.currentTotal = action.payload;
    },
    setUploadDate: (state, action: PayloadAction<string>) => {
      state.uploadDate = action.payload;
    },
    setSearchedDoctors: (state, action: PayloadAction<IDoctorCandidate[]>) => {
      state.searchedDoctors = action.payload;
    },
    setLoadedToStatistics: (state, action: PayloadAction<boolean>) => {
      state.loadedToStatistics = action.payload;
    },
    setSearchOpened: (state, action: PayloadAction<boolean>) => {
      state.isSearchOpened = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateRowMatch: (
      state,
      action: PayloadAction<{
        i: number;
        doctorID: number;
        doctorFullname: string;
      }>
    ) => {
      state.data = state.data.map((item, i) => {
        if (i === action.payload.i) {
          return {
            ...item,
            doctorID: action.payload.doctorID,
            doctorFullname: action.payload.doctorFullname,
            doctorCandidates: [],
          };
        } else return item;
      });
    },
    clearRowMatch: (state, action: PayloadAction<number>) => {
      state.data = state.data.map((item, i) => {
        if (i === action.payload) {
          return {
            ...item,
            doctorID: null,
            doctorFullname: null,
            doctorCandidates: [],
          };
        } else return item;
      });
    },
  },
});

export const {
  pushData,
  clearData,
  clearRowMatch,
  setTotal,
  setIsLoadCountVisible,
  setOffset,
  setHaveMore,
  setCurrentFileId,
  setIsHideMatched,
  setMismatchedCount,
  setCurrentTotal,
  setSearchedDoctors,
  setUploadDate,
  setLoadedToStatistics,
  setSearchOpened,
  setSearchValue,
  updateRowMatch,
  setSearchInput,
  setLoading,
} = uploadTableSlice.actions;
export const uploadTableReducer = uploadTableSlice.reducer;

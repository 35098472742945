import { ICustomizedHookItem } from '../../../../components/CustomizedHook/customized-hook.interfaces';
import { Button, IconButton, Menu } from '@mui/material';
import AutoCompleteSearchList from '../../../../components/AutoCompleteSearchList';
import { useState, MouseEvent } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Close } from '@mui/icons-material';
import MenuWrapper from '../MenuWrapper';

interface IAutoCompleteFilterProps {
  options?: ICustomizedHookItem[] | any[];
  selectedOption?: ICustomizedHookItem | null;
  placeholder?: string;
  onChange?: (item: ICustomizedHookItem | any) => void;
  onDelete?: () => void;
  onInput?: (event: any) => void;
  inputValue?: string;
  onListAcceptButtonClick?: () => void;
}
export default function AutoCompleteFilter(props: IAutoCompleteFilterProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onChange = (item: any) => {
    props.onChange && props.onChange(item);
    onMenuClose();
  };

  const onDelete = () => {
    props.onDelete && props.onDelete();
    onMenuClose();
  };

  const onDeleteBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onDelete && props.onDelete();
  };

  return (
    <>
      <Button
        onClick={onMenuOpen}
        className={`auto-complete-filter ${
          !!props.selectedOption ? 'auto-complete-filter_selected' : ''
        }`}
      >
        <p className="auto-complete-filter__value">
          {props.selectedOption?.title || 'Все'}
        </p>

        {!props.selectedOption ? (
          <KeyboardArrowDownIcon className="auto-complete-filter__arrow" />
        ) : (
          <IconButton
            className="auto-complete-filter__delete"
            onClick={onDeleteBtnClick}
          >
            <Close className="auto-complete-filter__delete-icon" />
          </IconButton>
        )}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onMenuClose}
        classes={{
          root: 'auto-complete-filter__menu',
          list: 'auto-complete-filter__menu',
          paper: 'auto-complete-filter__menu-paper',
        }}
      >
        <MenuWrapper className="auto-complete-filter__search-list-wrapper">
          <AutoCompleteSearchList
            {...props}
            selectedOptions={[props.selectedOption]}
            onChange={onChange}
            onDelete={onDelete}
            className="auto-complete-filter__search-list"
          />
        </MenuWrapper>
      </Menu>
    </>
  );
}

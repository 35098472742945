import React, {
  CSSProperties,
  useRef,
  useState,
  MouseEvent,
  useEffect,
} from 'react';
import { classNamesParser } from '../../helpers/classNamesParser';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import { ReactComponent as ErrorIcon } from '../../assets/vector/input-error.svg';
import CloseIcon from '@mui/icons-material/Close';

interface IInputProps {
  classNames?: string[];
  error?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  type?: 'text' | 'password' | 'tel';
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  multiline?: boolean;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  variant?: 'standard' | 'outlined';
  size?: 'small' | 'medium';
  //   iconName?: IconTypes;
  onIconClick?: any;
  helperText?: string;
  styles?: CSSProperties | any;
  stylesContainer?: CSSProperties | any;
  rows?: number;
  maxRows?: number;
  onClick?: (e: any) => void;
  endButtonText?: string;
  onEndButtonClick?: () => void;
  endButtonRemove?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  showCloseIcon?: boolean;
  focusAfterRender?: boolean;
  inputStyles?: CSSProperties | any;
  labelStyles?: CSSProperties | any;
}

const Input = (props: IInputProps) => {
  const [showPassword, setShowPassword] = useState(props.type !== 'password');

  const ref = useRef<any>(null);
  const classNames = props.classNames ? props.classNames : [];
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (props.focusAfterRender) {
      ref.current.querySelector('.MuiInputBase-input').focus();
    }
  }, []);

  return (
    <div
      className={classNamesParser('input', [
        ...classNames,
        props.disabled ? 'input_disabled' : '',
      ])}
    >
      {props.label && (
        <span className="input__label" style={props.labelStyles}>
          {props.label}
        </span>
      )}
      <div className="input__wrapper">
        <TextField
          autoFocus={props.autoFocus}
          onClick={props.onClick}
          style={props.styles}
          ref={ref}
          type={
            props.type === 'password'
              ? showPassword
                ? 'text'
                : 'password'
              : props.type
          }
          maxRows={props.maxRows || 10}
          multiline={props.multiline}
          onFocus={props.onFocus}
          disabled={props.disabled}
          className="input__field"
          rows={props.rows || undefined}
          // type={props.type}
          onInput={props.onInput}
          onBlur={props.onBlur}
          value={props.value}
          error={!!props.error}
          placeholder={props.placeholder}
          size={props.size || 'small'}
          onKeyDown={props.handleKeyDown}
          variant={props.variant}
          fullWidth={props.fullWidth}
          InputProps={{
            inputProps: {
              style: props.inputStyles,
            },
            endAdornment: (props.error ||
              props.type === 'password' ||
              props.showCloseIcon) && (
              <>
                <InputAdornment position="end">
                  {!!props.error && (
                    <Tooltip title={props.error} enterTouchDelay={60}>
                      <ErrorIcon />
                    </Tooltip>
                  )}
                  {props.type === 'password' ? (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ) : null}
                  {props.showCloseIcon && (
                    <CloseIcon
                      className="input__close"
                      onClick={props.onIconClick}
                      style={{
                        cursor: 'pointer',
                        width: 16,
                        height: 16,
                      }}
                    />
                  )}
                </InputAdornment>
              </>
            ),
          }}
        />
        {/* {props.endButtonText && !props.endButtonRemove && (
          <span className="input__end-button-wrapper">
            <Button
              onClick={props.onEndButtonClick}
              variant="contained"
              disableElevation
              className="button button_blue input__end-button"
            >
              {t(props.endButtonText)}
            </Button>
          </span>
        )} */}
      </div>
    </div>
  );
};

export default Input;

export const USERS_LIST_LIMIT = 8;

export const MEDIA = {
  XSM: 480,
  SM: 768,
  MD: 1024,
  LG: 1200,
};

export const MEETUP_WORD_MAP = {
  'envelope': 'Конверт',
  'meetup': 'Встреча',
  'unplanned': 'Встреча',
};

export const MEETUP_RESULT_MARKS_MAP = {
  'good': 'Хорошо',
  'bad': 'Плохо',
  'neutral': 'Нейтрально',
};

export const FEEDBACK_RESULT_MARKS_MAP = {
  'mobile_app': 'Мобильное приложение',
  'common': 'Общие вопросы',
  'update_personal_data': 'Внести изменения в личные данные',
};

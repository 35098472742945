import { EXPORT_REPORTS } from '../api/api';
import axios from '../axios/axios';

export class ExportService {
  downloadReport = async (fileID: number, type: 'pdf' | 'excel') => {
    let result = null;
    try {
      const response = await axios.get(`${EXPORT_REPORTS.GET_MIS_REPORT}`, {
        params: {
          fileID,
          type,
        },
      });
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  downloadDoctorsDynamics = async (
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number,
    limit?: number | null,
    offset?: number | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${EXPORT_REPORTS.DOWNLOAD_DOCTOR_DYNAMIC}`,
        {
          params: {
            monthStart,
            monthEnd,
            yearStart,
            yearEnd,
            limit,
            offset,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  downloadLpuDynamics = async (
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number,
    limit?: number | null,
    offset?: number | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${EXPORT_REPORTS.DOWNLOAD_LPU_DYNAMIC}`,
        {
          params: {
            monthStart,
            monthEnd,
            yearStart,
            yearEnd,
            limit,
            offset,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };

  downloadCityDynamics = async (
    monthStart: string,
    monthEnd: string,
    yearStart: number,
    yearEnd: number,
    limit?: number | null,
    offset?: number | null
  ) => {
    let result = null;
    try {
      const response = await axios.get(
        `${EXPORT_REPORTS.DOWNLOAD_CITY_DYNAMIC}`,
        {
          params: {
            monthStart,
            monthEnd,
            yearStart,
            yearEnd,
            limit,
            offset,
          },
        }
      );
      if (response.status === 200) {
        result = response.data;
      }
    } catch (error: any) {
      console.error(error);
    }
    return result;
  };
}

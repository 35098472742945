import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import { RouterProvider } from 'react-router';
import { routes } from './routes';
import { Provider } from 'react-redux';
import { store } from './app.store';
import { ThemeProvider } from '@mui/material';
import MuiTheme from './MUI/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Provider store={store}>
      <ThemeProvider theme={MuiTheme}>
        <RouterProvider router={routes} />
      </ThemeProvider>
    </Provider>
  </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

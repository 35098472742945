import React, { useEffect, useMemo } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip, debounce } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Form } from 'react-router-dom';
import Input from '../../../../ui/Input';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { EditLpuFormController } from '../../edit-lpu-form.controller';
import RoundMenuButton from '../../../../ui/RoundMenuButton';
import { IRoundMenuButtonItem } from '../../../../ui/RoundMenuButton/index.interfaces';
import { IApiLpuItem } from '../../../../interfaces/api.catalogs.interfaces';
import {
  clearStore,
  setData,
  setIsEdit,
} from '../../store/edit-lpu-form.slice';
import AppAutocomplete from '../../../../components/AppAutocomplete';
import RequiredRole from '../../../../components/RequiredRole';

interface IEditLpuFormProps {
  classNames?: string[];
  onCloseModal?: () => void;
  editData?: IApiLpuItem;
}

export const EditLpuForm = (props: IEditLpuFormProps) => {
  const editLpuForm = useAppSelector((state) => state.editLpuForm);
  const dispatch = useAppDispatch();
  const controller = new EditLpuFormController(dispatch);

  useEffect(() => {
    (async () => {
      controller.clearValues();
      if (props.editData?.address?.cityID) {
        console.log(props.editData);
        dispatch(setData(props.editData));
      } else {
        await controller.fetchLPUData(props.editData?.id);
      }

      await controller.fetchCities(true);
      await controller.fetchDistricts();

      // await controller.fetchDistricts();
    })();
    return () => {
      dispatch(setIsEdit(false));
      dispatch(clearStore());
    };
  }, [props.editData]);

  const onSave = async () => {
    await controller.onSaveClick();
    // props.onCloseModal && props.onCloseModal();
  };

  const delayedFetchCities = useMemo(
    () => debounce(controller.fetchCities, 500),
    []
  );

  const handleCityInput = (event: any) => {
    controller.handleCityChange(event);
    delayedFetchCities();
  };

  const handleMenuItemClick = (value: string) => {
    if (value.toLowerCase() === 'удалить') {
      controller.handleDeleteLPU(props.onCloseModal);
      return;
    }
    if (value.toLowerCase() === 'активировать') {
      controller.handleActivateLPU();
      return;
    } else controller.handleDeactivateLPU();
  };

  const roundButtonItems: IRoundMenuButtonItem[] = [
    {
      id: 2,
      text: editLpuForm?.deleted ? 'Активировать' : 'Деактивировать',
      color: editLpuForm?.deleted ? '#2574D0' : '#FF4C06',
    },
    {
      id: 3,
      text: 'Удалить',
      color: '#FF4C06',
      roles: ['admin'],
    },
  ];

  return (
    <div className={classNamesParser('edit-lpu-form', props.classNames)}>
      <div className="edit-lpu-form__heading">
        <div className="edit-lpu-form__title-container">
          <h3 className="edit-lpu-form__title">ЛПУ</h3>
          {editLpuForm.deleted ? (
            <Chip label="Запись не активна" className="edit-lpu-form__status" />
          ) : (
            ''
          )}
        </div>
        {!editLpuForm.isEdit && (
          <div className="edit-lpu-form__heading-controls">
            <RoundMenuButton
              classNames={['edit-lpu-form__menu']}
              menuItems={roundButtonItems}
              onMenuClick={handleMenuItemClick}
            />

            <RoundButton
              content={<EditNoteIcon />}
              backgroundColor="rgb(243 243 243)"
              onClick={controller.onEditClick}
              classNames={['edit-lpu-form__edit']}
            />
            <RoundButton
              classNames={['edit-lpu-form__close-modal']}
              onClick={props.onCloseModal}
              content={<CloseIcon />}
            />
          </div>
        )}
      </div>
      <Form style={{ marginBottom: 24 }}>
        <Input
          label={editLpuForm.isEdit ? '' : 'Наименование'}
          placeholder={editLpuForm.isEdit ? 'Наименование' : ''}
          disabled={!editLpuForm.isEdit}
          classNames={['edit-lpu-form__form-input']}
          value={editLpuForm.name}
          fullWidth
          onInput={controller.handleNameChange}
        />
        <AppAutocomplete
          error={editLpuForm.errors.city}
          classNames={['edit-lpu-form__form-input']}
          onInput={handleCityInput}
          options={editLpuForm.foundCities}
          //@ts-ignore
          onChange={controller.onCityChange}
          value={editLpuForm.address.placeName || ''}
          disabled={!editLpuForm.isEdit}
          label={editLpuForm.isEdit ? '' : 'Населенный пункт'}
          placeholder={editLpuForm.isEdit ? 'Населенный пункт' : ''}
        />
        {(editLpuForm.address?.district || editLpuForm.isEdit) && (
          <AppAutocomplete
            error={editLpuForm.errors.district}
            classNames={['edit-lpu-form__form-input']}
            // onInput={handleLocalityInput}
            options={editLpuForm.foundDistricts}
            onChange={controller.handleDistrictChange}
            value={editLpuForm.address.district || ''}
            disabled={!editLpuForm.isEdit}
            label={editLpuForm.isEdit ? '' : 'Район'}
            placeholder={editLpuForm.isEdit ? 'Район' : ''}
          />
        )}
        {/* <Input
          label={editLpuForm.isEdit ? '' : 'Адрес'}
          placeholder={editLpuForm.isEdit ? 'Город' : ''}
          disabled={!editLpuForm.isEdit}
          fullWidth
          classNames={['edit-lpu-form__city']}
          value={editLpuForm?.address?.placeName || ''}
          onInput={controller.handlePlaceChange}
        /> */}
        {/* <Input
          placeholder={editLpuForm.isEdit ? 'Район' : ''}
          disabled={!editLpuForm.isEdit}
          fullWidth
          classNames={['edit-lpu-form__district']}
          value={editLpuForm?.address?.district || ''}
          //@ts-ignore
          onInput={controller.handleDistrictChange}
        /> */}
        {(editLpuForm.address?.street || editLpuForm.isEdit) && (
          <Input
            placeholder={editLpuForm.isEdit ? 'Улица, номер дома' : ''}
            label={editLpuForm.isEdit ? '' : 'Улица, номер дома'}
            disabled={!editLpuForm.isEdit}
            fullWidth
            classNames={['edit-lpu-form__street']}
            value={editLpuForm?.address?.street || ''}
            onInput={controller.handleStreetChange}
          />
        )}
      </Form>
      {editLpuForm.isEdit && (
        <div className="edit-lpu-form__buttons">
          <Button
            className="edit-lpu-form__cancel"
            // onClick={props.onCloseModal}
            onClick={controller.handleCancelEditClick}
          >
            Отмена
          </Button>
          <Button
            className="edit-lpu-form__save"
            onClick={onSave}
            disabled={
              !!editLpuForm.errors.city || !!editLpuForm.errors.district
            }
          >
            Сохранить
          </Button>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import useAutocomplete, {
  AutocompleteGetTagProps,
} from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { classNamesParser } from '../../helpers/classNamesParser';
import { ICustomizedHookItem } from './customized-hook.interfaces';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import SearchButton from '../../ui/SearchButton';
import { Button, debounce, Menu, MenuItem } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
  background-color: #f3f3f3;
  border-radius: 6px;
`
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 'none';
  background-color: '#f3f3f3';
  border-radius: 6px;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  & input {
    background-color: #f3f3f3;
    color: ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.65)'
        : 'rgba(0,0,0,.85)'
    };
    height: 44px;
    box-sizing: border-box;
    padding: 4px 12px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 2px;
    outline: 0;
    border-radius: 6px;
    
    &::placeholder {
        font-family: 'Nunito', sans-serif;
        color: #BEBEBE;
        font-size: 16px;
    }
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  className?: string;
  options?: any[];
  handleTagClick?: (e: any, item: any) => void;
  id?: number;
  selectedOptions?: any[];
  handleOptionClick?: (id?: any, item?: any) => void;
  showSubMenu?: boolean;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    props.handleTagClick && props.handleTagClick(event, props.id);
  };
  const handleClose = (e?: any, item?: any, id?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleOptionClick = (e?: any, item?: any, id?: any) => {
    console.log(item);
    console.log(id);

    console.log(props.selectedOptions);

    if (
      props.selectedOptions?.length === 1 &&
      props.selectedOptions.includes(item)
    ) {
      console.log('close');
      handleClose(e);
    }

    props.handleOptionClick && props.handleOptionClick(id, item);
  };

  return (
    <div {...(other as any)} onClick={handleClick}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
        <span style={{ lineHeight: 1, marginBottom: 0 }}>{label}</span>
        {!!props.selectedOptions?.length && (
          <span style={{ fontSize: 12, color: 'grey', lineHeight: 1 }}>
            {`+${props.selectedOptions.length} района`}
          </span>
        )}
      </div>
      <CloseIcon
        onClick={(e: any) => {
          e.stopPropagation();
          onDelete(e);
        }}
        style={{ minWidth: 22, height: 22 }}
      />
      {props.showSubMenu && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            style: {
              paddingBottom: !!props.options?.length ? 48 : 8,
            },
          }}
        >
          {props.options?.length ? (
            props.options.map((item) => (
              <MenuItem
                key={item}
                onClick={(e: any) => handleOptionClick(e, item, props.id)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {item}
                {props.selectedOptions?.includes(item) && (
                  <DoneIcon style={{ width: 20, height: 20, marginLeft: 4 }} />
                )}
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={(e: any) => handleClose()}>
              Районы не найдены
            </MenuItem>
          )}
          {!!props.options?.length && (
            <Button
              style={{
                position: 'absolute',
                bottom: 4,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '95%',
                backgroundColor: '#263238',
              }}
              onClick={handleClose}
            >
              {`Выбрать${
                props.selectedOptions?.length
                  ? ' ' + props.selectedOptions.length
                  : ''
              }`}
            </Button>
          )}
        </Menu>
      )}
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 38px;
  max-height: 38px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 4px 4px 4px 10px;
  outline: 0;
  overflow: hidden;
  
  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const labelStyle: CSSProperties = {
  position: 'absolute',
  top: '-6px',
  left: '13px',
  padding: '0 4px',
  zIndex: 5,
  fontSize: '11px',
  backgroundColor: 'white',
  borderRadius: '4px',
  color: 'rgba(0, 0, 0, 0.38)',
  pointerEvents: 'none',
};

interface ICustomizedHookProps {
  options?: ICustomizedHookItem[] | any[];
  selectedOptions?: ICustomizedHookItem[] | any[];
  placeholder?: string;
  classNames?: string[];
  onChange?: (item: ICustomizedHookItem | any) => void;
  onDelete?: (item: ICustomizedHookItem | any) => void;
  onInput?: (event: any) => void;
  inputValue?: string;
  open?: boolean;
  showInputInList?: boolean;
  listWrapperStyles?: CSSProperties;
  handleTagClick?: (e: any, item: any) => void;
  subOptions?: any[];
  handleSubOptionClick?: (id?: any, item?: any) => void;
  showSubMenu?: boolean;
  showListAcceptButton?: boolean;
  onListAcceptButtonClick?: () => void;
  label?: string;
}
export default function CustomizedHook(props: ICustomizedHookProps) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    multiple: true,
    options: props.options as ICustomizedHookItem[],
    getOptionLabel: (option) => option.title,
    open: props.showInputInList !== undefined ? props.open : undefined,
  });
  const [scrollTop, setScrollTop] = useState(0);
  const [isScrolling, setScrolling] = useState(false);
  const listRef = useRef<any>(null);

  const updateScrolling = () => setScrolling(false);

  const handleUpdateScrollingState = useMemo(
    () => debounce(updateScrolling, 200),
    []
  );

  const handleListScroll = (e: React.UIEvent<HTMLElement>) => {
    setScrolling(true);
    setScrollTop(e.currentTarget.scrollTop);
    handleUpdateScrollingState();
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {props.label && <span style={labelStyle}>{props.label}</span>}
      <Root className={classNamesParser('customized-hook', props.classNames)}>
        <div {...getRootProps()} style={props.listWrapperStyles}>
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
            {props.selectedOptions &&
              props.selectedOptions.map(
                (option: ICustomizedHookItem, index: number) => (
                  <StyledTag
                    label={option.title}
                    {...getTagProps({ index })}
                    onDelete={() => props.onDelete && props.onDelete(option)}
                    handleTagClick={props.handleTagClick}
                    id={option.id}
                    options={props.subOptions}
                    handleOptionClick={props.handleSubOptionClick}
                    selectedOptions={option.subOptions}
                    showSubMenu={props.showSubMenu}
                    key={option.id}
                  />
                )
              )}
            <input
              {...getInputProps()}
              placeholder={props.placeholder}
              onInput={props.showInputInList ? () => {} : props.onInput}
              {...(props.inputValue !== undefined && !props.showInputInList
                ? { value: props.inputValue }
                : {})}
              // disabled={props.showInputInList}
              readOnly
              // onFocus={(e) => e.preventDefault()}
              style={{ pointerEvents: 'none' }}
              className="customized-hook__add-input"
            />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ||
        (props.showInputInList !== undefined && props.open) ? (
          <Listbox
            ref={listRef}
            onScroll={(e: any) => handleListScroll(e)}
            {...getListboxProps()}
            style={{
              padding: '8px',
              paddingBottom: props.showListAcceptButton ? 60 : 8,
              zIndex: 10,
            }}
          >
            {props.showInputInList && (
              <SearchButton
                placeholder={props.placeholder}
                onInput={props.onInput}
                {...(props.inputValue !== undefined
                  ? { value: props.inputValue }
                  : {})}
                open
                styles={{
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: props.options?.length ? '16px' : 0,
                }}
                inputStyles={{
                  backgroundColor: '#f3f3f3',
                }}
                iconType={'search'}
              />
            )}
            {props.options &&
              props.options.map((option: any, index: number) => (
                <li
                  {...getOptionProps({ option, index })}
                  onClick={() => props.onChange && props.onChange(option)}
                  aria-selected={
                    !!props.selectedOptions?.find(
                      (selectedOption) => selectedOption.id === option.id
                    )
                  }
                >
                  <span>{option.title}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            {isScrolling ||
              (props.showListAcceptButton ? (
                <Button
                  style={{
                    transition: 'none',
                    position: 'absolute',
                    top: 200 + scrollTop,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '95%',
                    backgroundColor: '#263238',
                  }}
                  onClick={props.onListAcceptButtonClick}
                >
                  {`Выбрать`}
                </Button>
              ) : null)}
          </Listbox>
        ) : null}
      </Root>
    </div>
  );
}

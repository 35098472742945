import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAppState {
  isLoading: boolean;
  showAppSnackBar: boolean;
  snackBarText: string;
  snackBarTimeout: number;
  snackBarSeverity: 'error' | 'warning' | 'info' | 'success' | string;
}

const initialState: IAppState = {
  isLoading: false,
  showAppSnackBar: false,
  snackBarText: '',
  snackBarTimeout: 3000,
  snackBarSeverity: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    showAppSnackBar: (
      state,
      action: PayloadAction<{
        text: string;
        snackBarTimeout?: number;
        snackBarSeverity?: 'error' | 'warning' | 'info' | 'success' | string;
      }>
    ) => {
      state.showAppSnackBar = true;
      state.snackBarText = action.payload.text;
      state.snackBarSeverity = action.payload.snackBarSeverity || '';
    },
    closeSnackBarWithDefaults: (state) => {
      state.showAppSnackBar = false;
      state.snackBarText = '';
      state.snackBarTimeout = 3000;
    },
  },
});

export const { showAppSnackBar, closeSnackBarWithDefaults } = appSlice.actions;

export const appReducer = appSlice.reducer;

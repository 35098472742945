import { IApiLpuItem } from '../../../interfaces/api.catalogs.interfaces';
import { ValueType } from '../types';

export const parseValue = (value: ValueType) => {
  if (typeof value === 'string') return value;
  if (Array.isArray(value)) {
    if ((value as (IApiLpuItem | string)[]).every((v) => typeof v === 'string'))
      return value.join(', ');
    else return value.map((v) => (v as IApiLpuItem).name).join(', ');
  }
  return typeof value === 'boolean'
    ? value
      ? 'Да'
      : 'Нет'
    : value === null
    ? 'Не указано'
    : value.name;
};

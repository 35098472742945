import React, { useEffect, useMemo } from 'react';
import { classNamesParser } from '../../../../helpers/classNamesParser';
import RoundButton from '../../../../ui/RoundButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, debounce } from '@mui/material';
import { Form } from 'react-router-dom';
import Input from '../../../../ui/Input';
import AppAutocomplete from '../../../../components/AppAutocomplete';
import { AddLpuFormController } from '../../add-lpu-form.controller';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { clearStore, setLocationError } from '../../store/add-lpu-form.slice';

interface IAddLpuFormProps {
  classNames?: string[];
  onCloseModal?: () => void;
}

export const AddLpuForm = (props: IAddLpuFormProps) => {
  const dispatch = useAppDispatch();
  const controller = new AddLpuFormController(dispatch);
  const addLpuForm = useAppSelector((state) => state.addLpuForm);

  const delayedFetchCities = useMemo(
    () => debounce(controller.fetchCities, 500),
    []
  );

  const handleLocalityInput = (event: any) => {
    controller.handleLocalityChange(event);
    delayedFetchCities();
  };

  useEffect(() => {
    controller.fetchCities();

    return () => {
      dispatch(clearStore());
    };
  }, []);

  return (
    <div className={classNamesParser('add-lpu-form', props.classNames)}>
      <div className="add-lpu-form__heading">
        <h3 className="add-lpu-form__title">Добавить ЛПУ</h3>
        <RoundButton
          classNames={['add-lpu-form__close-modal']}
          onClick={props.onCloseModal}
          content={<CloseIcon />}
        />
      </div>
      <Form style={{ marginBottom: 24 }}>
        <Input
          placeholder="Наименование"
          classNames={['add-lpu-form__form-input']}
          onInput={controller.handleNameChange}
          value={addLpuForm.name}
          fullWidth
        />
        <AppAutocomplete
          placeholder="Населенный пункт"
          classNames={['add-lpu-form__form-input']}
          onInput={handleLocalityInput}
          options={addLpuForm.foundLocalities}
          //@ts-ignore
          onChange={controller.onCityChange}
          error={addLpuForm.errors.location}
        />
        {addLpuForm.locality && (
          <>
            <AppAutocomplete
              placeholder="Район"
              options={addLpuForm.foundDistricts}
              onInput={controller.onDistrictInput}
              onChange={controller.onDistrictChange}
              classNames={['add-lpu-form__form-input']}
            />
            <Input
              placeholder="Улица, номер дома"
              onInput={controller.onStreetChange}
              value={addLpuForm.street}
              classNames={['add-lpu-form__form-input']}
            />
          </>
        )}
      </Form>
      <div className="add-lpu-form__buttons">
        <Button className="add-lpu-form__cancel" onClick={props.onCloseModal}>
          Отмена
        </Button>
        <Button
          className="add-lpu-form__save"
          onClick={controller.onSaveClick}
          disabled={!!addLpuForm.errors.location || !addLpuForm.locality}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

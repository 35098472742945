import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import HistoryReportRow from '../HistoryReportRow';
import { TableVirtuoso } from 'react-virtuoso';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { HistoryReportController } from '../../history-report.controller';
import FixedHeader from '../FixedHeader';

import HistoryReportTable from '../HistoryReportTable';

export const HistoryReport = () => {
  const historyReport = useAppSelector((state) => state.historyReport);
  const dispatch = useAppDispatch();
  const controller = new HistoryReportController(dispatch);

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      controller.fetchReport();
    }, 0);
  }, [controller.fetchReport]);

  useEffect(() => {
    const timeout = loadMore();
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    (async () => {
      controller.fetchSpecialties();
      controller.fetchLpuList();
      controller.fetchAgents();
    })();
  }, []);

  return (
    <TableVirtuoso
      endReached={loadMore}
      data={historyReport.list}
      overscan={200}
      fixedHeaderContent={FixedHeader}
      components={HistoryReportTable}
      itemContent={(index, data) => (
        <HistoryReportRow data={data} index={index} />
      )}
    />
  );
};

export const saveBase64AsFile = (filename: string, data: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.addEventListener('click', (e) => {
    setTimeout(() => window.URL.revokeObjectURL(a.href), 30 * 1000);
  });

  a.click();
};
